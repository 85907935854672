import React from 'react';
import { Route } from 'react-router-dom';
import { getCurrentUser } from '../../services/auth';
// import { userIsAdmin } from './../../services/auth';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => {
            if (!getCurrentUser())
               //return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
               window.location = `${process.env.PUBLIC_URL}/login`;
            return Component ? <Component {...props} /> : render(props);
         }}
      />
   );
};

export default ProtectedRoute;
