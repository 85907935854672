import React, { useEffect } from 'react';
import AuthorSubmenuButton from './author-submenu-button';
import MotionContainer from '../../common/motion-containers/motion-container';
import { submenu_animation } from './authors-submenu-animations';
import { userIsAdmin } from './../../../services/auth';
import MorphButton from '../../common/morph-button';
import { withRouter } from 'react-router-dom';

const thickness = 6;
const color = '#dd6e78';
const size = 75;

const AuthorsMenu = ({ history }) => {
   if (userIsAdmin()) history.push(`${process.env.PUBLIC_URL}/list-author`);

   return (
      <MotionContainer
         className='authors-menu'
         animationObject={submenu_animation}
      >
         {!userIsAdmin() && (
            <>
               <AuthorSubmenuButton
                  text='Dodaj autora'
                  path='add-author'
                  icon={
                     <MorphButton
                        type='crossSparks'
                        rolloverType='plus'
                        thickness={thickness}
                        color={color}
                        size={size}
                     />
                  }
               />
               <AuthorSubmenuButton
                  text='Dodaj grupu'
                  path='add-author-group'
                  icon={
                     <MorphButton
                        type='plusSparks'
                        rolloverType='plus'
                        thickness={thickness}
                        color={color}
                        size={size}
                     />
                  }
               />
            </>
         )}
         <AuthorSubmenuButton
            text='Pregled autora'
            path='list-author'
            icon={
               <MorphButton
                  type='bars'
                  rolloverType='arrowRight'
                  thickness={thickness}
                  color={color}
                  size={size}
               />
            }
         />
      </MotionContainer>
   );
};

export default withRouter(AuthorsMenu);
