import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import AddNewExhibitionCatalogue from './add-new-exhibition-catalogue';
import ButtonForm from './../common/btn-form';

const ExhibitionCatalogue = ({ onAddExhibitionCatalogue, defaultValue }) => {
   const [showAddCatalogue, setShowAddCatalogue] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   // const { cities } = useSelector((state) => state.author);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddExhibitionCatalogue(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewCatalogue = () => {
      setShowAddCatalogue(!showAddCatalogue);
   };

   const handle_OnCatalogueAdded = (catalogueData) => {
      setKolekcija([...kolekcija, catalogueData]);
   };

   const handle_RemoveFromCatalogueList = (id) => {
      setKolekcija(kolekcija.filter((catalogue) => catalogue.id !== id));
   };

   return (
      <>
         {showAddCatalogue && (
            <AddNewExhibitionCatalogue
               onCatalogueAdded={handle_OnCatalogueAdded}
               closeAddNewCatalogue={showHide_AddNewCatalogue}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               Katalozi
            </label>
            <ButtonForm
               text='Dodaj katalog'
               onClick={showHide_AddNewCatalogue}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih kataloga...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Autor</th>
                           <th>Naziv</th>
                           <th>Izdavač</th>
                           <th>Godina</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((catalogue) => (
                           <tr key={uuid()}>
                              {html.td(100, catalogue.Authors)}
                              {html.td(100, catalogue.Name)}
                              {html.td(100, catalogue.Publisher)}
                              {html.td(60, catalogue.Year)}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromCatalogueList,
                                 catalogue.id,
                                 {
                                    title: `Ukloni ${catalogue.Name}`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ExhibitionCatalogue;
