import React from 'react';
import Form from '../common/form';
import ShowTooltip from '../common/show-tooltip';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

/*
    ArtworkCreationYearId 
    Year
*/
class ArtworkCreationYearsComponent extends Form {
   state = {
      validationData: {},
      errors: {},
      creationYears: [],
      defaultValue: null
   };

   async componentDidMount() {
      const { defaultValue: creationYears } = this.props;
      this.setState({ creationYears });
   }

   render() {
      const { disabled } = this.props;
      const { creationYears, defaultValue } = this.state;

      return (
         <>
            <div className='row'>
               <div className='col'>
                  {this.renderDatePicker(
                     'Godina',
                     this.handleYearSelected,
                     {
                        picker: 'year',
                        placeholder: 'Odaberite godinu...',
                        format: 'YYYY',
                        value: defaultValue
                     },
                     true
                  )}
               </div>
            </div>
            {creationYears.length === 0 && (
               <p style={{ fontSize: '.7rem' }}>Nema odabranih godina...</p>
            )}
            {creationYears.length > 0 && (
               <div className='author-area-of-interests selected-options-grid'>
                  {creationYears.map((year) => (
                     <ShowTooltip
                        title={disabled ? '' : `Obriši ${year.Year}`}
                        placement='bottom'
                        tooltipColor='#f00'
                        visible={disabled}
                        key={uuid()}
                     >
                        <div
                           className='selected-option-style'
                           onClick={() => {
                              if (!disabled) this.handle_RemoveYear(year.Year);
                           }}
                        >
                           {year.Year}
                        </div>
                     </ShowTooltip>
                  ))}
               </div>
            )}
            <hr />
         </>
      );
   }

   handleYearSelected = (selectedYear) => {
      if (!selectedYear) return;

      const Year = Number(selectedYear.format('YYYY'));
      const { creationYears: stateYears } = this.state;

      const isAdded = stateYears.find((y) => y.Year === Year);
      if (isAdded) {
         toast.error(`Godina ${Year} je već odabrana!`);
         return;
      }

      const creationYears = [...this.state.creationYears, { Year }];
      this.setState({ creationYears });
      const { onYearsSelect } = this.props;
      onYearsSelect(creationYears);
   };

   handle_RemoveYear = (deleteYear) => {
      const { creationYears: stateYears } = this.state;
      const creationYears = stateYears.filter((y) => y.Year !== deleteYear);
      this.setState({ creationYears });
      const { onYearsSelect } = this.props;
      onYearsSelect(creationYears);
   };
}

export default ArtworkCreationYearsComponent;
