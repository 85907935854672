import React from 'react';
import { getCities, getCountries } from '../../services/api';
import { AddNewEntitySchema } from '../../validators/add-new-entity';
import Form from '../common/form';
import ShowTooltip from '../common/show-tooltip';
import Openstreet from '../openstreet/openstreet';
import PopupContainer from '../common/popup-container/popup-container';

class AddEntity extends Form {
    state = {
        validationData: {
            LegalEntityName: '',
            LegalEntityAddress: '',
            LegalEntityLatitude: '',
            LegalEntityLongitude: ''
        },
        restData: {
            LegalEntityCountryId: '',
            LegalEntityCityId: '',
            LegalEntityCityName: ''
        },
        errors: [],
        countries: [],
        cities: [],
        countriesLoaded: false,
        countrySelected: false,
        citySelected: false,
        addCityYourself: false
    };

    schema = AddNewEntitySchema;

    async componentDidMount() {
        const { data } = await getCountries();
        this.setState({ countries: data.data, countriesLoaded: true });
    }

    render() {
        const { countrySelected, cities, addCityYourself } = this.state;
        const {
            LegalEntityCountryId,
            LegalEntityCityId,
            LegalEntityCityName
        } = this.state.restData;
        const { LegalEntityAddress } = this.state.validationData;

        const disable =
            this.validateForm() ||
            LegalEntityCountryId === '' ||
            (LegalEntityCityId === '' && !addCityYourself) ||
            (LegalEntityCityName === '' && addCityYourself);

        return (
            <form
                onSubmit={this.handleFormSubmit}
                style={{ height: 0 }}
                onClick={this.handleExitClick}
            >
                <PopupContainer
                    title='Dodavanje institucije'
                    onPopupClose={this.props.showAddEntity}
                >
                    <div className='add-new-legal-entity-content'>
                        {this.renderSelectAnt(
                            'Država',
                            'Name',
                            'CountryId',
                            'Započnite unos ili odaberite državu...',
                            'Ne pronalazim traženu državu...',
                            this.state.countries,
                            this.handleCountrySelected
                        )}

                        {countrySelected && (
                            <div>
                                <div
                                    className={
                                        !addCityYourself
                                            ? 'row add-city-select-container'
                                            : 'row add-city-select-container hideAddCitySelect'
                                    }
                                >
                                    <div className='col-10'>
                                        {this.renderSelectAnt(
                                            'Grad',
                                            'Name',
                                            'CityId',
                                            'Započnite unos ili odaberite grad...',
                                            'Niste odabrali državu ili ne pronalazim traženi grad...',
                                            cities,
                                            this.handleCitySelected
                                        )}
                                    </div>

                                    <div
                                        className='col'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                            paddingBottom: '.75rem'
                                        }}
                                    >
                                        <ShowTooltip
                                            title='Ukoliko ne pronalazite grad među ponuđenim opcijama kliknite na gumb i samostalno unesite naziv grada'
                                            placement='bottom'
                                        >
                                            <button
                                                type='button'
                                                className='btn btn-secondary btn-sm'
                                                style={{
                                                    display: this.state
                                                        .countrySelected
                                                        ? 'block'
                                                        : 'none'
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const restData = {
                                                        ...this.state.restData
                                                    };
                                                    restData.LegalEntityCityId =
                                                        '';
                                                    restData.LegalEntityCityName =
                                                        '';
                                                    this.setState({
                                                        restData,
                                                        addCityYourself: true,
                                                        citySelected: true
                                                    });
                                                }}
                                            >
                                                Dodaj grad
                                            </button>
                                        </ShowTooltip>
                                    </div>
                                </div>

                                <div
                                    className={
                                        addCityYourself
                                            ? 'add-city-container addCityYourself'
                                            : 'add-city-container'
                                    }
                                >
                                    {this.renderInputRestData(
                                        'LegalEntityCityName',
                                        'Grad',
                                        this.handleNazivGradaChange,
                                        {
                                            placeholder:
                                                'Samostalno unesite naziv grada'
                                        }
                                    )}
                                </div>
                            </div>
                        )}

                        <div
                            className={
                                this.state.citySelected
                                    ? 'address showAddress'
                                    : 'address'
                            }
                        >
                            <div className='row'>
                                <div className='col'>
                                    {this.renderInput(
                                        'LegalEntityName',
                                        'Naziv institucije'
                                    )}
                                    {this.renderInputAntdesign(
                                        'LegalEntityAddress',
                                        'Adresa',
                                        {
                                            placeholder:
                                                'Možete unijeti i klikom na mapu...'
                                        }
                                    )}
                                </div>
                                <div className='col'>
                                    {this.renderInputAntdesign(
                                        'LegalEntityLatitude',
                                        'Zemljopisna širina',
                                        {
                                            placeholder:
                                                'Možete unijeti i klikom na mapu...'
                                        }
                                    )}
                                    {this.renderInputAntdesign(
                                        'LegalEntityLongitude',
                                        'Zemljopisna dužina',
                                        {
                                            placeholder:
                                                'Možete unijeti i klikom na mapu...'
                                        }
                                    )}
                                </div>
                            </div>

                            <Openstreet
                                onOpenstreetClick={this.handleOpenstreetClick}
                                center={`${LegalEntityCityName},${LegalEntityAddress}`}
                                disable={false}
                            />
                        </div>

                        {this.renderSubmitButton('Dodaj instituciju', disable)}
                    </div>
                </PopupContainer>
            </form>
        );
    }

    doSubmit = () => {
        const { handleAddNewLegalEntity } = this.props;
        const { validationData, restData } = this.state;
        handleAddNewLegalEntity({ ...restData, ...validationData });
    };

    handleExitClick = (e) => {
        e.stopPropagation();
        this.props.showAddEntity();
    };

    handleOpenstreetClick = ({ latlng, address }) => {
        //console.log('ADD', latlng, address);
        const validationData = { ...this.state.validationData };
        const restData = { ...this.state.restData };
        const { addCityYourself } = this.state;

        validationData.LegalEntityLatitude = latlng.lat;
        validationData.LegalEntityLongitude = latlng.lng;
        validationData.LegalEntityAddress = address.Address;
        if (addCityYourself) {
            //console.log('city', address.City, addCityYourself);
            restData.LegalEntityCityName = address.City;
        }
        //console.log('STATE', validationData, restData);
        this.setState({ validationData, restData });
    };

    handleGoogleMapsClick = (coordinates) => {
        //console.log('handleGoogleMapsClick', coordinates);
        const {
            lat: LegalEntityLatitude,
            lng: LegalEntityLongitude
        } = coordinates;

        const validationData = { ...this.state.validationData };
        validationData.LegalEntityLatitude = LegalEntityLatitude;
        validationData.LegalEntityLongitude = LegalEntityLongitude;

        this.setState({ validationData });
    };

    handleCountrySelected = async (value, obj) => {
        //console.log('handleCountrySelected', value);
        const { id: LegalEntityCountryId } = obj;
        const { data } = await getCities(LegalEntityCountryId);

        const restData = { ...this.state.restData };
        restData.LegalEntityCountryId = LegalEntityCountryId;

        this.setState({
            restData,
            cities: data.data,
            countrySelected: true
        });
        //console.log(`selected ${value}`, obj);
    };

    handleCitySelected = (value, obj) => {
        const { id: LegalEntityCityId, value: LegalEntityCityName } = obj;

        const restData = { ...this.state.restData };
        restData.LegalEntityCityId = LegalEntityCityId;
        restData.LegalEntityCityName = LegalEntityCityName;

        this.setState({ restData, citySelected: true });
        //console.log(`city ${LegalEntityCityId} - ${LegalEntityCityName}`, obj);
    };

    handleNazivGradaChange = ({ target: input }) => {
        const restData = { ...this.state.restData };
        const { errors } = this.state;

        restData.LegalEntityCityId = '';
        restData.LegalEntityCityName = input.value;

        if (input.value.trim() === '')
            errors[input.name] = 'Naziv grada je obavezan unos';
        else delete errors[input.name];

        this.setState({ restData, errors });
    };
}
export default AddEntity;
