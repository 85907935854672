import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import InputAntdesign from './input-ant';
import AutocompleteAntdesign from './autocomplete';
import SelectAnt from './select-ant';
import { notification, DatePicker } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import 'moment/locale/hr';
import locale from 'antd/es/date-picker/locale/hr_HR';
import Textarea from './textarea';
import html from '../../services/html';

class Form extends Component {
   state = {
      validationData: {},
      errors: {}
   };

   /*
      Validacija svih svojstava validationData objekta, cijele forme...
      onSubmit()
   */
   validateForm = () => {
      //validiraj
      const objectToValidate = this.state.validationData;
      const options = {
         abortEarly: false
      };
      const result = Joi.validate(objectToValidate, this.schema, options);

      //ako je sve ok...
      if (!result.error) return null;

      //dodijeli greške errors objektu
      const errors = {};
      for (const item of result.error.details) {
         errors[item.path[0]] = item.message;
      }

      return errors;
   };

   /*
    Validacija jednog od svojstava validationData objekta
 */
   validateProperty = ({ name, value }) => {
      //console.log('validateProperty', name, value);
      //validira se samo jedno svojstvo validationData objekta
      const objectToValidate = { [name]: value };
      //shemu uzimam sa postojece sheme za validationData objekt
      const schema = { [name]: this.schema[name] };

      const result = Joi.validate(objectToValidate, schema);
      return result.error ? result.error.details[0].message : null;
   };

   handleFormSubmit = (e) => {
      e.preventDefault();

      const errors = this.validateForm();
      //console.log('errors', errors);
      this.setState({ errors: errors || {} });

      this.doSubmit();
   };

   handleInputChange = ({ currentTarget: input, e, fn }) => {
      const errors = { ...this.state.errors };
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];

      const validationData = { ...this.state.validationData };
      validationData[input.id] = input.value;
      this.setState({ validationData, errors }, () => {
         if (fn !== null) fn(e);
      });
   };

   renderDatePicker = (
      label,
      onChange,
      rest,
      required,
      dateValidationMessage
   ) => {
      const style = {
         marginBottom: '2px',
         fontWeight: 700,
         fontSize: '.9rem'
      };
      return (
         <div className='form-group'>
            <span style={style} className='mr-1'>
               {label}
            </span>
            {required && html.renderRequiredTooltip()}
            <DatePicker
               format='DD.MM.YYYY'
               locale={locale}
               style={{ borderRadius: '.2rem', width: '100%' }}
               onChange={onChange}
               {...rest}
            />
            {dateValidationMessage && (
               <div className='alert alert-primary' style={{ fontWeight: 700 }}>
                  {dateValidationMessage}
               </div>
            )}
         </div>
      );
   };

   renderFormDatePicker = ({
      label,
      onChange,
      rest,
      required,
      dateValidationMessage
   }) => {
      const style = {
         marginBottom: '2px',
         fontWeight: 700,
         fontSize: '.9rem'
      };
      return (
         <div className='form-group'>
            <span style={style} className='mr-1'>
               {label}
            </span>
            {required && html.renderRequiredTooltip()}
            <DatePicker
               format='DD.MM.YYYY'
               locale={locale}
               style={{ borderRadius: '.2rem', width: '100%' }}
               onChange={onChange}
               {...rest}
            />
            {dateValidationMessage && (
               <div className='alert alert-primary' style={{ fontWeight: 700 }}>
                  {dateValidationMessage}
               </div>
            )}
         </div>
      );
   };

   renderRangePicker = (label, onChange, rest, required) => {
      const { RangePicker } = DatePicker;
      return (
         <div className='form-group'>
            <span className='label-style mr-1'>{label}</span>
            {required && html.renderRequiredTooltip()}
            <RangePicker
               format='DD.MM.YYYY'
               locale={locale}
               style={{ borderRadius: '.2rem', width: '100%' }}
               onChange={onChange}
               {...rest}
            />
         </div>
      );
   };

   renderSubmitButton = (label, disabled) => {
      return (
         <button
            className='btn btn-info btn-sm mr-1'
            style={{ fontWeight: 500 }}
            type='submit'
            disabled={disabled}
         >
            {label}
         </button>
      );
   };

   // fn - dodatni eventhandler koji se ispucava nakon state zapisa
   renderInput = (name, label, attributes, required, fn = null) => {
      const { validationData, errors } = this.state;
      return (
         <Input
            id={name}
            name={name}
            label={label}
            value={validationData[name]}
            error={errors[name]}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
            required={required}
            {...attributes}
         />
      );
   };

   renderFormInput = ({
      name,
      label,
      attributes,
      required,
      fn = null,
      disabled = false,
      placeholder
   }) => {
      const { validationData, errors } = this.state;
      return (
         <Input
            id={name}
            name={name}
            label={label}
            value={validationData[name]}
            error={errors[name]}
            disabled={disabled}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
            required={required}
            placeholder={placeholder}
            {...attributes}
         />
      );
   };

   // fn - dodatni eventhandler koji se ispucava nakon state zapisa
   renderTextArea = (name, label, attributes, requiredLabel, fn = null) => {
      const { validationData, errors } = this.state;
      return (
         <Textarea
            id={name}
            name={name}
            label={label}
            {...attributes}
            value={validationData[name]}
            error={errors[name]}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
            requiredLabel={requiredLabel}
         />
      );
   };

   renderFormTextArea = ({
      name,
      label,
      attributes,
      requiredLabel,
      disabled = false,
      placeholder,
      fn = null
   }) => {
      const { validationData, errors } = this.state;
      return (
         <Textarea
            id={name}
            name={name}
            label={label}
            value={validationData[name]}
            error={errors[name]}
            placeholder={placeholder}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
            requiredLabel={requiredLabel}
            disabled={disabled}
            {...attributes}
         />
      );
   };

   renderInputRestData = (name, label, onChange, attributes) => {
      const { restData, errors } = this.state;
      return (
         <Input
            id={name}
            name={name}
            label={label}
            {...attributes}
            value={restData[name]}
            error={errors[name]}
            onChange={onChange}
         />
      );
   };

   renderInputAntdesign = (name, label, attributes, required, fn = null) => {
      const { validationData, errors } = this.state;
      return (
         <InputAntdesign
            id={name}
            name={name}
            label={label}
            {...attributes}
            value={validationData[name]}
            error={errors[name]}
            onChange={(e) => {
               e.e = e;
               e.fn = fn;
               this.handleInputChange(e);
            }}
         />
      );
   };

   renderAutocomplete = (
      name,
      label,
      options,
      onSelect,
      onSearch,
      attributes
   ) => {
      const { autocompleteErrors } = this.state;
      return (
         <AutocompleteAntdesign
            id={name}
            name={name}
            label={label}
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            error={autocompleteErrors[name]}
            {...attributes}
         />
      );
   };

   renderSelectAnt = (
      label,
      text,
      value,
      placeholder,
      notFoundContent,
      selectOptions,
      onChange,
      onSearch,
      attributes,
      requiredLabel
   ) => {
      return (
         <SelectAnt
            label={label}
            text={text}
            value={value}
            placeholder={placeholder}
            notFoundContent={notFoundContent}
            selectOptions={selectOptions}
            onChange={onChange}
            onSearch={onSearch}
            attributes={attributes}
            requiredLabel={requiredLabel}
         />
      );
   };

   renderFormSelectAnt = ({
      label,
      text,
      value,
      placeholder = 'Započnite unos ili odaberite opciju...',
      notFoundContent = 'Ne pronalazim traženu vrijednost...',
      selectOptions,
      onChange,
      onSearch,
      attributes,
      required,
      disabled,
      defaultValue
   }) => {
      return (
         <SelectAnt
            label={label}
            text={text}
            value={value}
            placeholder={placeholder}
            notFoundContent={notFoundContent}
            selectOptions={selectOptions}
            onChange={onChange}
            onSearch={onSearch}
            attributes={attributes}
            requiredLabel={required}
            disabled={disabled}
         />
      );
   };

   showNotification = (message, description, icon, duration, onClose) => {
      notification.open({
         message,
         description,
         placement: 'bottomRight',
         className: 'notification-popup',
         duration: !duration ? 5 : duration,
         icon: !icon ? <WarningOutlined style={{ color: '#f00' }} /> : icon,
         onClick: () => {
            //console.log('Kliknuo si obavijest');
         },
         onClose
      });
   };
}

export default Form;
