import React, { useEffect } from 'react';
import { pageAnimation } from './page-transition-animation';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { forgotPasswordAnimation } from './animations/forgot-password-animation';
import ForgotPasswordChange from '../components/forgot-password/forgot-password-change';

const ForgotPasswordChangePage = (props) => {
    useEffect(() => {
        document.title = 'Promjena lozinke';
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MotionContainer
            className='register-page'
            animationObject={pageAnimation}
        >
            <div className='register-page-content'>
                <MotionContainer animationObject={forgotPasswordAnimation}>
                    <h1>
                        Promjena <span>lozinke</span>
                    </h1>
                </MotionContainer>
                <ForgotPasswordChange />
            </div>
        </MotionContainer>
    );
};

export default ForgotPasswordChangePage;
