import React from 'react';
import { Descriptions } from 'antd';
import { useHistory } from 'react-router-dom';

const AuthorGroupDescription = ({ data }) => {
   const { AuthorId, GroupName, GroupDescription, AreaOfInterests } = data;
   const history = useHistory();
   return (
      <div className='search-description'>
         <Descriptions
            title={
               <button
                  type='button'
                  style={{ textDecoration: 'none' }}
                  className='btn btn-link description-tearm'
                  onClick={() => {
                     history.push(
                        `${process.env.PUBLIC_URL}/details-author-group/${AuthorId}`
                     );
                  }}
               >{`${GroupName}`}</button>
            }
            bordered
            size='small'
         >
            <Descriptions.Item label='Opis' style={{ minWidth: '8rem' }}>
               {GroupDescription ? GroupDescription : '-'}
            </Descriptions.Item>
            <Descriptions.Item
               label='Područje interesa'
               contentStyle={{ width: '50%' }}
            >
               {AreaOfInterests ? AreaOfInterests : '-'}
            </Descriptions.Item>
         </Descriptions>
         <hr />
      </div>
   );
};

export default AuthorGroupDescription;
