import React from 'react';
import { getCountries } from '../../services/api';
import { AddNewLegalEntitySchema } from '../../validators/add-new-legal-entity';
import Form from './form';
import Openstreet from '../openstreet/openstreet';
import AddCity from './add-city';
import { createOrUpdateLegalEntity } from './../../services/api';
import { toast } from 'react-toastify';
import PopupContainer from './popup-container/popup-container';
import ButtonAdd from './btn-add';
import ButtonCancel from './btn-cancel';

class AddNewLegalEntity extends Form {
   state = {
      validationData: {
         Name: '',
         Address: '',
         GeoLatitude: '',
         GeoLongitude: ''
      },
      restData: {
         CountryId: '',
         CityId: '',
         CityName: ''
      },
      errors: [],
      countries: [],
      cities: [],
      countriesLoaded: false,
      countrySelected: false,
      citySelected: false,
      addCityYourself: false
   };

   schema = AddNewLegalEntitySchema;

   async componentDidMount() {
      const { data } = await getCountries();
      this.setState({ countries: data.data, countriesLoaded: true });
   }

   render() {
      const { countries, countrySelected, addCityYourself } = this.state;
      const { CountryId, CityId, CityName } = this.state.restData;
      const { Address } = this.state.validationData;
      const { closeAddNewLegalEntity } = this.props;

      const disabled =
         this.validateForm() ||
         CountryId === '' ||
         (CityId === '' && !addCityYourself) ||
         (CityName === '' && addCityYourself);

      return (
         <PopupContainer
            title='Dodavanje institucije'
            onPopupClose={closeAddNewLegalEntity}
         >
            <div className='add-new-legal-entity-content'>
               {this.renderSelectAnt(
                  'Država',
                  'Name',
                  'CountryId',
                  'Započnite unos ili odaberite državu...',
                  'Ne pronalazim traženu državu...',
                  countries,
                  this.handleCountrySelected
               )}

               <div
                  className={
                     this.state.countrySelected ? 'city showCity' : 'city'
                  }
               >
                  {countrySelected && (
                     <AddCity
                        countryId={CountryId}
                        onCitySelect={this.handleCitySelected}
                     />
                  )}
               </div>

               <div
                  className={
                     this.state.citySelected ? 'address showAddress' : 'address'
                  }
               >
                  <div className='row'>
                     <div className='col'>
                        {this.renderInput('Name', 'Naziv institucije')}
                        {this.renderInputAntdesign('Address', 'Adresa', {
                           placeholder: 'Možete unijeti i klikom na mapu...'
                        })}
                     </div>
                     <div className='col'>
                        {this.renderInputAntdesign(
                           'GeoLatitude',
                           'Zemljopisna širina',
                           {
                              placeholder: 'Možete unijeti i klikom na mapu...'
                           }
                        )}
                        {this.renderInputAntdesign(
                           'GeoLongitude',
                           'Zemljopisna dužina',
                           {
                              placeholder: 'Možete unijeti i klikom na mapu...'
                           }
                        )}
                     </div>
                  </div>

                  <Openstreet
                     onOpenstreetClick={this.handleOpenstreetClick}
                     center={`${CityName},${Address}`}
                     disable={false}
                  />

                  <ButtonAdd
                     disabled={disabled}
                     onClick={this.handleOnSubmit}
                  />
                  <ButtonCancel
                     onClick={() => {
                        this.props.closeAddNewLegalEntity();
                     }}
                  />
               </div>
            </div>
         </PopupContainer>
      );
   }

   handleOnSubmit = async () => {
      const { Name, Address, GeoLatitude, GeoLongitude } =
         this.state.validationData;

      const { CityId } = this.state.restData;

      const newLegalEntity = {
         CityId,
         Name,
         Address,
         GeoLatitude,
         GeoLongitude
      };
      const response = await createOrUpdateLegalEntity(newLegalEntity);

      const { status, message } = response.data;

      if (status === 1) {
         toast.info('Institucija uspješno dodana', {
            onClose: () => {
               //this.props.closeAddNewLegalEntity();
            }
         });
         this.props.closeAddNewLegalEntity();
      } else toast.error(`${message}`);
      console.log('response', response);
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewLegalEntity();
   };

   handleOpenstreetClick = (obj) => {
      if (!obj) return;

      const { latlng, address } = obj;
      const validationData = { ...this.state.validationData };
      const restData = { ...this.state.restData };
      const { addCityYourself } = this.state;

      validationData.GeoLatitude = latlng.lat;
      validationData.GeoLongitude = latlng.lng;
      validationData.Address = address.Address;

      if (addCityYourself) {
         restData.CityName = address.City;
      }

      this.setState({ validationData, restData });
   };

   handleCountrySelected = async (value, obj) => {
      const { id: CountryId } = obj;

      const restData = { ...this.state.restData };
      restData.CountryId = CountryId;

      this.setState({
         restData,
         countrySelected: true
      });
   };

   handleCitySelected = (value, obj) => {
      console.log('handleCitySelected');
      const { id: CityId, value: CityName } = obj;

      const restData = { ...this.state.restData };
      restData.CityId = CityId;
      restData.CityName = CityName;

      this.setState({ restData, citySelected: true });
      //console.log(`city ${CityId} - ${CityName}`, obj);
   };
}
export default AddNewLegalEntity;
