import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { homepageAnimation } from './animations/homepage-animation';
import { useDispatch, useSelector } from 'react-redux';
import html from '../services/html';
import { getCities } from '../services/api';
import { imgFallback } from './author-details-service';

import LoadingSpinner from '../components/common/loading-spinner';
import { detailsAnimation } from './animations/details-animation';
import { loadExhibitionStaticData } from './../store/exhibition';
import { isStaticDataLoaded } from './../services/created-exhibitions-page';
import { toast } from 'react-toastify';
import { loadExhibitionDetails } from '../store/common';
import { v4 as uuid } from 'uuid';
import { NavLink, useHistory } from 'react-router-dom';
import { Image } from 'antd';
import {
   ucitavamDodatnePodatke,
   ucitavamPodatke
} from './../services/toast-messages';
import ShowTooltip from '../components/common/show-tooltip';
import { hideToastProgressBar } from '../store/app-setup';
import HomepageLink from '../components/common/homepage-link';

const ExhibitionDetails = (props) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const { common, exhibition: exhibitionState } = useSelector(
      (state) => state
   );

   const {
      exhibitionDetails: exhibition,
      countries,
      redirectToErrorPage
   } = common;

   useEffect(() => {
      if (redirectToErrorPage)
         history.push(`${process.env.PUBLIC_URL}/error-page`);
   }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

   const {
      exhibitionTypeQuantity,
      exhibitionTypeKind,
      legalEntities,
      institutionType
   } = exhibitionState;

   const {
      LocationCountryId,
      LocationCityId,
      Name,
      NameEn,
      ExhibitionAuthors,
      ExhibitionTypeQuantityId,
      ExhibitionTypeKindId,
      Year,
      Curators,
      OrganizerLegalEntityId,
      InstitutionTypeId,
      LocationTypeId,
      LocationAddress,
      ExhibitionArtworks,
      ExhibitionCatalogues,
      ExhibitionMediaAnnouncements
   } = exhibition ? exhibition : {};

   const [cities, setCities] = useState(null);
   const [staticDataLoaded, setStaticDataLoaded] = useState(false);

   useEffect(() => {
      document.title = 'Detalji izložbe';
      const { id } = props.match.params;
      toast.info(ucitavamPodatke);
      dispatch(hideToastProgressBar(true));
      dispatch(loadExhibitionDetails(id)).then(() => {
         toast.dismiss();
         if (!isStaticDataLoaded(exhibitionState)) {
            toast(ucitavamDodatnePodatke);
            dispatch(loadExhibitionStaticData()).then(() => {
               setStaticDataLoaded(true);
               toast.dismiss();
            });
         } else setStaticDataLoaded(true);
      });

      return () => {
         dispatch(hideToastProgressBar(false));
         toast.dismiss();
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (LocationCountryId) getCountryCities(LocationCountryId);
   }, [LocationCountryId]);

   const getCountryCities = async (countryId) => {
      const { data } = await getCities(countryId);
      setCities(data.data);
   };

   const getCityName = () => {
      const cityName = cities
         ? html.getCityNameById(LocationCityId, cities)
         : '';
      return cityName;
   };

   const countryName = html.getCountryNameById(LocationCountryId, countries);
   const vrstaIzlozbe = html.getExhibitionTypeKindNameById(
      ExhibitionTypeKindId,
      exhibitionTypeKind
   );
   const tipIzlozbe = html.getExhibitionTypeNameById(
      ExhibitionTypeQuantityId,
      exhibitionTypeQuantity
   );
   const organizator = legalEntities
      ? html.getLegalEntityNameById(OrganizerLegalEntityId, legalEntities)
      : '';

   const vrstaInstitucije = institutionType
      ? html.getInstitutionTypeNameById(InstitutionTypeId, institutionType)
      : '-';

   return (
      <>
         {!staticDataLoaded && <LoadingSpinner />}
         {staticDataLoaded && (
            <MotionContainer
               className='exhibition-details'
               animationObject={homepageAnimation}
            >
               <div className='basic-information'>
                  <div className='pic'></div>
                  <div className='txt'>
                     <div className='name'>
                        <h1>{`${Name}`}</h1>
                     </div>
                  </div>
               </div>

               {staticDataLoaded && (
                  <MotionContainer
                     className='details'
                     animationObject={detailsAnimation}
                  >
                     <div className='info'>
                        <h2>Podaci o izložbi</h2>
                        <table className='table table-striped table-sm parent-table'>
                           <tbody>
                              <tr>
                                 <td className='align-middle'>Autori</td>
                                 <td className='align-middle'>
                                    {ExhibitionAuthors &&
                                       ExhibitionAuthors.map((a) => (
                                          <span
                                             style={{
                                                display: 'inline-block',
                                                marginRight: '1rem'
                                             }}
                                             key={uuid()}
                                          >
                                             {a.LinkAuthorId && (
                                                <NavLink
                                                   to={`${process.env.PUBLIC_URL}/details-author/${a.LinkAuthorId}`}
                                                >
                                                   {a.LinkAuthorOrGroupName
                                                      ? a.LinkAuthorOrGroupName
                                                      : a.JustTextAuthorOrGroupName}
                                                </NavLink>
                                             )}
                                             {!a.LinkAuthorId && (
                                                <span>
                                                   {a.LinkAuthorOrGroupName
                                                      ? a.LinkAuthorOrGroupName
                                                      : a.JustTextAuthorOrGroupName}
                                                </span>
                                             )}
                                          </span>
                                       ))}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Naziv na engleskom
                                 </td>
                                 <td className='align-middle'>{NameEn}</td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Tip izložbe</td>
                                 <td className='align-middle'>
                                    {tipIzlozbe ? tipIzlozbe : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Vrsta izložbe</td>
                                 <td className='align-middle'>
                                    {vrstaIzlozbe ? vrstaIzlozbe : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Godina održavanja
                                 </td>
                                 <td className='align-middle'>
                                    {Year ? Year : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Kustos(i)</td>
                                 <td className='align-middle'>
                                    {Curators ? Curators : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Organizator</td>
                                 <td className='align-middle'>
                                    {organizator ? organizator : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Vrsta institucije
                                 </td>
                                 <td className='align-middle'>
                                    {vrstaInstitucije ? vrstaInstitucije : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    {LocationTypeId === 1
                                       ? 'Adresa'
                                       : 'Lokacija'}
                                 </td>
                                 <td className='align-middle'>
                                    {LocationTypeId === 1
                                       ? `${LocationAddress}, ${getCityName()} - ${countryName}`
                                       : 'Online'}
                                 </td>
                              </tr>

                              <tr
                                 style={{
                                    backgroundColor: 'transparent'
                                 }}
                              >
                                 <td className='align-middle'>Katalozi</td>
                                 <td className='align-middle'>
                                    {ExhibitionCatalogues.length === 0 && (
                                       <span>-</span>
                                    )}
                                    {ExhibitionCatalogues &&
                                       ExhibitionCatalogues.map((e, i, arr) => (
                                          <div
                                             key={uuid()}
                                             style={{
                                                marginBottom:
                                                   i !== arr.length - 1
                                                      ? '1.5rem'
                                                      : '0'
                                             }}
                                          >
                                             <table
                                                id='catalogues'
                                                className='table-striped table-sm sub-table'
                                             >
                                                <tbody>
                                                   <tr>
                                                      <td>Naziv</td>
                                                      <td>
                                                         {e.Name ? e.Name : '-'}
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td>Izdavač</td>
                                                      <td>
                                                         {e.Publisher
                                                            ? e.Publisher
                                                            : '-'}
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td>Autori</td>
                                                      <td>
                                                         {e.Authors
                                                            ? e.Authors
                                                            : '-'}
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td>Grad</td>
                                                      <td>
                                                         {cities
                                                            ? html.getCityNameById(
                                                                 e.CityId,
                                                                 cities
                                                              )
                                                            : '-'}
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td>Godina</td>
                                                      <td>
                                                         {e.Year ? e.Year : '-'}
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                              <tr
                                 style={{
                                    backgroundColor: 'transparent'
                                 }}
                              >
                                 <td className='align-middle'>
                                    Medijske objave
                                 </td>
                                 <td className='align-middle'>
                                    {ExhibitionMediaAnnouncements.length ===
                                       0 && <span>-</span>}
                                    {ExhibitionMediaAnnouncements &&
                                       ExhibitionMediaAnnouncements.map(
                                          (e, i, arr) => (
                                             <div
                                                key={uuid()}
                                                style={{
                                                   marginBottom:
                                                      i !== arr.length - 1
                                                         ? '1.5rem'
                                                         : '0'
                                                }}
                                             >
                                                <table
                                                   id='media-anoncement'
                                                   className='table-striped table-sm sub-table'
                                                >
                                                   <tbody>
                                                      <tr>
                                                         <td>Naziv</td>
                                                         <td>
                                                            {e.Name
                                                               ? e.Name
                                                               : '-'}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>Izdanje</td>
                                                         <td>
                                                            {e.Edition
                                                               ? e.Edition
                                                               : '-'}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>Autori</td>
                                                         <td>
                                                            {e.Authors
                                                               ? e.Authors
                                                               : '-'}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>Grad</td>
                                                         <td>
                                                            {cities
                                                               ? html.getCityNameById(
                                                                    e.CityId,
                                                                    cities
                                                                 )
                                                               : '-'}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td>Godina</td>
                                                         <td>
                                                            {e.Year
                                                               ? e.Year
                                                               : '-'}
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </div>
                                          )
                                       )}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <hr />
                        <h2>Izložena djela</h2>
                        <hr />
                        {ExhibitionArtworks &&
                           ExhibitionArtworks.length === 0 && (
                              <p>Nema fotografija vezanih uz izložbu</p>
                           )}
                        {ExhibitionArtworks && ExhibitionArtworks.length > 0 && (
                           <div className='exhibition-photographs'>
                              {ExhibitionArtworks.map((p) => {
                                 if (p.LinkArtworkId) {
                                    return (
                                       <div
                                          className='image-container'
                                          key={uuid()}
                                       >
                                          <ShowTooltip
                                             title={`Prikaži detalje djela`}
                                          >
                                             <NavLink
                                                to={`${process.env.PUBLIC_URL}/details-artwork/${p.LinkArtworkId}`}
                                             >
                                                {p.LinkArtworkName}
                                             </NavLink>
                                          </ShowTooltip>
                                          <Image
                                             width={200}
                                             height={135}
                                             src={
                                                p.FirstPhotoFileContentBase64
                                                   ? p.FirstPhotoFileContentBase64
                                                   : 'error'
                                             }
                                             fallback={imgFallback}
                                          />
                                       </div>
                                    );
                                 } else {
                                    return (
                                       <span className='artwork-name'>
                                          {p.JustTextArtworkName}
                                       </span>
                                    );
                                 }
                              })}
                           </div>
                        )}
                     </div>
                     <HomepageLink />
                  </MotionContainer>
               )}
            </MotionContainer>
         )}
      </>
   );
};

export default ExhibitionDetails;
