export const registerHeaderAnimation = {
    hidden: {
        opacity: 0,
        y: -50
    },
    show: {
        opacity: 1,
        y: -100,
        transition: {
            duration: 0.5
        }
    },
    //što se dešava s komponentom kada se unmounta, kada se odlazi sa neke stranice
    exit: {
        opacity: 0,
        y: 30,
        transition: {
            duration: 0.1,
            ease: 'easeOut'
        }
    }
};
