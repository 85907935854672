import React, { useEffect, useState } from 'react';
import { pageAnimation } from './page-transition-animation';
import { authorAdd_StepsAnimation } from './animations/author-add-steps';
import { Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearAuthorGroupData,
    loadAuthorStaticData,
    setAuthorOrGroupEditAfterVerificationMode,
    setAuthorOrGroupEditMode
} from '../store/author';
import { toast } from 'react-toastify';
import { setToastAutoCloseInterval } from '../store/app-setup';
import { createAuthor, updateAuthor } from '../services/api';
import LoadingSpinner from '../components/common/loading-spinner';
import MotionContainer from '../components/common/motion-containers/motion-container';
import AuthorGroupBasicInformation from '../components/author-group/author-group-basic-information';
import AuthorGroupRestInformation from '../components/author-group/author-group-rest-information';
import ConfirmCreateAuthorGroup from './../components/author-group/confirm-create-author-group';
import AuthorGroupProfessionalActivityInformation from './../components/author-group/author-group-professional-activity-information';
import { useHistory } from 'react-router-dom';
import { updateVerifiedAuthor } from './../services/api';
import {
    authorGroupSuccess,
    authorGroupSuccessAfterEdit,
    authorGroupError,
    authorGroupErrorAfterEdit
} from './../services/confirm';
import { setConfirmData } from './../store/common';
import service from '../services/created-authors-page';
import { ucitavamDodatnePodatke } from './../services/toast-messages';

const AuthorGroupAddPage = (props) => {
    const { Step } = Steps;

    const [current, setCurrent] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);

    const {
        authorGroupData: authorGroupEditData,
        isEditMode,
        isEditAfterVerification,
        redirectToErrorPage
    } = useSelector((state) => state.author);

    let basicInformationData = {};
    let professionalInformationData = {};
    let restInformationData = {};

    if (authorGroupEditData) {
        basicInformationData = authorGroupEditData.basicInformation;
        professionalInformationData =
            authorGroupEditData.professionalGroupInformation;
        restInformationData = authorGroupEditData.restGroupInformation;
    }

    const [basicInformation, setBasicInformation] =
        useState(basicInformationData);
    const [professionalInformation, setProfessionalInformation] = useState(
        professionalInformationData
    );
    const [restInformation, setRestInformation] = useState(restInformationData);

    const [initRender, setInitRender] = useState(true);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => {
            dispatch(clearAuthorGroupData());
            dispatch(setAuthorOrGroupEditMode(false));
            dispatch(setAuthorOrGroupEditAfterVerificationMode(false));
            toast.dismiss();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (redirectToErrorPage)
            history.push(`${process.env.PUBLIC_URL}/error-page`);
        //window.location = `${process.env.PUBLIC_URL}/error-page`;
    }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const state = useSelector((state) => state.author);

    useEffect(() => {
        document.title = 'Dodavanje grupe autora';
        if (!service.isStaticDataLoaded(state)) {
            dispatch(setToastAutoCloseInterval(15000));
            toast(ucitavamDodatnePodatke);

            dispatch(loadAuthorStaticData()).then(() => {
                toast.dismiss();
                setDataLoaded(true);
                dispatch(setToastAutoCloseInterval(3000));
            });
        } else {
            setDataLoaded(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleBasicInformationSave = (data) => {
        setBasicInformation(data);
        // setCurrent(1);
    };

    const handleProfessionalInformationSave = (data) => {
        setProfessionalInformation(data);
        // setCurrent(2);
    };

    const handleRestInformationSave = (data) => {
        setRestInformation(data);
    };

    const allDataIsValid = () => {
        return (
            basicInformation.isValid &&
            professionalInformation.isValid &&
            restInformation.isValid
        );
    };

    const handleAuthorCreate = (data) => {
        setRestInformation(data);
        setCurrent(3);
    };
    const handleCancelAuthorCreate = () => {
        setCurrent(0);
    };

    const handleOnCreateAuthorGroupBtnClick = () => {
        sendDataToServer();
    };

    const sendDataToServer = async () => {
        const author = {
            ...basicInformation,
            ...professionalInformation,
            ...restInformation
        };

        author.AuthorTypeId = 2;
        if (isEditMode) author.AuthorId = authorGroupEditData.AuthorId;

        if (!allDataIsValid()) {
            toast.error('Nisu ispunjene sve informacije o grupi autora!');
            return;
        }

        console.log('Author', author);
        dispatch(setToastAutoCloseInterval(500));
        toast.info('Preusmjeravanje...');

        try {
            if (isEditMode) {
                const { data: response } = isEditAfterVerification
                    ? await updateVerifiedAuthor(author)
                    : await updateAuthor(author);
                handleAfterServerResponse(response);
            } else {
                const { data: response } = await createAuthor(author);
                handleAfterServerResponse(response);
            }
        } catch (error) {
            console.log('error', error);
            dispatch(setToastAutoCloseInterval(5000));
            toast.error(
                `${error.data.Message} - ${error.data.ExceptionMessage}`,
                {
                    onClose: () => {
                        history.push(`${process.env.PUBLIC_URL}/authors`);
                    }
                }
            );
        }
    };

    const handleAfterServerResponse = (response) => {
        if (response.status === 1) {
            if (isEditMode)
                dispatch(setConfirmData(authorGroupSuccessAfterEdit));
            else dispatch(setConfirmData(authorGroupSuccess));
        } else {
            if (isEditMode) {
                authorGroupErrorAfterEdit.subtitle = response.message;
                dispatch(setConfirmData(authorGroupErrorAfterEdit));
            } else {
                authorGroupError.subtitle = response.message;
                dispatch(setConfirmData(authorGroupError));
            }
        }
        history.push(`${process.env.PUBLIC_URL}/confirm`);
    };

    //stepIndex je index komponente koja je trenutno prikazana, na kojoj se kliknulo dalje>
    const handleOnNextClick = (stepIndex) => {
        //markiraj slijedeći korak kao kliknut
        if (!clickedSteps.includes(stepIndex + 1))
            setClickedSteps([...clickedSteps, stepIndex + 1]);

        setInitRender(false);
        setCurrent(stepIndex + 1);
    };

    //  Polje gdje pratim koji koraci su do sada bili kliknuti, inicijalno je prikazan prvi korak, zato index 0 u polju
    const [clickedSteps, setClickedSteps] = useState([0]);
    const onChange = (current) => {
        //markiraj kliknuti korak kao posjećen
        if (!clickedSteps.includes(current))
            setClickedSteps([...clickedSteps, current]);

        setInitRender(false);
        setCurrent(current);
    };

    /*
    Svaka komponenta vraca svojstvi [isValid] koje je istina ukolko su se ispunila obavezna polja
    Komponente gdje to nije napravljeno se markiraju kao nedovršene

    Inicijalni prikaz prikazuje sve komponente (osim prve, inicijalno prikazane) u stanju 'wait'
    Jednom kada se na neki korak klikne on je u stanju 'process'
    Kada se sa koraka makne, ako su obavezni podaci ispunjeni stanje ce biti 'finish' u protivnom 'error'
*/

    const isValidStepIndex = (stepIndex) => {
        let isValid = false;
        switch (stepIndex) {
            case 0:
                isValid = basicInformation.isValid;
                break;
            case 1:
                isValid = professionalInformation.isValid;
                break;
            case 2:
                isValid = restInformation.isValid;
                break;
            default:
                console.log('ERROR - isValidStepIndex(stepIndex)');
                break;
        }
        return isValid;
    };

    const getInitRenderStepStatus = (stepIndex) => {
        if (initRender && stepIndex === 0) return 'process';
        else return 'wait';
    };

    const getCurrentStepStatus = (stepIndex, currentStep) => {
        if (initRender) return getInitRenderStepStatus(stepIndex);

        if (!clickedSteps.includes(stepIndex)) return 'wait';

        return currentStep === stepIndex
            ? 'process'
            : isValidStepIndex(stepIndex)
            ? 'finish'
            : 'error';
    };

    return (
        <>
            {!dataLoaded && <LoadingSpinner text='' />}
            {dataLoaded && (
                <MotionContainer
                    className='author-group-add-page-container'
                    animationObject={pageAnimation}
                >
                    <div className='author-group-add-page-content'>
                        <div className='author-group-add-page'>
                            <MotionContainer
                                className='author-group-add-page-steps'
                                animationObject={authorAdd_StepsAnimation}
                            >
                                <Steps current={current} onChange={onChange}>
                                    <Step
                                        title='Osnovni podaci'
                                        description='Naziv skupine, Telefon, E-mail...'
                                        status={getCurrentStepStatus(
                                            0,
                                            current
                                        )}
                                    />
                                    <Step
                                        title='Profesionalno djelovanje'
                                        description='Područje interesa, nagrade, umjetnička djela...'
                                        status={getCurrentStepStatus(
                                            1,
                                            current
                                        )}
                                    />
                                    <Step
                                        title='Spremi podatke'
                                        description='Fotografija, napomena...'
                                        status={getCurrentStepStatus(
                                            2,
                                            current
                                        )}
                                    />
                                </Steps>
                            </MotionContainer>
                        </div>
                        <div>
                            {current === 0 && (
                                <AuthorGroupBasicInformation
                                    data={basicInformation}
                                    onSubmit={handleBasicInformationSave}
                                    onNextClick={() => {
                                        handleOnNextClick(current);
                                    }}
                                />
                            )}
                            {current === 1 && (
                                <AuthorGroupProfessionalActivityInformation
                                    data={professionalInformation}
                                    onSubmit={handleProfessionalInformationSave}
                                    onNextClick={() => {
                                        handleOnNextClick(current);
                                    }}
                                />
                            )}
                            {current === 2 && (
                                <AuthorGroupRestInformation
                                    data={restInformation}
                                    onSubmit={handleRestInformationSave}
                                    onCreateAuthor={handleAuthorCreate}
                                />
                            )}
                            {current === 3 && (
                                <ConfirmCreateAuthorGroup
                                    allDataIsValid={allDataIsValid()}
                                    data={basicInformation}
                                    onCreate={handleOnCreateAuthorGroupBtnClick}
                                    onCancelCreate={handleCancelAuthorCreate}
                                />
                            )}
                        </div>
                    </div>
                </MotionContainer>
            )}
        </>
    );
};

export default AuthorGroupAddPage;
