import React from 'react';
import { v4 as uuid } from 'uuid';
import MotionContainer from './motion-containers/motion-container';

const Errors = (props) => {
   const errors = localStorage.getObject('errors') ?? [];
   console.log('errors', errors);
   return (
      <div className='errors' style={{ backgroundColor: 'white' }}>
         {errors.length === 0 && <h3>Nema evidentiranih grešaka</h3>}
         {errors.length > 0 && (
            <MotionContainer>
               <table className='table table-sm table-striped'>
                  <thead className='header-dark'>
                     <tr style={{ backgroundColor: 'orange', fontWeight: 700 }}>
                        <td>Date - Time</td>
                        <td>Status</td>
                        <td>Status text</td>
                        <td>Error</td>
                        <td>URL</td>
                     </tr>
                  </thead>
                  <tbody>
                     {errors.map((e) => (
                        <tr key={uuid()}>
                           <td>{e.time}</td>
                           <td>{e.error.status}</td>
                           <td>{e.error.statusText}</td>
                           <td>{e.error.data.Message}</td>
                           <td>
                              <a href={`${e.error.config.url}`}>
                                 {e.error.config.url}
                              </a>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </MotionContainer>
         )}
      </div>
   );
};

export default Errors;
