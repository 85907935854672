import React from 'react';
import html from '../../services/html';
import MotionContainer from '../common/motion-containers/motion-container';
import { userIsAdmin } from '../../services/auth';
import { artworkAnim } from './artwork-anim';
import VerificationIndicator from './../common/verification-indicator';
import DataTable from './../common/table/datatable';

const TableCreatedArtworkList = ({
   artworks: allArtworks,
   onEdit,
   onDelete,
   onToVerification,
   enableEdit
}) => {
   const getBtnVerificirajText = (author) =>
      author.IsVerified ? 'Verificiran' : 'Verificiraj';

   const columns = [
      {
         sortable: true,
         label: 'Naziv',
         path: 'Name'
      },
      {
         sortable: true,
         label: 'Opis',
         path: 'Description'
      },
      {
         sortable: true,
         label: 'Dimenzije',
         path: 'Dimensions'
      },
      {
         key: 'Remark',
         label: 'Napomena',
         style: { textAlign: 'left' },
         content: (item) => <span> {item.Remark ? item.Remark : '-'}</span>
      },
      {
         sortable: true,
         label: 'Kreirao',
         path: 'AddedByUser'
      },
      {
         key: 'AddedDate',
         label: 'Vrijeme kreiranja',
         style: { textAlign: 'left' },
         content: (item) => <span> {html.convertDateTime(item.AddedDate)}</span>
      },
      {
         key: 'btn1',
         label: 'Verificirano',
         style: { textAlign: 'center' },
         content: (item) => (
            <>
               {!userIsAdmin() && (
                  <button
                     id='toVerification'
                     className='btn btn-sm btn-primary mr-1'
                     disabled={
                        item.IsReadyForVerification ||
                        (!item.IsReadyForReverification && item.IsVerified)
                     }
                     onClick={() => {
                        onToVerification(item.ArtworkId, item.Name);
                     }}
                  >
                     {getBtnVerificirajText(item)}
                  </button>
               )}
               <VerificationIndicator item={item} />
            </>
         )
      },
      {
         key: 'btn2',
         label: '',
         style: { textAlign: 'center' },
         content: (item) => (
            <>
               {!userIsAdmin() && !item.IsReadyForVerification && (
                  <button
                     id='edit'
                     className='btn btn-sm btn-secondary mr-1'
                     disabled={enableEdit}
                     onClick={() => {
                        onEdit(item.ArtworkId, item.IsVerified);
                     }}
                  >
                     Pregledaj
                  </button>
               )}
            </>
         )
      },
      {
         key: 'btn3',
         label: '',
         style: { textAlign: 'center' },
         content: (item) => (
            <>
               {!userIsAdmin() &&
                  !item.IsReadyForVerification &&
                  !item.IsVerified && (
                     <button
                        id='delete'
                        className='btn btn-sm btn-danger'
                        onClick={() => {
                           onDelete(item, false);
                        }}
                     >
                        Obriši
                     </button>
                  )}
               {userIsAdmin() && (
                  <button
                     id='edit'
                     className='btn btn-sm btn-secondary mr-1'
                     disabled={enableEdit}
                     onClick={() => {
                        onEdit(item.ArtworkId, item.IsVerified);
                     }}
                  >
                     Uredi
                  </button>
               )}
            </>
         )
      }
   ];

   return (
      <MotionContainer
         className='table-created-author-list'
         animationObject={artworkAnim}
      >
         <div>
            <h2>Pregled kreiranih umjetničkih djela</h2>

            <DataTable
               pageSize={10}
               columns={columns}
               data={allArtworks}
               hideSearch={false}
               searchLabel='Pretraži umjetnička djela'
               searchLabelPlaceholder='Započni pretragu...'
               searchColumns={['Name', 'NameEn', 'Year']}
               readonly={false}
               onRowClick={(item) => console.log(item)}
               markRowOnClick={false}
            />
         </div>
      </MotionContainer>
   );
};

export default TableCreatedArtworkList;
