import React from 'react';
import Form from '../common/form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPasswordSchema } from './forgot-password-validation';
import { sendEmailToForgotPassword } from '../../services/api';
import { toast } from 'react-toastify';
import {
   hideToastProgressBar,
   setToastAutoCloseInterval
} from '../../store/app-setup';

class ForgotPassword extends Form {
   state = {
      validationData: {
         email: ''
      },
      errors: {}
   };

   schema = forgotPasswordSchema;

   render() {
      return (
         <>
            <div className='register'>
               <form onSubmit={this.handleFormSubmit}>
                  {this.renderFormInput({
                     label: 'E-mail',
                     name: 'email',
                     placeholder:
                        'Unesite e-mail adresu na koju će Vam se poslati link za promjenu lozinke'
                  })}

                  <hr />
                  {this.renderSubmitButton(
                     'Pošalji link za promjenu lozinke',
                     this.validateForm()
                  )}
               </form>
            </div>
         </>
      );
   }

   doSubmit = async () => {
      const { email } = this.state.validationData;
      const { hideToastProgressBar, setToastAutoCloseInterval } = this.props;
      const { data: response } = await sendEmailToForgotPassword(email);
      console.log('response', response);
      const { status, message } = response;

      //  ako email ne postoji...
      if (status !== 1) {
         hideToastProgressBar(false);
         toast.error(message);
         return;
      }

      hideToastProgressBar(false);
      setToastAutoCloseInterval(1500);
      toast.info('Poslan vam je email...', {
         onClose: () => {
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
         }
      });
   };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
   hideToastProgressBar: (hide) => dispatch(hideToastProgressBar(hide)),
   setToastAutoCloseInterval: (interval) =>
      dispatch(setToastAutoCloseInterval(interval))
});

export default withRouter(
   connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
