import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import html from '../../services/html';

const ExhibitionCurators = ({ onExhibitionCuratorsChange, defaultValue }) => {
   const [IsCurators, setIsCurators] = useState(defaultValue.IsCurators);
   const [Curators, setCurators] = useState(defaultValue.Curators);
   const [error, setError] = useState(null);

   useEffect(() => {
      onExhibitionCuratorsChange({ IsCurators, Curators });
   }, [IsCurators, Curators]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleCheckboxChecked = ({ target: input }) => {
      if (input.checked) {
         setIsCurators(true);
      } else {
         setIsCurators(false);
         setCurators('');
      }
   };

   const handleOnInputChange = ({ target: input }) => {
      setCurators(input.value);
      if (input.value.trim() === '') setError('Kustos je obavezan unos');
      else setError('');
   };

   return (
      <div className='exhibition-curators'>
         <div style={{ marginBottom: '.5rem' }}>
            <Checkbox
               onChange={handleCheckboxChecked}
               checked={defaultValue.IsCurators}
            >
               Stavite kvačicu ako postoji kustos
            </Checkbox>
         </div>
         {IsCurators && (
            <div>
               {html.renderSFCInput(
                  'Kustos(i)',
                  'Curators',
                  error,
                  handleOnInputChange,
                  {
                     placeholder: 'Unesite kustosa...',
                     value: Curators
                  },
                  true
               )}
            </div>
         )}
      </div>
   );
};

export default ExhibitionCurators;
