import React from 'react';
import { registerNewUser } from '../../services/auth';
import { checkOib } from '../../utils/oib';
import { registerNewUserSchema } from '../../validators/register-new-user';
import { Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import AddEntity from './add-entity';
import Form from '../common/form';
import { getAllLegalEntities, getLegalEntities } from './../../services/api';
import { withRouter } from 'react-router-dom';
import ShowTooltip from './../common/show-tooltip';
import { toast } from 'react-toastify';
import { loadLegalEnteties } from './../../store/author';
import { connect } from 'react-redux';

class Register extends Form {
   state = {
      validationData: {
         FirstName: '',
         LastName: '',
         Oib: '',
         Email: '',
         Phone: '',
         Password: ''
      },
      restData: {
         Password_confirm: '',
         LegalEntityId: '',
         LegalEntityCountryId: '',
         LegalEntityCityId: '',
         LegalEntityCityName: '',
         LegalEntityLatitude: '',
         LegalEntityLongitude: '',
         LegalEntityName: '',
         LegalEntityAddress: ''
      },
      errors: {},
      legalEntities: [],
      isAffiliated: false,
      showAddEntity: false,
      entityAdded: false
   };

   schema = registerNewUserSchema;

   async componentDidMount() {
      try {
         const { data: response } = await getAllLegalEntities();
         const legalEntities = response.data;
         this.setState({ legalEntities });
      } catch (error) {
         console.log('error', error);
      }
   }

   render() {
      const {
         showAddEntity,
         legalEntities,
         isAffiliated,
         passwordsMatch,
         entityAdded
      } = this.state;
      const {
         LegalEntityId,
         LegalEntityName,
         LegalEntityAddress,
         LegalEntityCityName
      } = this.state.restData;

      const validacijaFormeFAIL = this.validateForm();
      const validacijaAfiliacijeFAIL = isAffiliated && LegalEntityId === '';
      const disable =
         validacijaFormeFAIL || validacijaAfiliacijeFAIL || !passwordsMatch;

      return (
         <>
            {showAddEntity && (
               <AddEntity
                  showAddEntity={this.showAddEntity}
                  handleAddNewLegalEntity={this.handleAddNewLegalEntity}
               />
            )}
            <div className='register'>
               <form onSubmit={this.handleFormSubmit}>
                  {this.renderFormInput({
                     label: 'Ime',
                     name: 'FirstName'
                  })}

                  {this.renderFormInput({
                     label: 'Prezime',
                     name: 'LastName'
                  })}

                  {this.renderFormInput({
                     label: 'OIB',
                     name: 'Oib',
                     placeholder: 'Jedanaesteroznamenkasti broj...',
                     attributes: { maxLength: 11 }
                  })}

                  {this.renderFormInput({
                     label: 'E-mail',
                     name: 'Email'
                  })}

                  {this.renderFormInput({
                     label: 'Telefon',
                     name: 'Phone'
                  })}

                  {this.renderFormInput({
                     label: 'Lozinka',
                     name: 'Password',
                     attributes: {
                        type: 'password'
                     }
                  })}

                  {this.renderInputRestData(
                     'Password_confirm',
                     'Ponovite lozinku',
                     ({ target: input }) => {
                        const { Password: password } =
                           this.state.validationData;
                        const { errors } = this.state;

                        const restData = { ...this.state.restData };
                        restData[input.name] = input.value;
                        const passwordsMatch =
                           restData[input.name] === password;

                        if (!passwordsMatch)
                           errors[input.name] = 'Lozinke se moraju podudarati';
                        else delete errors[input.name];

                        this.setState({ restData, passwordsMatch });
                     },
                     {
                        type: 'password'
                     }
                  )}

                  <div
                     className={`${
                        this.state.isAffiliated
                           ? 'affiliated is-affiliated'
                           : 'affiliated'
                     } ${LegalEntityCityName !== '' ? 'entity-added' : ''}`}
                  >
                     <hr />

                     <div
                        className='row'
                        style={{
                           display: entityAdded ? 'none' : 'flex'
                        }}
                     >
                        <div className='col-8'>
                           {!entityAdded &&
                              this.renderSelectAnt(
                                 'Institucija',
                                 'Name',
                                 'LegalEntityId',
                                 'Započnite unos naziva institucije...',
                                 'Ne pronalazim traženu instituciju...',
                                 legalEntities,
                                 this.handleLegalEntitiesSelected,
                                 this.handleLegalEntitySearch
                              )}
                        </div>

                        <div
                           className='col'
                           style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-end',
                              paddingBottom: '.75rem'
                           }}
                        >
                           <ShowTooltip
                              title='Ukoliko ne pronalazite instituciju među ponuđenim kliknite na gumb i samostalno unesite novu instituciju'
                              placement='bottom'
                           >
                              <button
                                 type='button'
                                 className='btn btn-secondary btn-sm'
                                 onClick={() => {
                                    this.showAddEntity();
                                 }}
                              >
                                 Dodaj instituciju
                              </button>
                           </ShowTooltip>
                        </div>
                     </div>
                  </div>
                  {this.renderIsAffiliated()}
                  {entityAdded && (
                     <p
                        style={{
                           display: 'flex',
                           alignItems: 'center'
                        }}
                     >
                        <span
                           style={{
                              fontWeight: 700,
                              fontSize: '1.1rem'
                           }}
                        >{`${LegalEntityName.toUpperCase()} `}</span>
                        <span
                           style={{
                              fontWeight: 400,
                              fontSize: '.9rem'
                           }}
                        >
                           {`- ${LegalEntityAddress} - ${LegalEntityCityName}`}
                        </span>
                     </p>
                  )}
                  <hr />
                  {this.renderSubmitButton('Pošalji', disable)}
               </form>
            </div>
         </>
      );
   }

   handleAfilijacijaSelected = (isAffiliated, AffiliationLegalEntityId) => {
      const restData = { ...this.state.restData };
      restData.IsAffiliated = isAffiliated;
      restData.AffiliationLegalEntityId = AffiliationLegalEntityId;
      this.setState({ restData });
   };

   handleLegalEntitiesSelected = (value, obj) => {
      const restData = { ...this.state.restData };
      const { id: LegalEntityId } = obj;
      restData.LegalEntityId = LegalEntityId;
      this.setState({ restData });
   };

   handleLegalEntitySearch = async (searchTerm) => {
      const { data } = await getLegalEntities(searchTerm);
      const legalEntities = data.data;
      this.setState({ legalEntities });
      // console.log('searchterm', searchTerm, data.data);
   };

   oibNotValid = (oib) => {
      const errors = { ...this.state.errors };
      if (!checkOib(oib)) {
         errors['Oib'] = 'Neispravan OIB';
         this.setState({ errors });
         return errors;
      } else return null;
   };

   btnIsAffiliated = React.createRef();

   renderIsAffiliated = () => {
      let { isAffiliated, entityAdded } = this.state;
      if (entityAdded) return null;
      return (
         <div className='form-group'>
            <Button
               ref={this.btnIsAffiliated}
               type='default'
               style={{ borderRadius: '.25rem' }}
               size='middle'
               icon={
                  isAffiliated ? (
                     <CheckCircleOutlined style={{ verticalAlign: 0 }} />
                  ) : (
                     <CloseCircleOutlined style={{ verticalAlign: 0 }} />
                  )
               }
               onClick={this.handleIsAfiliated}
            >
               Afilijacija
            </Button>
         </div>
      );
   };

   doSubmit = () => {
      const { Oib } = this.state.validationData;
      if (this.oibNotValid(Oib)) return;

      this.registerUser();
   };

   registerUser = async () => {
      const { validationData, restData, isAffiliated } = this.state;
      const user = { ...validationData, ...restData, isAffiliated };
      try {
         const { data } = await registerNewUser(user);
         const { status } = data;

         if (status === 1)
            this.props.history.push(
               `${process.env.PUBLIC_URL}/register-success`
            );
         else toast.error(`${data.message}`);
      } catch (ex) {
         toast.warning(
            `Greška ${ex.status} - ${ex.statusText} - ${ex.data.Message}`
         );
      }
   };

   handleIsAfiliated = () => {
      let { isAffiliated } = this.state;
      isAffiliated = !isAffiliated;
      this.setState({ isAffiliated });
      this.btnIsAffiliated.current.blur();
   };

   showAddEntity = () => {
      let { showAddEntity } = this.state;
      showAddEntity = !showAddEntity;
      this.setState({ showAddEntity });
   };

   handleAddNewLegalEntity = (restData) => {
      //[legalEntities] opcija firme na register formi koju moram obrisati
      //obzirom da je dodana nova firma koja nije bila ponuđena kao opcija
      this.setState({
         restData,
         showAddEntity: false,
         entityAdded: true
      });
   };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
   loadLegalEntities: () => dispatch(loadLegalEnteties())
   // add: (article) => dispatch(addArticle(article)),
   // remove: (id) => dispatch(removeArticle(id))
});

export default withRouter(
   connect(mapStateToProps, mapDispatchToProps)(Register)
);
