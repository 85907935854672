import Joi from 'joi-browser';

export const registerNewUserSchema = {
    FirstName: Joi.string()
        .required()
        .label('Ime')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezno`
            };
        }),
    LastName: Joi.string()
        .required()
        .label('Prezime')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezno`
            };
        }),
    Oib: Joi.string()
        .length(11)
        .regex(/^\d+$/)
        .required()
        .label('Oib')
        .error((errors) => {
            //console.log(errors);
            if (errors[0].type === 'string.length')
                return {
                    message: `${errors[0].flags.label} mora sadržavati 11 znameki`
                };
            if (errors[0].type === 'string.regex.base')
                return {
                    message: `${errors[0].flags.label} mora sadržavati samo znamenke`
                };
            return {
                message: `${errors[0].flags.label} je obavezan.`
            };
        }),
    Email: Joi.string()
        .email()
        .required()
        .label('E-mail')
        .error((errors) => {
            if (errors[0].type === 'any.empty')
                return {
                    message: `${errors[0].flags.label} je obavezan`
                };
            if (errors[0].type === 'string.email')
                return {
                    message: `Neispravna ${errors[0].flags.label} adresa`
                };
            return {
                message: `${errors[0].flags.label} - greška.`
            };
        }),
    Phone: Joi.string().label('Telefon').allow('').optional(),
    Password: Joi.string()
        .required()
        .label('Lozinka')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezna`
            };
        }),
    bla: Joi.any().optional()
};

//bla: Joi.any().optional(),
