import React from 'react';
import Form from '../../common/form';
import AddLegalEntity from '../../common/add-legal-entity';
import { v4 as uuid } from 'uuid';
import PopupContainer from '../../common/popup-container/popup-container';
import { connect } from 'react-redux';
import html from '../../../services/html';
import { getCities } from '../../../services/api';
import AddCity from '../../common/add-city';
import { Checkbox } from 'antd';
import { AddNewSpecializationTypeSchema } from '../../../validators/add-new-specialization-type';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

/*
    props
        selectedSpecializationType
        closeAuthorSpecializationType()
        onAuthorSpecializationTypeAdded()
*/

class AddSpecializationType extends Form {
   state = {
      validationData: {
         FinancialSupportSource: ''
      },
      restData: {
         AuthorSpecializationTypeId: null,
         SpecializationTypeId: null,
         DateFrom: '',
         DateTo: '',
         LegalEntityId: null
      },
      selectedSpecializationType: this.props.selectedSpecializationType,
      countrySelected: false,
      countryId: null,
      FinancialSupportCityId: null,
      OwnFinancing: true,
      cities: [],
      errors: []
   };

   schema = AddNewSpecializationTypeSchema;

   async componentDidMount() {
      const { restData, selectedSpecializationType } = this.state;
      restData.SpecializationTypeId =
         selectedSpecializationType.SpecializationTypeId;
      this.setState({
         restData
      });
   }

   handleCountrySelected = async (value, obj) => {
      const { id: countryId } = obj;
      const { data } = await getCities(countryId);

      this.setState({
         cities: data.data,
         countrySelected: true,
         countryId,
         FinancialSupportCityId: 0
      });
   };

   handleCitySelected = (value, obj) => {
      const { id: FinancialSupportCityId } = obj;

      this.setState({ FinancialSupportCityId });
   };

   handleCheckboxChecked = ({ target: input }) => {
      if (input.checked) {
         this.setState({ OwnFinancing: true });
      } else {
         this.setState({ OwnFinancing: false });
      }
      this.setState({ customExhibitionNameEnter: input.checked });
   };

   handleAddClick = () => {
      const { onAuthorSpecializationTypeAdded, closeAuthorSpecializationType } =
         this.props;
      const { FinancialSupportCityId, OwnFinancing } = this.state;
      const { FinancialSupportSource } = this.state.validationData;
      const data = { ...this.state.restData };
      //dodatne informacije za lakše renderiranje
      data.id = uuid();

      data.FinancialSupportSource = FinancialSupportSource;
      data.FinancialSupportCityId = FinancialSupportCityId;
      data.OwnFinancing = OwnFinancing;

      data.selectedSpecializationType = this.state.selectedSpecializationType;
      onAuthorSpecializationTypeAdded(data);
      closeAuthorSpecializationType();
   };

   render() {
      const { countries } = this.props.commonState;
      const { DateFrom, LegalEntityId } = this.state.restData;
      const {
         selectedSpecializationType,
         countryId,
         FinancialSupportCityId,
         countrySelected,
         OwnFinancing
      } = this.state;
      const { FinancialSupportSource } = this.state.validationData;
      const { closeAuthorSpecializationType } = this.props;

      const ownFinancingIsValid = OwnFinancing
         ? true
         : FinancialSupportCityId > 0 && FinancialSupportSource !== '';

      const disabled =
         DateFrom === '' || !LegalEntityId || !ownFinancingIsValid;

      const countries_defaultValue = countryId
         ? countries.length > 0
            ? {
                 value: html.getCountryNameById(countryId, countries)
              }
            : null
         : null;

      return (
         <PopupContainer
            title={selectedSpecializationType.Name}
            onPopupClose={closeAuthorSpecializationType}
         >
            <div className='row'>
               <div className='col'>
                  {this.renderDatePicker('Datum od', this.handleDateFrom)}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Datum do', this.handleDateTo)}
               </div>
            </div>

            <AddLegalEntity
               onLegalEntitySelect={this.handleLegalEntitySelected}
            />

            <div className='mb-2'>
               <Checkbox
                  onChange={this.handleCheckboxChecked}
                  checked={OwnFinancing}
               >
                  Vlastito financiranje
               </Checkbox>
            </div>

            {!OwnFinancing && (
               <>
                  {this.renderInput(
                     'FinancialSupportSource',
                     'Izvor financijske potpore',
                     null,
                     true
                  )}

                  {this.renderSelectAnt(
                     'Država',
                     'Name',
                     'CountryId',
                     'Započnite unos ili odaberite državu...',
                     'Ne pronalazim traženu državu...',
                     countries,
                     this.handleCountrySelected,
                     null,
                     countries_defaultValue,
                     true
                  )}
                  {countrySelected && (
                     <AddCity
                        label='Grad'
                        countryId={countryId}
                        onCitySelect={this.handleCitySelected}
                        required={true}
                        defaultCityId={
                           FinancialSupportCityId === 0
                              ? null
                              : FinancialSupportCityId
                        }
                     />
                  )}
               </>
            )}
            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleAddClick} />
               <ButtonCancel
                  onClick={() => {
                     this.props.closeAuthorSpecializationType();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleLegalEntitySelected = (value, obj) => {
      const { id: LegalEntityId, value: LegalEntityName } = obj;

      const selectedSpecializationType = {
         ...this.state.selectedSpecializationType
      };
      selectedSpecializationType.LegalEntityName = LegalEntityName;
      const { restData } = this.state;
      restData.LegalEntityId = LegalEntityId;
      this.setState({ restData, selectedSpecializationType });
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAuthorSpecializationType();
   };

   handleDateFrom = (e) => {
      const { restData } = this.state;
      restData.DateFrom = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };

   handleDateTo = (e) => {
      const { restData } = this.state;
      restData.DateTo = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   commonState: state.common,
   authorState: state.author,
   personalInformation: state.author.personalInformation
});

const mapDispatchToProps = (dispatch) => ({
   // add: (article) => dispatch(addArticle(article)),
   // remove: (id) => dispatch(removeArticle(id))
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddSpecializationType);
