import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configure-store';

import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();

ReactDOM.render(
   <Provider store={store}>
      {/* <React.StrictMode> */}
      <HashRouter>
         <App />
      </HashRouter>
      {/* </React.StrictMode> */}
   </Provider>,
   document.getElementById('root')
);

//  https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually?page=1&tab=votes#tab-top

/*
   Workaround za pohranjivanje polja i objekata u localStorage
*/
Storage.prototype.setObject = function (key, value) {
   this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
   var value = this.getItem(key);
   return value && JSON.parse(value);
};
