import React from 'react';
import { motion } from 'framer-motion';

const defaultAnimation = {
    hidden: {
        opacity: 0,
        x: 50
    },
    show: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.25,
            staggerChildren: 0.75,
            when: 'beforeChildren'
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut'
        }
    }
};

const MotionContainer = ({
    className,
    children,
    animationObject,
    style,
    rest
}) => {
    return (
        <motion.div
            className={className}
            style={style ? style : {}}
            variants={animationObject ? animationObject : defaultAnimation}
            initial='hidden'
            animate='show'
            exit='exit'
            {...rest}
        >
            {children}
        </motion.div>
    );
};

export default MotionContainer;
