import React from 'react';
import Form from '../common/form';
import PopupContainer from '../common/popup-container/popup-container';
import html from '../../services/html';
import { connect } from 'react-redux';
import { AddNewArtworkExhibitionSchema } from './../../validators/add-new-artwork-exhibition';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';

class AddNewArtworkExhibition extends Form {
   state = {
      validationData: {
         ExhibitionName: ''
      },
      restData: {
         ExhibitionId: null
      },
      exhibitions: [],
      customExhibitionNameEnter: false,
      errors: []
   };

   schema = AddNewArtworkExhibitionSchema;

   render() {
      const { customExhibitionNameEnter } = this.state;

      const { exhibitions, closeAddNewArtworkExhibition } = this.props;

      const { ExhibitionName } = this.state.validationData;
      const { ExhibitionId } = this.state.restData;

      const nijeUnesenaIzlozba =
         ExhibitionId === null && ExhibitionName.trim() === '';

      const disabled = nijeUnesenaIzlozba;

      return (
         <PopupContainer
            title='Odabir/unos izložbe umjetničkog djela'
            onPopupClose={closeAddNewArtworkExhibition}
         >
            {!customExhibitionNameEnter &&
               html.renderSelectAnt(
                  'Odaberi izložbu',
                  'Name',
                  'ExhibitionId',
                  'Započnite unos ili odaberite izložbu...',
                  'Ne pronalazim traženu izložbu...',
                  exhibitions,
                  this.handleExhibitionSelect
               )}

            {customExhibitionNameEnter && (
               <div>
                  {this.renderInput('ExhibitionName', 'Naziv izložbe', {
                     style: { height: '35px' },
                     placeholder: 'Unesite naziv izložbe...'
                  })}
               </div>
            )}

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewArtworkExhibition();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewArtworkExhibition();
   };

   handleOnSubmit = () => {
      const { onArtworkExibitionAdded, closeAddNewArtworkExhibition } =
         this.props;
      const { ExhibitionName } = this.state.validationData;
      const { ExhibitionId } = this.state.restData;

      if (ExhibitionName) onArtworkExibitionAdded(ExhibitionName);
      else onArtworkExibitionAdded(ExhibitionId);

      closeAddNewArtworkExhibition();
   };

   handleExhibitionSelect = (selectedText, valueObj) => {
      const restData = {};
      restData.ExhibitionId = valueObj.id;
      this.setState({ restData });
   };

   handleCheckboxChecked = ({ target: input }) => {
      if (input.checked) {
         const restData = {};
         restData.ExhibitionId = null;
         this.setState({ restData });
      } else {
         const validationData = {};
         validationData.ExhibitionName = '';
         this.setState({ validationData });
      }
      this.setState({ customExhibitionNameEnter: input.checked });
   };
}

const mapStateToProps = (state) => ({
   exhibitions: state.exhibition.exhibitions
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewArtworkExhibition);
