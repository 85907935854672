import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = (props) => {
    return (
        <NavLink
            to={`${process.env.PUBLIC_URL}/homepage`}
            style={{ fontSize: '1.2rem' }}
        >
            &lt; Početna stranica
        </NavLink>
    );
};

export default NotFound;
