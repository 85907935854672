import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
const swallSpeed = 1500;

const ConfirmCreateArtwork = ({
    data,
    allDataIsValid,
    onCreate,
    onCancelCreate
}) => {
    const { isEditMode } = useSelector((state) => state.artwork);
    const artworkName = `${data.Name}`;

    const title = isEditMode
        ? 'Ažurirati umjetničko djelo?'
        : 'Kreirati umjetničko djelo?';

    const titleOK = isEditMode ? 'AŽURIRANJE PODATAKA' : 'SPREMANJE PODATAKA';
    const textOK = isEditMode
        ? `${artworkName} će biti ažuriran!`
        : `${artworkName} će biti spremljen!`;

    const textCANCEL = isEditMode
        ? `${artworkName} nije ažuriran`
        : `${artworkName} nije spremljen`;

    // const history = useHistory();
    useEffect(() => {
        if (!allDataIsValid) {
            swal({
                title: `Nisu ispunjeni svi podaci`,
                text: `Ažurirajte podatke.`,
                icon: 'error',
                timer: 2500,
                buttons: {}
            }).then(() => {
                onCancelCreate();
            });
        } else {
            swal({
                html: true,
                title,
                text: artworkName,
                icon: 'info',
                buttons: {
                    remove: {
                        text: 'Potvrdi',
                        value: 'confirm'
                    },
                    cancel: 'Odustani'
                }
            }).then((value) => {
                switch (value) {
                    case 'confirm':
                        swal({
                            title: titleOK,
                            text: textOK,
                            icon: 'success',
                            timer: swallSpeed,
                            buttons: {}
                        }).then(() => {
                            onCreate();
                        });
                        break;
                    default:
                        swal({
                            title: `Odustajanje`,
                            text: textCANCEL,
                            icon: 'error',
                            timer: swallSpeed,
                            buttons: {}
                        }).then(() => {
                            onCancelCreate();
                        });
                }
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
};

export default ConfirmCreateArtwork;
