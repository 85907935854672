import { getListConfirmed, rejectUser } from '../services/api';

const initState = {
    users: [],
    usersLoaded: false
};

export const loadListConfirmed = (data) => async (dispatch, getState) => {
    try {
        const { data } = await getListConfirmed();
        const payload = {
            users: data.users,
            usersLoaded: true
        };
        dispatch({ type: 'GET_LIST_CONFIRMED', payload });
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log('EX data', ex.response?.data);
        }
    }
};

export const rejecConfirmtUser = (user) => async (dispatch, getState) => {
    try {
        const { data: response } = await rejectUser(user);
        console.log('result', response.status);
        console.log('user', user);
        if (response.status === 1) {
            const payload = { user };
            dispatch({ type: 'REJECT_USER_FROM_LIST_CONFIRMED', payload });
        }
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log('EX data', ex.response?.data);
        }
    }
};

//  REDUCER
export default function reducer(state = initState, action) {
    switch (action.type) {
        case 'GET_LIST_CONFIRMED':
            const users = [...action.payload.users];
            return { users, usersLoaded: action.payload.usersLoaded };
        case 'REJECT_USER_FROM_LIST_CONFIRMED':
            const filteredUsers = [...state.users].filter(
                (user) => user.UserId !== action.payload.user.UserId
            );
            return { users: filteredUsers };
        default:
            return state;
    }
}

//  ACTION CREATOR - async demo
// export const xyz = (data) => async (dispatch, getState) => {
//     try {
//        const { data: registeredUserData } = await axios.post(
//           api.registerUser,
//           user
//        );
//        const payload = {
//           user: registeredUserData
//        };
//        dispatch({ type: 'REGISTER_USER', payload });
//     } catch (ex) {
//        if (ex.response && ex.response.status === 400) {
//           console.log('EX data', ex.response?.data);
//        }
//     }
//  };

//https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
