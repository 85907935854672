import Joi from 'joi-browser';

export const AddAddressSchema = {
   Address: Joi.any()
      .optional()
      .label('Adresa')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   GeoLatitude: Joi.any()
      .optional()
      .label('Latitude')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   GeoLongitude: Joi.any()
      .optional()
      .label('Latitude')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      })
};
