import Joi from 'joi-browser';

export const authorGroupBasicInformationSchema = {
    GroupName: Joi.string()
        .required()
        .label('Naziv skupine')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    GroupDescription: Joi.string()
        .required()
        .label('Kratki opis skupine')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    ContactEmail: Joi.string()
        .email()
        .required()
        .label('E-mail')
        .error((errors) => {
            if (errors[0].type === 'any.empty')
                return {
                    message: `${errors[0].flags.label} je obavezan`
                };
            if (errors[0].type === 'string.email')
                return {
                    message: `Neispravno ${errors[0].flags.label}`
                };
            return {
                message: `${errors[0].flags.label} - greška.`
            };
        }),
    ContactPhone: Joi.any().optional().label('Telefon'),
    PersonalOrGroupUrl: Joi.any().optional().label('URL skupine'),
    SocialNetworks: Joi.any().optional().label('Društvene mreže skupine'),
    GroupDateOfCreation: Joi.any().optional().label('Društvene mreže skupine')
};
