import React from 'react';
import { authorContactInformationSchema } from '../../../validators/author-contact-information';
import Form from '../../common/form';
import MotionContainer from '../../common/motion-containers/motion-container';
import { userIsAdmin } from './../../../services/auth';
import { connect } from 'react-redux';
import ButtonNext from './../../common/btn-next';

class AuthorContactInformation extends Form {
   constructor(props) {
      super(props);

      const { ContactEmail, ContactPhone, PersonalOrGroupUrl, SocialNetworks } =
         props.data;

      this.ContactEmail = ContactEmail;
      this.ContactPhone = ContactPhone;
      this.PersonalOrGroupUrl = PersonalOrGroupUrl;
      this.SocialNetworks = SocialNetworks;

      this.state = {
         validationData: {
            ContactEmail: this.ContactEmail ?? '',
            ContactPhone: this.ContactPhone ?? '',
            PersonalOrGroupUrl: this.PersonalOrGroupUrl ?? '',
            SocialNetworks: this.SocialNetworks ?? ''
         },
         errors: {}
      };
   }

   schema = authorContactInformationSchema;

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const { isEditAfterVerification } = this.props.authorState;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      return (
         <>
            <MotionContainer className='author-contact-information'>
               <form onSubmit={this.handleFormSubmit} className='form-style'>
                  <h2>Kontakt podaci</h2>

                  {this.renderFormInput({
                     label: 'Telefon',
                     name: 'ContactPhone',
                     disabled: isUserEditAfterVerification
                  })}

                  {this.renderFormInput({
                     label: 'E-mail',
                     name: 'ContactEmail',
                     disabled: isUserEditAfterVerification,
                     required: true
                  })}

                  {this.renderFormInput({
                     label: 'URL',
                     name: 'PersonalOrGroupUrl',
                     disabled: isUserEditAfterVerification
                  })}

                  {this.renderFormInput({
                     label: 'Društvene mreže',
                     name: 'SocialNetworks',
                     disabled: isUserEditAfterVerification
                  })}

                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(1);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const { validationData: data } = this.state;
      data.isValid = !this.validateForm();

      this.props.onSubmit(data);
   };
}

const mapStateToProps = (state) => ({
   authorState: state.author
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorContactInformation);
