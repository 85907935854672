import React from 'react';
import { motion } from 'framer-motion';
import logo from '../resources/img/logo.png';

const Footer = (props) => {
    return (
        <motion.div
            className='footer'
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.25 }}
        >
            <div className='left'>
                <div className='copy'>&copy; Akademija likovnih umjetnosti</div>
                <div className='line'></div>
                <div className='address'>
                    <address>
                        <p>Sveučilište u Zagrebu</p>
                        <p>Akademija likovnih umjetnosti</p>
                        <p>Ilica 85, Zagreb</p>
                    </address>
                    <address>
                        <p>Tel. +385 1 3777 300</p>
                        {/* <p>Fax. +385 1 3711 440</p> */}
                        <p>
                            E-mail.{' '}
                            <a href='mailto:alu@alu.unizg.hr'>
                                alu@alu.unizg.hr
                            </a>{' '}
                        </p>
                    </address>
                </div>
            </div>

            <div className='center'>
                <img src={logo} alt='Logo' />
            </div>

            <div className='right'>
                Projekt provedbe HKO a na razini visokog obrazovanja “Akademija
                u hodu” (03.1.1.03.0033) Akademije likovnih umjetnosti,
                Sveučilišta u Zagrebu je sufinancirala Europska unija iz
                Europskog Socijalnog fonda (2019. 2022.).
            </div>
        </motion.div>
    );
};

export default Footer;
