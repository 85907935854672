export const exhibition_submenu_animation = {
   hidden: {
      opacity: 0,
      y: 50
   },
   show: {
      opacity: 1,
      y: 0,
      transition: {
         duration: 0.3,
         staggerChildren: 0.75,
         when: 'beforeChildren'
      }
   },
   exit: {
      opacity: 0,
      transition: {
         duration: 0.2,
         ease: 'easeOut'
      }
   }
};
