const initState = {
    toastAutoCloseInterval: 5000,
    hideProgressBar: true
};

//ACTION CREATOR
export const setToastAutoCloseInterval = (toastAutoCloseInterval) => ({
    type: 'SET_TOAST_AUTOCLOSE_INTERVAL',
    payload: {
        toastAutoCloseInterval
    }
});

export const hideToastProgressBar = (hideProgressBar) => ({
    type: 'SET_TOAST_HIDE_PROGRESS_BAR',
    payload: {
        hideProgressBar
    }
});

//  REDUCER
export default function reducer(state = initState, action) {
    switch (action.type) {
        case 'SET_TOAST_HIDE_PROGRESS_BAR':
            const { hideProgressBar } = action.payload;
            return {
                ...state,
                toastAutoCloseInterval: hideProgressBar ? 100000 : 5000,
                hideProgressBar
            };
        case 'SET_TOAST_AUTOCLOSE_INTERVAL':
            const { toastAutoCloseInterval } = action.payload;
            return { ...state, toastAutoCloseInterval };
        default:
            return state;
    }
}
