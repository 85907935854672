import React, { useState, useEffect } from 'react';
import AddAuthorThesis from './add-author-thesis';
import { v4 as uuid } from 'uuid';
import html from '../../../services/html';
import ButtonForm from './../../common/btn-form';

const AuthorThesis = ({ onAddAuthorThesis, defaultValue }) => {
   const [showAddAuthorThesis, setShowAddAuthorThesis] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddAuthorThesis(kolekcija);
   }, [kolekcija, onAddAuthorThesis]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHideAuthorThesis = () => {
      setShowAddAuthorThesis(!showAddAuthorThesis);
   };

   const handleAddAuthorThesis = () => {
      showHideAuthorThesis();
   };

   const handleOnAuthorThesisAdded = (authorThesisData) => {
      console.log('authorThesisData', authorThesisData);
      const arr = [...kolekcija];
      arr.push(authorThesisData);
      setKolekcija(arr);
   };

   const handleRemoveFromAuthorThesis = (id) => {
      setKolekcija(kolekcija.filter((thesis) => thesis.id !== id));
   };

   return (
      <div>
         {showAddAuthorThesis && (
            <AddAuthorThesis
               closeAuthorThesis={showHideAuthorThesis}
               onAuthorThesisAdded={handleOnAuthorThesisAdded}
            />
         )}
         <div className='row'>
            <div className='col'>
               <ButtonForm
                  text='Dodaj diplomski/poslijediplomski rad'
                  onClick={handleAddAuthorThesis}
               />
            </div>
         </div>

         <div className='list-container'>
            {kolekcija.length > 0 && (
               <table className='table table-sm table-striped'>
                  <thead className='thead-dark'>
                     <tr>
                        <th>Naslov</th>
                        <th>Mentor</th>
                        <th>Godina</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     {kolekcija.map((thesis) => (
                        <tr key={uuid()}>
                           {html.td(200, thesis.Title)}
                           {html.td(200, thesis.Mentor)}
                           {html.td(60, thesis.YearOfGraduation)}

                           {html.tdWithRemoveBtn(
                              handleRemoveFromAuthorThesis,
                              thesis.id,
                              {
                                 title: `Ukloni ${thesis.Title} sa liste`
                              }
                           )}
                        </tr>
                     ))}
                  </tbody>
               </table>
            )}
         </div>
      </div>
   );
};

export default AuthorThesis;
