import React from 'react';
import { authorGroupBasicInformationSchema } from '../../validators/author-group-basic-information';
import Form from '../common/form';
import MotionContainer from '../common/motion-containers/motion-container';
import html from '../../services/html';
import moment from 'moment';
import { connect } from 'react-redux';
import { userIsAdmin } from './../../services/auth';
import ButtonNext from './../common/btn-next';
import AntDatepicker from './../common/ant-datepicker';

class AuthorGroupBasicInformation extends Form {
   constructor(props) {
      super(props);

      const {
         GroupName,
         GroupDateOfCreation,
         ContactPhone,
         ContactEmail,
         PersonalOrGroupUrl,
         SocialNetworks,
         GroupDescription
      } = props.data;

      this.GroupName = GroupName;
      this.GroupDateOfCreation = GroupDateOfCreation;
      this.ContactPhone = ContactPhone;
      this.ContactEmail = ContactEmail;
      this.PersonalOrGroupUrl = PersonalOrGroupUrl;
      this.SocialNetworks = SocialNetworks;
      this.GroupDescription = GroupDescription;

      this.state = {
         validationData: {
            GroupName: this.GroupName ?? '',
            ContactEmail: this.ContactEmail ?? '',
            ContactPhone: this.ContactPhone ?? '',
            PersonalOrGroupUrl: this.PersonalOrGroupUrl ?? '',
            SocialNetworks: this.SocialNetworks ?? '',
            GroupDescription: this.GroupDescription ?? ''
         },
         restData: {
            GroupDateOfCreation: this.GroupDateOfCreation ?? ''
         },
         errors: {}
      };
   }

   schema = authorGroupBasicInformationSchema;

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const { isEditAfterVerification } = this.props.authorState;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      const { GroupDateOfCreation } = this.state.restData;

      return (
         <>
            <MotionContainer clasgdame='author-group-basic-information'>
               <form className='form-style'>
                  <h2>Osnovni podaci</h2>

                  {this.renderFormInput({
                     label: 'Naziv skupine',
                     name: 'GroupName',
                     required: true
                  })}

                  <AntDatepicker
                     label='Datum nastanka'
                     requiredLabel={true}
                     defaultDate={
                        GroupDateOfCreation ? moment(GroupDateOfCreation) : null
                     }
                     onChange={this.handleDatePickerChange}
                  />

                  {this.renderFormInput({
                     label: 'Telefon',
                     name: 'ContactPhone',
                     disabled: isUserEditAfterVerification
                  })}

                  {this.renderFormInput({
                     label: 'Email',
                     name: 'ContactEmail',
                     disabled: isUserEditAfterVerification,
                     required: true
                  })}

                  {this.renderFormInput({
                     label: 'URL',
                     name: 'PersonalOrGroupUrl',
                     disabled: isUserEditAfterVerification
                  })}

                  {this.renderFormInput({
                     label: 'Društvene mreže',
                     name: 'SocialNetworks',
                     disabled: isUserEditAfterVerification
                  })}

                  {this.renderFormTextArea({
                     label: 'Kratki opis skupine',
                     name: 'GroupDescription',
                     disabled: isUserEditAfterVerification,
                     placeholder: 'Maksimalno 150 riječi...',
                     attributes: { style: { height: '6rem' } },
                     requiredLabel: true
                  })}

                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(1);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const { validationData: data } = this.state;
      const { GroupDateOfCreation } = this.state.restData;
      data.GroupDateOfCreation = GroupDateOfCreation;

      data.isValid = !this.validateForm() && GroupDateOfCreation !== '';

      this.props.onSubmit(data);
   };

   handleDatePickerChange = (e) => {
      const restData = { ...this.state.restData };
      restData.GroupDateOfCreation = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   authorState: state.author
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorGroupBasicInformation);
