import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import {
   deleteExhibition,
   getCreatedExhibitions,
   reverifyExhibition
} from '../services/api';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/common/loading-spinner';
import swal from 'sweetalert';
import { animation } from './animations/created-authors-page-animation';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToastAutoCloseInterval } from '../store/app-setup';
import service from '../services/created-exhibitions-page';
import { setExhibitionEditAfterVerificationMode } from '../store/exhibition';
import {
   loadExhibitionStaticData,
   loadExhibitionData,
   setExhibitionEditMode
} from './../store/exhibition';
import TableCreatedExhibitionsList from '../components/exhibition/table-created-exhibitions-list';
import {
   uredjivanjeCeBitiOmoguceno,
   ucitavamPodatke
} from './../services/toast-messages';
import {
   markExhibitionAsReadyForVerification,
   markExhibitionAsVerified
} from './../services/api';
import NoDataInfo from './../components/common/no-data-info';

//const swallSpeed = 1500;

const CreatedExhibitionsPage = (props) => {
   const history = useHistory();
   const [dataLoaded, setDataLoaded] = useState(false);
   const [staticDataLoaded, setStaticDataLoaded] = useState(false);
   const [createdExhibitions, setCreatedExhibitions] = useState([]);
   const dispatch = useDispatch();
   const state = useSelector((state) => state.exhibition);

   const loadCreatedExhibitions = async () => {
      toast.info(ucitavamPodatke);
      const { data: response } = await getCreatedExhibitions();

      if (response.status === 1) {
         setCreatedExhibitions(response.details);
         setDataLoaded(true);
         toast.dismiss();
         loadStaticData();
      } else {
         toast.error(`${response.message}`);
      }
   };

   const loadStaticData = () => {
      if (!service.isStaticDataLoaded(state)) {
         dispatch(setToastAutoCloseInterval(10000));
         toast(uredjivanjeCeBitiOmoguceno);

         dispatch(loadExhibitionStaticData()).then(() => {
            toast.dismiss();
            dispatch(setToastAutoCloseInterval(3000));
            setStaticDataLoaded(true);
         });
      } else {
         setStaticDataLoaded(true);
      }
   };

   useEffect(() => {
      document.title = 'Lista izložbi';
      loadCreatedExhibitions();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleEditExhibition = async (ExhibitionId, isVerified) => {
      setDataLoaded(false);
      dispatch(setExhibitionEditAfterVerificationMode(isVerified));
      dispatch(loadExhibitionData(ExhibitionId)).then(() => {
         dispatch(setExhibitionEditMode(true));
         history.push(`${process.env.PUBLIC_URL}/add-exhibition`);
      });
   };

   const optimisticDeleteExhibition = (ExhibitionId) => {
      const filtriranoPolje = createdExhibitions.filter(
         (ce) => ce.ExhibitionId !== ExhibitionId
      );
      setCreatedExhibitions(filtriranoPolje);
   };

   const handleDeleteExhibition = async (exhibition) => {
      swal({
         html: true,
         title: `Obrisati?`,
         text: `${exhibition.Name}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Potvrdi',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then((value) => {
         switch (value) {
            case 'confirm':
               optimisticDeleteExhibition(exhibition.ExhibitionId);
               deleteExhibitionFromServer(exhibition.ExhibitionId);
               break;
            default:
               break;
         }
      });
   };

   const deleteExhibitionFromServer = async (exhibitionId) => {
      const { data: response } = await deleteExhibition(exhibitionId);
      if (response.status === 1) {
         toast.info(response.message);
      } else {
         toast.error(response.message);
         loadCreatedExhibitions();
      }
   };

   const handleToVerification = async (exhibitionId, exhibitionName) => {
      swal({
         html: true,
         title: `Poslati na verifikaciju?`,
         text: `${exhibitionName}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Pošalji',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then(async (value) => {
         switch (value) {
            case 'confirm':
               const { data: response } =
                  await markExhibitionAsReadyForVerification(exhibitionId);
               if (response.status === 1) {
                  loadCreatedExhibitions();
               } else {
                  toast.error(response.message);
               }
               break;
            default:
               break;
         }
      });
   };

   const handleOnVerify = async ({ ExhibitionId, IsVerified, Name }) => {
      swal({
         html: true,
         title: `Jeste li sigurni da želite verificirati?`,
         text: `${Name}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Verificiraj',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then(async (value) => {
         switch (value) {
            case 'confirm':
               let responseStatus;
               let responseMessage;
               if (IsVerified) {
                  const { data } = await reverifyExhibition(ExhibitionId);
                  responseStatus = data.status;
                  responseMessage = data.message;
               } else {
                  const { data } = await markExhibitionAsVerified(ExhibitionId);
                  responseStatus = data.status;
                  responseMessage = data.message;
               }
               if (responseStatus === 1) {
                  loadCreatedExhibitions();
               } else {
                  toast.error(responseMessage);
               }
               break;
            default:
               break;
         }
      });
   };

   return (
      <>
         {!dataLoaded && <LoadingSpinner type='' text='' />}
         {dataLoaded && (
            <MotionContainer
               animationObject={animation}
               className='created-authors-list-page'
            >
               <div className='table-container'>
                  {createdExhibitions.length === 0 && (
                     <NoDataInfo text='Nema kreiranih izložbi' />
                  )}
                  {createdExhibitions.length > 0 && (
                     <TableCreatedExhibitionsList
                        exhibitions={createdExhibitions}
                        enableEdit={!staticDataLoaded}
                        onEdit={handleEditExhibition}
                        onDelete={handleDeleteExhibition}
                        onToVerification={handleToVerification}
                        onVerify={handleOnVerify}
                     />
                  )}
               </div>
            </MotionContainer>
         )}
      </>
   );
};

export default CreatedExhibitionsPage;
