import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import AddNewEducationLevel from './add-new-education-level';
import { useDispatch, useSelector } from 'react-redux';
import html from '../../../services/html';
import { loadEducationLevels } from '../../../store/author';
import ButtonForm from './../../common/btn-form';

/*
    props
        onAddEducationLevel()
*/

const AuthorEducationLevel = ({ onAddEducationLevel, defaultValue }) => {
   const [showAddEducationLevel, setShowAddEducationLevel] = useState(false);
   const [selectedEducationLevel, setSelectedEducationLevel] = useState({});
   const [kolekcija, setKolekcija] = useState([]);

   const { educationLevels, legalEntities } = useSelector(
      (state) => state.author
   );
   const dispatch = useDispatch();

   useEffect(() => {
      setKolekcija(defaultValue);
      dispatch(loadEducationLevels());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddEducationLevel(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleEducationLevelSelected = (value, obj) => {
      const { id: EducationLevelId, value: Name } = obj;
      setSelectedEducationLevel({
         Name,
         EducationLevelId
      });
   };

   const showHideAuthorEducationLevel = () => {
      setShowAddEducationLevel(!showAddEducationLevel);
   };

   const handleOnEducationLevelAdded = (authorEducationLevelData) => {
      const educationLevelsArray = [...kolekcija, authorEducationLevelData];
      setKolekcija(educationLevelsArray);
   };

   const handleRemoveFromEducationList = (id) => {
      setKolekcija(kolekcija.filter((edu) => edu.id !== id));
   };

   const educationLevelSelected =
      Object.keys(selectedEducationLevel).length > 0;

   return (
      <>
         {showAddEducationLevel && (
            <AddNewEducationLevel
               selectedEducationLevel={selectedEducationLevel}
               closeAddEducationLevel={showHideAuthorEducationLevel}
               onEducationLevelAdded={handleOnEducationLevelAdded}
            />
         )}
         <div className='row'>
            <div className='col-9'>
               {html.renderSelectAnt(
                  'Obrazovanje',
                  'Name',
                  'EducationLevelId',
                  'Započnite unos ili odaberite obrazovanje...',
                  'Ne pronalazim traženu vrijednost...',
                  educationLevels,
                  handleEducationLevelSelected,
                  null,
                  null,
                  true
               )}
            </div>
            <div className='col'>
               <ButtonForm
                  text='Dodaj'
                  onClick={showHideAuthorEducationLevel}
                  disabled={!educationLevelSelected}
                  inline={true}
               />
            </div>
         </div>
         <div className='education-content'>
            {kolekcija.length > 0 && (
               <div className='list-container'>
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Obrazovanje</th>
                           <th>Od</th>
                           <th>Do</th>
                           <th>Ustanova</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((edu) => (
                           <tr key={uuid()}>
                              {html.td(
                                 170,

                                 html.getEducationLevelNameById(
                                    edu.EducationLevelId,
                                    educationLevels
                                 )
                              )}
                              {html.td(
                                 60,
                                 edu.DateFrom
                                    ? edu.DateFrom.indexOf('T') > 0
                                       ? html.convert_T_Date_ToLocal(
                                            edu.DateFrom
                                         )
                                       : html.convertDateToLocal(edu.DateFrom)
                                    : '-'
                              )}
                              {html.td(
                                 60,
                                 edu.DateTo
                                    ? edu.DateTo.indexOf('T') > 0
                                       ? html.convert_T_Date_ToLocal(edu.DateTo)
                                       : html.convertDateToLocal(edu.DateTo)
                                    : '-'
                              )}
                              {html.td(
                                 170,
                                 html.getLegalEntityNameById(
                                    edu.LegalEntityId,
                                    legalEntities
                                 )
                              )}

                              {html.tdWithRemoveBtn(
                                 handleRemoveFromEducationList,
                                 edu.id,
                                 {
                                    title: `Ukloni ${html.getLegalEntityNameById(
                                       edu.LegalEntityId,
                                       legalEntities
                                    )} sa liste`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            )}
         </div>
      </>
   );
};

export default AuthorEducationLevel;
