import React from 'react';
import { useSelector } from 'react-redux';
import html from '../../../services/html';

const AuthorStatus = ({ onAuthorStatusCheck, defaultValue, disabled }) => {
    const { authorAcademicStatuses } = useSelector((state) => state.author);
    const statuses = authorAcademicStatuses.map((cb) => ({
        label: cb.Name,
        value: cb.AcademicStatusId
    }));

    const statusChecked = (checkedStatusIds) => {
        onAuthorStatusCheck(checkedStatusIds);
    };

    return (
        <div
            className='author-status'
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {html.renderCheckBoxGroup(
                'Status',
                statuses,
                statusChecked,
                defaultValue,
                true,
                disabled
            )}
        </div>
    );
};

export default AuthorStatus;
