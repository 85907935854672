//provjeri jesu li na store učitani svi podaci
export const isStaticDataLoaded = (state) => {
    const { artworkTechnique, ownershipType } = state;

    return artworkTechnique.length > 0 && ownershipType.length > 0;
};

const exportObj = {
    isStaticDataLoaded
};

export default exportObj;
