import React, { useEffect, useState } from 'react';
import { pageAnimation } from './page-transition-animation';
import { authorAdd_StepsAnimation } from './animations/author-add-steps';
import { Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setToastAutoCloseInterval } from '../store/app-setup';
import { updateArtwork, updateVerifiedArtwork } from '../services/api';
import LoadingSpinner from '../components/common/loading-spinner';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { useHistory } from 'react-router-dom';
import {
    clearArtworkData,
    loadArtworkStaticData,
    setArtworkEditAfterVerificationMode,
    setArtworkEditMode
} from './../store/artwork';
import ArtworkInformation from '../components/artwork/artwork-basic-information';
import ArtworkRestInformation from '../components/artwork/artwork-rest-information';
import ConfirmCreateArtwork from '../components/artwork/confirm-create-artwork';
import { createArtwork } from './../services/api';
import service from '../services/created-artworks-page';
import {
    artworkSuccess,
    artworkSuccessAfterEdit,
    artworkError,
    artworkErrorAfterEdit
} from './../services/confirm';
import { setConfirmData } from './../store/common';
import { ucitavamDodatnePodatke } from '../services/toast-messages';

const ArtworkAddPage = (props) => {
    const { Step } = Steps;

    const [current, setCurrent] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);

    const {
        artworkData: artworkEditData,
        isEditMode,
        isEditAfterVerification
    } = useSelector((state) => state.artwork);

    let basicInformationData = {};
    let restInformationData = {};

    if (artworkEditData) {
        basicInformationData = artworkEditData.basicInformation;
        restInformationData = artworkEditData.restInformation;
    }

    const [basicInformation, setBasicInformation] =
        useState(basicInformationData);
    const [restInformation, setRestInformation] = useState(restInformationData);

    const [initRender, setInitRender] = useState(true);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => {
            dispatch(clearArtworkData());
            dispatch(setArtworkEditMode(false));
            dispatch(setArtworkEditAfterVerificationMode(false));
            toast.dismiss();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const state = useSelector((state) => state.artwork);

    useEffect(() => {
        document.title = 'Dodavanje umjetničkog djela';
        if (!service.isStaticDataLoaded(state)) {
            toast(ucitavamDodatnePodatke);

            dispatch(loadArtworkStaticData()).then(() => {
                toast.dismiss();
                setDataLoaded(true);
            });
        } else {
            setDataLoaded(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleBasicInformationSave = (data) => {
        setBasicInformation(data);
        // setCurrent(1);
    };

    const handleRestInformationSave = (data) => {
        setRestInformation(data);
    };

    const allDataIsValid = () => {
        return basicInformation.isValid && restInformation.isValid;
    };

    const handleArtworkCreate = (data) => {
        setRestInformation(data);
        setCurrent(2);
    };

    const handleCancelArtworkCreate = () => {
        setCurrent(0);
    };

    const handleOnCreateArtworkBtnClick = () => {
        sendDataToServer();
    };

    const sendDataToServer = async () => {
        const artwork = {
            ...basicInformation,
            ...restInformation
        };

        if (isEditMode) artwork.ArtworkId = artworkEditData.ArtworkId;

        if (!allDataIsValid()) {
            toast.error('Nisu ispunjene sve informacije o umjetničkom djelu!');
            return;
        }

        dispatch(setToastAutoCloseInterval(500));
        toast.info('Preusmjeravanje...');

        try {
            if (isEditMode) {
                const { data: response } = isEditAfterVerification
                    ? await updateVerifiedArtwork(artwork)
                    : await updateArtwork(artwork);
                handleAfterServerResponse(response);
            } else {
                const { data: response } = await createArtwork(artwork);
                handleAfterServerResponse(response);
            }
        } catch (error) {
            console.log('error', error);
            dispatch(setToastAutoCloseInterval(5000));
            toast.error(
                `${error.data.Message} - ${error.data.ExceptionMessage}`,
                {
                    onClose: () => {
                        history.push(`${process.env.PUBLIC_URL}/artworks`);
                    }
                }
            );
        }
    };

    const handleAfterServerResponse = (response) => {
        if (response.status === 1) {
            if (isEditMode) dispatch(setConfirmData(artworkSuccessAfterEdit));
            else dispatch(setConfirmData(artworkSuccess));
        } else {
            if (isEditMode) {
                artworkErrorAfterEdit.subtitle = response.message;
                dispatch(setConfirmData(artworkErrorAfterEdit));
            } else {
                artworkError.subtitle = response.message;
                dispatch(setConfirmData(artworkError));
            }
        }
        history.push(`${process.env.PUBLIC_URL}/confirm`);
    };

    //stepIndex je index komponente koja je trenutno prikazana, na kojoj se kliknulo dalje>
    const handleOnNextClick = (stepIndex) => {
        //markiraj slijedeći korak kao kliknut
        if (!clickedSteps.includes(stepIndex + 1))
            setClickedSteps([...clickedSteps, stepIndex + 1]);

        setInitRender(false);
        setCurrent(stepIndex + 1);
    };

    //  Polje gdje pratim koji koraci su do sada bili kliknuti, inicijalno je prikazan prvi korak, zato index 0 u polju
    const [clickedSteps, setClickedSteps] = useState([0]);
    const onChange = (current) => {
        //markiraj kliknuti korak kao posjećen
        if (!clickedSteps.includes(current))
            setClickedSteps([...clickedSteps, current]);

        setInitRender(false);
        setCurrent(current);
    };

    /*
    Svaka komponenta vraca svojstvi [isValid] koje je istina ukolko su se ispunila obavezna polja
    Komponente gdje to nije napravljeno se markiraju kao nedovršene

    Inicijalni prikaz prikazuje sve komponente (osim prve, inicijalno prikazane) u stanju 'wait'
    Jednom kada se na neki korak klikne on je u stanju 'process'
    Kada se sa koraka makne, ako su obavezni podaci ispunjeni stanje ce biti 'finish' u protivnom 'error'
*/

    const isValidStepIndex = (stepIndex) => {
        let isValid = false;
        switch (stepIndex) {
            case 0:
                isValid = basicInformation.isValid;
                break;
            case 1:
                isValid = restInformation.isValid;
                break;
            default:
                console.log('ERROR - isValidStepIndex(stepIndex)');
                break;
        }
        return isValid;
    };

    const getInitRenderStepStatus = (stepIndex) => {
        if (initRender && stepIndex === 0) return 'process';
        else return 'wait';
    };

    const getCurrentStepStatus = (stepIndex, currentStep) => {
        if (initRender) return getInitRenderStepStatus(stepIndex);

        if (!clickedSteps.includes(stepIndex)) return 'wait';

        return currentStep === stepIndex
            ? 'process'
            : isValidStepIndex(stepIndex)
            ? 'finish'
            : 'error';
    };

    return (
        <>
            {!dataLoaded && <LoadingSpinner text='' />}
            {dataLoaded && (
                <MotionContainer
                    className='artwork-add-page-container'
                    animationObject={pageAnimation}
                >
                    <div className='artwork-add-page-content'>
                        <div className='author-group-add-page'>
                            <MotionContainer
                                className='artwork-add-page-steps'
                                animationObject={authorAdd_StepsAnimation}
                            >
                                <Steps current={current} onChange={onChange}>
                                    <Step
                                        title='Podaci o umjetničkom djelu'
                                        description='Autori, Naziv, Vrijeme i mjesto nastanka...'
                                        status={getCurrentStepStatus(
                                            0,
                                            current
                                        )}
                                    />
                                    <Step
                                        title='Spremi podatke'
                                        description='Fotografije, napomena, url...'
                                        status={getCurrentStepStatus(
                                            1,
                                            current
                                        )}
                                    />
                                </Steps>
                            </MotionContainer>
                        </div>
                        <div>
                            {current === 0 && (
                                <ArtworkInformation
                                    data={basicInformation}
                                    onSubmit={handleBasicInformationSave}
                                    onNextClick={() => {
                                        handleOnNextClick(current);
                                    }}
                                />
                            )}
                            {current === 1 && (
                                <ArtworkRestInformation
                                    data={restInformation}
                                    onSubmit={handleRestInformationSave}
                                    onCreateArtwork={handleArtworkCreate}
                                />
                            )}
                            {current === 2 && (
                                <ConfirmCreateArtwork
                                    allDataIsValid={allDataIsValid()}
                                    data={basicInformation}
                                    onCreate={handleOnCreateArtworkBtnClick}
                                    onCancelCreate={handleCancelArtworkCreate}
                                />
                            )}
                        </div>
                    </div>
                </MotionContainer>
            )}
        </>
    );
};

export default ArtworkAddPage;
