import Joi from 'joi-browser';

export const authorContactInformationSchema = {
   ContactEmail: Joi.string()
      .email()
      .required()
      .label('E-mail')
      .error((errors) => {
         if (errors[0].type === 'any.empty')
            return {
               message: `${errors[0].flags.label} je obavezan`
            };
         if (errors[0].type === 'string.email')
            return {
               message: `Neispravno ${errors[0].flags.label}`
            };
         return {
            message: `${errors[0].flags.label} - greška.`
         };
      }),
   ContactPhone: Joi.any().optional().label('Telefon'),
   PersonalOrGroupUrl: Joi.any().optional().label('URL'),
   SocialNetworks: Joi.any().optional().label('Društvene mreže')
};

//bla: Joi.any().optional(),
