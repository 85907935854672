import destructureTo from '../services/destructure-data';
import {
   getArtworkDetails,
   getArtworkTechnique,
   getCities,
   getOwnershipType,
   getAuthorList,
   getCollections
} from '../services/api';
import { getCurrentUser } from '../services/auth';
import {
   getExhibitions,
   loadExhibitionsAndCollections
} from './../services/api';
import axios from 'axios';

const initState = {
   artworkTechnique: [],
   ownershipType: [],
   authors: [],
   countries: [],
   collections: [],
   cities: [],
   exhibitions: [],
   artworkData: null,
   isEditMode: false,
   isEditAfterVerification: false
};

export const loadArtworkStaticData = () => async (dispatch, getState) => {
   try {
      // const { data: artworkTechnique } = await getArtworkTechnique();
      const { data: ownershipType } = await getOwnershipType();

      if (getCurrentUser()) await dispatch(storeAuthors());
      if (getCurrentUser()) await dispatch(storeExhibitions());

      const payload = {
         // artworkTechnique: artworkTechnique.data,
         ownershipType: ownershipType.data
      };

      await dispatch({ type: 'LOAD_ARTWORK_STATIC_DATA', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_ARTWORK_STATIC_DATA', ex.response?.data);
      }
   }
};

export const storeAuthors = () => async (dispatch, getState) => {
   try {
      const { data: authors } = await getAuthorList();

      const payload = {
         authors: authors.data
      };
      await dispatch({ type: 'STORE_AUTHORS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('STORE_AUTHORS', ex.response?.data);
      }
   }
};

export const storeExhibitions = () => async (dispatch, getState) => {
   try {
      const { data: exhibitions } = await getExhibitions();
      const payload = {
         exhibitions: exhibitions.details
      };
      await dispatch({ type: 'STORE_EXHIBITIONS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('STORE_EXHIBITIONS', ex.response?.data);
      }
   }
};

export const loadCities = (countryId) => async (dispatch, getState) => {
   try {
      const { data: cities } = await getCities(countryId);
      const payload = {
         cities: cities.data
      };
      dispatch({ type: 'LOAD_CITIES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_CITIES', ex.response?.data);
      }
   }
};

export const loadCollections = () => async (dispatch, getState) => {
   try {
      const { data: collections } = await getCollections('');

      const payload = {
         collections: collections.data
      };
      dispatch({ type: 'LOAD_COLLECTIONS', payload });
   } catch (ex) {
      console.error(ex);
      if (ex.response && ex.response?.status === 400) {
         console.log('LOAD_COLLECTIONS', ex.response?.data);
      }
   }
};

export const setArtworkEditAfterVerificationMode = (
   isEditAfterVerification
) => ({
   type: 'SET_ARTWORK_EDIT_AFTER_VERIFICATION_MODE',
   payload: {
      isEditAfterVerification
   }
});

export const loadArtworkData = (artworkId) => async (dispatch, getState) => {
   try {
      const { data: artworkData } = await getArtworkDetails(artworkId);

      const payload = {
         artworkData: artworkData.details
      };

      await dispatch(storeExhibitions());
      await dispatch(loadCollections());
      await dispatch({ type: 'LOAD_ARTWORK_DATA', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_ARTWORK_DATA', ex.response?.data);
      }
   }
};

export const setArtworkEditMode = (mode) => ({
   type: 'SET_ARTWORK_EDIT_MODE',
   payload: {
      mode
   }
});

export const clearArtworkData = () => ({
   type: 'CLEAR_ARTWORK_DATA'
});

//  REDUCER
export default function reducer(state = initState, action) {
   switch (action.type) {
      case 'LOAD_ARTWORK_STATIC_DATA':
         const { ownershipType } = action.payload;
         return {
            ...state,
            ownershipType
         };
      case 'STORE_AUTHORS':
         const { authors } = action.payload;
         return {
            ...state,
            authors
         };
      case 'STORE_EXHIBITIONS':
         const { exhibitions } = action.payload;
         return {
            ...state,
            exhibitions
         };
      case 'SET_ARTWORK_EDIT_MODE':
         const { mode } = action.payload;
         return {
            ...state,
            isEditMode: mode
         };
      case 'LOAD_CITIES':
         const { cities } = action.payload;
         return {
            ...state,
            cities
         };
      case 'LOAD_COLLECTIONS':
         const { collections } = action.payload;
         return {
            ...state,
            collections
         };
      case 'SET_ARTWORK_EDIT_AFTER_VERIFICATION_MODE':
         const { isEditAfterVerification } = action.payload;
         return {
            ...state,
            isEditAfterVerification
         };
      case 'CLEAR_ARTWORK_DATA':
         return {
            ...state,
            artworkData: null
         };
      case 'LOAD_ARTWORK_DATA':
         const { artworkData } = action.payload;

         artworkData.authors = [...state.authors];
         artworkData.exhibitions = state.exhibitions
            ? [...state.exhibitions]
            : [];
         artworkData.collections = state.collections
            ? [...state.collections]
            : [];

         const basicInformation =
            destructureTo.artworkBasicInformation(artworkData);

         const restInformation =
            destructureTo.artworkRestInformation(artworkData);

         artworkData.basicInformation = { ...basicInformation };
         artworkData.restInformation = { ...restInformation };

         return {
            ...state,
            artworkData
         };
      default:
         return state;
   }
}
