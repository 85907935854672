import React from 'react';
import { Select } from 'antd';
import { v4 as uuid } from 'uuid';
import { LoadingOutlined } from '@ant-design/icons';
import html from '../../services/html';

const SelectAnt = (props) => {
   const { Option } = Select;
   const {
      selectOptions,
      allowClear,
      text,
      value,
      label,
      showLoadingIcon,
      notFoundContent,
      placeholder,
      hideLabel,
      error,
      onChange,
      onBlur,
      onFocus,
      onSearch,
      requiredLabel,
      selectedOption,
      attributes,
      disabled
   } = props;

   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };

   return (
      <div className='form-group'>
         {!hideLabel && (
            <label style={style}>
               <span className='mr-1'>{label}</span>
               {requiredLabel && html.renderRequiredTooltip()}
            </label>
         )}
         {selectOptions && selectOptions.length === 0 && showLoadingIcon && (
            <LoadingOutlined className='ml-3' style={{ verticalAlign: 0 }} />
         )}
         <Select
            allowClear={allowClear}
            showSearch
            className='form-control'
            placeholder={placeholder}
            optionFilterProp='children'
            disabled={disabled}
            notFoundContent={notFoundContent}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            value={selectedOption}
            {...attributes}
            filterOption={(input, option) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
         >
            {selectOptions &&
               selectOptions.map((option) => (
                  <Option key={uuid()} id={option[value]} value={option[text]}>
                     {option[text]}
                  </Option>
               ))}
         </Select>
         {error && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {error}
            </div>
         )}
      </div>
   );
};

export default SelectAnt;

//    const onChange = (value, a) => {
//       console.log(`selected ${value}`, a);
//    };

//    const onBlur = () => {
//       console.log('blur');
//    };

//    const onFocus = () => {
//       console.log('focus');
//    };

//    const onSearch = (val) => {
//       console.log('search:', val);
//    };
