import React from 'react';
import ShowTooltip from './show-tooltip';

const ButtonFormTooltip = ({
   inline = false,
   ref = null,
   text = 'Form button',
   tooltip = 'Tooltip text',
   tooltipPlacement = 'bottom',
   disabled = false,
   onClick
}) => {
   const style = {
      marginTop: '1.5rem'
   };
   const classBase = 'button-form button-dark';

   return (
      <ShowTooltip title={tooltip} placement={tooltipPlacement}>
         <button
            className={classBase}
            disabled={disabled}
            onClick={onClick}
            type='button'
            {...(ref ? { ref } : {})}
            {...(inline ? { style } : {})}
         >
            {text}
         </button>
      </ShowTooltip>
   );
};

export default ButtonFormTooltip;
