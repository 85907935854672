import React from 'react';
import Form from '../common/form';
import MotionContainer from '../common/motion-containers/motion-container';
import { connect } from 'react-redux';
import { loadExhibitionStaticData } from '../../store/exhibition';
import moment from 'moment';
import ExhibitionAuthor from './exhibition-author';
import { ExhibitionBasicInformationSchema } from './../../validators/exhibition-basic-information';
import ExhibitionCurators from './exhibition-curators';
import ExhibitionArtwork from './exhibition-artwork';
import ExhibitionCatalogue from './exhibition-catalogue';
import ExhibitionMediaAnnouncement from './exhibition-media-announcement';
import AddLegalEntity from './../common/add-legal-entity';
import ButtonNext from './../common/btn-next';
import DDL from './../common/ant-ddl';
import AntDatepicker from './../common/ant-datepicker';

class ExhibitionBasicInformation extends Form {
   constructor(props) {
      super(props);

      const {
         Name,
         NameEn,
         ExhibitionAuthors,
         ExhibitionCatalogues,
         ExhibitionMediaAnnouncements,
         ArtworkExhibitions,
         Year,
         ExhibitionTypeQuantityId,
         ExhibitionTypeKindId,
         InstitutionTypeId,
         IsCurators,
         Curators,
         LocationCountryId,
         LocationTypeId,
         OrganizerLegalEntityId,
         ExhibitionKindOther,
         LocationLegalEntityId,
         Url,
         Remark
      } = props.data;

      this.Name = Name;
      this.NameEn = NameEn;
      this.ExhibitionAuthors = ExhibitionAuthors;
      this.ExhibitionCatalogues = ExhibitionCatalogues;
      this.ExhibitionMediaAnnouncements = ExhibitionMediaAnnouncements;
      this.ArtworkExhibitions = ArtworkExhibitions;
      this.Year = Year;
      this.ExhibitionTypeQuantityId = ExhibitionTypeQuantityId;
      this.ExhibitionTypeKindId = ExhibitionTypeKindId;
      this.InstitutionTypeId = InstitutionTypeId;
      this.IsCurators = IsCurators;
      this.Curators = Curators;
      this.LocationCountryId = LocationCountryId;
      this.LocationTypeId = LocationTypeId;
      this.OrganizerLegalEntityId = OrganizerLegalEntityId;
      this.ExhibitionKindOther = ExhibitionKindOther;
      this.LocationLegalEntityId = LocationLegalEntityId;
      this.Url = Url;
      this.Remark = Remark;

      this.state = {
         validationData: {
            Name: this.Name ?? '',
            NameEn: this.NameEn ?? '',
            ExhibitionKindOther: this.ExhibitionKindOther ?? ''
         },
         restData: {
            ExhibitionAuthors: this.ExhibitionAuthors ?? [],
            ExhibitionCatalogues: this.ExhibitionCatalogues ?? [],
            ExhibitionMediaAnnouncements:
               this.ExhibitionMediaAnnouncements ?? [],
            ArtworkExhibitions: this.ArtworkExhibitions ?? [],
            Year: this.Year ?? null,
            ExhibitionTypeQuantityId: this.ExhibitionTypeQuantityId ?? null,
            ExhibitionTypeKindId: this.ExhibitionTypeKindId ?? null,
            InstitutionTypeId: this.InstitutionTypeId ?? null,
            IsCurators: this.IsCurators ?? false,
            Curators: this.Curators ?? '',
            LocationCountryId: this.LocationCountryId ?? null,
            LocationTypeId: this.LocationTypeId ?? null,
            OrganizerLegalEntityId: this.OrganizerLegalEntityId ?? null,
            LocationLegalEntityId: this.LocationLegalEntityId ?? null,
            Url: this.Url ?? '',
            Remark: this.Remark ?? ''
         },
         countrySelected: this.drzavaJeOdabrana ?? false,
         errors: {}
      };
   }

   isValid = false;

   schema = ExhibitionBasicInformationSchema;

   async componentDidMount() {
      this.props.loadExhibitionStaticData();
   }

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const {
         ExhibitionAuthors,
         Year,
         ExhibitionTypeQuantityId,
         ExhibitionTypeKindId,
         InstitutionTypeId,
         LocationTypeId,
         IsCurators,
         Curators,
         OrganizerLegalEntityId,
         ArtworkExhibitions,
         ExhibitionCatalogues,
         ExhibitionMediaAnnouncements,
         LocationLegalEntityId
      } = this.state.restData;

      const { ExhibitionKindOther } = this.state.validationData;

      const {
         exhibitionTypeQuantity,
         exhibitionTypeKind,
         institutionType,
         locationType
      } = this.props;

      const { Name, NameEn } = this.state.validationData;

      const nijeUnioAutore = ExhibitionAuthors.length === 0;
      const nemaNaziva = Name.length === 0;
      const nemaNazivaEn = NameEn.length === 0;
      const nijeOdabranaVrsta = ExhibitionTypeKindId === null;
      const nijeOdabranTip = ExhibitionTypeQuantityId === null;
      const nijeOdabranaGodina = Year === null;
      const nijeOdabranaVrstaInstitucije = InstitutionTypeId === null;
      const nijeUpisanKustos = IsCurators && Curators === '';
      const upisaoOstalo =
         ExhibitionTypeKindId === 5 ? ExhibitionKindOther !== '' : true;
      const odabraoInstituciju =
         LocationTypeId === 2 ? LocationLegalEntityId !== null : true;

      this.isValid =
         !nijeUnioAutore &&
         !nemaNaziva &&
         !nemaNazivaEn &&
         !nijeOdabranaVrsta &&
         !nijeOdabranTip &&
         !nijeOdabranaGodina &&
         !nijeOdabranaVrstaInstitucije &&
         !nijeUpisanKustos &&
         upisaoOstalo &&
         odabraoInstituciju &&
         OrganizerLegalEntityId != null;

      const curattorsDefaultValue = { IsCurators, Curators };

      return (
         <>
            <MotionContainer className='author-professional-activity'>
               <form
                  onSubmit={this.handleFormSubmit}
                  className='form-style-width'
               >
                  <h2>Osnovni podaci o izložbi</h2>
                  <div className='row'>
                     <div className='col-6'>
                        <ExhibitionAuthor
                           onAddExhibitionAuthor={
                              this.handleExhibitionAuthorAdded
                           }
                           defaultValue={ExhibitionAuthors}
                        />
                        <hr />

                        {this.renderFormInput({
                           label: 'Naziv izložbe',
                           name: 'Name',
                           required: true
                        })}

                        {this.renderFormInput({
                           label: 'Naziv izložbe na engleskom',
                           name: 'NameEn',
                           required: true
                        })}

                        <DDL
                           label='Samostalna/grupna'
                           text='Name'
                           value='ExhibitionTypeQuantityId'
                           options={exhibitionTypeQuantity}
                           onChange={this.handleExhibitionTypeQuantitySelected}
                           selectedOption={ExhibitionTypeQuantityId}
                           requiredLabel={true}
                        />

                        <DDL
                           label='Vrsta'
                           text='Name'
                           value='ExhibitionTypeKindId'
                           options={exhibitionTypeKind}
                           onChange={this.handleExhibitionTypeKindSelected}
                           selectedOption={ExhibitionTypeKindId}
                           requiredLabel={true}
                        />

                        {ExhibitionTypeKindId === 5 &&
                           this.renderFormTextArea({
                              label: 'Ostalo',
                              name: 'ExhibitionKindOther'
                           })}

                        <AntDatepicker
                           label='Godina održavanja'
                           requiredLabel={true}
                           defaultDate={
                              Year ? moment(new Date(Year, 0, 1)) : null
                           }
                           onChange={this.handleYearSelected}
                           rest={{
                              picker: 'year',
                              placeholder: 'Odaberite godinu...',
                              format: 'YYYY'
                           }}
                        />

                        <ExhibitionCurators
                           onExhibitionCuratorsChange={
                              this.handleExhibitionCuratorsChange
                           }
                           defaultValue={curattorsDefaultValue}
                        />

                        <AddLegalEntity
                           label='Organizator'
                           required={true}
                           defaultValue={OrganizerLegalEntityId}
                           onLegalEntitySelect={this.handleLegalEntitySelect}
                        />

                        <DDL
                           label='Vrsta institucije'
                           text='Name'
                           value='InstitutionTypeId'
                           options={institutionType}
                           onChange={this.handleInstitutionTypeSelected}
                           selectedOption={InstitutionTypeId}
                           requiredLabel={true}
                        />
                     </div>
                     <div className='col-6'>
                        <DDL
                           label='Lokacija izložbe'
                           text='Name'
                           value='LocationTypeId'
                           options={locationType}
                           onChange={this.handleLocationTypeSelected}
                           selectedOption={LocationTypeId}
                           requiredLabel={true}
                        />

                        {LocationTypeId === 2 && (
                           <AddLegalEntity
                              label='Dodaj instituciju'
                              required={true}
                              defaultValue={LocationLegalEntityId}
                              onLegalEntitySelect={
                                 this.handleLocationLegalEntityIdSelect
                              }
                           />
                        )}

                        <ExhibitionArtwork
                           onAddExhibitionArtwork={
                              this.handleExhibitionAtrworkAdd
                           }
                           defaultValue={ArtworkExhibitions}
                        />
                        <hr />
                        <ExhibitionCatalogue
                           onAddExhibitionCatalogue={
                              this.handleExCatalogueAdded
                           }
                           defaultValue={ExhibitionCatalogues}
                        />
                        <hr />
                        <ExhibitionMediaAnnouncement
                           onAddExhibitionMedia={this.handleAddExhibitionMedia}
                           defaultValue={ExhibitionMediaAnnouncements}
                        />
                     </div>
                  </div>
                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(2);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const validationData = { ...this.state.validationData };
      const restData = { ...this.state.restData };
      const data = { ...restData, ...validationData };
      data.isValid = this.isValid;

      this.props.onSubmit(data);
   };

   handleExhibitionAuthorAdded = (artworkAuthors) => {
      const restData = { ...this.state.restData };
      restData.ExhibitionAuthors = artworkAuthors;
      this.setState({ restData });
   };

   handleYearSelected = (e) => {
      if (!e) return;
      const restData = { ...this.state.restData };
      restData.Year = Number(e.format('YYYY'));
      this.setState({ restData });
   };

   handleExhibitionTypeQuantitySelected = ({
      value: ExhibitionTypeQuantityId
   }) => {
      const restData = { ...this.state.restData };
      restData.ExhibitionTypeQuantityId = ExhibitionTypeQuantityId;
      this.setState({ restData });
   };

   handleExhibitionTypeKindSelected = ({ value: ExhibitionTypeKindId }) => {
      const restData = { ...this.state.restData };
      restData.ExhibitionTypeKindId = ExhibitionTypeKindId;
      this.setState({ restData });
   };

   handleInstitutionTypeSelected = ({ value: InstitutionTypeId }) => {
      const restData = { ...this.state.restData };
      restData.InstitutionTypeId = InstitutionTypeId;
      this.setState({ restData });
   };

   handleLocationTypeSelected = ({ value: LocationTypeId }) => {
      const restData = { ...this.state.restData };

      restData.LocationTypeId = LocationTypeId;
      if (LocationTypeId !== 2) restData.LocationLegalEntityId = null;

      this.setState({ restData });
   };

   handleExhibitionCuratorsChange = (exhibitionCuratorsData) => {
      const { IsCurators, Curators } = exhibitionCuratorsData;
      const restData = { ...this.state.restData };
      restData.IsCurators = IsCurators;
      restData.Curators = Curators;

      this.setState({ restData });
   };

   handleExhibitionLegalEntityAdded = (data) => {
      const {
         LocationCountryId,
         LocationCityId,
         LocationLatitude,
         LocationLongitude,
         LocationAddress
      } = data;

      const restData = { ...this.state.restData };
      restData.LocationCountryId = LocationCountryId;
      restData.LocationCityId = LocationCityId;
      restData.LocationLatitude = LocationLatitude;
      restData.LocationLongitude = LocationLongitude;
      restData.LocationAddress = LocationAddress;

      this.setState({ restData });
   };

   handleLegalEntitySelect = (txt, value) => {
      const { id: OrganizerLegalEntityId } = value;
      const restData = { ...this.state.restData };
      restData.OrganizerLegalEntityId = OrganizerLegalEntityId;
      this.setState({ restData });
   };

   handleLocationLegalEntityIdSelect = (txt, value) => {
      const { id: LocationLegalEntityId } = value;
      const restData = { ...this.state.restData };
      restData.LocationLegalEntityId = LocationLegalEntityId;
      this.setState({ restData });
   };

   handleExhibitionAtrworkAdd = (artworkExhibitions) => {
      const restData = { ...this.state.restData };
      restData.ArtworkExhibitions = artworkExhibitions;
      this.setState({ restData });
   };

   handleExCatalogueAdded = (ExhibitionCatalogues) => {
      const restData = { ...this.state.restData };
      restData.ExhibitionCatalogues = ExhibitionCatalogues;
      this.setState({ restData });
   };

   handleAddExhibitionMedia = (ExhibitionMediaAnnouncements) => {
      const restData = { ...this.state.restData };
      restData.ExhibitionMediaAnnouncements = ExhibitionMediaAnnouncements;
      this.setState({ restData });
   };
}
const mapStateToProps = (state) => ({
   exhibitionState: state.exhibition,
   exhibitionTypeQuantity: state.exhibition.exhibitionTypeQuantity,
   exhibitionTypeKind: state.exhibition.exhibitionTypeKind,
   institutionType: state.exhibition.institutionType,
   locationType: state.exhibition.locationType,
   isEditAfterVerification: state.exhibition.isEditAfterVerification
});

const mapDispatchToProps = (dispatch) => ({
   loadExhibitionStaticData: () => dispatch(loadExhibitionStaticData())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ExhibitionBasicInformation);
