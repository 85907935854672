import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { homepageAnimation } from './animations/homepage-animation';
import { searchForTerm } from '../services/api';
import ArtworkDescription from '../components/artwork/artwork-description';
import { v4 as uuid } from 'uuid';
import AuthorDescription from '../components/author/author-description';
import ExhibitionDescription from '../components/exhibition/exhibition-description';
import AuthorGroupDescription from '../components/author/author-group-description';
import { searchResultAnimation } from './animations/search-animation';
import { useAnimation } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { redirectToErrorPage } from '../store/common';

const searchFilter = [
   {
      text: 'Sve',
      value: 0
   },
   {
      text: 'Umjetnici autori',
      value: 1
   },
   {
      text: 'Umjetnička djela',
      value: 2
   },
   {
      text: 'Mjesta izlaganja',
      value: 3
   }
];

const HomePageID = (props) => {
   const pageSize = 200;
   const [moreIndex, setMoreIndex] = useState(1);
   const [showLoadMore, setShowLoadMore] = useState(false);
   const [SearchWhat, setSearchWhat] = useState(0);
   const [SearchTerm, setSearchTerm] = useState('');
   const [responseData, setResponseData] = useState([]);
   const [isLoadingData, setIsLoadingData] = useState(false);
   const controls = useAnimation();
   const { artworks, authors: allAuthors, exhibitions } = responseData;

   const dispatch = useDispatch();

   useEffect(() => {
      document.title = 'Digitalni arhiv akademije likovnih umjetnosti';
      const { id } = props.match.params;

      switch (id) {
         case 'authors':
            setSearchWhat(1);
            setSearchTerm('%');
            break;
         case 'artworks':
            setSearchWhat(2);
            setSearchTerm('%');
            break;
         case 'exhibitions':
            setSearchWhat(3);
            setSearchTerm('%');
            break;

         default:
            break;
      }

      return () => {};
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   /*
        Izracunaj koliko je setova podataka prikazano i na osnovu toga podesi
        moreIndex varijablu.
        Ako su prikazani potpuni podaci prikazanih setova 
        prikaži gumb za učitavanje još podataka
    */
   useEffect(() => {
      const { id } = props.match.params;
      let dataLength = 0;

      const getArrLength = (arr) =>
         responseData[arr] ? responseData[arr].length : 0;

      switch (id) {
         case 'authors':
            dataLength = getArrLength('authors');
            break;
         case 'artworks':
            dataLength = getArrLength('artworks');
            break;
         case 'exhibitions':
            dataLength = getArrLength('exhibitions');
            break;
         default:
            break;
      }

      const pages = Math.floor(dataLength / pageSize);
      setMoreIndex(pages);

      if (dataLength % pageSize === 0) setShowLoadMore(true);
      else setShowLoadMore(false);
   }, [responseData]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      const fetchData = async () => {
         setIsLoadingData(true);
         const getTopN = pageSize * (moreIndex + 1);
         const { data: response } = await searchForTerm({
            SearchWhat,
            SearchTerm,
            getTopN
         });
         setResponseData(response.data);
         setIsLoadingData(false);
      };
      if (SearchTerm.trim() !== '') fetchData();
      else setResponseData([]);

      if (SearchTerm) controls.start('hidden');
      else controls.start('show');

      dispatch(redirectToErrorPage(false));
   }, [SearchTerm, SearchWhat]); // eslint-disable-line react-hooks/exhaustive-deps

   const isResponseEmpty = () => {
      if (Array.isArray(responseData)) return true;

      const { artworks, authors, exhibitions } = responseData;
      if (
         artworks.length === 0 &&
         authors.length === 0 &&
         exhibitions.length === 0
      )
         return true;
      return false;
   };

   // const handleDDLChange = ({ target: input }) => {
   //     setSearchWhat(Number(input.value));
   // };

   // const handleOnSearch = (searchValue) => {
   //     setSearchTerm(searchValue);
   // };

   const authors = allAuthors
      ? allAuthors.filter((a) => a.AuthorTypeId === 1)
      : [];
   const authorGroups = allAuthors
      ? allAuthors.filter((a) => a.AuthorTypeId === 2)
      : [];

   const loadMore = (
      <button
         style={{
            fontSize: '1.2rem',
            border: 'none',
            background: 'none',
            opacity: 0.75,
            outline: 'none',
            textAlign: 'center',
            color: 'rgb(110, 57, 255)'
         }}
         onClick={() => {
            // setMoreIndex((prevState) => {
            //     return prevState + 1;
            // });
            const getTopN = pageSize * (moreIndex + 1);
            console.log(getTopN, 'getTopN');
            const fetchData = async () => {
               setIsLoadingData(true);
               const { data: response } = await searchForTerm({
                  SearchWhat,
                  SearchTerm,
                  getTopN
               });
               setResponseData(response.data);
               setIsLoadingData(false);
            };
            fetchData();
         }}
      >
         <span
            style={{
               fontSize: '1rem',
               display: 'block',
               marginBottom: '-.5rem'
            }}
         >
            {`Učitaj slijedećih ${pageSize}...`}
         </span>
         {/* <CloudDownloadOutlined /> */}
      </button>
   );

   return (
      <>
         {isLoadingData && (
            <p style={{ textAlign: 'center', opacity: 0.25 }}>Učitavanje...</p>
         )}
         <MotionContainer
            className='homepage'
            animationObject={homepageAnimation}
         >
            {!isResponseEmpty() && (
               <MotionContainer animationObject={searchResultAnimation}>
                  {authors && authors.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[1].text}</h2>
                           <h4>Kliknite na ime autora za više detalja</h4>
                        </div>
                        {authors.map((author) => (
                           <AuthorDescription key={uuid()} data={author} />
                        ))}
                        {showLoadMore && loadMore}
                     </div>
                  )}

                  {authorGroups && authorGroups.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[1].text} - grupe</h2>
                           <h4>Kliknite na ime grupe autora za više detalja</h4>
                        </div>
                        {authorGroups.map((group) => (
                           <AuthorGroupDescription key={uuid()} data={group} />
                        ))}
                        {showLoadMore && loadMore}
                     </div>
                  )}

                  {artworks && artworks.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[2].text}</h2>
                           <h4>
                              Kliknite na ime umjetničkog djela za više detalja
                           </h4>
                        </div>
                        {artworks.map((artwork) => (
                           <ArtworkDescription data={artwork} key={uuid()} />
                        ))}
                        {showLoadMore && loadMore}
                     </div>
                  )}

                  {exhibitions && exhibitions.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[3].text}</h2>
                           <h4>Kliknite na ime izložbe za više detalja</h4>
                        </div>
                        {exhibitions.map((exhibition) => (
                           <ExhibitionDescription
                              key={uuid()}
                              data={exhibition}
                           />
                        ))}
                        {showLoadMore && loadMore}
                     </div>
                  )}
               </MotionContainer>
            )}
         </MotionContainer>
      </>
   );
};

export default HomePageID;
