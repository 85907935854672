import React, { useState } from 'react';
import PopupContainer from '../common/popup-container/popup-container';

import html from '../../services/html';
import { v4 as uuid } from 'uuid';
import Collection from './collection';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';

const AddNewArtworkCollection = ({ onAdd, onClose }) => {
   const [Url, setUrl] = useState('');
   const [error, setError] = useState(null);

   const [CollectionName, setCollectionName] = useState('');
   const [CollectionId, setCollectionId] = useState(0);
   const [ArtworkCollectionId, setArtworkCollectionId] = useState(null);

   const handleOnInputChange = ({ target: input }) => {
      setUrl(input.value.trim());
   };

   const handleOnSubmit = () => {
      const newCollection = {
         id: uuid(),
         CollectionId,
         ArtworkCollectionId,
         Url
      };
      onAdd(newCollection);
      onClose();
   };

   const onCollectionSelect = (value, obj) => {
      const { id, value: name } = obj;
      setCollectionId(id);
      setCollectionName(name);
   };

   const disabled = CollectionId === 0;

   return (
      <PopupContainer title='Zbirka' onPopupClose={onClose}>
         <Collection onGallerySelect={onCollectionSelect} />
         {html.renderSFCInput('URL', 'Url', error, handleOnInputChange)}

         <div style={{ marginTop: '2rem' }}>
            <ButtonAdd disabled={disabled} onClick={handleOnSubmit} />
            <ButtonCancel
               onClick={() => {
                  onClose();
               }}
            />
         </div>
      </PopupContainer>
   );
};

export default AddNewArtworkCollection;
