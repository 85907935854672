import Joi from 'joi-browser';

export const authorPersonalDataSchema = {
    FirstName: Joi.string()
        .required()
        .label('Ime')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezno`
            };
        }),
    LastName: Joi.string()
        .required()
        .label('Prezime')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezno`
            };
        })
};

//bla: Joi.any().optional(),
