import Joi from 'joi-browser';

export const AddNewSpecializationTypeSchema = {
   FinancialSupportSource: Joi.string()
      .required()
      .max(500)
      .label('Izvor financijske potpore')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} - je obavezan unos`
         };
      })
};
