import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import AddNewArtworkAward from './add-new-artwork-award';
import ButtonForm from './../common/btn-form';

/*
    props
        onAddArtworkAward()
*/

const ArtworkAward = ({ onAddArtworkAward, defaultValue, disabled }) => {
   const [showAddAward, setShowAddAward] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   // const { cities } = useSelector((state) => state.author);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddArtworkAward(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewAward = () => {
      setShowAddAward(!showAddAward);
   };

   const handle_OnAwardAdded = (authorAwardData) => {
      setKolekcija([...kolekcija, authorAwardData]);
   };

   const handle_RemoveFromAwardList = (id) => {
      setKolekcija(kolekcija.filter((work) => work.id !== id));
   };

   return (
      <>
         {showAddAward && (
            <AddNewArtworkAward
               onAwardAdded={handle_OnAwardAdded}
               closeAddNewAward={showHide_AddNewAward}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               Nagrade
            </label>
            <ButtonForm text='Dodaj nagradu' onClick={showHide_AddNewAward} />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih nagrada...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Naziv</th>
                           {/* <th>Grad</th> */}
                           <th>Godina</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((award) => (
                           <tr key={uuid()}>
                              {html.td(230, award.Name)}
                              {/* {html.td(120, award.CityName)} */}
                              {html.td(60, award.Year)}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromAwardList,
                                 award.id,
                                 {
                                    title: `Ukloni ${award.Name}`
                                 },
                                 disabled
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ArtworkAward;
