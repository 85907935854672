import React from 'react';
import MotionContainer from '../../common/motion-containers/motion-container';
import { userIsAdmin } from '../../../services/auth';
import ArtworkSubmenuButton from './artwork-submenu-button';
import { artwork_submenu_animation } from './artwork-submenu-animations';
import MorphButton from './../../common/morph-button';
import { withRouter } from 'react-router-dom';

const thickness = 6;
const color = '#dd6e78';
const size = 75;

const ArtworkMenu = ({ history }) => {
   if (userIsAdmin()) history.push(`${process.env.PUBLIC_URL}/list-artworks`);

   return (
      <MotionContainer
         className='authors-menu'
         animationObject={artwork_submenu_animation}
      >
         {!userIsAdmin() && (
            <ArtworkSubmenuButton
               text='Dodaj djelo'
               path='add-artwork'
               icon={
                  <MorphButton
                     type='crossSparks'
                     rolloverType='plus'
                     thickness={thickness}
                     color={color}
                     size={size}
                  />
               }
            />
         )}
         <ArtworkSubmenuButton
            text='Pregled djela'
            path='list-artworks'
            icon={
               <MorphButton
                  type='bars'
                  rolloverType='arrowRight'
                  thickness={thickness}
                  color={color}
                  size={size}
               />
            }
         />
      </MotionContainer>
   );
};

export default withRouter(ArtworkMenu);
