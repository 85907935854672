import React from 'react';
import { Descriptions } from 'antd';
import { useHistory } from 'react-router-dom';

const ArtworkDescription = ({ data }) => {
    const {
        ArtworkId,
        Description,
        Dimensions,
        ArtistStatement,
        AreaOfInterests,
        Remark
    } = data;

    const history = useHistory();
    return (
        <div className='search-description'>
            <Descriptions
                title={
                    <button
                        type='button'
                        className='btn btn-link description-tearm'
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                            history.push(
                                `${process.env.PUBLIC_URL}/details-artwork/${ArtworkId}`
                            );
                        }}
                    >{`${data.Name}`}</button>
                }
                bordered
                size='small'
            >
                <Descriptions.Item label='Opis' style={{ minWidth: '8rem' }}>
                    {Description ? Description : '-'}
                </Descriptions.Item>
                <Descriptions.Item label='Dimenzije'>
                    {Dimensions ? Dimensions : '-'}
                </Descriptions.Item>
                <Descriptions.Item label='Izjava umjetnika'>
                    {ArtistStatement ? ArtistStatement : '-'}
                </Descriptions.Item>
                <Descriptions.Item label='Tehnika'>
                    {AreaOfInterests ? AreaOfInterests : '-'}
                </Descriptions.Item>
                <Descriptions.Item label='Napomena'>
                    {Remark ? Remark : '-'}
                </Descriptions.Item>
            </Descriptions>
            <hr />
        </div>
    );
};

export default ArtworkDescription;
