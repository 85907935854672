import Joi from 'joi-browser';

export const AddNewEducationLevelSchema = {
   Thesis: Joi.string()
      .max(500)
      .label('Završni/diplomski rad')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} - dozvoljeno maksimalno 500 znakova`
         };
      })
};
