import React, { useState } from 'react';
import UserConfirmationEdit from './user-confirmation-edit';
import { useSelector, useDispatch } from 'react-redux';
import { loadListConfirmed } from '../store/list-confirmed';
import { updateUser } from '../services/api';
import html from '../services/html';
import DataTable from './common/table/datatable';

const UserConfirmation = (props) => {
   const [showEditUser, setShowEditUser] = useState(false);
   const [editUser] = useState({});
   const dispatch = useDispatch();

   const handleOnEditSave = async (user) => {
      const { data: response } = await updateUser(user);
      console.log('updated user', user);
      console.log('reponse', response);

      setShowEditUser(false);
   };

   const showUserEditor = () => {
      setShowEditUser(!showEditUser);
   };

   const handleUserActivation = async (user) => {
      console.log(user);
      const updatedUser = { ...user };
      updatedUser.StatusId = updatedUser.StatusId === 3 ? 4 : 3;

      const { data: response } = await updateUser(updatedUser);
      console.log('handleUserActivation', response);
      dispatch(loadListConfirmed());

      console.log('updatedUser', updatedUser);
   };

   const isApprovedByAdmin = (user) => user.StatusId === 3;

   const { users } = useSelector((state) => state.listOfUnconfirmedUsers);

   const columns = [
      {
         sortable: true,
         label: 'Ime',
         path: 'FirstName'
      },
      {
         sortable: true,
         label: 'Prezime',
         path: 'LastName'
      },
      {
         key: 'UsernameAndEmail',
         label: 'Email',
         style: { textAlign: 'left' },
         content: (item) => (
            <a href={`mailto:${item.UsernameAndEmail}`}>
               {item.UsernameAndEmail}
            </a>
         )
      },
      {
         key: 'AddedDate',
         label: 'Datum dodavanja',
         style: { textAlign: 'left' },
         content: (item) => <span> {html.convertDateTime(item.AddedDate)}</span>
      },
      {
         key: 'IsAffiliated',
         label: 'Afilijacija',
         style: { textAlign: 'center' },
         content: (item) => <span> {item.IsAffiliated ? 'DA' : 'NE'}</span>
      },
      {
         sortable: true,
         label: 'Institucija',
         path: 'AffiliationName'
      },
      {
         sortable: true,
         label: 'Adresa',
         path: 'AffiliationAddress'
      },
      {
         sortable: true,
         label: 'Grad',
         path: 'AffiliationCity'
      },
      {
         key: 'btnEdit',
         style: { textAlign: 'right' },
         content: (item) => (
            <button
               className='btn btn-sm btn-primary'
               style={{
                  backgroundColor: isApprovedByAdmin(item) ? '#f00' : '#026973'
               }}
               onClick={() => handleUserActivation(item)}
            >
               {isApprovedByAdmin(item) ? 'Deaktiviraj' : 'Aktiviraj'}
            </button>
         )
      }
   ];

   return (
      <div className='tbl-container'>
         <h2>Korisnici</h2>
         {showEditUser && (
            <UserConfirmationEdit
               closeUserEditor={showUserEditor}
               editUser={editUser}
               onEditSave={handleOnEditSave}
            />
         )}
         <div className='user-confirmation'>
            <DataTable
               pageSize={10}
               columns={columns}
               data={users}
               hideSearch={false}
               searchLabel='Pretraži korisnike'
               searchLabelPlaceholder='Započni pretragu...'
               searchColumns={['FirstName', 'LastName', 'UsernameAndEmail']}
               readonly={false}
               onRowClick={(item) => console.log(item)}
               markRowOnClick={false}
            />
         </div>
      </div>
   );
};

export default UserConfirmation;
