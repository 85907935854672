import React from 'react';
import { Tooltip } from 'antd';

const ShowTooltip = (props) => {
    let { title, placement, tooltipColor, rest } = props;

    title = title === undefined ? 'Ja sam tooltip' : title;
    placement = placement === undefined ? 'top' : placement;
    tooltipColor = tooltipColor === undefined ? '#000' : tooltipColor;

    return (
        <Tooltip
            overlayStyle={{
                boxShadow: '0 0 0 #000'
            }}
            title={title}
            color={tooltipColor}
            placement={placement}
            {...rest}
        >
            {props.children}
        </Tooltip>
    );
};

export default ShowTooltip;
