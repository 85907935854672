import React, { useState } from 'react';
import { createOrUpdateCollection } from '../../services/api';
import { toast } from 'react-toastify';
import PopupContainer from '../common/popup-container/popup-container';
import html from '../../services/html';
import AddAddress from '../common/add-address';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';

const AddNewCollection = ({ onClose }) => {
   const [Name, setName] = useState('');
   const [Address, setAddress] = useState('');
   const [GeoLatitude, setGeoLatitude] = useState('');
   const [GeoLongitude, setGeoLongitude] = useState('');
   const [CityId, setCityId] = useState(0);
   const [error, setError] = useState(null);

   const handleOnInputChange = ({ target: input }) => {
      setName(input.value.trim());
      if (input.value.trim() === '') setError('Naziv zbirke je obavezan unos');
      else setError('');
   };

   const addNewCollection = async () => {
      const newCollection = {
         CityId,
         Name,
         Address,
         GeoLatitude,
         GeoLongitude
      };

      const response = await createOrUpdateCollection(newCollection);
      const { status, message } = response.data;

      if (status === 1) {
         toast.info('Zbirka uspješno dodana');
         onClose();
      } else toast.error(`${message}`);
   };

   const handleOnSubmit = async (e) => {
      e.stopPropagation();
      addNewCollection();
   };

   const handleOnCitySelect = (cityId) => {
      console.log('cityId', cityId);
      setCityId(cityId);
   };

   const handleTextInputChange = ({ target: input }) => {
      switch (input.name) {
         case 'Address':
            setAddress(input.value);
            break;
         case 'GeoLatitude':
            setGeoLatitude(input.value);
            break;
         case 'GeoLongitude':
            setGeoLongitude(input.value);
            break;
         default:
            break;
      }
   };

   const handleOnMapClick = ({ GeoLatitude, GeoLongitude, Address }) => {
      setAddress(Address);
      setGeoLatitude(GeoLatitude);
      setGeoLongitude(GeoLongitude);
   };

   const disabled = error || error === null || CityId === 0;

   return (
      <PopupContainer title='Dodavanje zbirke' onPopupClose={onClose}>
         {html.renderSFCInput(
            'Naziv zbirke',
            'Name',
            error,
            handleOnInputChange
         )}

         <AddAddress
            onCitySelect={handleOnCitySelect}
            onTextInputChange={handleTextInputChange}
            onMapClick={handleOnMapClick}
         />

         <hr />

         <ButtonAdd
            text='Dodaj novu zbirku'
            disabled={disabled}
            onClick={handleOnSubmit}
         />
         <ButtonCancel
            onClick={() => {
               onClose();
            }}
         />
      </PopupContainer>
   );
};

export default AddNewCollection;
