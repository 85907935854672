import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

/*
    Generirao sam vlastitu listu [imageList] da ne diram u funkcionalnost komponente
    Svaka slika dobije uid unutar komponente pa sam tu informaciju vezao uz svaki objekt slike
    u vlastitoj kolekciji [imageList] na svojstvo id.... tako ih brišem u [handleOnRemove]
*/

const maxFileSize = 1000000;

//shape: round/rect
const ImageUpload = ({
   aspect,
   shape,
   max = 5,
   onPhotoAdded,
   onPhotoRemove,
   defaultComponentValues,
   defaultArtworkPhotographies,
   isEditMode,
   disable
}) => {
   const [fileList, setFileList] = useState([]);
   const [imageList, setImageList] = useState([]);
   const [portraitPicture, setPortraitPicture] = useState(false);
   const [fileSizeNotAllowed, setFileSizeNotAllowed] = useState(false);
   const [notAllowedFileName, setNotAllowedFileName] = useState('');
   const [notAllowedFileList, setNotAllowedFileList] = useState([]);

   useEffect(() => {
      setFileList(defaultComponentValues);
      setImageList(defaultArtworkPhotographies);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onPhotoAdded(imageList, fileList);
   }, [imageList, fileList]); // eslint-disable-line react-hooks/exhaustive-deps

   /*
    fileList je kolekcija koju puni Upload komponenta
    datoteke koje nisu dozvoljene moram iz te kolekcije filtrirati
    */
   const onChange = ({ fileList: newFileList }) => {
      if (fileSizeNotAllowed) {
         console.log('nedozvoljena velicina');
         setFileSizeNotAllowed(false);
         setNotAllowedFileList([...notAllowedFileList, newFileList.pop()]);
         return;
      }
      console.log('newFileList', newFileList);
      const filteredFileList = newFileList.filter((f) => {
         return !notAllowedFileList.find(
            (ff) => ff.uid === f.originFileObj.uid
         );
      });

      //response i status kako bi maknuo tooltip nad thumbnailom
      const fileList = filteredFileList.map((f) => ({
         ...f,
         response: '',
         status: 'success'
      }));

      setTimeout(() => {
         setFileList(fileList);
      }, 250);
   };

   const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = (error) => reject(error);
      });
   };

   /*
            U fileList kolekciji su sve informacije o fotki, no njih nemam kada sam u edit modu
            Zato spriječavam čitanje bloba kojega nema
    */
   const onPreview = async (file) => {
      if (isEditMode) return;
      let src = file.url;

      if (!src) {
         src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
         });
      }

      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
   };

   //return false da spriječim automatski upload slike
   const handleBeforeUpload = async (file) => {
      if (file.size > maxFileSize) {
         setFileSizeNotAllowed(true);
         setNotAllowedFileName(file.name);
         return false;
      }
      setNotAllowedFileName('');

      const imageFile = {
         id: file.uid,
         PhotoFileContentBase64: await getBase64(file),
         PhotoOriginalFileName: file.name
      };

      setImageList([...imageList, imageFile]);
      return false;
   };

   const handleOnRemove = (removedImage) => {
      const images = imageList.filter((f) => f.id !== removedImage.uid);
      setImageList(images);
      onPhotoRemove(imageList, fileList);
   };

   const handleAspectRatio = ({ target: input }) => {
      const { checked } = input;
      setPortraitPicture(checked);
   };

   return (
      <div>
         <div className='form-group form-check' style={{ textAlign: 'center' }}>
            <input
               type='checkbox'
               className='form-check-input'
               id='aspect'
               onChange={handleAspectRatio}
            />
            <label htmlFor='aspect' className='form-check-label'>
               Portretni omjer slike
            </label>
         </div>
         <div style={{ textAlign: 'center' }}>
            <ImgCrop
               aspect={portraitPicture ? 2 / 3 : 3 / 2}
               minZoom={1}
               maxZoom={10}
               modalTitle='Uređivanje slike'
               modalWidth={800}
               modalCancel='Odustani'
               grid={true}
               quality={0.8}
               rotate
               fillColor='black'
               shape={shape ? shape : 'rect'}
            >
               <Upload
                  listType='picture-card'
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  beforeUpload={handleBeforeUpload}
                  onRemove={handleOnRemove}
               >
                  {fileList.length < max && '+ Odaberi'}
               </Upload>
            </ImgCrop>
            {notAllowedFileName && (
               <span
                  className='alert alert-primary'
                  style={{ fontSize: '.9rem' }}
               >
                  <span>
                     Slika <b>{notAllowedFileName}</b> se ne može dodati jer je
                     veća od <b>1MB</b>.<br />
                     Odaberite manju datoteku ili odrežite segment{' '}
                     <b>{notAllowedFileName}</b> slike prije samog odabira kako
                     bi joj smanjili veličinu.
                  </span>
               </span>
            )}
         </div>
      </div>
   );
};

export default ImageUpload;
