import React from 'react';
import html from '../../../services/html';
import MotionContainer from './../../common/motion-containers/motion-container';
import { authorsAnim } from './authors-anim';
import { userIsAdmin } from './../../../services/auth';
import VerificationIndicator from './../../common/verification-indicator';
import DataTable from './../../common/table/datatable';

const TableCreatedAuthorList = ({
   authors: allAuthors,
   onEdit,
   onDelete,
   onToVerification,
   enableEdit
}) => {
   const getBtnVerificirajText = (author) =>
      author.IsVerified ? 'Verificiran' : 'Verificiraj';

   const columns = [
      {
         sortable: true,
         label: 'Ime',
         path: 'FirstName'
      },
      {
         sortable: true,
         label: 'Prezime',
         path: 'LastName'
      },
      {
         key: 'Napomena',
         label: 'Napomena',
         style: { textAlign: 'left' },
         content: (item) => <span> {item.Remark ? item.Remark : '-'}</span>
      },
      {
         key: 'Email',
         label: 'E-mail',
         style: { textAlign: 'left' },
         content: (item) => (
            <a href={`mailto:${item.ContactEmail}`}>{item.ContactEmail}</a>
         )
      },
      {
         sortable: true,
         label: 'Telefon',
         path: 'ContactPhone'
      },
      {
         key: 'VrijemeKreiranja',
         label: 'Vrijeme kreiranja',
         style: { textAlign: 'left' },
         content: (item) => html.convertDateTime(item.AddedDate)
      },
      {
         key: 'btn1',
         label: 'Verificirano',
         style: { textAlign: 'center' },
         content: (item) => (
            <>
               {!userIsAdmin() && (
                  <button
                     id='toVerification'
                     className='btn btn-sm btn-primary mr-1'
                     disabled={
                        item.IsReadyForVerification ||
                        (!item.IsReadyForReverification && item.IsVerified)
                     }
                     onClick={() => {
                        onToVerification(
                           item.AuthorId,
                           `${item.FirstName} ${item.LastName}`
                        );
                     }}
                  >
                     {getBtnVerificirajText(item)}
                  </button>
               )}

               <VerificationIndicator item={item} />
            </>
         )
      },
      {
         key: 'btn2',
         label: '',
         style: { textAlign: 'center' },
         content: (item) => (
            <>
               {!userIsAdmin() && !item.IsReadyForVerification && (
                  <button
                     id='edit'
                     className='btn btn-sm btn-secondary mr-1'
                     disabled={enableEdit}
                     onClick={() => {
                        onEdit(item.AuthorId, item.IsVerified);
                     }}
                  >
                     Pregledaj
                  </button>
               )}
            </>
         )
      },
      {
         key: 'btn3',
         label: '',
         style: { textAlign: 'center' },
         content: (item) => (
            <>
               {!userIsAdmin() &&
                  !item.IsReadyForVerification &&
                  !item.IsVerified && (
                     <button
                        id='delete'
                        className='btn btn-sm btn-danger'
                        onClick={() => {
                           onDelete(item, false);
                        }}
                     >
                        Obriši
                     </button>
                  )}
               {userIsAdmin() && (
                  <button
                     id='edit'
                     className='btn btn-sm btn-secondary mr-1'
                     disabled={enableEdit}
                     onClick={() => {
                        onEdit(item.AuthorId, item.IsVerified);
                     }}
                  >
                     Uredi
                  </button>
               )}
            </>
         )
      }
   ];

   return (
      <MotionContainer
         className='table-created-author-list'
         animationObject={authorsAnim}
      >
         <div>
            <h2>Pregled kreiranih autora</h2>

            <DataTable
               pageSize={10}
               columns={columns}
               data={allAuthors}
               hideSearch={false}
               searchLabel='Pretraži autore'
               searchLabelPlaceholder='Započni pretragu...'
               searchColumns={['FirstName', 'LastName']}
               readonly={false}
               onRowClick={(item) => console.log(item)}
               markRowOnClick={false}
            />
         </div>
      </MotionContainer>
   );
};

export default TableCreatedAuthorList;
