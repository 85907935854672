import React from 'react';
import Form from '../common/form';
import { v4 as uuid } from 'uuid';
import AddCity from '../common/add-city';
import PopupContainer from '../common/popup-container/popup-container';
import { AddNewExhibitionMediaAnnouncementSchema } from './../../validators/add-new-exhibition-media-announcement';
import { connect } from 'react-redux';
import MediaAnnouncementType from './media-announcement';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';

class AddNewExhibitionMediaAnnouncement extends Form {
   state = {
      validationData: {
         Authors: '',
         Name: '',
         Edition: '',
         ExhibitionMediaAnnouncements: [],
         Url: '',
         MediaAnnouncementTypeId: null,
         MediaAnnouncementTypeOther: ''
      },
      restData: {
         ExhibitionCatalogueId: null,
         Year: '',
         CountryId: null,
         CityId: null,
         CityName: ''
      },
      legalEntitySelected: {},
      errors: []
   };

   schema = AddNewExhibitionMediaAnnouncementSchema;

   render() {
      const { Authors, Name, Edition, ExhibitionMediaAnnouncements } =
         this.state.validationData;
      const { Year, CountryId, CityId } = this.state.restData;
      const { countries } = this.props.commonState;
      const { closeAddNewMedia } = this.props;
      const disabled =
         Authors === '' ||
         Name === '' ||
         Edition === '' ||
         Year === '' ||
         ExhibitionMediaAnnouncements === '' ||
         CityId === null;

      return (
         <PopupContainer
            title='Dodavanje medijske objave'
            onPopupClose={closeAddNewMedia}
         >
            <div className='row'>
               <div className='col'>{this.renderInput('Name', 'Naslov')}</div>
               <div className='col'>{this.renderInput('Authors', 'Autor')}</div>
            </div>

            <div className='row'>
               <div className='col'>
                  {this.renderInput('Edition', 'Izdanje')}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Godina', this.handleYearSelected, {
                     picker: 'year',
                     placeholder: 'Odaberite godinu...',
                     format: 'YYYY'
                  })}
               </div>
            </div>

            <div className='row'>
               <div className='col'>{this.renderTextArea('Url', 'URL')}</div>
            </div>

            <div className='row'>
               <div className='col'>
                  <MediaAnnouncementType
                     onChange={this.handleMediaAnnouncementChange}
                  />
               </div>
            </div>

            <div className='row'>
               <div className='col'>
                  {this.renderSelectAnt(
                     'Država',
                     'Name',
                     'CountryId',
                     'Započnite unos ili odaberite državu...',
                     'Ne pronalazim traženu državu...',
                     countries,
                     this.handleCountrySelected
                  )}
               </div>
            </div>

            <div className='row'>
               <div className='col'>
                  {CountryId && (
                     <AddCity
                        countryId={CountryId}
                        onCitySelect={this.handleCitySelected}
                     />
                  )}
               </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewMedia();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleMediaAnnouncementChange = ({
      MediaAnnouncementTypeId,
      MediaAnnouncementTypeOther
   }) => {
      const validationData = { ...this.state.validationData };
      validationData.MediaAnnouncementTypeId = MediaAnnouncementTypeId;
      validationData.MediaAnnouncementTypeOther = MediaAnnouncementTypeOther;

      this.setState({ validationData });
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewMedia();
   };

   handleOnSubmit = () => {
      const { onMediaAdded, closeAddNewMedia } = this.props;
      const newCatalogue = {
         ...this.state.validationData,
         ...this.state.restData
      };
      newCatalogue.id = uuid();
      onMediaAdded(newCatalogue);
      closeAddNewMedia();
   };

   handleCountrySelected = async (value, obj) => {
      const { id: CountryId } = obj;

      const restData = { ...this.state.restData };
      restData.CountryId = CountryId;

      this.setState({
         restData
      });
   };

   handleYearSelected = (e) => {
      if (!e) return;
      const { restData } = this.state;
      restData.Year = Number(e.format('YYYY'));
      this.setState({ restData });
   };

   handleCitySelected = (value, obj) => {
      const { id: CityId, value: CityName } = obj;

      const restData = { ...this.state.restData };
      restData.CityId = CityId;
      restData.CityName = CityName;

      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   commonState: state.common
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewExhibitionMediaAnnouncement);
