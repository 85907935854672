import { LoadingOutlined } from '@ant-design/icons';

export const ucitavamPodatke = (
    <div>
        <span style={{ color: '#fff' }}>
            <LoadingOutlined />
            <br />
            Učitavam podatke...
        </span>
        <br />
    </div>
);

export const ucitavamDodatnePodatke = (
    <div>
        <span style={{ color: '#333' }}>
            <LoadingOutlined />
            <br />
            Učitavam dodatne podatke...
        </span>
        <br />
        <span style={{ fontSize: '.6rem' }}>
            Dodatni podaci se učitavaju samo prilikom prvog pristupa
        </span>
    </div>
);

export const uredjivanjeCeBitiOmoguceno = (
    <div>
        <LoadingOutlined />
        <br />
        <span style={{ color: '#333' }}>
            Uređivanje će biti omogućeno za koji trenutak...
        </span>
        <br />
        <span style={{ fontSize: '.6rem' }}>
            Omogućavanje uređivanja se odvija samo prilikom prvog pristupa
        </span>
    </div>
);
