import React from 'react';
import { AuthorProfessionalActivitySchema } from '../../../validators/author-professional-activity';
import Form from '../../common/form';
import AuthorAreaOfInterest from './author-area-of-interest';
import AuthorAward from './author-award';
import AuthorBibliography from './author-bibliography';
import AuthorAssociation from './author-association';
import AuthorAtelier from './author-atelier';
import AddAuthorRepresentation from './add-author-representation';
import MotionContainer from '../../common/motion-containers/motion-container';
import AuthorWorkExperience from './author-work-experience';
import { connect } from 'react-redux';
import { userIsAdmin } from './../../../services/auth';
import { Checkbox } from 'antd';
import AuthorAreaOfActivity from './author-area-of-activity';
import { motion } from 'framer-motion';
import ButtonNext from './../../common/btn-next';

class AuthorProfessionalActivityInformation extends Form {
   constructor(props) {
      super(props);

      const {
         ShortBiography,
         ArtGroupOrCollective,
         IsAuthorRepresentation,
         IsArtGroupOrCollective,
         IsArtworkInCollections,
         ArtworkInCollectionsDescription,
         AuthorAreaOfInterests,
         AuthorAreaOfActivities,
         AuthorRepresentations,
         AuthorWorkExperiences,
         AuthorAwards,
         AuthorBibliographies,
         AuthorAssociations,
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         AtelierCityName
      } = props.data;

      this.ShortBiography = ShortBiography;
      this.ArtGroupOrCollective = ArtGroupOrCollective;

      this.IsAuthorRepresentation = IsAuthorRepresentation;
      this.IsArtworkInCollections = IsArtworkInCollections;
      this.ArtworkInCollectionsDescription = ArtworkInCollectionsDescription;
      this.AuthorAreaOfInterests = AuthorAreaOfInterests;
      this.AuthorAreaOfActivities = AuthorAreaOfActivities;
      this.AuthorRepresentations = AuthorRepresentations;
      this.AuthorWorkExperiences = AuthorWorkExperiences;
      this.AuthorAwards = AuthorAwards;
      this.AuthorBibliographies = AuthorBibliographies;
      this.AuthorAssociations = AuthorAssociations;
      this.IsAtelier = IsAtelier;
      this.AtelierAddress = AtelierAddress;
      this.AtelierGeoLatitude = AtelierGeoLatitude;
      this.AtelierGeoLongitude = AtelierGeoLongitude;
      this.AtelierCityName = AtelierCityName;
      this.IsArtGroupOrCollective = IsArtGroupOrCollective;

      this.state = {
         validationData: {
            ShortBiography: this.ShortBiography ?? '',
            ArtGroupOrCollective: this.ArtGroupOrCollective ?? ''
         },
         restData: {
            IsArtworkInCollections: false,
            ArtworkInCollectionsDescription: '',
            AuthorAreaOfInterests: this.AuthorAreaOfInterests ?? [],
            AuthorAreaOfActivities: this.AuthorAreaOfActivities ?? [],
            IsAuthorRepresentation: this.IsAuthorRepresentation ?? false,
            AuthorRepresentations: this.AuthorRepresentations ?? [],
            AuthorWorkExperiences: this.AuthorWorkExperiences ?? [],
            AuthorAwards: this.AuthorAwards ?? [],
            AuthorBibliographies: this.AuthorBibliographies ?? [],
            AuthorAssociations: this.AuthorAssociations ?? [],
            IsAtelier: this.IsAtelier ?? false,
            AtelierAddress: this.AtelierAddress ?? '',
            AtelierGeoLatitude: this.AtelierGeoLatitude ?? null,
            AtelierGeoLongitude: this.AtelierGeoLongitude ?? null,
            AtelierCityName: this.AtelierCityName ?? '',
            IsArtGroupOrCollective: this.IsArtGroupOrCollective ?? false
         },
         errors: {}
      };
   }

   schema = AuthorProfessionalActivitySchema;

   isValid = false;

   async componentDidMount() {}

   componentWillUnmount() {
      this.doSubmit();
   }

   handleIsArtGroupOrColective = () => {
      const restData = { ...this.state.restData };
      restData.IsArtGroupOrCollective = !restData.IsArtGroupOrCollective;
      this.setState({ restData });
   };

   render() {
      const {
         AuthorAreaOfInterests,
         AuthorAreaOfActivities,
         IsArtworkInCollections,
         ArtworkInCollectionsDescription,
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         IsAuthorRepresentation,
         AuthorRepresentations,
         AtelierCityName,
         AuthorWorkExperiences,
         AuthorAwards,
         AuthorBibliographies,
         AuthorAssociations,
         IsArtGroupOrCollective
      } = this.state.restData;

      const nijeUnioPodrujaInteresa = AuthorAreaOfInterests.length === 0;
      const nijeUnioumjetnickaDjela =
         IsArtworkInCollections && ArtworkInCollectionsDescription.length === 0;
      const nijeUnioAtelje = IsAtelier && AtelierAddress.length === 0;
      const nijeUnioGalerijskaZastupstva =
         IsAuthorRepresentation && AuthorRepresentations.length === 0;

      //const disable = this.validateForm();
      this.isValid = !(
         nijeUnioPodrujaInteresa ||
         nijeUnioumjetnickaDjela ||
         nijeUnioAtelje ||
         nijeUnioGalerijskaZastupstva
      );

      const artworkRepresentations_defaultValue = {
         IsAuthorRepresentation,
         AuthorRepresentations
      };

      const atelier_defaultValue = {
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         AtelierCityName
      };

      const { isEditAfterVerification } = this.props.authorState;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      return (
         <>
            <MotionContainer className='author-professional-activity'>
               <form
                  onSubmit={this.handleFormSubmit}
                  className='form-style-width'
               >
                  <h2>Podaci o profesionalnom djelovanju</h2>
                  <div className='row'>
                     <div className='col-6'>
                        {this.renderTextArea('ShortBiography', 'Biografija', {
                           style: { height: '6rem' },
                           placeholder: 'Maksimalno 150 riječi...',
                           disabled: isUserEditAfterVerification
                        })}

                        <Checkbox
                           checked={IsArtGroupOrCollective}
                           onChange={this.handleIsArtGroupOrColective}
                           disabled={isUserEditAfterVerification}
                           style={{ marginBottom: '.5rem' }}
                        >
                           Stavite kvačicu ako je autor član umjetničke
                           grupe/kolektiva
                        </Checkbox>
                        <motion.div
                           animate={{
                              height: IsArtGroupOrCollective ? '4rem' : 0,
                              opacity: IsArtGroupOrCollective ? 1 : 0,
                              transition: { duration: 0.5 }
                           }}
                        >
                           {this.renderInput(
                              'ArtGroupOrCollective',
                              'Pripadnost umjetničkoj grupi/kolektivu',
                              { disabled: isUserEditAfterVerification }
                           )}
                        </motion.div>

                        <hr />
                        <AuthorAreaOfInterest
                           disabled={isUserEditAfterVerification}
                           defaultValue={AuthorAreaOfInterests}
                           onAreaOfInterestSelect={
                              this.handleAuthorAreaOfInterests
                           }
                        />
                        <hr />
                        <AuthorAreaOfActivity
                           disabled={isUserEditAfterVerification}
                           defaultValue={AuthorAreaOfActivities}
                           onAreaOfInterestSelect={
                              this.handleAuthorAreaOfActivity
                           }
                        />
                        <hr />

                        <AddAuthorRepresentation
                           disabled={isUserEditAfterVerification}
                           defaultValue={artworkRepresentations_defaultValue}
                           onArtworkInCollectionsChange={
                              this.handleRepresentationAdded
                           }
                        />
                        <hr />
                        <AuthorAtelier
                           disabled={isUserEditAfterVerification}
                           defaultValue={atelier_defaultValue}
                           onAdd={this.handleAuthorAtelierAdded}
                        />
                     </div>

                     <div className='col-6'>
                        <AuthorWorkExperience
                           defaultValue={AuthorWorkExperiences}
                           onAddWorkExperience={this.handleAuthorWorkExperience}
                        />
                        <hr />
                        <AuthorAward
                           defaultValue={AuthorAwards}
                           onAddAuthorAward={this.handleAwardAdded}
                        />

                        <hr />
                        <AuthorBibliography
                           defaultValue={AuthorBibliographies}
                           onAddAuthorBibliography={
                              this.handle_BibliographyAdded
                           }
                        />
                        <hr />
                        <AuthorAssociation
                           defaultValue={AuthorAssociations}
                           onAdd={this.handle_AssociationsAdded}
                        />
                     </div>
                  </div>
                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(3);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const restData = { ...this.state.restData };
      const validationData = { ...this.state.validationData };
      const data = { ...restData, ...validationData };
      data.isValid = this.isValid;

      this.props.onSubmit(data);
   };

   handleAuthorAreaOfInterests = (authorAreaOfInterests) => {
      const restData = { ...this.state.restData };
      restData.AuthorAreaOfInterests = authorAreaOfInterests;
      this.setState({ restData });
   };

   handleAuthorAreaOfActivity = (authorAreaOfActivities) => {
      const restData = { ...this.state.restData };
      restData.AuthorAreaOfActivities = authorAreaOfActivities;
      this.setState({ restData });
   };

   handleRepresentationAdded = (representations, IsAuthorRepresentation) => {
      const restData = { ...this.state.restData };
      restData.AuthorRepresentations = representations;
      restData.IsAuthorRepresentation = IsAuthorRepresentation;
      this.setState({ restData });
   };

   handleAuthorWorkExperience = (authorWorkExperiences) => {
      const restData = { ...this.state.restData };
      restData.AuthorWorkExperiences = authorWorkExperiences;
      this.setState({ restData });
   };

   handle_BibliographyAdded = (authorBibliographies) => {
      const restData = { ...this.state.restData };
      restData.AuthorBibliographies = authorBibliographies;
      this.setState({ restData });
   };

   handle_AssociationsAdded = (authorAssociations) => {
      const restData = { ...this.state.restData };
      restData.AuthorAssociations = authorAssociations;
      this.setState({ restData });
   };

   handleAwardAdded = (awards) => {
      const restData = { ...this.state.restData };
      restData.AuthorAwards = awards;
      this.setState({ restData });
   };

   handleAuthorAtelierAdded = (atelierData) => {
      const {
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         AtelierCityName
      } = atelierData;

      const restData = { ...this.state.restData };
      restData.IsAtelier = IsAtelier;
      restData.AtelierAddress = AtelierAddress;
      restData.AtelierGeoLatitude = AtelierGeoLatitude;
      restData.AtelierGeoLongitude = AtelierGeoLongitude;
      restData.AtelierCityName = AtelierCityName;

      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   authorState: state.author
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorProfessionalActivityInformation);
