//provjeri jesu li na store učitani svi podaci
export const isStaticDataLoaded = (state) => {
    const {
        exhibitionTypeQuantity,
        exhibitionTypeKind,
        institutionType,
        locationType
    } = state;

    return (
        exhibitionTypeQuantity.length > 0 &&
        exhibitionTypeKind.length > 0 &&
        institutionType.length > 0 &&
        locationType.length > 0
    );
};

const exportObj = {
    isStaticDataLoaded
};

export default exportObj;
