import React from 'react';
import AddNewLegalEntity from './add-new-legal-entity';
import Form from './form';
import ShowTooltip from './show-tooltip';
import { connect } from 'react-redux';
import { loadLegalEnteties } from './../../store/author';
import html from '../../services/html';
import ButtonFormTooltip from './btn-form-tooltip';
class AddLegalEntity extends Form {
   state = {
      validationData: {},
      errors: {},
      legalEntities: [],
      showAddNewLegalEntity: false
   };

   componentDidMount() {
      this.props.loadLegalEntities();
   }

   handleCloseAddNewLegalEntity = (e) => {
      this.props.loadLegalEntities();
      this.setState({ showAddNewLegalEntity: false });
   };

   render() {
      const { showAddNewLegalEntity } = this.state;
      const { label, required, onLegalEntitySelect, defaultValue, disabled } =
         this.props;
      const { legalEntities } = this.props.authorState;

      const legalEntity_defaultValue = defaultValue
         ? {
              value: html.getLegalEntityNameById(defaultValue, legalEntities),
              disabled
           }
         : null;

      return (
         <>
            {showAddNewLegalEntity && (
               <AddNewLegalEntity
                  closeAddNewLegalEntity={this.handleCloseAddNewLegalEntity}
               />
            )}
            <div className='row'>
               <div className='col-8'>
                  {this.renderSelectAnt(
                     label ? label : 'Institucija',
                     'Name',
                     'LegalEntityId',
                     'Započnite unos naziva institucije...',
                     'Ne pronalazim traženu instituciju...',
                     legalEntities,
                     onLegalEntitySelect,
                     null,
                     legalEntity_defaultValue,
                     required
                  )}
               </div>
               <div className='col'>
                  {!disabled && (
                     <ButtonFormTooltip
                        inline={true}
                        text='Dodaj instituciju'
                        onClick={(e) => {
                           e.stopPropagation();
                           this.setState({
                              showAddNewLegalEntity: true
                           });
                        }}
                        tooltip='Ukoliko ne pronalazite instituciju među ponuđenim opcijama, dodajte ju sami.'
                     />
                  )}
               </div>
            </div>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   authorState: state.author
   //personalInformation: state.author.personalInformation
});

const mapDispatchToProps = (dispatch) => ({
   loadLegalEntities: () => dispatch(loadLegalEnteties())
   // add: (article) => dispatch(addArticle(article)),
   // remove: (id) => dispatch(removeArticle(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLegalEntity);
