import React, { useState } from 'react';
import PopupContainer from '../../common/popup-container/popup-container';
import html from '../../../services/html';
import Gallery from './gallery';
import { v4 as uuid } from 'uuid';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

const AddNewAuthorRepresentation = ({ onAdd, onClose }) => {
   const [Url, setUrl] = useState('');
   const [error, setError] = useState(null);

   const [GalleryName, setGalleryName] = useState('');
   const [GalleryId, setGalleryId] = useState(null);
   const [DateFrom, setDateFrom] = useState('');
   const [DateTo, setDateTo] = useState('');

   const handleOnInputChange = ({ target: input }) => {
      setUrl(input.value.trim());
      if (input.value.trim() === '') setError('URL je obavezan');
      else setError('');
   };

   const handleOnSubmit = () => {
      const newRepresentation = {
         id: uuid(),
         GalleryId,
         GalleryName,
         DateFrom,
         DateTo,
         Url
      };
      onAdd(newRepresentation);
      onClose();
   };

   const onGallerySelect = (value, obj) => {
      const { id, value: name } = obj;
      setGalleryId(id);
      setGalleryName(name);
   };

   const handleSelectDateFrom = (e) => {
      setDateFrom(e.format('YYYY-MM-DD'));
   };

   const handleSelectDateTo = (e) => {
      setDateTo(e.format('YYYY-MM-DD'));
   };

   const disabled = GalleryId === 0 || DateFrom === '';

   return (
      <PopupContainer
         title='Dodavanje galerijskog zastupstva'
         onPopupClose={onClose}
      >
         <Gallery onGallerySelect={onGallerySelect} />
         <div className='row'>
            <div className='col'>
               {html.renderDatePicker('Datum od', handleSelectDateFrom)}
            </div>
            <div className='col'>
               {html.renderDatePicker('Datum do', handleSelectDateTo)}
            </div>
         </div>
         {html.renderSFCInput('URL', 'Url', error, handleOnInputChange)}

         <div style={{ marginTop: '2rem' }}>
            <ButtonAdd disabled={disabled} onClick={handleOnSubmit} />
            <ButtonCancel
               onClick={() => {
                  onClose();
               }}
            />
         </div>
      </PopupContainer>
   );
};

export default AddNewAuthorRepresentation;
