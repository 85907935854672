import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { homepageAnimation } from './animations/homepage-animation';
import Search from './../components/home-page/search';
import { searchForTerm } from '../services/api';
import ArtworkDescription from '../components/artwork/artwork-description';
import { v4 as uuid } from 'uuid';
import AuthorDescription from './../components/author/author-description';
import ExhibitionDescription from '../components/exhibition/exhibition-description';
import AuthorGroupDescription from './../components/author/author-group-description';
import {
   searchAnimation,
   searchResultAnimation,
   noResultAnimation
} from './animations/search-animation';
import { motion, useAnimation } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { redirectToErrorPage } from './../store/common';

const searchFilter = [
   {
      text: 'Sve',
      value: 0
   },
   {
      text: 'Umjetnici autori',
      value: 1
   },
   {
      text: 'Umjetnička djela',
      value: 2
   },
   {
      text: 'Mjesta izlaganja',
      value: 3
   }
];

const HomePage = (props) => {
   const [SearchWhat, setSearchWhat] = useState(0);
   const [SearchTerm, setSearchTerm] = useState('');
   const [responseData, setResponseData] = useState([]);
   const [isLoadingData, setIsLoadingData] = useState(false);
   const controls = useAnimation();
   const noResultsControls = useAnimation();
   const { artworks, authors: allAuthors, exhibitions } = responseData;

   const dispatch = useDispatch();

   useEffect(() => {
      document.title = 'Digitalni arhiv akademije likovnih umjetnosti';
      return () => {};
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (isResponseEmpty() && !isLoadingData) noResultsControls.start('show');
      else noResultsControls.start('hidden');
   }, [responseData, isLoadingData]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      const fetchData = async () => {
         setIsLoadingData(true);
         const { data: response } = await searchForTerm({
            SearchWhat,
            SearchTerm
         });
         setResponseData(response.data);
         console.log('R', response.data);
         setIsLoadingData(false);
      };
      if (SearchTerm.trim() !== '') fetchData();
      else setResponseData([]);

      if (SearchTerm) controls.start('hidden');
      else controls.start('show');

      dispatch(redirectToErrorPage(false));
   }, [SearchTerm, SearchWhat]); // eslint-disable-line react-hooks/exhaustive-deps

   const isResponseEmpty = () => {
      if (Array.isArray(responseData)) return true;

      const { artworks, authors, exhibitions } = responseData;
      if (
         artworks.length === 0 &&
         authors.length === 0 &&
         exhibitions.length === 0
      )
         return true;
      return false;
   };

   const handleDDLChange = ({ target: input }) => {
      setSearchWhat(Number(input.value));
   };

   const handleOnSearch = (searchValue) => {
      setSearchTerm(searchValue);
   };

   const authors = allAuthors
      ? allAuthors.filter((a) => a.AuthorTypeId === 1)
      : [];
   const authorGroups = allAuthors
      ? allAuthors.filter((a) => a.AuthorTypeId === 2)
      : [];

   return (
      <>
         <MotionContainer
            className='homepage'
            animationObject={homepageAnimation}
         >
            <motion.div
               className='search'
               variants={searchAnimation}
               animate={controls}
            >
               <div className='search-input-fields'>
                  <Search
                     searchOnTyping={true}
                     onSearch={handleOnSearch}
                     isLoadingData={isLoadingData}
                  />
                  <select
                     className='search-ddl'
                     onChange={handleDDLChange}
                     value={SearchWhat}
                  >
                     {searchFilter.map((sf) => (
                        <option value={sf.value} key={uuid()}>
                           {sf.text}
                        </option>
                     ))}
                  </select>
               </div>
               {/* <div className='search-input-fields-disclamer'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aliquid doloremque adipisci, repellendus cupiditate enim
                        sint vitae asperiores eum cum molestiae facere,
                        accusamus esse consequuntur nostrum amet laboriosam quam
                        blanditiis exercitationem.
                    </div> */}
            </motion.div>

            <motion.p
               className='no-results'
               variants={noResultAnimation}
               animate={noResultsControls}
            >
               {SearchTerm.length > 0 && isResponseEmpty() > 0 && (
                  <motion.span
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ delay: 0.3 }}
                  >
                     Za uneseni pojam{' '}
                     <span className='searchTerm'>{SearchTerm}</span> nema
                     rezultata...
                  </motion.span>
               )}
            </motion.p>

            {!isResponseEmpty() && (
               <MotionContainer animationObject={searchResultAnimation}>
                  {authors && authors.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[1].text}</h2>
                           <h4>Kliknite na ime autora za više detalja</h4>
                        </div>
                        {authors.map((author) => (
                           <AuthorDescription key={uuid()} data={author} />
                        ))}
                     </div>
                  )}

                  {authorGroups && authorGroups.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[1].text} - grupe</h2>
                           <h4>Kliknite na ime grupe autora za više detalja</h4>
                        </div>
                        {authorGroups.map((group) => (
                           <AuthorGroupDescription key={uuid()} data={group} />
                        ))}
                     </div>
                  )}

                  {artworks && artworks.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[2].text}</h2>
                           <h4>
                              Kliknite na ime umjetničkog djela za više detalja
                           </h4>
                        </div>
                        {artworks.map((artwork) => (
                           <ArtworkDescription data={artwork} key={uuid()} />
                        ))}
                     </div>
                  )}

                  {exhibitions && exhibitions.length > 0 && (
                     <div className='search-results'>
                        <div>
                           <h2>{searchFilter[3].text}</h2>
                           <h4>Kliknite na ime izložbe za više detalja</h4>
                        </div>
                        {exhibitions.map((exhibition) => (
                           <ExhibitionDescription
                              key={uuid()}
                              data={exhibition}
                           />
                        ))}
                     </div>
                  )}
               </MotionContainer>
            )}
         </MotionContainer>
      </>
   );
};

export default HomePage;
