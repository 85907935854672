import React, { useState } from 'react';
import { createOrUpdateAreaOfInterest } from '../../../services/api';
import { toast } from 'react-toastify';
import PopupContainer from '../../common/popup-container/popup-container';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

/*
    onExitClick()
*/

const AddNewAuthorAreaOfInterest = (props) => {
   const [Name, setName] = useState('');
   const [error, setError] = useState(null);

   const handleOnInputChange = ({ target: input }) => {
      setName(input.value.trim());
      if (input.value.trim() === '')
         setError('Područje interesa je obavezan unos');
      else setError('');
   };

   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };

   const renderInput = (name, error) => (
      <div className='form-group'>
         <label style={style}>Područje interesa/djelovanja</label>
         <input
            autoComplete='off'
            autoFocus
            name={name}
            id={name}
            className='form-control form-control-sm'
            onChange={handleOnInputChange}
         />
         {error && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {error}
            </div>
         )}
      </div>
   );

   const addNewAreaOfInterest = async () => {
      const { closeAddNewAuthorAreaOfInterest } = props;
      const newAreaOfInterest = {
         Name
      };
      const response = await createOrUpdateAreaOfInterest(newAreaOfInterest);
      const { status, message } = response.data;
      console.log(response);
      if (status === 1) {
         toast.info('Područje interesa/djelovanja uspješno dodano');
         closeAddNewAuthorAreaOfInterest();
      } else toast.error(`${message}`);
      console.log('response', response);
   };

   const handleOnSubmit = async (e) => {
      e.stopPropagation();
      addNewAreaOfInterest();
   };

   const disabled = error || error === null;
   const { closeAddNewAuthorAreaOfInterest } = props;
   return (
      <PopupContainer
         title='Dodavanje područja interesa/djelovanja'
         onPopupClose={closeAddNewAuthorAreaOfInterest}
      >
         {renderInput('Name', error)}
         <hr />

         <ButtonAdd
            text='Dodaj područje interesa/djelovanja'
            disabled={disabled}
            onClick={handleOnSubmit}
         />
         <ButtonCancel
            onClick={() => {
               props.closeAddNewAuthorAreaOfInterest();
            }}
         />
      </PopupContainer>
   );
};

export default AddNewAuthorAreaOfInterest;
