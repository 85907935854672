import React from 'react';
import Form from '../common/form';
import PopupContainer from '../common/popup-container/popup-container';
import html from '../../services/html';
import { connect } from 'react-redux';
import { AddNewExhibitionArtworkSchema } from './../../validators/add-new-exhibition-artwork';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';
class AddNewExhibitionArtwork extends Form {
   state = {
      validationData: {
         ExhibitionArtworkName: ''
      },
      restData: {
         ExhibitionArtworkId: null
      },
      artworks: [],
      customExhibitionNameEnter: false,
      errors: []
   };

   schema = AddNewExhibitionArtworkSchema;

   render() {
      const { customExhibitionNameEnter } = this.state;

      const { verifiedArtworks, closeAddNewExhibitionArtwork } = this.props;

      const { ExhibitionArtworkName } = this.state.validationData;
      const { ExhibitionArtworkId } = this.state.restData;

      const nijeUnesenoDjelo =
         ExhibitionArtworkId === null && ExhibitionArtworkName.trim() === '';

      const disabled = nijeUnesenoDjelo;

      return (
         <PopupContainer
            title='Odabir/unos umjetničkog djela'
            onPopupClose={closeAddNewExhibitionArtwork}
         >
            {!customExhibitionNameEnter &&
               html.renderSelectAnt(
                  'Odaberi djelo',
                  'Name',
                  'ArtworkId',
                  'Započnite unos ili odaberite djelo...',
                  'Ne pronalazim traženo djelo...',
                  verifiedArtworks,
                  this.handleExhibitionArtworkSelect
               )}

            {customExhibitionNameEnter && (
               <div>
                  {this.renderInput('ExhibitionArtworkName', 'Naziv djela', {
                     style: { height: '35px' },
                     placeholder: 'Unesite naziv djela...'
                  })}
               </div>
            )}

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewExhibitionArtwork();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewExhibitionArtwork();
   };

   handleOnSubmit = () => {
      const { onExibitionArtworkAdded, closeAddNewExhibitionArtwork } =
         this.props;
      const { ExhibitionArtworkName } = this.state.validationData;
      const { ExhibitionArtworkId } = this.state.restData;

      if (ExhibitionArtworkName) onExibitionArtworkAdded(ExhibitionArtworkName);
      else onExibitionArtworkAdded(ExhibitionArtworkId);

      closeAddNewExhibitionArtwork();
   };

   handleExhibitionArtworkSelect = (selectedText, valueObj) => {
      console.log('valueObj', valueObj);
      const { id: ExhibitionArtworkId } = valueObj;
      const restData = { ...this.state.restData };
      restData.ExhibitionArtworkId = ExhibitionArtworkId;
      this.setState({ restData });
   };

   handleCheckboxChecked = ({ target: input }) => {
      if (input.checked) {
         const restData = { ...this.state.restData };
         restData.ExhibitionArtworkId = null;
         this.setState({ restData });
      } else {
         const validationData = { ...this.state.validationData };
         validationData.ExhibitionArtworkName = '';
         this.setState({ validationData });
      }
      this.setState({ customExhibitionNameEnter: input.checked });
   };
}

const mapStateToProps = (state) => ({
   verifiedArtworks: state.exhibition.verifiedArtworks
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewExhibitionArtwork);
