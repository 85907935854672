import React, { Component } from 'react';
import { Input } from 'antd';

class InputAntDesign extends Component {
   state = {};
   inputElement = React.createRef();
   render() {
      const { name, label, error, ...rest } = this.props;

      const style = {
         marginBottom: '2px',
         fontWeight: 700,
         fontSize: '.9rem'
      };
      return (
         <div className='input'>
            <div className='form-group'>
               <label htmlFor={name} style={style}>
                  {label}
               </label>
               <Input ref={this.inputElement} name={name} id={name} {...rest} />
               {error && (
                  <div
                     className='alert alert-primary'
                     style={{ fontWeight: 700 }}
                  >
                     {error}
                  </div>
               )}
            </div>
         </div>
      );
   }

   componentDidMount() {
      const { autoFocus } = this.props;
      if (autoFocus) this.inputElement.current.focus();
   }
}

/* autoFocus se samo doda kao atribut... bez vrijednosti... */
InputAntDesign.defaultProps = {
   label: 'Label',
   placeholder: '',
   type: 'text',
   autoComplete: 'off',
   autoFocus: false
};
export default InputAntDesign;
