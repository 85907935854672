import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadListConfirmed } from '../store/list-confirmed';
import { pageAnimation } from './page-transition-animation';
import UserConfirmation from '../components/user-confirmation';
import { toast } from 'react-toastify';
import MotionContainer from '../components/common/motion-containers/motion-container';

const AdminListConfirmed = (props) => {
    const [usersLoaded, setUsersLoaded] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Aktivacija korisnika';
        toast('Učitavanje podataka...');
        dispatch(loadListConfirmed()).then(() => {
            setUsersLoaded(true);
            toast.dismiss();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const { users } = useSelector((state) => state.listOfUnconfirmedUsers);

    return (
        <MotionContainer
            className='admin-list-confirmed-page'
            animationObject={pageAnimation}
        >
            <div className='admin-list-confirmed-page-content'>
                {users.length > 0 && <UserConfirmation />}
                {users.length === 0 && usersLoaded && (
                    <p>
                        Nema prijavljenih autora koji su verificirali e-mail
                        adresu...
                    </p>
                )}
            </div>
        </MotionContainer>
    );
};

export default AdminListConfirmed;
