import React, { useEffect } from 'react';
import NotFound from '../components/not-found';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { notFoundAnimation } from './animations/not-found';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ErrorPage = (props) => {
    const { errorMessage } = useSelector((state) => state.common);

    useEffect(() => {
        document.title = '404 - traženi resurs nije pronađen';
    });

    return (
        <MotionContainer
            className='not-found-page'
            animationObject={notFoundAnimation}
        >
            <h1>
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className='icon-header mr-3'
                />
                Error
                <br />
                <span style={{ fontSize: '1rem' }}>{errorMessage}</span>
                <hr />
                <NotFound />
            </h1>
        </MotionContainer>
    );
};

export default withRouter(ErrorPage);
