import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import { pageAnimation } from './page-transition-animation';
import { registerHeaderAnimation } from './animations/register-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrinAlt } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';
import MotionContainer from '../components/common/motion-containers/motion-container';

const RegisterSuccess = (props) => {
    useEffect(() => {
        document.title = 'Registracija uspješna :)';
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RegisterSuccessPageStyled
            variants={pageAnimation}
            initial='hidden'
            animate='show'
            exit='exit'
        >
            <div className='register-success-content'>
                <MotionContainer animationObject={registerHeaderAnimation}>
                    <h1>
                        <div>
                            <FontAwesomeIcon
                                icon={faGrinAlt}
                                className='icon-header mr-3'
                            />
                        </div>
                        Registracija uspješna
                        <hr />
                        <span>
                            Molimo Vas da putem poruke koju smo upravo poslali
                            verificirate Vašu e-mail adresu
                        </span>
                        {/* <span style={{ fontSize: '1.5rem', marginTop: '2rem' }}>
                        <NavLink to='/login'>Prijava</NavLink>
                    </span> */}
                    </h1>
                </MotionContainer>
            </div>
        </RegisterSuccessPageStyled>
    );
};

const RegisterSuccessPageStyled = styled(motion.div)`
    min-height: calc(100vh - 10rem);
    display: flex;
    justify-content: center;
    align-items: center;

    .register-success-content {
        width: 70vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 4rem;
            font-weight: 100;
            text-align: center;
            span {
                margin-left: 6rem;
                display: block;
                font-size: 2rem;
                color: #999;
            }
        }
    }
`;

export default RegisterSuccess;
