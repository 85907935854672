import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import AddLegalEntity from './add-legal-entity';

/*
    props
        onAfilijacijaSelected()
*/

const Afilijacija = ({
    onAfilijacijaSelected,
    defaultValue,
    disabled,
    required
}) => {
    const [IsAffiliated, setIsAffiliated] = useState(false);
    const [AffiliationLegalEntityId, setLegalEntityId] = useState(null);

    const handleIsAfiliated = () => {
        setIsAffiliated(!IsAffiliated);
        //ref.current.blur();
    };

    const handleLegalEntitySelect = (value, obj) => {
        const { id: legalEntityId } = obj;
        setLegalEntityId(legalEntityId);
    };

    useEffect(() => {
        const { IsAffiliated, AffiliationLegalEntityId } = defaultValue;
        setIsAffiliated(IsAffiliated);
        setLegalEntityId(AffiliationLegalEntityId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onAfilijacijaSelected(IsAffiliated, AffiliationLegalEntityId);
    }, [IsAffiliated, AffiliationLegalEntityId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='afilijacija'>
            <Checkbox
                checked={IsAffiliated}
                onChange={handleIsAfiliated}
                disabled={disabled}
            >
                Afilijacija
            </Checkbox>
            <div
                className={
                    IsAffiliated
                        ? 'afilijacija-legal-entity show-legal-entity'
                        : 'afilijacija-legal-entity'
                }
            >
                <AddLegalEntity
                    disabled={disabled}
                    required={required}
                    defaultValue={AffiliationLegalEntityId}
                    onLegalEntitySelect={handleLegalEntitySelect}
                />
            </div>
        </div>
    );
};

export default Afilijacija;
