const animationSpeedShow = 0.1;

export const searchAnimation = {
    hidden: {
        y: 10,
        transition: {
            duration: animationSpeedShow,
            type: 'spring',
            stiffness: 200
        }
    },
    show: {
        opacity: 1,
        y: 300,
        transition: {
            duration: animationSpeedShow,
            type: 'spring',
            stiffness: 200
        }
    }
};

export const searchResultAnimation = {
    hidden: {
        opacity: 0,
        y: 100
    },
    show: {
        opacity: 1,
        y: 30,
        transition: {
            duration: 0.25
        }
    }
};

export const noResultAnimation = {
    hidden: {
        opacity: 0,
        y: 46,
        transition: {
            duration: 0.1
        }
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.25
        }
    }
};
