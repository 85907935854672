import Joi from 'joi-browser';

export const AuthorProfessionalActivitySchema = {
    ShortBiography: Joi.any().optional().label('Biografija'),
    ArtGroupOrCollective: Joi.any()
        .optional()
        .label('Pripadnost umjetničkoj grupi/kolektivu')
    // AuthorAreaOfInterest: Joi.string()
    //     .required()
    //     .label('Područje interesa/djelovanja')
    //     .error((errors) => {
    //         return {
    //             message: `${errors[0].flags.label} je obavezno`
    //         };
    //     })
};
