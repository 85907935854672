import React from 'react';
import { getAreaofInterest } from '../../../services/api';
import Form from '../../common/form';
import ShowTooltip from '../../common/show-tooltip';
import AddNewAuthorAreaOfInterest from './add-new-author-area-of-interest';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import html from '../../../services/html';
import ButtonForm from './../../common/btn-form';
import ButtonFormTooltip from './../../common/btn-form-tooltip';

/*
    areaofinterestsInit - inicijalno polje svih interesa jer kako koji pojam dodajem 
    micem ga iz polja areaofinterests
*/
class AuthorAreaOfInterest extends Form {
   state = {
      validationData: {},
      errors: {},
      areaofinterests: [],
      areaofinterestsInit: [],
      selectedAreaOfInterest: {},
      authorAreaOfInterests: this.props.defaultValue
         ? this.props.defaultValue
         : [],
      showAddNewAreaOfInterest: false
   };

   loadAuthorAreaOfInterests = async () => {
      const { data } = await getAreaofInterest();

      this.setState({
         areaofinterests: data.data,
         areaofinterestsInit: data.data
      });
   };

   async componentDidMount() {
      this.loadAuthorAreaOfInterests();
   }

   handleCloseAddNewAreaOfInterest = (e) => {
      this.loadAuthorAreaOfInterests();
      this.setState({ showAddNewAreaOfInterest: false });
   };

   handleAreaOfInterestSelected = (value, obj) => {
      const { id: AreaOfInterestId, value: Name } = obj;
      const selectedAreaOfInterest = {
         AreaOfInterestId,
         Name
      };
      // this.ref.current.focus();
      this.setState({ selectedAreaOfInterest });
   };

   handleAddSelectedAreaOfInterest = () => {
      const { selectedAreaOfInterest } = this.state;

      //da li je pojam vec odabran
      let authorAreaOfInterests = [...this.state.authorAreaOfInterests];
      const pojamVecOdabran = authorAreaOfInterests.some(
         (aeoi) =>
            aeoi.AreaOfInterestId === selectedAreaOfInterest.AreaOfInterestId
      );
      if (pojamVecOdabran) {
         toast.error(`Pojam ${selectedAreaOfInterest.Name} je već odabran`);
         return;
      }

      //Maksimalno se može dodati 20 pojmova
      if (authorAreaOfInterests.length === 20) {
         toast.error(`Dosegli ste maksimalan broj pojmova`);
         return;
      }

      authorAreaOfInterests = [
         ...authorAreaOfInterests,
         selectedAreaOfInterest
      ];
      this.setState({ authorAreaOfInterests });

      const { onAreaOfInterestSelect } = this.props;
      onAreaOfInterestSelect(authorAreaOfInterests);
   };

   handle_RemoveInterest = (id) => {
      const { authorAreaOfInterests } = this.state;

      const filtriranoPolje = authorAreaOfInterests.filter(
         (a) => a.AreaOfInterestId !== id
      );
      this.setState({
         authorAreaOfInterests: filtriranoPolje
      });

      const { onAreaOfInterestSelect } = this.props;
      onAreaOfInterestSelect(filtriranoPolje);
   };

   ref = React.createRef();

   render() {
      let {
         areaofinterestsInit,
         areaofinterests,
         selectedAreaOfInterest,
         showAddNewAreaOfInterest,
         authorAreaOfInterests
      } = this.state;

      /*
            prikazujem samo pojmove koji još nisu odabrani...
        */
      const areaOfInterestsIds = authorAreaOfInterests.map(
         (a) => a.AreaOfInterestId
      );
      areaofinterests = areaofinterests.filter(
         (aoi) => !areaOfInterestsIds.includes(aoi.AreaOfInterestId)
      );

      const disableAddBtn = Object.keys(selectedAreaOfInterest).length === 0;

      const { disabled } = this.props;
      return (
         <>
            {showAddNewAreaOfInterest && (
               <AddNewAuthorAreaOfInterest
                  closeAddNewAuthorAreaOfInterest={
                     this.handleCloseAddNewAreaOfInterest
                  }
               />
            )}
            <div className='row'>
               <div className='col-6'>
                  {this.renderSelectAnt(
                     'Područje interesa',
                     'Name',
                     'AreaOfInterestId',
                     'Započnite unos ili odaberite područje interesa...',
                     'Ne pronalazim traženo područje interesa...',
                     areaofinterests,
                     this.handleAreaOfInterestSelected,
                     null,
                     { disabled },
                     true
                  )}
               </div>
               <div className='col'>
                  {!disabled && (
                     <ButtonForm
                        inline={true}
                        text='Odaberi'
                        onClick={this.handleAddSelectedAreaOfInterest}
                        disabled={disableAddBtn}
                        ref={this.ref}
                     />
                  )}
               </div>
               <div
                  className='col'
                  style={{
                     display: 'flex',
                     justifyContent: 'flex-end',
                     alignItems: 'flex-end',
                     paddingBottom: '.75rem'
                  }}
               >
                  {!disabled && (
                     <ButtonFormTooltip
                        inline={true}
                        text='Dodaj'
                        onClick={(e) => {
                           e.stopPropagation();
                           this.setState({
                              showAddNewAreaOfInterest: true
                           });
                        }}
                        tooltip='Ukoliko ne pronalazite pojam među ponuđenim opcijama područja interesa/djelovanja, dodajte ga sami.'
                     />
                  )}
               </div>
            </div>
            {authorAreaOfInterests.length === 0 && (
               <p style={{ fontSize: '.7rem' }}>
                  Nema odabranih područja interesa/djelovanja...
               </p>
            )}
            {authorAreaOfInterests.length > 0 &&
               areaofinterestsInit.length > 0 && (
                  <div className='author-area-of-interests selected-options-grid'>
                     {authorAreaOfInterests.map((aeoi) => (
                        <ShowTooltip
                           title={
                              disabled
                                 ? ''
                                 : `Obriši ${html.getAreaOfInterestNameById(
                                      aeoi.AreaOfInterestId,
                                      areaofinterestsInit
                                   )}`
                           }
                           placement='bottom'
                           tooltipColor='#f00'
                           visible={disabled}
                           key={uuid()}
                        >
                           <div
                              className='selected-option-style'
                              onClick={() => {
                                 if (!disabled)
                                    this.handle_RemoveInterest(
                                       aeoi.AreaOfInterestId
                                    );
                              }}
                           >
                              {html.getAreaOfInterestNameById(
                                 aeoi.AreaOfInterestId,
                                 areaofinterestsInit
                              )}
                           </div>
                        </ShowTooltip>
                     ))}
                  </div>
               )}
         </>
      );
   }
}

export default AuthorAreaOfInterest;
