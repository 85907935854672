import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { homepageAnimation } from './animations/homepage-animation';
import { v4 as uuid } from 'uuid';
import { loadAuthorDetails } from '../store/common';
import { useDispatch, useSelector } from 'react-redux';
import html from '../services/html';
import { getCities } from '../services/api';
import { Image, Avatar } from 'antd';
import { imgFallback } from './author-details-service';
import { loadAuthorStaticData } from '../store/author';

import LoadingSpinner from './../components/common/loading-spinner';
import { isStaticDataLoaded } from './../services/created-authors-page';
import { detailsAnimation } from './animations/details-animation';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hideToastProgressBar } from './../store/app-setup';
import {
   ucitavamDodatnePodatke,
   ucitavamPodatke
} from './../services/toast-messages';
import HomepageLink from '../components/common/homepage-link';
import ShowTooltip from '../components/common/show-tooltip';

const AuthorDetails = (props) => {
   const dispatch = useDispatch();
   const history = useHistory();

   const [cities, setCities] = useState(null);
   const [staticDataLoaded, setStaticDataLoaded] = useState(false);

   const { common, author: authorState } = useSelector((state) => state);
   const { authorDetails: author, countries, redirectToErrorPage } = common;

   useEffect(() => {
      if (redirectToErrorPage) {
         history.push(`${process.env.PUBLIC_URL}/error-page`);
      }
   }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      document.title = 'Detalji autora';
      const { id } = props.match.params;

      toast.info(ucitavamPodatke);
      dispatch(hideToastProgressBar(true));

      dispatch(loadAuthorDetails(id)).then(() => {
         toast.dismiss();
         if (!isStaticDataLoaded(authorState)) {
            toast(ucitavamDodatnePodatke);
            dispatch(loadAuthorStaticData()).then(() => {
               setStaticDataLoaded(true);
               toast.dismiss();
            });
         } else setStaticDataLoaded(true);
      });

      return () => {
         dispatch(hideToastProgressBar(false));
         toast.dismiss();
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const {
      legalEntities,
      authorAcademicStatuses,
      educationLevels,
      specializationTypes,
      authorAreaOfInterests: areaOfInterests,
      galleries,
      jobfunctions,
      authorBibliographies
   } = authorState;

   const {
      BirthCountryId,
      BirthCityId,
      PhotoFileContentBase64: avatar,
      BirthDate,
      SexId,
      ContactPhone,
      PersonalOrGroupUrl,
      SocialNetworks,
      AffiliationLegalEntityId,
      AuthorAcademicStatuses,
      AuthorEducationLevels,
      AuthorThesis,
      AuthorSpecializationTypes,
      ScientificRegisterCode,
      ShortBiography,
      ArtGroupOrCollective,
      AuthorAreaOfInterests,
      ArtworkInCollectionsDescription,
      AuthorRepresentations,
      IsAtelier,
      AtelierAddress,
      AuthorWorkExperiences,
      AuthorAwards,
      AuthorBibliographies,
      AuthorAssociations,
      AuthorArtworks,
      AuthorExhibitions
   } = author ? author : {};

   useEffect(() => {
      if (BirthCountryId) getCountryCities(BirthCountryId);
   }, [BirthCountryId]);

   const getCountryCities = async (countryId) => {
      const { data } = await getCities(countryId);

      setCities(data.data);
   };

   const getCityName = () => {
      const cityName = cities ? html.getCityNameById(BirthCityId, cities) : '';
      return cityName;
   };

   const countryName = html.getCountryNameById(BirthCountryId, countries);
   const legalEntityName = legalEntities
      ? html.getLegalEntityNameById(AffiliationLegalEntityId, legalEntities)
      : '-';

   const acStatuses =
      staticDataLoaded && AuthorAcademicStatuses
         ? html
              .getAcademicStatusNamesFromIDs(
                 AuthorAcademicStatuses,
                 authorAcademicStatuses
              )
              .join(', ')
         : '-';

   const authorsInterests =
      staticDataLoaded && AuthorAreaOfInterests
         ? AuthorAreaOfInterests.map((aei) =>
              html.getAreaOfInterestNameById(
                 aei.AreaOfInterestId,
                 areaOfInterests
              )
           ).join(', ')
         : '-';

   return (
      <>
         {!staticDataLoaded && <LoadingSpinner />}
         {staticDataLoaded && (
            <MotionContainer
               className='author-details'
               animationObject={homepageAnimation}
            >
               <div className='basic-information'>
                  <div className='pic'>
                     <Avatar
                        size={100}
                        shape='circle'
                        src={avatar ? avatar : imgFallback}
                     />
                  </div>
                  <div className='txt'>
                     <div className='name'>
                        <h1>{`${author.FirstName} ${author.LastName}`}</h1>
                     </div>
                     <div className='address'>
                        <a href={`mailto:${author.ContactEmail}`}>
                           {author.ContactEmail}
                        </a>
                        {`${getCityName()} - ${countryName}`}
                     </div>
                  </div>
               </div>
               {!staticDataLoaded && <LoadingSpinner />}

               {staticDataLoaded && (
                  <MotionContainer
                     className='details'
                     animationObject={detailsAnimation}
                  >
                     <div className='info'>
                        <h2>Kontakt podaci</h2>
                        <table className='table table-striped table-sm'>
                           <tbody>
                              <tr>
                                 <td className='align-middle'>Datum rođenja</td>
                                 <td className='align-middle'>
                                    {BirthDate
                                       ? html.convert_T_Date_ToLocal(BirthDate)
                                       : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Spol</td>
                                 <td className='align-middle'>
                                    {html.getSexNameById(SexId, common.sex)}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Telefon</td>
                                 <td className='align-middle'>
                                    {ContactPhone}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>URL</td>
                                 <td className='align-middle'>
                                    <a
                                       style={{
                                          width: '40rem',
                                          display: 'inline-block',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis'
                                       }}
                                       href={`${PersonalOrGroupUrl}`}
                                       target='_blank'
                                       rel='noreferrer'
                                    >
                                       {PersonalOrGroupUrl
                                          ? PersonalOrGroupUrl
                                          : '-'}
                                    </a>
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Društvene mreže
                                 </td>
                                 <td className='align-middle'>
                                    {SocialNetworks ? SocialNetworks : '-'}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div className='info'>
                        <h2>Podaci o obrazovanju</h2>
                        <table className='table table-striped table-sm'>
                           <tbody>
                              <tr>
                                 <td className='align-middle'>Afilijacija</td>
                                 <td className='align-middle'>
                                    {legalEntityName ? legalEntityName : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Akademski staus
                                 </td>
                                 <td className='align-middle'>{acStatuses}</td>
                              </tr>
                              {AuthorThesis &&
                                 AuthorThesis.map((at) => (
                                    <tr key={uuid()}>
                                       <td className='align-middle'>
                                          Diplomski/posljediplomski rad
                                       </td>
                                       <td className='align-middle'>
                                          {`${at.Title}, Mentor: ${at.Mentor}, ${at.YearOfGraduation}`}
                                       </td>
                                    </tr>
                                 ))}
                              {AuthorEducationLevels &&
                                 AuthorEducationLevels.map((ael) => (
                                    <tr key={uuid()}>
                                       <td className='align-middle'>
                                          {educationLevels
                                             ? html.getEducationLevelNameById(
                                                  ael.EducationLevelId,
                                                  educationLevels
                                               )
                                             : '-'}
                                       </td>
                                       <td className='align-middle'>
                                          {legalEntities
                                             ? `${
                                                  legalEntities
                                                     ? html.getLegalEntityNameById(
                                                          ael.LegalEntityId,
                                                          legalEntities
                                                       )
                                                     : '-'
                                               } (${html.convert_T_Date_ToLocal(
                                                  ael.DateFrom
                                               )} - ${html.convert_T_Date_ToLocal(
                                                  ael.DateTo
                                               )})`
                                             : '-'}
                                       </td>
                                    </tr>
                                 ))}
                              {AuthorSpecializationTypes &&
                                 AuthorSpecializationTypes.map((st) => (
                                    <tr key={uuid()}>
                                       <td className='align-middle'>
                                          {html.getSpecializationTypeNameById(
                                             st.SpecializationTypeId,
                                             specializationTypes
                                          )}
                                       </td>
                                       <td className='align-middle'>
                                          {legalEntities
                                             ? `${html.getLegalEntityNameById(
                                                  st.LegalEntityId,
                                                  legalEntities
                                               )} (${html.convert_T_Date_ToLocal(
                                                  st.DateFrom
                                               )} - ${html.convert_T_Date_ToLocal(
                                                  st.DateTo
                                               )})`
                                             : '-'}
                                       </td>
                                    </tr>
                                 ))}
                              <tr>
                                 <td className='align-middle'>
                                    Upisnik znanstvenika u RH
                                 </td>
                                 <td className='align-middle'>
                                    {ScientificRegisterCode
                                       ? ScientificRegisterCode
                                       : '-'}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div className='info'>
                        <h2>Podaci o profesionalnom djelovanju</h2>
                        <table className='table table-striped table-sm'>
                           <tbody>
                              <tr>
                                 <td className='align-middle'>Biografija</td>
                                 <td className='align-middle'>
                                    {ShortBiography}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Pripadnost umjetničkoj grupi/kolektivu
                                 </td>
                                 <td className='align-middle'>
                                    {ArtGroupOrCollective
                                       ? ArtGroupOrCollective
                                       : '-'}
                                 </td>
                              </tr>

                              <tr>
                                 <td className='align-middle'>
                                    Područje interesa/djelovanja
                                 </td>
                                 <td className='align-middle'>
                                    {authorsInterests}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Umjetnička djela u zbirkama
                                 </td>
                                 <td className='align-middle'>
                                    {ArtworkInCollectionsDescription
                                       ? ArtworkInCollectionsDescription
                                       : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Galerijska zastupstva
                                 </td>
                                 <td className='align-middle'>
                                    {AuthorRepresentations.length === 0
                                       ? '-'
                                       : ''}
                                    {AuthorRepresentations &&
                                       AuthorRepresentations.map((ar) => (
                                          <div key={uuid()}>
                                             {`${
                                                galleries
                                                   ? html.getGalleryNameById(
                                                        ar.GalleryId,
                                                        galleries
                                                     )
                                                   : '-'
                                             } (${html.convert_T_Date_ToLocal(
                                                ar.DateFrom
                                             )} - ${html.convert_T_Date_ToLocal(
                                                ar.DateTo
                                             )})`}{' '}
                                             <a
                                                href={ar.Url}
                                                target='_blank'
                                                rel='noreferrer'
                                             >
                                                Više...
                                             </a>
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Atelje</td>
                                 <td className='align-middle'>
                                    {IsAtelier ? AtelierAddress : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Radno iskustvo
                                 </td>
                                 <td className='align-middle'>
                                    {AuthorWorkExperiences.length === 0
                                       ? '-'
                                       : ''}
                                    {AuthorWorkExperiences &&
                                       AuthorWorkExperiences.map((aex) => (
                                          <div key={uuid()}>
                                             {`${
                                                jobfunctions
                                                   ? html.getJobfunctionNameById(
                                                        aex.JobFunctionId,
                                                        jobfunctions
                                                     )
                                                   : '-'
                                             } - ${
                                                legalEntities
                                                   ? html.getLegalEntityNameById(
                                                        aex.LegalEntityId,
                                                        legalEntities
                                                     )
                                                   : '-'
                                             } (${html.convert_T_Date_ToLocal(
                                                aex.DateFrom
                                             )} - ${html.convert_T_Date_ToLocal(
                                                aex.DateTo
                                             )})`}{' '}
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Nagrade</td>
                                 <td className='align-middle'>
                                    {AuthorAwards.length === 0 ? '-' : ''}
                                    {AuthorAwards &&
                                       AuthorAwards.map((a) => (
                                          <div key={uuid()}>
                                             {`${a.Name}, ${
                                                cities
                                                   ? html.getCityNameById(
                                                        a.CityId,
                                                        cities
                                                     )
                                                   : '-'
                                             } - ${a.Year}`}
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                              <tr
                                 style={{
                                    backgroundColor: 'transparent'
                                 }}
                              >
                                 <td className='align-middle'>Bibliografija</td>
                                 <td className='align-middle'>
                                    {AuthorBibliographies.length === 0
                                       ? '-'
                                       : ''}
                                    {AuthorBibliographies &&
                                       AuthorBibliographies.map((ab) => (
                                          <div
                                             key={uuid()}
                                             style={{
                                                margin: '.5rem 0'
                                             }}
                                          >
                                             <table
                                                id='bibliography'
                                                className='table-striped table-sm sub-table'
                                             >
                                                <tbody>
                                                   <tr>
                                                      <td>Naziv</td>
                                                      <td>{ab.Name}</td>
                                                   </tr>
                                                   <tr>
                                                      <td>Izdanje</td>
                                                      <td>{ab.Edition}</td>
                                                   </tr>
                                                   <tr>
                                                      <td>Autori</td>
                                                      <td>{ab.Authors}</td>
                                                   </tr>
                                                   <tr>
                                                      <td>Godina</td>
                                                      <td>{ab.Year}</td>
                                                   </tr>
                                                   <tr>
                                                      <td>Potkategorija</td>
                                                      <td>
                                                         {authorBibliographies
                                                            ? html.getBibliographyNameById(
                                                                 ab.BibliographyCategoryId,
                                                                 authorBibliographies
                                                              )
                                                            : '-'}
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td>Grad</td>
                                                      <td>
                                                         {cities
                                                            ? html.getCityNameById(
                                                                 ab.CityId,
                                                                 cities
                                                              )
                                                            : '-'}
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                             <hr />
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Članstvo u udrugama{' '}
                                 </td>
                                 <td className='align-middle'>
                                    {AuthorAssociations.length === 0 ? '-' : ''}
                                    {AuthorAssociations &&
                                       AuthorAssociations.map((aa) => (
                                          <div key={uuid()}>
                                             {`${aa.Name}, ${
                                                cities
                                                   ? html.getCityNameById(
                                                        aa.CityId,
                                                        cities
                                                     )
                                                   : '-'
                                             } (${html.convert_T_Date_ToLocal(
                                                aa.DateFrom
                                             )} - ${html.convert_T_Date_ToLocal(
                                                aa.DateTo
                                             )})`}
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div className='info'>
                        <h2>Umjetnička djela autora</h2>
                        <div className='artworks'>
                           {AuthorArtworks && AuthorArtworks.length === 0 && (
                              <p>Nema umjetničkih djela vezanih uz autora</p>
                           )}
                           {AuthorArtworks &&
                              AuthorArtworks.map((a) => (
                                 <div
                                    className='artwork-container'
                                    key={uuid()}
                                 >
                                    <table className='table table-striped table-sm'>
                                       <tbody>
                                          <tr>
                                             <td className='align-middle'>
                                                Naziv
                                             </td>
                                             <td className='align-middle naziv'>
                                                <ShowTooltip
                                                   tooltipColor='#026973'
                                                   title='Prikaži detalje'
                                                >
                                                   <button
                                                      className='btn btn-link btn-sm'
                                                      style={{
                                                         padding: 0
                                                      }}
                                                      onClick={() => {
                                                         history.push(
                                                            `${process.env.PUBLIC_URL}/details-artwork/${a.ArtworkId}`
                                                         );
                                                      }}
                                                   >
                                                      {a.Name}
                                                   </button>
                                                </ShowTooltip>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td className='align-middle'>
                                                Opis
                                             </td>
                                             <td className='align-middle'>
                                                {a.Description
                                                   ? a.Description
                                                   : '-'}
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                    {a.Photographs.length === 0 && (
                                       <p>
                                          Nema fotografija vezanih uz umjetničko
                                          djelo...
                                       </p>
                                    )}
                                    {a.Photographs.length > 0 && (
                                       <div className='author-photographs'>
                                          <hr />
                                          {a.Photographs.map((p) => (
                                             <div
                                                key={uuid()}
                                                className='image-container'
                                             >
                                                <Image
                                                   width={200}
                                                   height={135}
                                                   src={
                                                      p.PhotoFileContentBase64
                                                         ? p.PhotoFileContentBase64
                                                         : 'error'
                                                   }
                                                   fallback={imgFallback}
                                                />
                                             </div>
                                          ))}
                                       </div>
                                    )}
                                 </div>
                              ))}
                        </div>
                     </div>
                     <div className='info'>
                        <h2>Izložbe autora</h2>
                        <div className='exhibitions'>
                           {AuthorExhibitions &&
                              AuthorExhibitions.length === 0 && (
                                 <p>Nema izložbi vezanih uz autora</p>
                              )}
                           {AuthorExhibitions && AuthorExhibitions.length > 0 && (
                              <table className='table table-striped table-sm'>
                                 <thead>
                                    <tr>
                                       <th className='align-middle'>Naziv</th>
                                       <th className='align-middle'>
                                          Naziv na engleskom
                                       </th>
                                       <th className='align-middle'>Godina</th>
                                       <th></th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {AuthorExhibitions.map((a) => (
                                       <tr key={uuid()}>
                                          <td className='align-middle'>
                                             {a.Name}
                                          </td>
                                          <td className='align-middle'>
                                             {a.NameEn}
                                          </td>
                                          <td className='align-middle'>
                                             {a.Year}
                                          </td>
                                          <td className='align-middle'>
                                             <button
                                                className='btn btn-link btn-sm'
                                                style={{
                                                   padding: 0
                                                }}
                                                onClick={() => {
                                                   history.push(
                                                      `${process.env.PUBLIC_URL}/details-exhibition/${a.ExhibitionId}`
                                                   );
                                                }}
                                             >
                                                Prikaži
                                             </button>
                                          </td>
                                       </tr>
                                    ))}
                                 </tbody>
                              </table>
                           )}
                        </div>
                     </div>
                     <HomepageLink />
                  </MotionContainer>
               )}
            </MotionContainer>
         )}
      </>
   );
};

export default AuthorDetails;
