import LoginPage from '../pages/login-page';
import MailVerifiedPage from '../pages/mail-verified-page';
import RegisterSuccess from '../pages/register-success';
import RegisterPage from '../pages/register-page';
import NotFoundPage from '../pages/not-found-page';
import AdminListConfirmed from '../pages/admin-list-confirmed';
import Logout from './../components/logout';
import AuthorsMenu from './../components/author/authors-menu/authors-menu';
import ArtworkMenu from './../components/artwork/artwork-menu/artwork-menu';
import ArtworkAddPage from './../pages/artwork-add-page';
import CreatedArtworksPage from './../pages/created-artworks-page';
import ExhibitionMenu from './../components/exhibition/exhibition-menu/exhibition-menu';
import ExhibitionAddPage from './../pages/exhibition-add-page';
import CreatedExhibitionsPage from './../pages/created-exhibitions-page';
import AuthorAddPage from '../pages/author-add-page';
import AuthorGroupAddPage from './../pages/author-group-add-page';
import CreatedAuthorsPage from './../pages/created-authors-page';
import Confirm from './../components/common/confirm';
import AuthorDetails from './../pages/author-details';
import AuthorGroupDetails from './../pages/author-group-details';
import ArtworkDetails from './../pages/artwork-details';
import ExhibitionDetails from './../pages/exhibition-details';
import HomePage from './../pages/home-page';
import HomePageID from './../pages/home-page-id';
import ErrorPage from '../pages/error-page';
import Errors from './../components/common/showErrors';
import ForgotPasswordPage from './../pages/forgot-password-page';
import ForgotPasswordChangePage from './../pages/forgoted-password-change-page';

export const routes = [
    {
        path: '/not-found',
        component: NotFoundPage,
        protected: false
    },
    {
        path: '/error-page',
        component: ErrorPage,
        protected: false
    },
    {
        path: '/forgot-password',
        component: ForgotPasswordPage,
        protected: false
    },
    {
        path: '/forgot-password-change/:data',
        component: ForgotPasswordChangePage,
        protected: false
    },
    {
        path: '/register',
        component: RegisterPage,
        protected: false
    },
    {
        path: '/register-success',
        component: RegisterSuccess,
        protected: false
    },
    {
        path: '/login',
        component: LoginPage,
        protected: false
    },
    {
        path: '/mail-verified',
        component: MailVerifiedPage,
        protected: false
    },
    {
        path: '/admin-list-confirmed',
        component: AdminListConfirmed,
        protected: true
    },
    {
        path: '/logout',
        component: Logout,
        protected: false
    },
    {
        path: '/authors',
        component: AuthorsMenu,
        protected: true
    },
    {
        path: '/artworks',
        component: ArtworkMenu,
        protected: true
    },
    {
        path: '/add-artwork',
        component: ArtworkAddPage,
        protected: true
    },
    {
        path: '/list-artworks',
        component: CreatedArtworksPage,
        protected: true
    },
    {
        path: '/exhibitions',
        component: ExhibitionMenu,
        protected: true
    },
    {
        path: '/add-exhibition',
        component: ExhibitionAddPage,
        protected: true
    },
    {
        path: '/list-exhibitions',
        component: CreatedExhibitionsPage,
        protected: true
    },
    {
        path: '/add-author',
        component: AuthorAddPage,
        protected: true
    },
    {
        path: '/add-author-group',
        component: AuthorGroupAddPage,
        protected: true
    },
    {
        path: '/list-author',
        component: CreatedAuthorsPage,
        protected: true
    },
    {
        path: '/confirm',
        component: Confirm,
        protected: true
    },
    {
        path: '/errors',
        component: Errors,
        protected: false
    },
    {
        path: '/homepage/:id',
        component: HomePageID,
        protected: false
    },
    {
        path: '/homepage',
        component: HomePage,
        protected: false
    },
    {
        path: '/details-author/:id',
        component: AuthorDetails,
        protected: false
    },
    {
        path: '/details-author-group/:id',
        component: AuthorGroupDetails,
        protected: false
    },
    {
        path: '/details-artwork/:id',
        component: ArtworkDetails,
        protected: false
    },
    {
        path: '/details-exhibition/:id',
        component: ExhibitionDetails,
        protected: false
    }
];
