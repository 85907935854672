import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { motion } from 'framer-motion';

const Submenu = (props) => {
   const { isEditMode } = useSelector((state) => state.author);

   let show = false;
   let text;
   let location = useLocation();
   const url = `${process.env.PUBLIC_URL}`;

   /*   Ako sam na putanji koja ima ID, maknem taj ID za switch provjeru  */
   const arr = location.pathname.split('/');
   const nemaID = isNaN(Number(arr[arr.length - 1]));
   if (!nemaID) arr.pop();
   const putanja = nemaID ? location.pathname : arr.join('/');

   switch (putanja) {
      case `${url}/add-author`:
         text = isEditMode ? 'Uređivanje autora' : 'Dodavanje autora';
         show = true;
         break;
      case `${url}/add-author-group`:
         text = isEditMode
            ? 'Uređivanje grupe autora'
            : 'Dodavanje grupe autora';
         show = true;
         break;
      case `${url}/list-author`:
         text = 'Pregled unesenih autora';
         show = true;
         break;
      case `${url}/admin-list-confirmed`:
         text = 'Aktivacija korisnika';
         show = true;
         break;
      case `${url}/add-artwork`:
         text = 'Dodavanje umjetničkih djela';
         show = true;
         break;
      case `${url}/list-artworks`:
         text = 'Pregled umjetničkih djela';
         show = true;
         break;
      case `${url}/add-exhibition`:
         text = 'Dodavanje izložbe';
         show = true;
         break;
      case `${url}/list-exhibitions`:
         text = 'Pregled izložbi';
         show = true;
         break;
      case `${url}/details-author`:
         text = 'Detalji autora';
         show = true;
         break;
      case `${url}/details-artwork`:
         text = 'Detalji umjetničkog djela';
         show = true;
         break;
      case `${url}/details-exhibition`:
         text = 'Detalji izložbe';
         show = true;
         break;

      default:
         text = '';
         show = false;
         break;
   }

   const cssclass = show ? 'submenu show-submenu' : 'submenu';
   return (
      <motion.div
         className={cssclass}
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ delay: 0.5 }}
      >
         <div className='submenu-text'>{text}</div>
      </motion.div>
   );
};

export default Submenu;
