import React, { useEffect } from 'react';
import NotFound from '../components/not-found';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { notFoundAnimation } from './animations/not-found';
import { withRouter } from 'react-router-dom';

const NotFoundPage = (props) => {
    useEffect(() => {
        document.title = '404 - traženi resurs nije pronađen';
    });

    return (
        <MotionContainer
            className='not-found-page'
            animationObject={notFoundAnimation}
        >
            <h1>
                <FontAwesomeIcon icon={faBug} className='icon-header mr-3' />
                404 <span>traženi resurs nije pronađen</span>
                <hr />
                <NotFound />
            </h1>
        </MotionContainer>
    );
};

export default withRouter(NotFoundPage);
