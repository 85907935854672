import React from 'react';
import Form from '../common/form';
import ArtworkInCollections from '../author/professionalactivity-information/artwork-in-collections';
import AuthorAreaOfInterest from '../author/professionalactivity-information/author-area-of-interest';
import AuthorAward from '../author/professionalactivity-information/author-award';
import AuthorBibliography from '../author/professionalactivity-information/author-bibliography';
import AuthorAssociation from '../author/professionalactivity-information/author-association';
import AuthorAtelier from '../author/professionalactivity-information/author-atelier';
import AddAuthorRepresentation from '../author/professionalactivity-information/add-author-representation';
import MotionContainer from '../common/motion-containers/motion-container';
import html from '../../services/html';
import { connect } from 'react-redux';
import { userIsAdmin } from './../../services/auth';
import ButtonNext from './../common/btn-next';

class AuthorGroupProfessionalActivityInformation extends Form {
   constructor(props) {
      super(props);
      this.iar = props.data.IsAuthorRepresentation;
      this.iaic = props.data.IsArtworkInCollections;
      this.aicd = props.data.ArtworkInCollectionsDescription;
      this.aaoi = props.data.AuthorAreaOfInterests;
      this.ar = props.data.AuthorRepresentations;
      this.awe = props.data.AuthorWorkExperiences;
      this.aw = props.data.AuthorAwards;
      this.ab = props.data.AuthorBibliographies;
      this.aa = props.data.AuthorAssociations;
      this.IsAtelier = props.data.IsAtelier;
      this.ata = props.data.AtelierAddress;
      this.lat = props.data.AtelierGeoLatitude;
      this.lng = props.data.AtelierGeoLongitude;
      this.acn = props.data.AtelierCityName;

      this.state = {
         validationData: {},
         restData: {
            IsArtworkInCollections: this.iaic ? this.iaic : false,
            ArtworkInCollectionsDescription: this.aicd ? this.aicd : '',
            AuthorAreaOfInterests: this.aaoi ? this.aaoi : [],
            IsAuthorRepresentation: this.iar ? this.iar : false,
            AuthorRepresentations: this.ar ? this.ar : [],
            AuthorWorkExperiences: this.awe ? this.awe : [],
            AuthorAwards: this.aw ? this.aw : [],
            AuthorBibliographies: this.ab ? this.ab : [],
            AuthorAssociations: this.aa ? this.aa : [],
            IsAtelier: this.IsAtelier ? this.IsAtelier : false,
            AtelierAddress: this.ata ? this.ata : '',
            AtelierGeoLatitude: this.lat ? this.lat : null,
            AtelierGeoLongitude: this.lng ? this.lng : null,
            AtelierCityName: this.acn ? this.acn : ''
         },
         errors: {}
      };
   }

   isValid = false;

   async componentDidMount() {}

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const {
         AuthorAreaOfInterests,
         IsArtworkInCollections,
         ArtworkInCollectionsDescription,
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         IsAuthorRepresentation,
         AuthorRepresentations,
         AtelierCityName,
         AuthorAwards,
         AuthorBibliographies,
         AuthorAssociations
      } = this.state.restData;

      const nijeUnioPodrujaInteresa = AuthorAreaOfInterests.length === 0;
      const nijeUnioumjetnickaDjela =
         IsArtworkInCollections && ArtworkInCollectionsDescription.length === 0;
      const nijeUnioAtelje = IsAtelier && AtelierAddress.length === 0;
      const nijeUnioGalerijskaZastupstva =
         IsAuthorRepresentation && AuthorRepresentations.length === 0;

      //const disable = this.validateForm();
      this.isValid = !(
         nijeUnioPodrujaInteresa ||
         nijeUnioumjetnickaDjela ||
         nijeUnioAtelje ||
         nijeUnioGalerijskaZastupstva
      );

      const artworkInCollections_defaultValue = {
         IsArtworkInCollections,
         ArtworkInCollectionsDescription
      };

      const artworkRepresentations_defaultValue = {
         IsAuthorRepresentation,
         AuthorRepresentations
      };

      const atelier_defaultValue = {
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         AtelierCityName
      };

      const { isEditAfterVerification } = this.props.authorState;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      return (
         <>
            <MotionContainer className='author-professional-activity'>
               <form
                  onSubmit={this.handleFormSubmit}
                  className='form-style-width'
               >
                  <h2>Podaci o profesionalnom djelovanju skupine</h2>
                  <div className='row'>
                     <div className='col-6'>
                        <ArtworkInCollections
                           defaultValue={artworkInCollections_defaultValue}
                           onArtworkInCollectionsChange={
                              this.handle_OnArtworkInCollectionsChange
                           }
                        />
                        <hr />
                        <AddAuthorRepresentation
                           disabled={isUserEditAfterVerification}
                           defaultValue={artworkRepresentations_defaultValue}
                           onArtworkInCollectionsChange={
                              this.handleRepresentationAdded
                           }
                        />
                        <hr />
                        <AuthorAtelier
                           disabled={isUserEditAfterVerification}
                           defaultValue={atelier_defaultValue}
                           onAdd={this.handleAuthorAtelierAdded}
                        />
                        <hr />
                        <AuthorAreaOfInterest
                           defaultValue={AuthorAreaOfInterests}
                           onAreaOfInterestSelect={
                              this.handleAuthorAreaOfInterests
                           }
                        />
                     </div>
                     <div className='col-6'>
                        <AuthorAward
                           defaultValue={AuthorAwards}
                           onAddAuthorAward={this.handleAwardAdded}
                        />

                        <hr />
                        <AuthorBibliography
                           defaultValue={AuthorBibliographies}
                           onAddAuthorBibliography={
                              this.handle_BibliographyAdded
                           }
                        />
                        <hr />
                        <AuthorAssociation
                           defaultValue={AuthorAssociations}
                           onAdd={this.handle_AssociationsAdded}
                        />
                     </div>
                  </div>
                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(2);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const restData = { ...this.state.restData };
      const validationData = { ...this.state.validationData };
      const data = { ...restData, ...validationData };
      data.isValid = this.isValid;

      this.props.onSubmit(data);
   };

   handleAuthorAreaOfInterests = (authorAreaOfInterests) => {
      const restData = { ...this.state.restData };
      restData.AuthorAreaOfInterests = authorAreaOfInterests;
      this.setState({ restData });
   };

   handleRepresentationAdded = (representations, IsAuthorRepresentation) => {
      const restData = { ...this.state.restData };
      restData.AuthorRepresentations = representations;
      restData.IsAuthorRepresentation = IsAuthorRepresentation;
      this.setState({ restData });
   };

   handle_BibliographyAdded = (authorBibliographies) => {
      const restData = { ...this.state.restData };
      restData.AuthorBibliographies = authorBibliographies;
      this.setState({ restData });
   };

   handle_AssociationsAdded = (authorAssociations) => {
      const restData = { ...this.state.restData };
      restData.AuthorAssociations = authorAssociations;
      this.setState({ restData });
   };

   handle_OnArtworkInCollectionsChange = (data) => {
      const { IsArtworkInCollections, ArtworkInCollectionsDescription } = data;

      const restData = { ...this.state.restData };
      restData.IsArtworkInCollections = IsArtworkInCollections;
      restData.ArtworkInCollectionsDescription =
         ArtworkInCollectionsDescription;

      this.setState({ restData });
   };

   handleAwardAdded = (awards) => {
      const restData = { ...this.state.restData };
      restData.AuthorAwards = awards;
      this.setState({ restData });
   };

   handleAuthorAtelierAdded = (atelierData) => {
      const {
         IsAtelier,
         AtelierAddress,
         AtelierGeoLatitude,
         AtelierGeoLongitude,
         AtelierCityName
      } = atelierData;

      const restData = { ...this.state.restData };
      restData.IsAtelier = IsAtelier;
      restData.AtelierAddress = AtelierAddress;
      restData.AtelierGeoLatitude = AtelierGeoLatitude;
      restData.AtelierGeoLongitude = AtelierGeoLongitude;
      restData.AtelierCityName = AtelierCityName;

      this.setState({ restData });
   };
}
const mapStateToProps = (state) => ({
   authorState: state.author
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorGroupProfessionalActivityInformation);
