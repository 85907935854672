const initState = {
    userFirstName: '',
    userLastName: '',
    username: '',
    token: '',
    refreshToken: '',
    roleId: 0
};

export const setUserToStore = (user) => ({
    type: 'SET_USER',
    payload: {
        user
    }
});

export const removeUserFromStore = () => ({
    type: 'REMOVE_USER_FROM_STORE'
});

//  REDUCER
export default function reducer(state = initState, action) {
    switch (action.type) {
        case 'SET_USER':
            const {
                username,
                userFirstName,
                userLastName,
                token,
                refreshToken,
                roleId
            } = action.payload.user;
            return {
                username,
                userFirstName,
                userLastName,
                token,
                refreshToken,
                roleId
            };
        case 'REMOVE_USER_FROM_STORE':
            return initState;
        default:
            return state;
    }
}
