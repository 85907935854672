import Joi from 'joi-browser';

export const AddNewExhibitionMediaAnnouncementSchema = {
   Authors: Joi.string()
      .required()
      .label('Autor')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Name: Joi.string()
      .required()
      .label('Nagrada')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Edition: Joi.string()
      .required()
      .label('Izdanje')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Url: Joi.any().optional(),
   MediaAnnouncementTypeId: Joi.any().optional(),
   MediaAnnouncementTypeOther: Joi.any().optional()
};
