import Joi from 'joi-browser';

export const AddNewAuthorAwardSchema = {
    Name: Joi.string()
        .required()
        .label('Nagrada')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
