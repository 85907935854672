export const popup_container_animation = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.3,
            staggerChildren: 0.75,
            when: 'beforeChildren'
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut'
        }
    }
};

export const popup_container_header_animation = {
    hidden: {
        opacity: 0,
        y: 0
    },
    show: {
        opacity: 1,
        y: 20,
        transition: {
            duration: 0.2
        }
    }
};
// export const addEntity_Header_Animation = {
//    hidden: {
//       opacity: 0,
//       y: 0
//    },
//    show: {
//       opacity: 1,
//       y: 20,
//       transition: {
//          duration: 0.2,
//          y: {
//             type: 'spring',
//             stiffness: 250
//          }
//       }
//    }
// };
