import React from 'react';
import Form from './common/form';
import { getCurrentUser, loginUser } from '../services/auth';
import { loginUserSchema } from './../validators/login-user';
import { toast } from 'react-toastify';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserToStore } from './../store/user';
class LoginUser extends Form {
    state = {
        validationData: {
            Username: '',
            Password: ''
        },
        errors: {}
    };

    schema = loginUserSchema;

    homePage = 'homepage';

    doSubmit = async () => {
        const user = { ...this.state.validationData };
        const { saveToStore } = this.props;
        user.GrantType = 'password';
        try {
            const { data } = await loginUser(user);

            if (data.statusId === 1) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('roleId', data.roleId);

                const {
                    userFirstName,
                    userLastName,
                    token,
                    refreshToken,
                    roleId
                } = data;

                const userData = {
                    username: `${user.Username}`,
                    userFirstName,
                    userLastName,
                    token,
                    refreshToken,
                    roleId
                };

                saveToStore(userData);
                //window.location = `${process.env.PUBLIC_URL}?page=${this.homePage}`;
                //window.location = `${process.env.PUBLIC_URL}/#/${this.homePage}`;
                window.location = `/`;
            } else {
                toast.error(`${data.message}`);
            }
        } catch (ex) {
            toast.warning(`${ex.status} - ${ex.data.Message}`);
        }
    };

    render() {
        if (getCurrentUser()) {
            return (
                <Redirect to={`${process.env.PUBLIC_URL}/${this.homePage}`} />
            );
        }
        return (
            <div className='login-user'>
                <form onSubmit={this.handleFormSubmit}>
                    {this.renderInput('Username', 'Korisničko ime', {
                        placeholder: 'E-mail...',
                        autoFocus: true
                    })}
                    {this.renderInput('Password', 'Lozinka', {
                        type: 'password'
                    })}
                    <hr />
                    <div
                        style={{
                            marginBottom: '1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div>
                            Nemate korisnički račun?{' '}
                            <NavLink
                                to={`${process.env.PUBLIC_URL}/register`}
                                style={{ color: '#6e39ff' }}
                            >
                                Registrirajte se
                            </NavLink>
                        </div>
                        <div>
                            <NavLink
                                to={`${process.env.PUBLIC_URL}/forgot-password`}
                                style={{ color: '#6e39ff' }}
                            >
                                Zaboravili ste lozinku?
                            </NavLink>
                        </div>
                    </div>
                    {this.renderSubmitButton('Prijava', this.validateForm())}
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    saveToStore: (data) => dispatch(setUserToStore(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginUser);
