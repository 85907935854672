import React from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/hr_HR';
import moment from 'moment';
import html from '../../services/html';

const AntDatepicker = ({
   label,
   defaultDate = moment(new Date()),
   requiredLabel,
   onChange,
   validationMessage = '',
   placeholder = 'Odaberite datum',
   rest
}) => {
   const handleOnChange = (e) => {
      if (!e) return;
      onChange(e);
   };

   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };

   return (
      <div className='form-group'>
         <label style={style}>
            <span className='mr-1'>{label}</span>
            {requiredLabel && html.renderRequiredTooltip()}
         </label>
         <DatePicker
            format='DD.MM.YYYY'
            locale={locale}
            style={{ borderRadius: '.2rem', width: '100%' }}
            onChange={handleOnChange}
            defaultValue={defaultDate}
            placeholder={placeholder}
            {...rest}
         />
         {validationMessage && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {validationMessage}
            </div>
         )}
      </div>
   );
};

export default AntDatepicker;
