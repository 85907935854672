import React from 'react';
import Form from '../common/form';
import MotionContainer from '../common/motion-containers/motion-container';
import { connect } from 'react-redux';
import { loadArtworkStaticData, loadCities } from '../../store/artwork';
import { ArtworkBasicInformationSchema } from '../../validators/artwork-basic-information';
import ImageUpload from '../common/image-upload';
import { Image } from 'antd';
import { v4 as uuid } from 'uuid';
import { userIsAdmin } from './../../services/auth';
import ButtonSave from './../common/btn-save';

class ArtworkRestInformation extends Form {
   constructor(props) {
      super(props);

      const { Remark, Url, ArtworkPhotographies, componentPhotographies } =
         props.data;

      this.Remark = Remark;
      this.Url = Url;
      this.ArtworkPhotographies = ArtworkPhotographies;
      this.componentPhotographies = componentPhotographies;

      this.state = {
         validationData: {
            Remark: this.Remark ?? '',
            Url: this.Url ?? ''
         },
         restData: {
            ArtworkPhotographies: this.ArtworkPhotographies ?? [],
            componentPhotographies: this.componentPhotographies ?? []
         },
         errors: {}
      };
   }

   isValid = false;

   schema = ArtworkBasicInformationSchema;

   async componentDidMount() {
      this.props.loadArtworkStaticData();
   }

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const { componentPhotographies, ArtworkPhotographies } =
         this.state.restData;

      const { isEditMode, isEditAfterVerification } = this.props.artwork;

      const btnClassBase = 'btn form-control btn-';
      const btnClass = isEditMode ? `edit-mode` : `primary-mode`;

      const btnText = isEditMode
         ? 'Ažuriraj podatke umjetničkog djela'
         : 'Kreiraj umjetničko djelo';

      const showSaveButton = !isEditAfterVerification || userIsAdmin();

      return (
         <>
            <MotionContainer className='author-professional-activity'>
               <form onSubmit={this.handleFormSubmit} className='form-style'>
                  <h2>Ostali podaci o umjetničkom djelu</h2>
                  <div>
                     <div className='label-style mr-1 mb-3'>
                        {isEditAfterVerification
                           ? 'Fotografije'
                           : 'Odaberite fotografije'}
                        {!isEditAfterVerification && (
                           <span
                              style={{
                                 fontSize: '.6rem',
                                 display: 'inherit',
                                 opacity: '.5'
                              }}
                           >
                              Maksimalna dozvoljena veličina fotografije: 1MB
                           </span>
                        )}
                     </div>
                     <div className='rest-pictures'>
                        {isEditAfterVerification &&
                           ArtworkPhotographies.map((photo) => (
                              <div
                                 style={{
                                    margin: '.3rem',
                                    display: 'inline-block',
                                    cursor: 'pointer'
                                 }}
                                 key={uuid()}
                              >
                                 <Image
                                    width={100}
                                    height={66}
                                    src={photo.PhotoFileContentBase64}
                                 />
                              </div>
                           ))}
                        {!isEditAfterVerification && (
                           <ImageUpload
                              onPhotoAdded={this.handleOnPhotoAddOrRemove}
                              defaultComponentValues={componentPhotographies}
                              defaultArtworkPhotographies={ArtworkPhotographies}
                              onPhotoRemove={this.handleOnPhotoAddOrRemove}
                              isEditMode={this.props.artwork.isEditMode}
                           />
                        )}
                     </div>
                     <hr />
                     {this.renderInput('Remark', 'Napomena')}
                     {this.renderInput('Url', 'URL na djelo')}
                     <hr />
                     {showSaveButton && (
                        <ButtonSave
                           text={btnText}
                           className={btnClass}
                           onClick={this.handleCreateOrUpdateArtwork}
                        />
                     )}
                  </div>
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const restData = { ...this.state.restData };
      const validationData = { ...this.state.validationData };
      const data = { ...restData, ...validationData };

      data.isValid = true;
      //spremi podatke na state-u parent kontrole [ArtworkAddPage]
      this.props.onSubmit(data);
   };

   // handleOnPhotoRemove = (ArtworkPhotographies, componentPhotographies) => {
   //     const PhotoFileContentBase64 = '';
   //     const PhotoOriginalFileName = '';

   //     this.setState({
   //         PhotoFileContentBase64,
   //         PhotoOriginalFileName,
   //         componentPhotographies,
   //         ArtworkPhotographies
   //     });
   // };

   handleOnPhotoAddOrRemove = (
      ArtworkPhotographies,
      componentPhotographies
   ) => {
      const restData = { ...this.state.restData };
      restData.ArtworkPhotographies = ArtworkPhotographies;
      restData.componentPhotographies = componentPhotographies;

      this.setState({ restData });
   };

   handleCreateOrUpdateArtwork = () => {
      const { onCreateArtwork } = this.props;
      const { Remark, Url } = this.state.validationData;
      const { ArtworkPhotographies } = this.state.restData;
      const data = {
         ArtworkPhotographies,
         Url,
         Remark
      };
      this.doSubmit();
      data.isValid = true;
      onCreateArtwork(data);
   };
}
const mapStateToProps = (state) => ({
   artwork: state.artwork,
   countries: state.artwork.countries,
   artworkTechnique: state.artwork.artworkTechnique,
   ownershipType: state.artwork.ownershipType
});

const mapDispatchToProps = (dispatch) => ({
   loadArtworkStaticData: () => dispatch(loadArtworkStaticData()),
   loadCities: (countryId) => dispatch(loadCities(countryId))
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ArtworkRestInformation);
