import React from 'react';
import Form from '../../common/form';
import AddLegalEntity from '../../common/add-legal-entity';
import { v4 as uuid } from 'uuid';
import PopupContainer from '../../common/popup-container/popup-container';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

class AddScholarship extends Form {
   state = {
      validationData: {},
      restData: {
         AuthorScholarshipId: null,
         DateFrom: '',
         DateTo: '',
         LegalEntityId: null
      },
      errors: []
   };

   handleAddClick = () => {
      const { onAdd, onClose } = this.props;
      const data = { ...this.state.restData };
      //dodatne informacije za lakše renderiranje
      data.id = uuid();
      onAdd(data);
      onClose();
   };

   render() {
      const { DateFrom, LegalEntityId } = this.state.restData;
      const { onClose } = this.props;

      const disabled = DateFrom === '' || !LegalEntityId;

      return (
         <PopupContainer title='Dodavanje stipendije' onPopupClose={onClose}>
            <div className='row'>
               <div className='col'>
                  {this.renderDatePicker('Datum od', this.handleDateFrom)}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Datum do', this.handleDateTo)}
               </div>
            </div>
            <AddLegalEntity
               onLegalEntitySelect={this.handleLegalEntitySelected}
            />
            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleAddClick} />
               <ButtonCancel
                  onClick={() => {
                     this.props.onClose();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleLegalEntitySelected = (value, obj) => {
      const { id: LegalEntityId, value: LegalEntityName } = obj;

      const selectedSpecializationType = {
         ...this.state.selectedSpecializationType
      };
      selectedSpecializationType.LegalEntityName = LegalEntityName;
      const { restData } = this.state;
      restData.LegalEntityId = LegalEntityId;
      this.setState({ restData, selectedSpecializationType });
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.onClose();
   };

   handleDateFrom = (e) => {
      const { restData } = this.state;
      restData.DateFrom = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };

   handleDateTo = (e) => {
      const { restData } = this.state;
      restData.DateTo = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };
}
export default AddScholarship;
