import React, { useEffect } from 'react';
import { pageAnimation } from './page-transition-animation';
import { loginHeaderAnimation } from './animations/login-page';
import LoginUser from '../components/login-user';
import MotionContainer from '../components/common/motion-containers/motion-container';

const LoginPage = (props) => {
   useEffect(() => {
      document.title = 'Prijava';
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <MotionContainer className='login-page' animationObject={pageAnimation}>
         <div className='login-page-content'>
            <MotionContainer
               className='login-page'
               animationObject={loginHeaderAnimation}
            >
               <h1>
                  Prijava <span>korisnika</span>
               </h1>
            </MotionContainer>
            <LoginUser />
         </div>
      </MotionContainer>
   );
};

export default LoginPage;
