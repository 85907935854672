import React from 'react';
import Form from '../../common/form';
import { v4 as uuid } from 'uuid';
import AddCity from '../../common/add-city';
import { AddNewAuthorBibliographySchema } from '../../../validators/add-author-bibliography';

import PopupContainer from '../../common/popup-container/popup-container';
import { connect } from 'react-redux';
import { loadAuthorBibliographies } from '../../../store/author';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

class AddNewAuthorBibliography extends Form {
   state = {
      validationData: {
         Authors: '',
         Edition: '',
         Name: ''
      },
      restData: {
         BibliographyCategoryId: 0,
         Year: '',
         CountryId: null,
         CityId: 0,
         CityName: '',
         BibliographyName: ''
      },
      errors: []
   };

   schema = AddNewAuthorBibliographySchema;

   render() {
      const { Year, BibliographyCategoryId, CountryId, CityId } =
         this.state.restData;
      //store
      const { authorBibliographies } = this.props.authorState;
      const { countries } = this.props.commonState;

      const { closeAddNewBibliography } = this.props;
      const disabled =
         this.validateForm() ||
         BibliographyCategoryId === 0 ||
         CityId === 0 ||
         Year === 0;

      return (
         <PopupContainer
            title='Dodavanje bibliografije'
            onPopupClose={closeAddNewBibliography}
         >
            <div className='row'>
               <div className='col'>{this.renderInput('Name', 'Naziv')}</div>
               <div className='col'>
                  {this.renderInput('Edition', 'Izdanje')}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {this.renderInput('Authors', 'Autori')}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Godina', this.handleYearSelected, {
                     picker: 'year',
                     placeholder: 'Odaberite godinu...',
                     format: 'YYYY'
                  })}
               </div>
            </div>
            {this.renderSelectAnt(
               'Potkategorija',
               'Name',
               'BibliographyCategoryId',
               'Započnite unos ili odaberite bibliografiju...',
               'Ne pronalazim traženu bibliografiju...',
               authorBibliographies,
               this.handleBibliographySelected
            )}
            <div className='row'>
               <div className='col'>
                  {this.renderSelectAnt(
                     'Država',
                     'Name',
                     'CountryId',
                     'Započnite unos ili odaberite državu...',
                     'Ne pronalazim traženu državu...',
                     countries,
                     this.handleCountrySelected
                  )}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {CountryId && (
                     <AddCity
                        countryId={CountryId}
                        onCitySelect={this.handleCitySelected}
                     />
                  )}
               </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewBibliography();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleBibliographySelected = (value, obj) => {
      const { id: BibliographyCategoryId, value: BibliographyName } = obj;

      const restData = { ...this.state.restData };
      restData.BibliographyCategoryId = BibliographyCategoryId;
      restData.BibliographyName = BibliographyName;
      this.setState({ restData });
   };

   handleCountrySelected = async (value, obj) => {
      const { id: CountryId } = obj;

      const restData = { ...this.state.restData };
      restData.CountryId = CountryId;

      this.setState({
         restData
      });
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewBibliography();
   };

   handleOnSubmit = () => {
      const { onBibliographyAdded, closeAddNewBibliography } = this.props;

      const newBibliography = {
         ...this.state.validationData,
         ...this.state.restData
      };

      newBibliography.id = uuid();
      console.log(newBibliography);
      onBibliographyAdded(newBibliography);
      closeAddNewBibliography();
   };

   handleYearSelected = (e) => {
      const { restData } = this.state;
      restData.Year = e.format('YYYY');
      this.setState({ restData });
   };

   handleCitySelected = (value, obj) => {
      const { id: CityId, value: CityName } = obj;

      const restData = { ...this.state.restData };
      restData.CityId = CityId;
      restData.CityName = CityName;

      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   authorState: state.author,
   commonState: state.common
});

const mapDispatchToProps = (dispatch) => ({
   loadAuthorBibliographies: () => dispatch(loadAuthorBibliographies())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewAuthorBibliography);
