import React, { Component } from 'react';
import { AutoComplete } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

class AutocompleteAntdesign extends Component {
    state = {};
    inputElement = React.createRef();
    render() {
        const {
            name,
            label,
            error,
            options,
            onSelect,
            onSearch,
            loadingIndicator,
            ...rest
        } = this.props;

        const style = {
            marginBottom: '2px',
            fontWeight: 700,
            fontSize: '.9rem'
        };
        return (
            <div className='autocomplete'>
                <div className='form-group'>
                    <label htmlFor={name} style={style}>
                        {label}
                        {loadingIndicator && (
                            <LoadingOutlined className='ml-1' />
                        )}
                    </label>
                    <AutoComplete
                        filterOption={true}
                        options={options}
                        onSelect={onSelect}
                        onSearch={onSearch}
                        style={{ width: '100%' }}
                        dropdownMatchSelectWidth={false}
                        ref={this.inputElement}
                        name={name}
                        id={name}
                        {...rest}
                    />
                    {error && (
                        <div
                            className='alert alert-primary'
                            style={{ fontWeight: 700 }}
                        >
                            {error}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { autoFocus } = this.props;
        if (autoFocus) this.inputElement.current.focus();
    }
}

/* autoFocus se samo doda kao atribut... bez vrijednosti... */
AutocompleteAntdesign.defaultProps = {
    label: 'Autocomplete',
    placeholder: 'Započnite unos...',
    autoComplete: 'off',
    autoFocus: false
};
export default AutocompleteAntdesign;
