import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import AddNewArtworkBibliography from './add-new-artwork-bibliography';
import ButtonForm from './../common/btn-form';

const ArtworkBibliography = ({
   onAddAuthorBibliography,
   defaultValue,
   disabled
}) => {
   const [showBibliography, setShowBibliography] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddAuthorBibliography(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewBibliography = () => {
      setShowBibliography(!showBibliography);
   };

   const handle_OnBibliographyAdded = (bibliographyData) => {
      setKolekcija([...kolekcija, bibliographyData]);
   };

   const handle_RemoveFromBibliographyList = (id) => {
      setKolekcija(kolekcija.filter((bibliography) => bibliography.id !== id));
   };

   return (
      <>
         {showBibliography && (
            <AddNewArtworkBibliography
               onBibliographyAdded={handle_OnBibliographyAdded}
               closeAddNewBibliography={showHide_AddNewBibliography}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               Bibliografija
            </label>
            <ButtonForm
               text='Dodaj bibliografiju'
               onClick={showHide_AddNewBibliography}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih bibliografija...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Naziv</th>
                           <th>Autori</th>
                           <th>Izdanje</th>
                           <th>Godina</th>
                           {/* <th>Grad</th> */}
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((bibliography) => (
                           <tr key={uuid()}>
                              {html.td(85, bibliography.Name)}
                              {html.td(85, bibliography.Authors)}
                              {html.td(60, bibliography.Edition)}
                              {html.td(50, bibliography.Year)}
                              {/* {html.td(60, bibliography.CityName)} */}
                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromBibliographyList,
                                 bibliography.id,
                                 {
                                    title: `Ukloni ${bibliography.Name}`
                                 },
                                 disabled
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ArtworkBibliography;
