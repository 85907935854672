import React from 'react';
import Form from '../common/form';
import PopupContainer from '../common/popup-container/popup-container';
import html from '../../services/html';
import { connect } from 'react-redux';
import { AddNewExhibitionAuthorSchema } from './../../validators/add-new-exhibition-author';
import { refreshAuthorsList } from '../../store/common';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';

class AddNewExhibitionAuthor extends Form {
   state = {
      validationData: {
         AuthorName: ''
      },
      restData: {
         AuthorId: null
      },
      authors: [],
      customAuthorNameEnter: false,
      errors: []
   };

   schema = AddNewExhibitionAuthorSchema;

   async componentDidMount() {
      this.props.refreshAuthorsList();
   }

   render() {
      const { customAuthorNameEnter } = this.state;

      const { authors, closeAddNewArtworkAuthor } = this.props;

      const { AuthorName } = this.state.validationData;
      const { AuthorId } = this.state.restData;

      const nijeUnesenAutor = AuthorId === null && AuthorName.trim() === '';

      const disabled = nijeUnesenAutor;

      const _authors = authors
         .filter((a) => a.AuthorTypeId === 1)
         .map((a) => ({
            AuthorId: a.AuthorId,
            Name: `${a.Name}`
         }));

      return (
         <PopupContainer
            title='Odabir/unos autora izložbe'
            onPopupClose={closeAddNewArtworkAuthor}
         >
            {!customAuthorNameEnter &&
               html.renderSelectAnt(
                  'Izlagač(i) / Autor(i)',
                  'Name',
                  'AuthorId',
                  'Započnite unos ili odaberite izlagača/autora...',
                  'Ne pronalazim traženu izložbu...',
                  _authors,
                  this.handleAuthorSelect
               )}

            {customAuthorNameEnter && (
               <div>
                  {this.renderInput('AuthorName', 'Izlagač(i) / Autor(i)', {
                     style: { height: '35px' },
                     placeholder: 'Unesite naziv izlagača/autora...'
                  })}
               </div>
            )}

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewArtworkAuthor();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewArtworkAuthor();
   };

   handleOnSubmit = () => {
      const { onArtworkAuthorAdded, closeAddNewArtworkAuthor } = this.props;
      const { AuthorName } = this.state.validationData;
      const { AuthorId } = this.state.restData;

      onArtworkAuthorAdded({ AuthorName, AuthorId });
      closeAddNewArtworkAuthor();
   };

   handleAuthorSelect = (selectedText, valueObj) => {
      const restData = {};
      restData.AuthorId = valueObj.id;
      this.setState({ restData });
   };

   handleCheckboxChecked = ({ target: input }) => {
      if (input.checked) {
         const restData = {};
         restData.AuthorId = null;
         this.setState({ restData });
      } else {
         const validationData = {};
         validationData.AuthorName = '';
         this.setState({ validationData });
      }
      this.setState({ customAuthorNameEnter: input.checked });
   };
}

const mapStateToProps = (state) => ({
   authors: state.common.authors
});

const mapDispatchToProps = (dispatch) => ({
   refreshAuthorsList: () => dispatch(refreshAuthorsList())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewExhibitionAuthor);
