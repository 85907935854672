export const authorAdd_StepsAnimation = {
   hidden: {
      opacity: 0,
      x: -50
   },
   show: {
      opacity: 1,
      x: 0,
      transition: {
         duration: 0.25
      }
   }
};
