import axios from 'axios';

const err = (error) => {
   return {
      time: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
      error
   };
};

const saveError = (error) => {
   let errorRepo;
   if (localStorage.getObject('errors'))
      errorRepo = localStorage.getObject('errors');
   else errorRepo = [];

   if (errorRepo.length > 30) errorRepo = [];
   errorRepo.push(err(error));
   localStorage.setObject('errors', errorRepo);
};

//AXIOS can intecept errors
// success - error are functions
//axios.interceptors.response.use(success, error)
axios.interceptors.response.use(null, (error) => {
   //expected error - in 400 range
   const expectedError =
      error.response && error.response.status >= 400 && error.status < 500;
   if (expectedError) {
      return Promise.reject(error.response);
   } else {
      //Znaci ovaj mehanizam omogucava custom logiku za neocekivane greske
      //Moze se npr ugraditi funkcionalnost logiranja gresaka i sl... koju onda ne trebamo
      //implementirati u svakom try-catch bloku
      if (error.response) {
         console.log('axios.interceptors.error', error.response);

         saveError(error.response);

         if (error.response.status === 401) {
            // console.log('401 - Unauthorized');
            // console.log('error.response', error.response);
            //alert('401 - Unauthorized');
            localStorage.removeItem('token');
            localStorage.removeItem('roleId');
            //const redirectURL = 'logout/';
            //window.location = `${process.env.PUBLIC_URL}?page=${redirectURL}`;
            window.location = `${process.env.PUBLIC_URL}/#/logout`;
            //window.location = `/`;
         }
         if (error.response.status >= 500) {
            //const { status, statusText } = error.response;
            console.log('error.response', error.response);
            //alert(`Status: ${status}\n${statusText}`);
            //const redirectURL = 'homepage/';
            //window.location = `${process.env.PUBLIC_URL}?page=${redirectURL}`;
            window.location = `${process.env.PUBLIC_URL}/#/homepage`;
         }
      }
      // console.log(
      //     'Unexpected error',
      //     `status: ${error.response.status}`,
      //     `status-text: ${error.response.statusText}`
      // );
   }

   //to pass control to catch() block we need to return a rejected Promise with error included
   return Promise.reject(error.response);
});

export function setJWTtoHeader(jwt) {
   axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
}

const jwt = 'token';

const userIsLogged = () => {
   return localStorage.getItem(jwt) ? true : false;
};
if (userIsLogged())
   axios.defaults.headers.common[
      'Authorization'
   ] = `Bearer ${localStorage.getItem(jwt)}`;

const exportObj = {
   get: axios.get,
   post: axios.post,
   put: axios.put,
   delete: axios.delete,
   all: axios.all,
   spread: axios.spread,
   userIsLogged,
   setJWTtoHeader
};
export default exportObj;
