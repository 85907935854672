import Joi from 'joi-browser';

export const forgotPasswordSchema = {
    email: Joi.string()
        .email()
        .required()
        .label('E-mail')
        .error((errors) => {
            if (errors[0].type === 'any.empty')
                return {
                    message: `${errors[0].flags.label} je obavezan`
                };
            if (errors[0].type === 'string.email')
                return {
                    message: `Neispravna ${errors[0].flags.label} adresa`
                };
            return {
                message: `${errors[0].flags.label} - greška.`
            };
        })
};

//bla: Joi.any().optional(),
