import React from 'react';
import AddNewJobFunction from './add-new-jobfunction';
import Form from '../../common/form';
import ShowTooltip from '../../common/show-tooltip';
import { connect } from 'react-redux';
import { loadJobFunctions } from '../../../store/author';
import ButtonFormTooltip from './../../common/btn-form-tooltip';

class AddJobFunction extends Form {
   state = {
      validationData: {},
      errors: {},
      jobfunctions: [],
      showAddNewJobFunction: false
   };

   async componentDidMount() {
      this.props.loadJobfunctions();
   }

   handleCloseAddNewJobFunction = (e) => {
      this.props.loadJobfunctions();
      this.setState({ showAddNewJobFunction: false });
   };

   render() {
      const { showAddNewJobFunction } = this.state;
      const { jobfunctions } = this.props.authorState;
      return (
         <>
            {showAddNewJobFunction && (
               <AddNewJobFunction
                  closeAddNewJobFunction={this.handleCloseAddNewJobFunction}
               />
            )}
            <div className='row'>
               <div className='col-8'>
                  {this.renderSelectAnt(
                     'Radno mjesto',
                     'Name',
                     'JobFunctionId',
                     'Započnite unos ili odaberite radno mjesto...',
                     'Ne pronalazim traženo radno mjesto...',
                     jobfunctions,
                     this.props.onJobFunctionSelect
                  )}
               </div>
               <div className='col'>
                  <ButtonFormTooltip
                     inline={true}
                     text='Dodaj radno mjesto'
                     onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                           showAddNewJobFunction: true
                        });
                     }}
                     tooltip='Ukoliko ne pronalazite radno mjesto među ponuđenim opcijama, dodajte ga sami.'
                  />
               </div>
            </div>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   authorState: state.author
   //personalInformation: state.author.personalInformation
});

const mapDispatchToProps = (dispatch) => ({
   loadJobfunctions: () => dispatch(loadJobFunctions())
   // add: (article) => dispatch(addArticle(article)),
   // remove: (id) => dispatch(removeArticle(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddJobFunction);
