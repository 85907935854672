import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import {
   deleteAuthor,
   getUserCreatedAuthors,
   markAsVerified,
   reverifyAuthor
} from '../services/api';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/common/loading-spinner';
import swal from 'sweetalert';
import { animation } from './animations/created-authors-page-animation';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
   loadAuthorData,
   loadAuthorGroupData,
   loadAuthorStaticData,
   setAuthorOrGroupEditAfterVerificationMode,
   setAuthorOrGroupEditMode
} from '../store/author';
import TableCreatedAuthorList from '../components/author/created-author-or-group-list/table-created-author-list';
import TableCreatedAuthorGroupList from '../components/author/created-author-or-group-list/table-created-author-group-list';
import { setToastAutoCloseInterval } from './../store/app-setup';
import service from '../services/created-authors-page';
import { markAsReadyForVerification } from './../services/api';
import {
   uredjivanjeCeBitiOmoguceno,
   ucitavamPodatke
} from './../services/toast-messages';
import NoDataInfo from './../components/common/no-data-info';

// const swallSpeed = 1500;

const CreatedAuthorsPage = (props) => {
   const history = useHistory();
   const [dataLoaded, setDataLoaded] = useState(false);
   const [staticDataLoaded, setStaticDataLoaded] = useState(false);
   const [createdAuthors, setCreatedAuthors] = useState([]);
   const dispatch = useDispatch();
   const state = useSelector((state) => state.author);

   const loadCreatedAuthors = async () => {
      toast.info(ucitavamPodatke);
      const { data: response } = await getUserCreatedAuthors();

      if (response.status === 1) {
         setCreatedAuthors(response.details);
         setDataLoaded(true);
         toast.dismiss();
         loadStaticData();
      } else {
         toast.error(`${response.message}`);
      }
   };

   const loadStaticData = () => {
      if (!service.isStaticDataLoaded(state)) {
         dispatch(setToastAutoCloseInterval(10000));
         toast(uredjivanjeCeBitiOmoguceno);

         dispatch(loadAuthorStaticData()).then(() => {
            toast.dismiss();
            dispatch(setToastAutoCloseInterval(3000));
            setStaticDataLoaded(true);
         });
      } else {
         setStaticDataLoaded(true);
      }
   };

   useEffect(() => {
      document.title = 'Lista kreiranih autora';
      loadCreatedAuthors();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleEditAuthor = async (
      authorId,
      isVerified,
      IsReadyForVerification
   ) => {
      setDataLoaded(false);
      dispatch(setAuthorOrGroupEditAfterVerificationMode(isVerified));

      dispatch(loadAuthorData(authorId)).then(() => {
         dispatch(setAuthorOrGroupEditMode(true));
         history.push(`${process.env.PUBLIC_URL}/add-author`);
      });
   };

   const optimisticDeleteAuthor = (authorId) => {
      const filtriranoPolje = createdAuthors.filter(
         (ca) => ca.AuthorId !== authorId
      );
      setCreatedAuthors(filtriranoPolje);
   };

   const handleDeleteAuthorOrGroup = async (author, isGroup) => {
      swal({
         html: true,
         title: `Obrisati?`,
         text: !isGroup
            ? `${author.FirstName} ${author.LastName}`
            : author.GroupName,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Potvrdi',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then((value) => {
         switch (value) {
            case 'confirm':
               optimisticDeleteAuthor(author.AuthorId);
               deleteAuthorFromServer(author.AuthorId);
               break;
            default:
               break;
         }
      });
   };

   const deleteAuthorFromServer = async (authorId) => {
      const { data: response } = await deleteAuthor(authorId);
      if (response.status === 1) {
         toast.info(response.message);
      } else {
         toast.error(response.message);
         loadCreatedAuthors();
      }
   };

   const handleEditAuthorGroup = (authorId, isVerified) => {
      console.log('authorId, isVerified', authorId, isVerified);
      setDataLoaded(false);
      dispatch(setAuthorOrGroupEditAfterVerificationMode(isVerified));

      dispatch(loadAuthorGroupData(authorId)).then(() => {
         dispatch(setAuthorOrGroupEditMode(true));
         history.push(`${process.env.PUBLIC_URL}/add-author-group`);
      });
   };

   const handleToVerification = async (authorId, authorOrGroupName) => {
      swal({
         html: true,
         title: `Poslati na verifikaciju?`,
         text: `${authorOrGroupName}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Pošalji',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then(async (value) => {
         switch (value) {
            case 'confirm':
               const { data: response } = await markAsReadyForVerification(
                  authorId
               );
               if (response.status === 1) {
                  loadCreatedAuthors();
               } else {
                  toast.error(response.message);
               }

               break;
            default:
               break;
         }
      });
   };

   const handleOnVerify = async ({
      AuthorId,
      IsVerified,
      FirstName,
      LastName,
      GroupName
   }) => {
      const authorName = `${FirstName} ${LastName}`;
      swal({
         html: true,
         title: `Jeste li sigurni da želite verificirati?`,
         text: `${GroupName ? GroupName : authorName}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Verificiraj',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then(async (value) => {
         switch (value) {
            case 'confirm':
               let responseStatus;
               let responseMessage;
               if (IsVerified) {
                  const { data } = await reverifyAuthor(AuthorId);
                  responseStatus = data.status;
                  responseMessage = data.message;
               } else {
                  const { data } = await markAsVerified(AuthorId);
                  responseStatus = data.status;
                  responseMessage = data.message;
               }

               if (responseStatus === 1) {
                  loadCreatedAuthors();
               } else {
                  toast.error(responseMessage);
               }
               break;
            default:
               break;
         }
      });
   };

   const authors = createdAuthors.filter((ca) => ca.AuthorTypeId === 1);
   const authorGroups = createdAuthors.filter((ca) => ca.AuthorTypeId === 2);

   return (
      <>
         {!dataLoaded && <LoadingSpinner type='' text='' />}
         {dataLoaded && (
            <MotionContainer
               animationObject={animation}
               className='created-authors-list-page'
            >
               <div className='table-container'>
                  {authors.length === 0 && (
                     <NoDataInfo text='Nema kreiranih autora' />
                  )}
                  {authors.length > 0 && (
                     <TableCreatedAuthorList
                        authors={authors}
                        enableEdit={!staticDataLoaded}
                        onEdit={handleEditAuthor}
                        onDelete={handleDeleteAuthorOrGroup}
                        onToVerification={handleToVerification}
                        onVerify={handleOnVerify}
                     />
                  )}
                  <div style={{ height: '5rem' }}></div>
                  {authorGroups.length === 0 && (
                     <NoDataInfo text='Nema kreiranih grupa autora' />
                  )}
                  {authorGroups.length > 0 && (
                     <TableCreatedAuthorGroupList
                        authorGroups={authorGroups}
                        enableEdit={!staticDataLoaded}
                        onEdit={handleEditAuthorGroup}
                        onDelete={handleDeleteAuthorOrGroup}
                        onToVerification={handleToVerification}
                        onVerify={handleOnVerify}
                     />
                  )}
               </div>
            </MotionContainer>
         )}
      </>
   );
};

export default CreatedAuthorsPage;

//     swal({
//         title: `Brisanje!`,
//         text: author.FirstName
//             ? `${author.FirstName} ${author.LastName} će biti obrisan.`
//             : `${author.GroupName}  će biti obrisana.`,
//         icon: 'success',
//         timer: swallSpeed,
//         buttons: {}
//     }).then(() => {
//     });
//     break;
// default:
//     swal({
//         title: `Odustajanje`,
//         text: author.FirstName
//             ? `${author.FirstName} ${author.LastName} nije obrisan`
//             : `${author.GroupName} nije obrisana.`,
//         icon: 'error',
//         timer: swallSpeed,
//         buttons: {}
//     }).then(() => {});
