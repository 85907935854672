export const exhibitionSuccess = {
    title: 'Izložba uspješno kreirana!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novu izložbu',
    btnSecondary: 'Pregled izložbi',
    onPrimary: 'add-exhibition',
    onSecondary: 'list-exhibitions'
};

export const exhibitionSuccessAfterEdit = {
    title: 'Izložba uspješno ažurirana!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novu izložbu',
    btnSecondary: 'Pregled izložbi',
    onPrimary: 'add-exhibition',
    onSecondary: 'list-exhibitions'
};

export const exhibitionError = {
    title: 'Izložba nije kreirana!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novu izložbu',
    btnSecondary: 'Pregled izložbi',
    onPrimary: 'add-exhibition',
    onSecondary: 'list-exhibitions'
};

export const exhibitionErrorAfterEdit = {
    title: 'Izložba nije ažurirana!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novu izložbu',
    btnSecondary: 'Pregled izložbi',
    onPrimary: 'add-exhibition',
    onSecondary: 'list-exhibitions'
};

export const artworkSuccess = {
    title: 'Umjetničko djelo uspješno kreirano!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novo umjetničko djelo',
    btnSecondary: 'Pregled umjetničkih djela',
    onPrimary: 'add-artwork',
    onSecondary: 'list-artworks'
};

export const artworkSuccessAfterEdit = {
    title: 'Umjetničko djelo uspješno ažurirano!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novo umjetničko djelo',
    btnSecondary: 'Pregled umjetničkih djela',
    onPrimary: 'add-artwork',
    onSecondary: 'list-artworks'
};

export const artworkError = {
    title: 'Umjetničko djelo nije kreirano!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novo umjetničko djelo',
    btnSecondary: 'Pregled umjetničkih djela',
    onPrimary: 'add-artwork',
    onSecondary: 'list-artworks'
};

export const artworkErrorAfterEdit = {
    title: 'Umjetničko djelo nije ažurirano!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novo umjetničko djelo',
    btnSecondary: 'Pregled umjetničkih djela',
    onPrimary: 'add-artwork',
    onSecondary: 'list-artworks'
};

export const authorSuccess = {
    title: 'Autor je uspješno kreiran!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novog autora',
    btnSecondary: 'Pregled autora',
    onPrimary: 'add-author',
    onSecondary: 'list-author'
};

export const authorSuccessAfterEdit = {
    title: 'Autor je uspješno ažuriran!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novog autora',
    btnSecondary: 'Pregled autora',
    onPrimary: 'add-author',
    onSecondary: 'list-author'
};

export const authorError = {
    title: 'Autor nije kreiran!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novog autora',
    btnSecondary: 'Pregled autora',
    onPrimary: 'add-author',
    onSecondary: 'list-author'
};

export const authorErrorAfterEdit = {
    title: 'Autor nije ažuriran!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novog autora',
    btnSecondary: 'Pregled autora',
    onPrimary: 'add-author',
    onSecondary: 'list-author'
};

export const authorGroupSuccess = {
    title: 'Grupa autora je uspješno kreirana!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novu grupu autora',
    btnSecondary: 'Pregled grupa autora',
    onPrimary: 'add-author-group',
    onSecondary: 'list-author'
};

export const authorGroupSuccessAfterEdit = {
    title: 'Grupa autora je uspješno ažurirana!',
    status: 'success',
    subtitle: '...',
    btnPrimary: 'Dodaj novu grupu autora',
    btnSecondary: 'Pregled grupa autora',
    onPrimary: 'add-author-group',
    onSecondary: 'list-author'
};

export const authorGroupError = {
    title: 'Grupa autora nije kreirana!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novu grupu autora',
    btnSecondary: 'Pregled grupa autora',
    onPrimary: 'add-author-group',
    onSecondary: 'list-author'
};
export const authorGroupErrorAfterEdit = {
    title: 'Grupa autora nije ažurirana!',
    status: 'warning',
    subtitle: '...',
    btnPrimary: 'Dodaj novu grupu autora',
    btnSecondary: 'Pregled grupa autora',
    onPrimary: 'add-author-group',
    onSecondary: 'list-author'
};
