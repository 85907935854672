// import store from './../store/configure-store';
import { v4 as uuid } from 'uuid';
// const state = store().getState();
// console.log('state', state);
//https://daveceddia.com/access-redux-store-outside-react/
//https://stackoverflow.com/questions/38460949/what-is-the-best-way-to-access-redux-store-outside-a-react-component

const personalInformation = (data) => {
   const {
      BirthCityId,
      BirthCityName,
      BirthCountryId,
      BirthDate,
      FirstName,
      LastName,
      SexId
   } = data;
   console.log('author personalInformation', data);
   return {
      BirthCityId,
      BirthCityName,
      BirthCountryId,
      BirthDate,
      FirstName,
      LastName,
      SexId,
      countrySelected: true,
      isValid: true
   };
};

const contactInformation = (data) => {
   const { ContactEmail, ContactPhone, PersonalOrGroupUrl, SocialNetworks } =
      data;
   console.log('author contactInformation', data);

   return {
      ContactEmail,
      ContactPhone,
      PersonalOrGroupUrl,
      SocialNetworks,
      isValid: true
   };
};

const educationInformation = (data) => {
   console.log('author educationInformation', data);
   let {
      ScientificRegisterCode,
      IsAffiliated,
      AffiliationLegalEntityId,
      AuthorAcademicStatuses,
      AuthorEducationLevels,
      AuthorThesis,
      AuthorSpecializationTypes,
      AuthorScholarships
   } = data;

   const statusIndices = AuthorAcademicStatuses.map(
      (status) => status.AcademicStatusId
   );

   AuthorThesis = AuthorThesis.map((e) => {
      return {
         ...e,
         id: uuid()
      };
   });
   console.log('author AuthorThesis', AuthorThesis);

   AuthorEducationLevels = AuthorEducationLevels.map((e) => {
      return {
         ...e,
         id: uuid()
      };
   });
   console.log('author AuthorEducationLevels', AuthorEducationLevels);
   AuthorSpecializationTypes = AuthorSpecializationTypes.map((s) => {
      return {
         ...s,
         id: uuid()
      };
   });
   console.log('author AuthorSpecializationTypes', AuthorSpecializationTypes);

   AuthorScholarships = AuthorScholarships.map((s) => {
      return {
         ...s,
         id: uuid()
      };
   });

   console.log('author AuthorScholarships', AuthorScholarships);
   return {
      ScientificRegisterCode,
      IsAffiliated,
      AffiliationLegalEntityId,
      AuthorAcademicStatuses,
      AuthorEducationLevels,
      AuthorThesis,
      AuthorSpecializationTypes,
      AuthorScholarships,
      showAuthorEducationLevel: true,
      showAuthorThesis: true,
      showAuthorSpecializationType: true,
      statusIndices,
      isValid: true
   };
};

const professionalInformation = (data) => {
   let {
      ArtGroupOrCollective,
      IsArtworkInCollections,
      IsArtGroupOrCollective,
      ArtworkInCollectionsDescription,
      AtelierAddress,
      AtelierCityName,
      AtelierGeoLatitude,
      AtelierGeoLongitude,
      AuthorAreaOfInterests,
      AuthorAreaOfActivities,
      AuthorAssociations,
      AuthorAwards,
      AuthorBibliographies,
      AuthorRepresentations,
      AuthorWorkExperiences,
      IsAtelier,
      IsGalleryRepresentation,
      ShortBiography,
      associations
   } = data;

   console.log('data', data);

   AuthorBibliographies = AuthorBibliographies.map((a) => {
      return {
         ...a,
         id: uuid()
      };
   });
   AuthorWorkExperiences = AuthorWorkExperiences.map((w) => {
      return {
         ...w,
         id: uuid()
      };
   });

   AuthorAssociations = AuthorAssociations.map((aa) => {
      const associationFound = associations.find(
         (a) => a.AssociationId === aa.AssociationId
      );

      return {
         ...aa,
         id: uuid(),
         Name: associationFound ? associationFound.Name : '-'
      };
   });

   AuthorAwards = AuthorAwards.map((a) => {
      return {
         ...a,
         id: uuid()
      };
   });
   AuthorRepresentations = AuthorRepresentations.map((r) => {
      return {
         ...r,
         id: uuid()
      };
   });

   return {
      ArtGroupOrCollective,
      IsArtworkInCollections,
      IsArtGroupOrCollective,
      ArtworkInCollectionsDescription,
      AtelierAddress,
      AtelierCityName,
      AtelierGeoLatitude,
      AtelierGeoLongitude,
      AuthorAreaOfInterests,
      AuthorAreaOfActivities,
      AuthorAssociations,
      AuthorAwards,
      AuthorBibliographies,
      AuthorRepresentations,
      IsAuthorRepresentation: AuthorRepresentations.length > 0 ? true : false,
      AuthorWorkExperiences,
      IsAtelier,
      IsGalleryRepresentation,
      ShortBiography,
      isValid: true
   };
};

const restInformation = (data) => {
   const { PhotoFileContentBase64, PhotoOriginalFileName, Remark } = data;

   return {
      authorPhotographies: PhotoFileContentBase64
         ? [
              {
                 PhotoFileContentBase64,
                 PhotoOriginalFileName
              }
           ]
         : [],
      componentPhotographies: PhotoFileContentBase64
         ? [
              {
                 thumbUrl: PhotoFileContentBase64,
                 name: PhotoOriginalFileName
              }
           ]
         : [],
      Remark,
      isValid: true
   };
};

/*



        GRUPA AUTORA



*/

const basicInformation = (data) => {
   let {
      ContactEmail,
      ContactPhone,
      GroupDateOfCreation,
      GroupDescription,
      GroupName,
      PersonalOrGroupUrl,
      SocialNetworks
   } = data;

   return {
      ContactEmail,
      ContactPhone,
      GroupDateOfCreation,
      GroupDescription,
      GroupName,
      PersonalOrGroupUrl,
      SocialNetworks,
      isValid: true
   };
};

const professionalGroupInformation = (data) => {
   let {
      ArtworkInCollectionsDescription,
      AtelierAddress,
      AtelierCityName,
      AtelierGeoLatitude,
      AtelierGeoLongitude,
      AuthorAreaOfInterests,
      AuthorAssociations,
      AuthorAwards,
      AuthorBibliographies,
      AuthorRepresentations,
      AuthorWorkExperiences,
      IsArtworkInCollections,
      IsAtelier
   } = data;

   AuthorBibliographies = AuthorBibliographies.map((a) => {
      return {
         ...a,
         id: uuid()
      };
   });
   AuthorWorkExperiences = AuthorWorkExperiences.map((w) => {
      return {
         ...w,
         id: uuid()
      };
   });
   AuthorAssociations = AuthorAssociations.map((a) => {
      return {
         ...a,
         id: uuid()
      };
   });
   AuthorAwards = AuthorAwards.map((a) => {
      return {
         ...a,
         id: uuid()
      };
   });
   AuthorRepresentations = AuthorRepresentations.map((r) => {
      return {
         ...r,
         id: uuid()
      };
   });

   return {
      ArtworkInCollectionsDescription,
      AtelierAddress,
      AtelierCityName,
      AtelierGeoLatitude,
      AtelierGeoLongitude,
      AuthorAreaOfInterests,
      AuthorAssociations,
      AuthorAwards,
      AuthorBibliographies,
      AuthorRepresentations,
      AuthorWorkExperiences,
      IsArtworkInCollections,
      IsAtelier,
      IsAuthorRepresentation: AuthorRepresentations.length > 0 ? true : false,
      isValid: true
   };
};

const restGroupInformation = (data) => {
   let { PhotoFileContentBase64, PhotoOriginalFileName, Remark } = data;

   return {
      authorPhotographies: PhotoFileContentBase64
         ? [
              {
                 PhotoFileContentBase64,
                 PhotoOriginalFileName
              }
           ]
         : [],
      componentPhotographies: PhotoFileContentBase64
         ? [
              {
                 thumbUrl: PhotoFileContentBase64,
                 name: PhotoOriginalFileName
              }
           ]
         : [],
      Remark,
      isValid: true
   };
};

/*



        ARTWORK



*/

const artworkBasicInformation = (data) => {
   console.log('DATA', data);
   let {
      Name,
      Dimensions,
      Description,
      ArtistStatement,
      ArtworkAuthors,
      ArtworkCreationYears,
      ArtworkCreationCountryId,
      ArtworkCreationCityId,
      Techniques,
      OwnershipTypeId,
      ArtworkAwards,
      ArtworkBibliographies,
      ArtworkExhibitions,
      ArtworkCollections,
      authors,
      exhibitions,
      collections
   } = data;

   ArtworkAuthors = ArtworkAuthors.map((a) => {
      if (a.AuthorId) {
         const authorFound = authors.find(
            (author) => author.AuthorId === a.AuthorId
         );
         const authorName = authorFound ? `${authorFound.Name}` : '- -';
         return {
            ...a,
            id: uuid(),
            Name: authorName
         };
      }

      return {
         ...a,
         id: uuid(),
         Name: a.AuthorOrGroupName
      };
   });

   ArtworkExhibitions = ArtworkExhibitions.map((a) => {
      if (a.ExhibitionId) {
         const exhibitionFound = exhibitions.find(
            (ex) => ex.ExhibitionId === a.ExhibitionId
         );
         const ExhibitionName = exhibitionFound.Name;

         return {
            ...a,
            id: uuid(),
            ExhibitionName
         };
      }
      return {
         ...a,
         id: uuid()
      };
   });

   ArtworkCollections = ArtworkCollections
      ? ArtworkCollections.map((a) => {
           if (a.CollectionId) {
              const collectionFound = collections.find(
                 (ex) => ex.CollectionId === a.CollectionId
              );
              const CollectionName = collectionFound.Name;

              return {
                 ...a,
                 id: uuid(),
                 CollectionName
              };
           }
           return {
              ...a,
              id: uuid()
           };
        })
      : [];

   ArtworkAwards = ArtworkAwards.map((w) => {
      return {
         ...w,
         id: uuid()
      };
   });
   ArtworkBibliographies = ArtworkBibliographies.map((a) => {
      return {
         ...a,
         id: uuid()
      };
   });

   return {
      Name,
      Dimensions,
      Description,
      ArtistStatement,
      ArtworkAuthors,
      ArtworkCreationYears,
      ArtworkCreationCountryId,
      ArtworkCreationCityId,
      Techniques,
      OwnershipTypeId,
      ArtworkAwards,
      ArtworkBibliographies,
      ArtworkExhibitions,
      ArtworkCollections,
      countrySelected: ArtworkCreationCountryId === 0 ? false : true,
      isValid: true
   };
};

const artworkRestInformation = (data) => {
   let { Remark, Url, ArtworkPhotographies } = data;

   return {
      Remark,
      Url,
      ArtworkPhotographies,
      componentPhotographies: ArtworkPhotographies.map((a) => ({
         thumbUrl: a.PhotoFileContentBase64,
         name: a.PhotoOriginalFileName
      })),
      isValid: true
   };
};

/*



        EXHIBITION



*/

const exhibitionBasicInformation = (data) => {
   let {
      Name,
      NameEn,
      ExhibitionAuthor: ExhibitionAuthors,
      Year,
      ExhibitionTypeQuantityId,
      LocationLegalEntityId,
      ExhibitionTypeKindId,
      ExhibitionKindOther,
      InstitutionTypeId,
      LocationTypeId,
      LocationCountryId,
      IsCurators,
      Curators,
      OrganizerLegalEntityId,
      ExhibitionCatalogues,
      ExhibitionMediaAnnouncements,
      ArtworkExhibitions,
      Remark,
      Url,
      authors,
      artworks
   } = data;

   ExhibitionCatalogues = ExhibitionCatalogues.map((w) => {
      return {
         ...w,
         id: uuid()
      };
   });

   ExhibitionMediaAnnouncements = ExhibitionMediaAnnouncements.map((w) => {
      return {
         ...w,
         id: uuid()
      };
   });

   ExhibitionAuthors = ExhibitionAuthors.map((a) => {
      if (a.AuthorId) {
         const authorFound = authors.find(
            (author) => author.AuthorId === a.AuthorId
         );
         const authorName = `${authorFound.Name}`;
         return {
            ...a,
            id: uuid(),
            Name: authorName
         };
      }

      return {
         ...a,
         id: uuid(),
         Name: a.AuthorName
      };
   });

   ArtworkExhibitions = ArtworkExhibitions.map((aex) => {
      if (aex.ArtworkId) {
         const artworkFound = artworks.find(
            (a) => a.ArtworkId === aex.ArtworkId
         );
         const artworkName = `${artworkFound.Name}`;
         return {
            ...aex,
            id: uuid(),
            Naziv: artworkName ? artworkName : ''
         };
      }

      return {
         ...aex,
         id: uuid(),
         Naziv: aex.ArtworkName ? aex.ArtworkName : ''
      };
   });

   return {
      Name,
      NameEn,
      ExhibitionAuthors,
      Year,
      ExhibitionTypeQuantityId,
      LocationLegalEntityId,
      ExhibitionTypeKindId,
      ExhibitionKindOther,
      InstitutionTypeId,
      LocationTypeId,
      LocationCountryId,
      IsCurators,
      Curators,
      OrganizerLegalEntityId,
      ExhibitionCatalogues,
      ExhibitionMediaAnnouncements,
      ArtworkExhibitions,
      Remark,
      Url,
      isValid: true
   };
};

const exhibitionRestInformation = (data) => {
   let { Remark, Url } = data;

   return {
      Remark,
      Url,
      isValid: true
   };
};

const exportObj = {
   personalInformation,
   contactInformation,
   educationInformation,
   professionalInformation,
   restInformation,
   basicInformation,
   professionalGroupInformation,
   restGroupInformation,
   artworkBasicInformation,
   artworkRestInformation,
   exhibitionBasicInformation,
   exhibitionRestInformation
};

export default exportObj;

/*
    Informacije o autoru i o grupi autora se prikupljaju u nekoliko kategorija
        AUTOR
            personalInformation
            contactInformation
            educationLevel
            professionalActivity
            restInformation

        GRUPA AUTORA
            basicInformation
            professionalInformation
            restInformation


    Autor ili grupa autora se dohvaća istim parametrom AutorId

    Podaci se vračaju kao skup podataka koje ja naknadno podjelim u skupine:
        AUTHOR - author-add-page.jsx
           personalInformationData
           contactInformationData
           educationLevelData
           professionalActivityData
           restInformationData

        GRUPA AUTORA - author-group-add-page.jsx
            basicInformationData
            professionalInformationData
            restInformationData


    Ti podaci se dodjeljuju kao defaultData komponenti koje ih prikazuju


    U ovoj datoteci [destructure-data.js] koja se poziva na store-u [author.js] skup podataka pretvaram u kategorije podataka
    

*/
