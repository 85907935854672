import Joi from 'joi-browser';

export const ArtworkBasicInformationSchema = {
   Name: Joi.string()
      .required()
      .label('Naziv umjetničkog djela')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Techniques: Joi.any().optional(),
   Dimensions: Joi.any().optional(),
   Description: Joi.any().optional(),
   ArtistStatement: Joi.any().optional(),
   Remark: Joi.any().optional(),
   Url: Joi.any().optional()
};
