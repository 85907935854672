import React from 'react';
import { getCountries } from '../../services/api';
import Form from './form';
import Openstreet from '../openstreet/openstreet';
import { AddAddressSchema } from './../../validators/add-address';
import AddCityAllData from './add-city-all-data';

class AddAddress extends Form {
   state = {
      validationData: {
         Name: '',
         Address: '',
         GeoLatitude: '',
         GeoLongitude: ''
      },
      restData: {
         CountryId: '',
         CityId: '',
         CityName: ''
      },
      errors: [],
      countries: [],
      cities: [],
      countriesLoaded: false,
      countrySelected: false,
      citySelected: false,
      addCityYourself: false
   };

   schema = AddAddressSchema;

   async componentDidMount() {
      const { data } = await getCountries();
      this.setState({ countries: data.data, countriesLoaded: true });
   }

   render() {
      const { countries, countrySelected } = this.state;
      const { CountryId, CityName } = this.state.restData;
      const { Address } = this.state.validationData;

      return (
         <div className='add-new-legal-entity-content'>
            {this.renderSelectAnt(
               'Država',
               'Name',
               'CountryId',
               'Započnite unos ili odaberite državu...',
               'Ne pronalazim traženu državu...',
               countries,
               this.handleCountrySelected,
               null,
               null,
               true
            )}

            <div
               className={this.state.countrySelected ? 'city showCity' : 'city'}
            >
               {countrySelected && (
                  <AddCityAllData
                     required={true}
                     countryId={CountryId}
                     cityName={CityName}
                     onCitySelect={this.handleCitySelected}
                  />
               )}
            </div>

            <div
               className={
                  this.state.citySelected ? 'address showAddress' : 'address'
               }
            >
               <div className='row'>
                  <div className='col'>
                     {this.renderInputAntdesign(
                        'Address',
                        'Adresa',
                        {
                           placeholder: 'Možete unijeti i klikom na mapu...'
                        },
                        null,
                        this.props.onTextInputChange
                     )}
                  </div>
               </div>

               <div className='row'>
                  <div className='col'>
                     {this.renderInputAntdesign(
                        'GeoLatitude',
                        'Zemljopisna širina',
                        {
                           placeholder: 'Možete unijeti i klikom na mapu...'
                        },
                        null,
                        this.props.onTextInputChange
                     )}
                  </div>
                  <div className='col'>
                     {this.renderInputAntdesign(
                        'GeoLongitude',
                        'Zemljopisna dužina',
                        {
                           placeholder: 'Možete unijeti i klikom na mapu...'
                        },
                        null,
                        this.props.onTextInputChange
                     )}
                  </div>
               </div>

               <Openstreet
                  onOpenstreetClick={this.handleOpenstreetClick}
                  center={`${CityName},${Address}`}
                  disable={false}
               />
            </div>
         </div>
      );
   }

   handleOnSubmit = async () => {
      const { Name, Address, GeoLatitude, GeoLongitude } =
         this.state.validationData;

      const { CityId } = this.state.restData;
      const { cities } = this.state;
      const newLegalEntity = {
         CityId,
         Name,
         Address,
         GeoLatitude,
         GeoLongitude,
         cities
      };

      this.props.onAdd(newLegalEntity);
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.onCloseAddAddress();
   };

   handleOpenstreetClick = (obj) => {
      if (!obj) return;

      const { latlng, address } = obj;
      const validationData = { ...this.state.validationData };
      const restData = { ...this.state.restData };
      const { addCityYourself } = this.state;

      validationData.GeoLatitude = latlng.lat;
      validationData.GeoLongitude = latlng.lng;
      validationData.Address = address.Address;

      if (addCityYourself) {
         restData.CityName = address.City;
      }

      this.setState({ validationData, restData });

      const { GeoLatitude, GeoLongitude, Address } = validationData;
      this.props.onMapClick({ GeoLatitude, GeoLongitude, Address });
   };

   handleCountrySelected = async (value, obj) => {
      const { id: CountryId } = obj;

      const restData = { ...this.state.restData };
      restData.CountryId = CountryId;
      restData.CityId = null;
      restData.CityName = null;

      this.setState({
         restData,
         countrySelected: true,
         citySelected: false
      });
   };

   handleCitySelected = (value, obj) => {
      const { id: CityId, value: CityName } = obj;

      const restData = { ...this.state.restData };
      restData.CityId = CityId;
      restData.CityName = CityName;

      this.setState({ restData, citySelected: true });

      this.props.onCitySelect(CityId);
   };
}
export default AddAddress;
