import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import MotionContainer from './motion-containers/motion-container';

const LoadingSpinner = ({ type, text }) => {
    const animationSpeedShow = 0.75;

    const loadingspinnerAnimation = {
        hidden: {
            opacity: 0,
            scale: 2,
            y: 200
        },
        show: {
            opacity: 1,
            scale: 1,
            y: 200,
            transition: {
                duration: animationSpeedShow
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.2,
                ease: 'easeOut'
            }
        }
    };

    return (
        <MotionContainer animationObject={loadingspinnerAnimation}>
            <div className='loading-spinner'>
                {type === '1' && (
                    <div>
                        <SyncOutlined spin />
                        <p>{text}</p>
                    </div>
                )}

                {!type && <Spin size='large' tip={text} />}
            </div>
        </MotionContainer>
    );
};
export default LoadingSpinner;
