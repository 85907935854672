import React from 'react';
import { motion } from 'framer-motion';

const NoDataInfo = ({ text }) => {
   const styles = {
      maxWidth: '90rem',
      textAlign: 'center',
      backgroundColor: '#fff',
      margin: 'auto',
      padding: '2rem',
      borderRadius: '.5rem',
      boxShadow: '0 0 .5rem rgba(0, 0, 0, .1)',
      fontSize: '1rem',
      fontWeight: 700
   };
   return (
      <motion.div
         style={styles}
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.5, delay: 0.25 }}
      >
         {text}
      </motion.div>
   );
};

export default NoDataInfo;
