const animationSpeedShow = 0.25;
const animationSpeedExit = 0.1;

export const artworkAnim = {
    hidden: {
        x: 50
    },
    show: {
        x: 0,
        transition: {
            duration: animationSpeedShow
        }
    },
    //što se dešava s komponentom kada se unmounta, kada se odlazi sa neke stranice
    exit: {
        opacity: 0,
        y: 30,
        transition: {
            duration: animationSpeedExit,
            ease: 'easeOut'
        }
    }
};
