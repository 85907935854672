import destructureTo from '../services/destructure-data';
import {
   getAcademicStatus,
   getSpecializationType,
   getEducationLevels,
   getAuthorDetails,
   getLegalEntities,
   getGallery,
   getJobFunction,
   getCities,
   getBibliographyCategories,
   getAssociation,
   getAreaofInterest
} from '../services/api';

const initState = {
   authorAcademicStatuses: [],
   specializationTypes: [],
   educationLevels: [],
   authorData: null,
   authorGroupData: null,
   galleries: [],

   jobfunctions: [],
   legalEntities: [],
   authorBibliographies: [],
   authorAssociations: [],
   authorAreaOfInterests: [],
   isEditMode: false,
   isEditAfterVerification: false,
   redirectToErrorPage: false,
   errorMessage: ''
};

export const loadAuthorStaticData = () => async (dispatch, getState) => {
   try {
      const { data: academicStatuses } = await getAcademicStatus();

      const payload = {
         authorAcademicStatuses: academicStatuses.data
      };

      //https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel
      // const { data: educationLevels } = await getEducationLevels();
      // const { data: galleries } = await getGalleryForUser();
      // const { data: specializationTypes } = await getSpecializationType();
      // const {
      //     data: authorBibliographies
      // } = await getBibliographyCategories();
      // const { data: authorAssociations } = await getAssociationForUser();
      // const { data: legalEntities } = await getLegalEntities();
      // const { data: jobfunctions } = await getJobFunctionForUser();
      // const {
      //     data: authorAreaOfInterests
      // } = await getAreaofInterestForUser();

      // const payload = {
      //     sex: sex.data,
      //     countries: countries.data,
      //     authorAcademicStatuses: academicStatuses.data,
      //     educationLevels:educationLevels.data,
      //     galleries:galleries.data,
      //     specializationTypes:specializationTypes.data,
      //     authorBibliographies:authorBibliographies.data,
      //     authorAssociations:authorAssociations.data,
      //     legalEntities:legalEntities.data,
      //     jobfunctions:jobfunctions.data,
      //     authorAreaOfInterests:authorAreaOfInterests.data
      // };

      await dispatch(loadEducationLevels());
      await dispatch(loadSpecializationTypes());
      await dispatch(loadAuthorBibliographies());
      await dispatch(loadAuthorAssociations());
      //   await dispatch(loadLegalEnteties());
      await dispatch(loadJobFunctions());
      await dispatch(loadGalleries());
      await dispatch(loadAuthorAreaOfInterests());
      await dispatch({ type: 'LOAD_AUTHOR_STATIC_DATA', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_AUTHOR_STATIC_DATA', ex.response?.data);
      }
   }
};

export const redirectToErrorPage = (redirectToErrorPage) => ({
   type: 'REDIRECT_TO_ERROR_PAGE',
   payload: {
      redirectToErrorPage
   }
});

export const saveErrorMessage = (errorMessage) => ({
   type: 'SAVE_ERROR_MESSAGE',
   payload: {
      errorMessage
   }
});

export const loadAuthorData = (authorId) => async (dispatch, getState) => {
   try {
      dispatch(saveErrorMessage(''));
      dispatch(redirectToErrorPage(false));

      const { data: authorData } = await getAuthorDetails(authorId);
      console.log('authorData', authorData);
      if (authorData.status === 0) {
         dispatch(saveErrorMessage(authorData.message));
         dispatch(redirectToErrorPage(true));
         return;
      }

      const payload = {
         authorData: authorData.details
      };
      await dispatch({ type: 'LOAD_AUTHOR_DATA', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_AUTHOR_DATA', ex.response?.data);
      }
   }
};

export const loadAuthorGroupData =
   (authorGroupId) => async (dispatch, getState) => {
      try {
         dispatch(saveErrorMessage(''));
         dispatch(redirectToErrorPage(false));

         const { data: authorGroupData } = await getAuthorDetails(
            authorGroupId
         );
         console.log('Edit AuthorId response', authorGroupData);
         if (authorGroupData.status === 0) {
            dispatch(saveErrorMessage(authorGroupData.message));
            dispatch(redirectToErrorPage(true));
            return;
         }
         const payload = {
            authorGroupData: authorGroupData.details
         };
         await dispatch({ type: 'LOAD_AUTHOR_GROUP_DATA', payload });
      } catch (ex) {
         if (ex.response && ex.response.status === 400) {
            console.log('LOAD_AUTHOR_GROUP_DATA', ex.response?.data);
         }
      }
   };

export const loadEducationLevels = () => async (dispatch, getState) => {
   try {
      const { educationLevels: el } = getState().author;
      if (el.length !== 0) return;

      const { data: educationLevels } = await getEducationLevels();
      const payload = {
         educationLevels: educationLevels.data
      };
      dispatch({ type: 'LOAD_EDUCATION_LEVELS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_EDUCATION_LEVELS', ex.response?.data);
      }
   }
};

export const loadGalleries = () => async (dispatch, getState) => {
   try {
      const { data: galleries } = await getGallery();
      const payload = {
         galleries: galleries.data
      };
      dispatch({ type: 'LOAD_GALLERIES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_GALLERIES', ex.response?.data);
      }
   }
};

export const loadAuthorAreaOfInterests = () => async (dispatch, getState) => {
   try {
      const { data: authorAreaOfInterests } = await getAreaofInterest();
      const payload = {
         authorAreaOfInterests: authorAreaOfInterests.data
      };
      dispatch({ type: 'LOAD_AREA_OF_INTERESTS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_AREA_OF_INTERESTS', ex.response?.data);
      }
   }
};

export const loadAuthorAssociations = () => async (dispatch, getState) => {
   try {
      const { data: authorAssociations } = await getAssociation();
      const payload = {
         authorAssociations: authorAssociations.data
      };
      dispatch({ type: 'LOAD_ASSOCIATIONS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_ASSOCIATIONS', ex.response?.data);
      }
   }
};

export const loadJobFunctions = () => async (dispatch, getState) => {
   try {
      const { data: jobfunctions } = await getJobFunction();
      const payload = {
         jobfunctions: jobfunctions.data
      };
      dispatch({ type: 'LOAD_JOBFUNCTIONS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_JOBFUNCTIONS', ex.response?.data);
      }
   }
};

export const loadLegalEnteties = () => async (dispatch, getState) => {
   try {
      const { data: legalEntities } = await getLegalEntities();
      const payload = {
         legalEntities: legalEntities.data
      };
      dispatch({ type: 'LOAD_LEGALENTETIES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_LEGALENTETIES', ex.response?.data);
      }
   }
};

export const loadSpecializationTypes = () => async (dispatch, getState) => {
   try {
      const { specializationTypes: st } = getState().author;
      if (st.length !== 0) return;

      const { data: specializationTypes } = await getSpecializationType();
      const payload = {
         specializationTypes: specializationTypes.data
      };
      dispatch({ type: 'LOAD_SPECIALIZATION_TYPES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_SPECIALIZATION_TYPES', ex.response?.data);
      }
   }
};

export const loadAuthorBibliographies = () => async (dispatch, getState) => {
   try {
      const { data: authorBibliographies } = await getBibliographyCategories();
      const payload = {
         authorBibliographies: authorBibliographies.data
      };
      dispatch({ type: 'LOAD_AUTHOR_BIBLIOGRAPHIES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_AUTHOR_BIBLIOGRAPHIES', ex.response?.data);
      }
   }
};

export const loadCities = (countryId) => async (dispatch, getState) => {
   try {
      const { data: cities } = await getCities(countryId);
      const payload = {
         cities: cities.data
      };
      dispatch({ type: 'LOAD_CITIES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_CITIES', ex.response?.data);
      }
   }
};

export const clearAuthorData = () => ({
   type: 'CLEAR_AUTHOR_DATA'
});

export const clearAuthorGroupData = () => ({
   type: 'CLEAR_AUTHOR_GROUP_DATA'
});

export const setAuthorOrGroupEditMode = (mode) => ({
   type: 'SET_AUTHOR_OR_GROUP_EDIT_MODE',
   payload: {
      mode
   }
});

export const setAuthorOrGroupEditAfterVerificationMode = (
   isEditAfterVerification
) => ({
   type: 'SET_AUTHOR_OR_GROUP_EDIT_AFTER_VERIFICATION_MODE',
   payload: {
      isEditAfterVerification
   }
});

//  REDUCER
export default function reducer(state = initState, action) {
   switch (action.type) {
      case 'REDIRECT_TO_ERROR_PAGE':
         const { redirectToErrorPage } = action.payload;
         return {
            ...state,
            redirectToErrorPage
         };
      case 'SAVE_ERROR_MESSAGE':
         const { errorMessage } = action.payload;
         return {
            ...state,
            errorMessage
         };

      case 'SET_AUTHOR_OR_GROUP_EDIT_MODE':
         const { mode } = action.payload;
         return {
            ...state,
            isEditMode: mode
         };
      case 'SET_AUTHOR_OR_GROUP_EDIT_AFTER_VERIFICATION_MODE':
         const { isEditAfterVerification } = action.payload;
         return {
            ...state,
            isEditAfterVerification
         };
      case 'LOAD_EDUCATION_LEVELS':
         const { educationLevels } = action.payload;
         return {
            ...state,
            educationLevels
         };
      case 'LOAD_AREA_OF_INTERESTS':
         const { authorAreaOfInterests } = action.payload;
         return {
            ...state,
            authorAreaOfInterests
         };
      case 'LOAD_ASSOCIATIONS':
         const { authorAssociations } = action.payload;
         return {
            ...state,
            authorAssociations
         };
      case 'LOAD_AUTHOR_BIBLIOGRAPHIES':
         const { authorBibliographies } = action.payload;
         return {
            ...state,
            authorBibliographies
         };
      case 'LOAD_GALLERIES':
         const { galleries } = action.payload;
         return {
            ...state,
            galleries
         };

      case 'LOAD_JOBFUNCTIONS':
         const { jobfunctions } = action.payload;
         return {
            ...state,
            jobfunctions
         };
      case 'LOAD_CITIES':
         const { cities } = action.payload;
         return {
            ...state,
            cities
         };
      case 'LOAD_SPECIALIZATION_TYPES':
         const nema = {
            SpecializationTypeId: 4,
            Name: 'Nema'
         };
         const specializationTypes = [
            ...action.payload.specializationTypes,
            nema
         ];
         return {
            ...state,
            specializationTypes
         };
      case 'LOAD_LEGALENTETIES':
         const { legalEntities } = action.payload;
         return {
            ...state,
            legalEntities
         };
      case 'LOAD_AUTHOR_DATA':
         const { authorData } = action.payload;
         authorData.associations = [...state.authorAssociations];
         const personalInformation =
            destructureTo.personalInformation(authorData);
         const contactInformation =
            destructureTo.contactInformation(authorData);
         const educationInformation =
            destructureTo.educationInformation(authorData);
         const professionalInformation =
            destructureTo.professionalInformation(authorData);
         const restInformation = destructureTo.restInformation(authorData);

         authorData.personalInformation = { ...personalInformation };
         authorData.contactInformation = { ...contactInformation };
         authorData.educationInformation = { ...educationInformation };
         authorData.professionalInformation = { ...professionalInformation };
         authorData.restInformation = { ...restInformation };

         return {
            ...state,
            authorData
         };
      case 'LOAD_AUTHOR_GROUP_DATA':
         const { authorGroupData } = action.payload;
         console.log('PAYLOAD', authorGroupData);
         const basicInformation =
            destructureTo.basicInformation(authorGroupData);
         const professionalGroupInformation =
            destructureTo.professionalGroupInformation(authorGroupData);
         const restGroupInformation =
            destructureTo.restGroupInformation(authorGroupData);

         authorGroupData.basicInformation = { ...basicInformation };
         authorGroupData.professionalGroupInformation = {
            ...professionalGroupInformation
         };
         authorGroupData.restGroupInformation = { ...restGroupInformation };

         return {
            ...state,
            authorGroupData
         };
      case 'LOAD_AUTHOR_STATIC_DATA':
         const { authorAcademicStatuses } = action.payload;
         return {
            ...state,
            authorAcademicStatuses
         };
      case 'CLEAR_AUTHOR_DATA':
         return {
            ...state,
            authorData: null
         };
      case 'CLEAR_AUTHOR_GROUP_DATA':
         return {
            ...state,
            authorGroupData: null
         };
      default:
         return state;
   }
}

//https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
