import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import {
   deleteArtwork,
   getCreatedArtworks,
   reverifyArtwork
} from '../services/api';
import { toast } from 'react-toastify';
import LoadingSpinner from '../components/common/loading-spinner';
import swal from 'sweetalert';
import { animation } from './animations/created-authors-page-animation';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToastAutoCloseInterval } from '../store/app-setup';
import service from '../services/created-artworks-page';
import {
   loadArtworkData,
   loadArtworkStaticData,
   setArtworkEditAfterVerificationMode,
   setArtworkEditMode
} from '../store/artwork';
import TableCreatedArtworkList from '../components/artwork/table-created-artwork-list';
import {
   ucitavamPodatke,
   uredjivanjeCeBitiOmoguceno
} from './../services/toast-messages';
import {
   markArtworkAsReadyForVerification,
   markArtworkAsVerified
} from './../services/api';
import NoDataInfo from './../components/common/no-data-info';

// const swallSpeed = 1500;

const CreatedArtworksPage = (props) => {
   const history = useHistory();
   const [dataLoaded, setDataLoaded] = useState(false);
   const [staticDataLoaded, setStaticDataLoaded] = useState(false);
   const [createdArtworks, setCreatedArtworks] = useState([]);
   const dispatch = useDispatch();
   const state = useSelector((state) => state.artwork);

   const loadCreatedArtworks = async () => {
      toast.info(ucitavamPodatke);
      const { data: response } = await getCreatedArtworks();

      if (response.status === 1) {
         setCreatedArtworks(response.details);
         setDataLoaded(true);
         toast.dismiss();
         loadStaticData();
      } else {
         toast.error(`${response.message}`);
      }
   };

   const loadStaticData = () => {
      if (!service.isStaticDataLoaded(state)) {
         dispatch(setToastAutoCloseInterval(10000));
         toast(uredjivanjeCeBitiOmoguceno);

         dispatch(loadArtworkStaticData()).then(() => {
            toast.dismiss();
            dispatch(setToastAutoCloseInterval(3000));
            setStaticDataLoaded(true);
         });
      } else {
         setStaticDataLoaded(true);
      }
   };

   useEffect(() => {
      document.title = 'Lista umjetničkih djela';
      loadCreatedArtworks();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleEditArtwork = async (ArtworkId, isVerified) => {
      console.log('ucitavam artwork', ArtworkId, isVerified);
      setDataLoaded(false);
      dispatch(setArtworkEditAfterVerificationMode(isVerified));

      dispatch(loadArtworkData(ArtworkId)).then(() => {
         dispatch(setArtworkEditMode(true));
         history.push(`${process.env.PUBLIC_URL}/add-artwork`);
      });
   };

   const optimisticDeleteArtwork = (ArtworkId) => {
      const filtriranoPolje = createdArtworks.filter(
         (ca) => ca.ArtworkId !== ArtworkId
      );
      setCreatedArtworks(filtriranoPolje);
   };

   const handleDeleteArtwork = async (artwork) => {
      swal({
         html: true,
         title: `Obrisati?`,
         text: `${artwork.Name}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Potvrdi',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then((value) => {
         switch (value) {
            case 'confirm':
               optimisticDeleteArtwork(artwork.ArtworkId);
               deleteArtworkFromServer(artwork.ArtworkId);
               break;
            default:
               break;
         }
      });
   };

   const deleteArtworkFromServer = async (artworkId) => {
      const { data: response } = await deleteArtwork(artworkId);
      if (response.status === 1) {
         toast.info(response.message);
      } else {
         toast.error(response.message);
         loadCreatedArtworks();
      }
   };

   const handleToVerification = async (artworkId, artworkName) => {
      swal({
         html: true,
         title: `Poslati na verifikaciju?`,
         text: `${artworkName}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Pošalji',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then(async (value) => {
         switch (value) {
            case 'confirm':
               const { data: response } =
                  await markArtworkAsReadyForVerification(artworkId);
               if (response.status === 1) {
                  loadCreatedArtworks();
               } else {
                  toast.error(response.message);
               }
               break;
            default:
               break;
         }
      });
   };

   const handleOnVerify = async ({ ArtworkId, IsVerified, Name }) => {
      swal({
         html: true,
         title: `Jeste li sigurni da želite verificirati?`,
         text: `${Name}`,
         icon: 'info',
         buttons: {
            remove: {
               text: 'Verificiraj',
               value: 'confirm'
            },
            cancel: 'Odustani'
         }
      }).then(async (value) => {
         switch (value) {
            case 'confirm':
               let responseStatus;
               let responseMessage;
               if (IsVerified) {
                  const { data } = await reverifyArtwork(ArtworkId);
                  responseStatus = data.status;
                  responseMessage = data.message;
               } else {
                  const { data } = await markArtworkAsVerified(ArtworkId);
                  responseStatus = data.status;
                  responseMessage = data.message;
               }
               if (responseStatus === 1) {
                  loadCreatedArtworks();
               } else {
                  toast.error(responseMessage);
               }
               break;
            default:
               break;
         }
      });
   };

   return (
      <>
         {!dataLoaded && <LoadingSpinner type='' text='' />}
         {dataLoaded && (
            <MotionContainer
               animationObject={animation}
               className='created-authors-list-page'
            >
               <div className='table-container'>
                  {createdArtworks.length === 0 && (
                     <NoDataInfo text='Nema kreiranih umjetničkih djela' />
                  )}
                  {createdArtworks.length > 0 && (
                     <TableCreatedArtworkList
                        artworks={createdArtworks}
                        enableEdit={!staticDataLoaded}
                        onEdit={handleEditArtwork}
                        onDelete={handleDeleteArtwork}
                        onToVerification={handleToVerification}
                        onVerify={handleOnVerify}
                     />
                  )}
               </div>
            </MotionContainer>
         )}
      </>
   );
};

export default CreatedArtworksPage;

//     swal({
//         title: `Brisanje!`,
//         text: author.FirstName
//             ? `${author.FirstName} ${author.LastName} će biti obrisan.`
//             : `${author.GroupName}  će biti obrisana.`,
//         icon: 'success',
//         timer: swallSpeed,
//         buttons: {}
//     }).then(() => {
//     });
//     break;
// default:
//     swal({
//         title: `Odustajanje`,
//         text: author.FirstName
//             ? `${author.FirstName} ${author.LastName} nije obrisan`
//             : `${author.GroupName} nije obrisana.`,
//         icon: 'error',
//         timer: swallSpeed,
//         buttons: {}
//     }).then(() => {});
