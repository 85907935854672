import React from 'react';

const ButtonAdd = ({ text = 'Dodaj', disabled = false, onClick }) => {
   return (
      <button
         className='btn btn-info btn-sm mr-2'
         style={{ fontWeight: 700, padding: '.5rem 1rem', minWidth: '7rem' }}
         disabled={disabled}
         onClick={onClick}
         type='button'
      >
         {text}
      </button>
   );
};

export default ButtonAdd;
