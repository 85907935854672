import { combineReducers } from 'redux';
import registerReducer from './register-user';
import listConfirmedReducer from './list-confirmed';
import appSetupReducer from './app-setup';
import authorReducer from './author';
import artworkReducer from './artwork';
import exhibitionReducer from './exhibition';
import userReducer from './user';
import commonReducer from './common';

export default combineReducers({
    register: registerReducer,
    listOfUnconfirmedUsers: listConfirmedReducer,
    appSetup: appSetupReducer,
    author: authorReducer,
    artwork: artworkReducer,
    exhibition: exhibitionReducer,
    user: userReducer,
    common: commonReducer
});
