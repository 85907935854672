import React, { useState, useEffect } from 'react';
import AddSpecializationType from './add-specialization-type';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import html from '../../../services/html';
import { getObjectFromArray } from './../../../services/array-service';
import ButtonForm from './../../common/btn-form';

const AuthorSpecializationType = ({ onAddSpecialization, defaultValue }) => {
   const [selectedSpecialization, setSelectedSpecialization] = useState({});
   const [specializationTypeId, setSpecializationTypeId] = useState(4);
   const [kolekcija, setKolekcija] = useState([]);
   const [showAddSpecialization, setShowAddSpecialization] = useState(false);

   const { specializationTypes, legalEntities } = useSelector(
      (state) => state.author
   );

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddSpecialization(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleSpecializationTypeSelected = (value, obj) => {
      const { id: SpecializationTypeId, value: Name } = obj;

      setSpecializationTypeId(SpecializationTypeId);
      setSelectedSpecialization({
         Name,
         SpecializationTypeId
      });
   };

   const showHideAuthorSpecializationType = () => {
      setShowAddSpecialization(!showAddSpecialization);
   };

   const handleRemoveFromKolekcija = (id) => {
      setKolekcija(kolekcija.filter((spec) => spec.id !== id));
   };

   const handleAddToKolekcija = (authorSpecializationTypeData) => {
      setKolekcija([...kolekcija, authorSpecializationTypeData]);
   };

   const btnAddEnabled =
      Object.keys(selectedSpecialization).length > 0 &&
      specializationTypeId !== 4;

   const specializationType_defaultValue = specializationTypeId
      ? specializationTypes.length > 0
         ? {
              value: getObjectFromArray(
                 specializationTypes,
                 'SpecializationTypeId',
                 specializationTypeId
              ).Name
           }
         : null
      : null;

   return (
      <>
         {showAddSpecialization && (
            <AddSpecializationType
               selectedSpecializationType={selectedSpecialization}
               closeAuthorSpecializationType={showHideAuthorSpecializationType}
               onAuthorSpecializationTypeAdded={handleAddToKolekcija}
            />
         )}
         <div className='row'>
            <div className='col-9'>
               {html.renderSelectAnt(
                  'Usavršavanje',
                  'Name',
                  'SpecializationTypeId',
                  'Započnite unos ili odaberite obrazovanje...',
                  'Ne pronalazim traženu vrijednost...',
                  specializationTypes,
                  handleSpecializationTypeSelected,
                  null,
                  specializationType_defaultValue
               )}
            </div>
            <div className='col'>
               <ButtonForm
                  text='Dodaj'
                  onClick={showHideAuthorSpecializationType}
                  disabled={!btnAddEnabled}
                  inline={true}
               />
            </div>
         </div>
         {kolekcija.length > 0 && (
            <div className='list-container'>
               <table className='table table-sm table-striped'>
                  <thead className='thead-dark'>
                     <tr>
                        <th>Usavršavanje</th>
                        <th>Od</th>
                        <th>Do</th>
                        <th>Ustanova</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     {kolekcija.map((spec) => (
                        <tr key={uuid()}>
                           {html.td(
                              170,
                              specializationTypes.length > 0
                                 ? specializationTypes.find(
                                      (st) =>
                                         st.SpecializationTypeId ===
                                         spec.SpecializationTypeId
                                   ).Name
                                 : ''
                           )}
                           {html.td(
                              60,
                              spec.DateFrom.indexOf('T') > 0
                                 ? html.convert_T_Date_ToLocal(spec.DateFrom)
                                 : html.convertDateToLocal(spec.DateFrom)
                           )}
                           {html.td(
                              60,
                              spec.DateTo
                                 ? spec.DateTo.indexOf('T') > 0
                                    ? html.convert_T_Date_ToLocal(spec.DateTo)
                                    : html.convertDateToLocal(spec.DateTo)
                                 : '-'
                           )}
                           {html.td(
                              170,
                              legalEntities.length > 0
                                 ? legalEntities.find(
                                      (le) =>
                                         le.LegalEntityId === spec.LegalEntityId
                                   ).Name
                                 : ''
                           )}

                           {html.tdWithRemoveBtn(
                              handleRemoveFromKolekcija,
                              spec.id,
                              {
                                 title: `Ukloni ${
                                    legalEntities.length > 0
                                       ? legalEntities.find(
                                            (le) =>
                                               le.LegalEntityId ===
                                               spec.LegalEntityId
                                         ).Name
                                       : ''
                                 } sa liste`
                              }
                           )}
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         )}
      </>
   );
};

export default AuthorSpecializationType;
