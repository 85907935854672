import React from 'react';

const ButtonForm = ({
   inline = false,
   ref = null,
   text = 'Form button',
   disabled = false,
   onClick
}) => {
   const style = {
      marginTop: '1.5rem'
   };
   const classBase = 'button-form';

   return (
      <button
         className={classBase}
         disabled={disabled}
         onClick={onClick}
         type='button'
         {...(ref ? { ref } : {})}
         {...(inline ? { style } : {})}
      >
         {text}
      </button>
   );
};

export default ButtonForm;
