import destructureTo from '../services/destructure-data';
import {
   getAllLegalEntities,
   getAuthorList,
   getCreatedArtworks,
   getExhibitionDetails,
   getExhibitions,
   getExhibitionTypeKind,
   getLocationType,
   getUserCreatedAuthors,
   getVerifiedArtworks
} from '../services/api';
import {
   getExhibitionTypeQuantity,
   getExhibitionInstitutionType
} from './../services/api';
import { getCurrentUser } from '../services/auth';

const initState = {
   exhibitions: [],
   exhibitionTypeQuantity: [],
   exhibitionTypeKind: [],
   institutionType: [],
   locationType: [],
   legalEntities: [],
   authors: [],
   artworks: [],
   verifiedArtworks: [],
   exhibitionData: null,
   isEditMode: false,
   isEditAfterVerification: false,
   redirectToErrorPage: false,
   errorMessage: ''
};

export const loadExhibitionStaticData = () => async (dispatch, getState) => {
   try {
      if (getCurrentUser()) {
         const { data: exhibitions } = await getExhibitions();
         const { data: artworks } = await getCreatedArtworks();
         const { data: verifiedArtworks } = await getVerifiedArtworks();

         const { data: exhibitionTypeQuantity } =
            await getExhibitionTypeQuantity();
         const { data: exhibitionTypeKind } = await getExhibitionTypeKind();
         const { data: institutionType } = await getExhibitionInstitutionType();
         const { data: locationType } = await getLocationType();
         const { data: authors } = await getAuthorList();
         const { data: legalEntities } = await getAllLegalEntities();
         //await dispatch(storeAuthors());
         //await dispatch(loadLegalEnteties());

         const payload = {
            exhibitions: exhibitions.details,
            authors: authors.data,
            artworks: artworks.details,
            verifiedArtworks: verifiedArtworks.data,
            exhibitionTypeQuantity: exhibitionTypeQuantity.data,
            exhibitionTypeKind: exhibitionTypeKind.data,
            institutionType: institutionType.data,
            locationType: locationType.data,
            legalEntities: legalEntities.data
         };
         await dispatch({ type: 'LOAD_EXHIBITION_STATIC_DATA', payload });
      } else {
         const { data: exhibitionTypeQuantity } =
            await getExhibitionTypeQuantity();
         const { data: exhibitionTypeKind } = await getExhibitionTypeKind();
         const { data: institutionType } = await getExhibitionInstitutionType();
         const { data: locationType } = await getLocationType();

         const payload = {
            exhibitionTypeQuantity: exhibitionTypeQuantity.data,
            exhibitionTypeKind: exhibitionTypeKind.data,
            institutionType: institutionType.data,
            locationType: locationType.data
         };

         await dispatch({ type: 'LOAD_EXHIBITION_STATIC_DATA', payload });
      }
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_EXHIBITION_STATIC_DATA', ex.response?.data);
      }
   }
};

export const loadLegalEnteties = () => async (dispatch, getState) => {
   try {
      const { data: legalEntities } = await getAllLegalEntities();
      const payload = {
         legalEntities: legalEntities.data
      };
      dispatch({ type: 'LOAD_LEGAL_ENTETIES', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('LOAD_LEGAL_ENTETIES', ex.response?.data);
      }
   }
};

export const storeAuthors = () => async (dispatch, getState) => {
   try {
      const { data: authors } = await getUserCreatedAuthors();
      const payload = {
         authors: authors.details
      };
      await dispatch({ type: 'STORE_AUTHORS', payload });
   } catch (ex) {
      if (ex.response && ex.response.status === 400) {
         console.log('STORE_AUTHORS', ex.response?.data);
      }
   }
};

export const redirectToErrorPage = (redirectToErrorPage) => ({
   type: 'REDIRECT_TO_ERROR_PAGE',
   payload: {
      redirectToErrorPage
   }
});

export const saveErrorMessage = (errorMessage) => ({
   type: 'SAVE_ERROR_MESSAGE',
   payload: {
      errorMessage
   }
});

export const loadExhibitionData =
   (exhibitionId) => async (dispatch, getState) => {
      try {
         dispatch(saveErrorMessage(''));
         dispatch(redirectToErrorPage(false));
         const { data: exhibitionData } = await getExhibitionDetails(
            exhibitionId
         );
         if (exhibitionData.status === 0) {
            dispatch(saveErrorMessage(exhibitionData.message));
            dispatch(redirectToErrorPage(true));
            return;
         }

         const payload = {
            exhibitionData: exhibitionData.details
         };
         await dispatch({ type: 'LOAD_EXHIBITION_DATA', payload });
      } catch (ex) {
         if (ex.response && ex.response.status === 400) {
            console.log('LOAD_EXHIBITION_DATA', ex.response?.data);
         }
      }
   };

export const setExhibitionEditAfterVerificationMode = (
   isEditAfterVerification
) => ({
   type: 'SET_EXHIBITION_EDIT_AFTER_VERIFICATION_MODE',
   payload: {
      isEditAfterVerification
   }
});

export const setExhibitionEditMode = (mode) => ({
   type: 'SET_EXHIBITION_EDIT_MODE',
   payload: {
      mode
   }
});

export const clearExhibitionkData = () => ({
   type: 'CLEAR_EXHIBITION_DATA'
});

//  REDUCER
export default function reducer(state = initState, action) {
   switch (action.type) {
      case 'LOAD_EXHIBITION_STATIC_DATA':
         if (getCurrentUser()) {
            const {
               exhibitions,
               authors,
               artworks,
               verifiedArtworks,
               exhibitionTypeQuantity,
               exhibitionTypeKind,
               institutionType,
               locationType,
               legalEntities
            } = action.payload;
            return {
               ...state,
               exhibitions,
               authors,
               verifiedArtworks,
               artworks,
               exhibitionTypeQuantity,
               exhibitionTypeKind,
               institutionType,
               locationType,
               legalEntities
            };
         } else {
            const {
               exhibitionTypeQuantity,
               exhibitionTypeKind,
               institutionType,
               locationType
            } = action.payload;
            return {
               ...state,
               exhibitionTypeQuantity,
               exhibitionTypeKind,
               institutionType,
               locationType
            };
         }
      case 'SET_EXHIBITION_EDIT_MODE':
         const { mode } = action.payload;
         return {
            ...state,
            isEditMode: mode
         };
      case 'LOAD_LEGAL_ENTETIES':
         const { legalEntities } = action.payload;
         return {
            ...state,
            legalEntities
         };

      case 'REDIRECT_TO_ERROR_PAGE':
         const { redirectToErrorPage } = action.payload;
         return {
            ...state,
            redirectToErrorPage
         };
      case 'SAVE_ERROR_MESSAGE':
         const { errorMessage } = action.payload;
         return {
            ...state,
            errorMessage
         };

      case 'CLEAR_EXHIBITION_DATA':
         return {
            ...state,
            exhibitionData: null
         };
      case 'STORE_AUTHORS':
         const { authors } = action.payload;
         return {
            ...state,
            authors
         };

      case 'SET_EXHIBITION_EDIT_AFTER_VERIFICATION_MODE':
         const { isEditAfterVerification } = action.payload;
         return {
            ...state,
            isEditAfterVerification
         };

      case 'LOAD_EXHIBITION_DATA':
         const { exhibitionData } = action.payload;
         exhibitionData.authors = [...state.authors];
         exhibitionData.artworks = [...state.artworks];

         const basicInformation =
            destructureTo.exhibitionBasicInformation(exhibitionData);
         const restInformation =
            destructureTo.exhibitionRestInformation(exhibitionData);

         exhibitionData.basicInformation = { ...basicInformation };
         exhibitionData.restInformation = { ...restInformation };

         return {
            ...state,
            exhibitionData
         };

      default:
         return state;
   }
}
