import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import html from '../../../services/html';
import { motion } from 'framer-motion';

const ArtworkInCollections = ({
   onArtworkInCollectionsChange,
   defaultValue
}) => {
   const [IsArtworkInCollections, setIsArtworkInCollections] = useState(false);
   const [ArtworkInCollectionsDescription, setArtworkInCollectionsDescription] =
      useState('');
   const [error, setError] = useState(null);

   const handle_IsArtworkInCollections = () => {
      setIsArtworkInCollections(!IsArtworkInCollections);
      setArtworkInCollectionsDescription('');
      setError(null);
   };

   useEffect(() => {
      const { IsArtworkInCollections, ArtworkInCollectionsDescription } =
         defaultValue;
      setIsArtworkInCollections(IsArtworkInCollections);
      setArtworkInCollectionsDescription(ArtworkInCollectionsDescription);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onArtworkInCollectionsChange({
         IsArtworkInCollections,
         ArtworkInCollectionsDescription
      });
   }, [IsArtworkInCollections, ArtworkInCollectionsDescription]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleOnInputChange = ({ target: input }) => {
      setArtworkInCollectionsDescription(input.value);
      if (input.value.trim() === '')
         setError('Umjetnička djela u zbirkama je obavezan unos');
      else setError('');
   };

   return (
      <div className='artwork-in-collections'>
         <Checkbox
            checked={IsArtworkInCollections}
            onChange={handle_IsArtworkInCollections}
            style={{ marginBottom: '.5rem' }}
         >
            Umjetnička djela u zbirkama
         </Checkbox>
         <motion.div
            animate={{
               height: IsArtworkInCollections ? '4rem' : 0,
               opacity: IsArtworkInCollections ? 1 : 0,
               display: IsArtworkInCollections ? 'block' : 'none',
               transition: { duration: 0.2, display: { delay: 0.2 } }
            }}
         >
            {html.renderSFCInput(
               'Umjetnička djela u zbirkama',
               'Name',
               error,
               handleOnInputChange,
               { value: ArtworkInCollectionsDescription },
               true
            )}
         </motion.div>
      </div>
   );
};

export default ArtworkInCollections;
