import React from 'react';
import { getCities, getCountries, getUserDetails } from '../services/api';
import { AddNewEntitySchema } from '../validators/add-new-entity';
import Form from './common/form';
import ShowTooltip from './common/show-tooltip';
import Openstreet from './openstreet/openstreet';
import { connect } from 'react-redux';
import { loadListConfirmed } from '../store/list-confirmed';
import Input from './common/input';
import { toast } from 'react-toastify';
import PopupContainer from './common/popup-container/popup-container';
import html from '../services/html';
import ButtonCancel from './common/btn-cancel';

class UserConfirmationEdit extends Form {
   state = {
      validationData: {
         LegalEntityName: '',
         LegalEntityAddress: '',
         LegalEntityLatitude: '',
         LegalEntityLongitude: ''
      },
      restData: {
         LegalEntityCountryId: 0,
         LegalEntityCityId: 0,
         LegalEntityCityName: ''
      },
      errors: [],
      countries: [],
      cities: [],
      countriesLoaded: false,
      countrySelected: false,
      citySelected: false,
      addCityYourself: false,
      authorDetails: {}
   };

   schema = AddNewEntitySchema;

   async componentDidMount() {
      toast('Učitavanje podataka...');
      const { UserId } = this.props.editUser;
      const { data: countries } = await getCountries();

      const { data: authorDetails } = await getUserDetails(UserId);

      let {
         LegalEntityName,
         LegalEntityAddress,
         LegalEntityLatitude,
         LegalEntityLongitude
      } = authorDetails.details;

      //  controled components must not be null
      LegalEntityName = LegalEntityName ?? '';
      LegalEntityAddress = LegalEntityAddress ?? '';
      LegalEntityLatitude = LegalEntityLatitude ?? '';
      LegalEntityLongitude = LegalEntityLongitude ?? '';

      const validationData = {
         LegalEntityName,
         LegalEntityAddress,
         LegalEntityLatitude,
         LegalEntityLongitude
      };

      let {
         LegalEntityCountryId,
         LegalEntityCityId,
         LegalEntityCityName,
         LegalEntityId,
         FirstName,
         LastName,
         Remark
      } = authorDetails.details;

      //  controled components must not be null
      LegalEntityId = LegalEntityId ?? 0;
      LegalEntityCityId = LegalEntityCityId ?? 0;
      LegalEntityCityName = LegalEntityCityName ?? '';

      const restData = {
         LegalEntityCountryId,
         LegalEntityCityId,
         LegalEntityCityName,
         LegalEntityId,
         FirstName,
         LastName,
         Remark,
         UserId
      };

      //  učitaj gradove samo ako je definirana država
      if (LegalEntityCountryId) {
         const { data: cities } = await getCities(LegalEntityCountryId);
         this.setState({ cities: cities.data });
      }

      toast.dismiss();

      this.setState({
         countries: countries.data,
         authorDetails: authorDetails.details,
         validationData,
         restData,
         countriesLoaded: true
      });
   }

   render() {
      const { countriesLoaded, countries, cities, addCityYourself } =
         this.state;
      const { LegalEntityCountryId, LegalEntityCityId, LegalEntityCityName } =
         this.state.restData;
      const { LegalEntityAddress } = this.state.validationData;

      const disable =
         this.validateForm() ||
         LegalEntityCountryId === '' ||
         (LegalEntityCityId === '' && !addCityYourself) ||
         (LegalEntityCityName === '' && addCityYourself);

      const { FirstName, LastName, Phone, EMail } = this.state.authorDetails;

      const country_dv = {
         value: html.getCountryNameById(LegalEntityCountryId, countries)
      };

      return (
         <form
            onSubmit={this.handleFormSubmit}
            style={{ height: 0 }}
            onClick={this.handleExitClick}
         >
            {countriesLoaded && (
               <PopupContainer
                  title='Uređivanje podataka'
                  onPopupClose={this.props.closeUserEditor}
               >
                  <div className='user-confirmation-edit-content'>
                     <div className='row'>
                        <div className='col'>
                           <Input
                              name='FirstName'
                              label='Ime'
                              value={FirstName}
                              disabled
                           />
                        </div>
                        <div className='col'>
                           <Input
                              name='LastName'
                              label='Prezime'
                              value={LastName}
                              disabled
                           />
                        </div>
                     </div>

                     <div className='row'>
                        <div className='col'>
                           <Input
                              name='EMail'
                              label='E-mail'
                              value={EMail}
                              disabled
                           />
                        </div>
                        <div className='col'>
                           <Input
                              name='Phone'
                              label='Telefon'
                              value={Phone}
                              disabled
                           />
                        </div>
                     </div>

                     <div className='row'>
                        <div className='col'>
                           {this.renderSelectAnt(
                              'Država',
                              'Name',
                              'CountryId',
                              'Započnite unos ili odaberite državu...',
                              'Ne pronalazim traženu državu...',
                              countries,
                              this.handleCountrySelected,
                              null,
                              country_dv
                           )}
                        </div>
                     </div>

                     {LegalEntityCountryId && (
                        <>
                           <div
                              className={
                                 !addCityYourself
                                    ? 'row add-city-select-container'
                                    : 'row add-city-select-container hideAddCitySelect'
                              }
                           >
                              <div className='col-10'>
                                 {this.renderSelectAnt(
                                    'Grad',
                                    'Name',
                                    'CityId',
                                    'Započnite unos ili odaberite grad...',
                                    'Niste odabrali državu ili ne pronalazim traženi grad...',
                                    cities,
                                    this.handleCitySelected,
                                    null,
                                    {
                                       defaultValue:
                                          this.props.editUser.AffiliationCity
                                    }
                                 )}
                              </div>
                              <div
                                 className='col'
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    paddingBottom: '.75rem'
                                 }}
                              >
                                 <ShowTooltip
                                    title='Ukoliko ne pronalazite grad među ponuđenim opcijama kliknite na gumb i samostalno unesite naziv grada'
                                    placement='bottom'
                                 >
                                    <button
                                       type='button'
                                       className='btn btn-secondary btn-sm'
                                       onClick={(e) => {
                                          e.stopPropagation();
                                          const restData = {
                                             ...this.state.restData
                                          };
                                          restData.LegalEntityCityId = '';
                                          restData.LegalEntityCityName = '';
                                          this.setState({
                                             restData,
                                             addCityYourself: true,
                                             citySelected: true
                                          });
                                       }}
                                    >
                                       Dodaj grad
                                    </button>
                                 </ShowTooltip>
                              </div>
                           </div>

                           <div
                              className={
                                 addCityYourself
                                    ? 'add-city-container addCityYourself'
                                    : 'add-city-container'
                              }
                           >
                              {this.renderInputRestData(
                                 'LegalEntityCityName',
                                 'Grad',
                                 this.handleNazivGradaChange,
                                 {
                                    placeholder:
                                       'Samostalno unesite naziv grada'
                                 }
                              )}
                           </div>
                        </>
                     )}
                     {LegalEntityCityName && (
                        <div className='address showAddress'>
                           <div className='row'>
                              <div className='col'>
                                 {this.renderInputAntdesign(
                                    'LegalEntityAddress',
                                    'Adresa',
                                    {
                                       placeholder:
                                          'Možete unijeti i klikom na mapu...'
                                    }
                                 )}
                                 {this.renderInput(
                                    'LegalEntityName',
                                    'Naziv institucije'
                                 )}
                              </div>
                              <div className='col'>
                                 {this.renderInputAntdesign(
                                    'LegalEntityLatitude',
                                    'Zemljopisna širina',
                                    {
                                       placeholder:
                                          'Možete unijeti i klikom na mapu...'
                                    }
                                 )}
                                 {this.renderInputAntdesign(
                                    'LegalEntityLongitude',
                                    'Zemljopisna dužina',
                                    {
                                       placeholder:
                                          'Možete unijeti i klikom na mapu...'
                                    }
                                 )}
                              </div>
                           </div>

                           <Openstreet
                              onOpenstreetClick={this.handleOpenstreetClick}
                              center={`${LegalEntityCityName},${LegalEntityAddress}`}
                              disable={false}
                           />
                        </div>
                     )}

                     {this.renderSubmitButton(
                        'Ažuriraj podatke i prihvati korisnika',
                        disable
                     )}

                     <ButtonCancel
                        onClick={() => {
                           this.props.closeUserEditor();
                        }}
                     />
                  </div>
               </PopupContainer>
            )}
         </form>
      );
   }

   doSubmit = async () => {
      const { refreshUserConfirmList, onEditSave } = this.props;

      const validationData = { ...this.state.validationData };
      const restData = { ...this.state.restData };
      const updatedUser = { ...validationData, ...restData };

      onEditSave(updatedUser);
      refreshUserConfirmList();
   };

   handleExitClick = (e) => {
      e.stopPropagation();

      const { closeUserEditor } = this.props;
      closeUserEditor();
   };

   handleOpenstreetClick = ({ latlng, address }) => {
      const validationData = { ...this.state.validationData };
      const restData = { ...this.state.restData };
      const { addCityYourself } = this.state;

      validationData.LegalEntityLatitude = latlng.lat;
      validationData.LegalEntityLongitude = latlng.lng;
      validationData.LegalEntityAddress = address.Address;
      if (addCityYourself) {
         restData.LegalEntityCityName = address.City;
      }
      this.setState({ validationData, restData });
   };

   handleCountrySelected = async (value, obj) => {
      const { id: LegalEntityCountryId } = obj;
      const { data } = await getCities(LegalEntityCountryId);

      const restData = { ...this.state.restData };
      restData.LegalEntityCountryId = LegalEntityCountryId;

      this.setState({
         restData,
         cities: data.data,
         countrySelected: true
      });
   };

   handleCitySelected = (value, obj) => {
      const { id: LegalEntityCityId, value: LegalEntityCityName } = obj;

      const restData = { ...this.state.restData };
      restData.LegalEntityCityId = LegalEntityCityId;
      restData.LegalEntityCityName = LegalEntityCityName;

      this.setState({ restData, citySelected: true });
   };

   handleNazivGradaChange = ({ target: input }) => {
      const restData = { ...this.state.restData };
      const { errors } = this.state;

      restData.LegalEntityCityId = '';
      restData.LegalEntityCityName = input.value;

      if (input.value.trim() === '')
         errors[input.name] = 'Naziv grada je obavan unos';
      else delete errors[input.name];

      this.setState({ restData, errors });
   };
}

//  uzima cijeli store i vrača dio stora koji me zanima
//  svojstva ovog objekta će se nalaziti na props objektu UserConfirmationEdit komponente
const mapStateToProps = (state) => ({
   users: state.users
});

//  ova funkcija uzima dispatch() metodu sa stora i mapira ju na props objekt od UserConfirmationEdit komponente
//  svojstva ovog objekta će biti na UserConfirmationEdit props objektu
const mapDispatchToProps = (dispatch) => ({
   refreshUserConfirmList: () => dispatch(loadListConfirmed())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(UserConfirmationEdit);
