import Joi from 'joi-browser';

export const AddNewExhibitionArtworkSchema = {
    ExhibitionArtworkName: Joi.string()
        .required()
        .label('Naziv djela')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
