import _api from '../config/api-config.json';
import _apiPublic from '../config/api-public-config.json';
import _apiArtwork from '../config/api-artwork-config.json';
import _apiExhibition from '../config/api-exhibition-config.json';
import http from './http';
import { getCurrentUser } from './auth';
//const api = 'https://alu-develop-azure.azurewebsites.net/';
const api = 'https://auh.alu.hr/';

export function getCountries() {
   const url = `${api}${_api.country}`;
   return http.post(url);
}

export function getCities(CountryId) {
   let url = '';
   if (http.userIsLogged()) url = `${api}${_api.city}`;
   else url = `${api}${_api.city}`;

   return http.post(url, { CountryId });
}

export function createOrUpdateCity(city) {
   const url = `${api}${_api.createorupdatecity}`;
   return http.post(url, city);
}

export function sendEmailToForgotPassword(email) {
   const url = `${api}${_api.forgotpasswordbegin}`;
   return http.post(url, { EMail: email });
}

export function sendNewPasswordAndGUID(password, guid) {
   const url = `${api}${_api.forgotpasswordcomplete}`;
   return http.post(url, { Password: password, queryStringBase64: guid });
}

export function createAuthor(author) {
   const url = `${api}${_api.authorcreate}`;
   return http.post(url, author);
}

export function updateAuthor(author) {
   const url = `${api}${_api.authorupdate}`;
   return http.post(url, author);
}

export function getLegalEntities() {
   let url;
   if (getCurrentUser()) url = `${api}${_api.legalentity}`;
   else url = `${api}${_apiPublic.legalentity}`;

   // if (!userIsAdmin()) url = `${api}${_api.legalentityforuser}`;
   // else url = `${api}${_api.legalentity}`;
   return http.post(url);
}

export function getAssociation() {
   let url;
   if (getCurrentUser()) url = `${api}${_api.association}`;
   else url = `${api}${_apiPublic.association}`;

   return http.post(url);
}

export function createOrUpdateAssociation(association) {
   const url = `${api}${_api.createorupdateassociation}`;
   return http.post(url, association);
}

export function getGallery() {
   let url;
   if (getCurrentUser()) url = `${api}${_api.gallery}`;
   else url = `${api}${_apiPublic.gallery}`;
   return http.post(url);
}

export function createOrUpdateGallery(gallery) {
   const url = `${api}${_api.createorupdategallery}`;
   return http.post(url, gallery);
}

export function createOrUpdateLegalEntity(newLegalEntity) {
   const url = `${api}${_api.createorupdatelegalentity}`;
   return http.post(url, newLegalEntity);
}

export function getListConfirmed() {
   const url = `${api}${_api.listconfirmed}`;
   return http.post(url);
}

export function getUserDetails(UserId) {
   const url = `${api}${_api.details}`;
   return http.post(url, { UserId });
}

export function updateUser(user) {
   const url = `${api}${_api.updateuser}`;
   return http.post(url, user);
}

export function getSex() {
   const url = `${api}${_api.sex}`;
   return http.post(url);
}

export function getAcademicStatus() {
   const url = `${api}${_api.academicstatus}`;
   return http.post(url);
}

export function getSpecializationType() {
   const url = `${api}${_api.specializationtype}`;
   return http.post(url);
}

export function getAreaofInterest() {
   let url;
   if (getCurrentUser()) url = `${api}${_api.areaofinterest}`;
   else url = `${api}${_apiPublic.areaofinterest}`;
   return http.post(url);
}

export function getAreaofActivity() {
   let url;
   if (getCurrentUser()) url = `${api}${_api.areaofactivity}`;
   else url = `${api}${_apiPublic.areaofactivity}`;
   return http.post(url);
}

export function createOrUpdateAreaOfInterest(data) {
   //{ AreaOfInterestId ,Name }
   const url = `${api}${_api.createorupdateareaofinterest}`;
   return http.post(url, data);
}

export function getJobFunction() {
   let url;
   if (getCurrentUser()) url = `${api}${_api.jobfunction}`;
   else url = `${api}${_apiPublic.jobfunction}`;
   return http.post(url);
}

export function createOrUpdateJobFunction(data) {
   //{ JobFunctionId ,Name }
   const url = `${api}${_api.createorupdatejobfunction}`;
   return http.post(url, data);
}

export function getEducationLevels() {
   const url = `${api}${_api.educationlevel}`;
   return http.post(url);
}

export function getBibliographyCategories() {
   const url = `${api}${_api.bibliographycategory}`;
   return http.post(url);
}

export function getUserCreatedAuthors() {
   const url = `${api}${_api.list}`;
   return http.post(url);
}

export function deleteAuthor(AuthorId) {
   const url = `${api}${_api.authordelete}`;
   return http.post(url, { AuthorId });
}

export function getAuthorDetails(AuthorId) {
   const url = `${api}${_api.authordetails}`;
   console.log(`${url} - AuthorId: ${AuthorId}`);
   return http.post(url, { AuthorId });
}

export function markAsReadyForVerification(AuthorId) {
   const url = `${api}${_api.markasreadyforverification}`;
   return http.post(url, { AuthorId });
}

export function markAsVerified(AuthorId) {
   const url = `${api}${_api.markasverified}`;
   return http.post(url, { AuthorId });
}

export function updateVerifiedAuthor(author) {
   const url = `${api}${_api.updateVerifiedAuthor}`;
   return http.post(url, author);
}

export function reverifyAuthor(AuthorId) {
   const url = `${api}${_api.reverify}`;
   return http.post(url, { AuthorId });
}

export function updateVerifiedAuthorDetails(author) {
   const url = `${api}${_api.detailsverified}`;
   return http.post(url, author);
}

export function rejectUser(user) {
   const url = `${api}${_api.rejectuser}`;
   const payload = { UserId: user.UserId };
   return http.post(url, payload);
}

/*
            ARTWORK
*/

export function getArtworkTechnique() {
   const url = `${api}${_apiArtwork.technique}`;
   return http.post(url);
}

export function getOwnershipType() {
   const url = `${api}${_apiArtwork.ownershiptype}`;
   return http.post(url);
}

export function createArtwork(artwork) {
   const url = `${api}${_apiArtwork.artworkcreate}`;
   return http.post(url, artwork);
}

export function getCreatedArtworks() {
   const url = `${api}${_apiArtwork.list}`;
   return http.post(url);
}

export function getVerifiedArtworks() {
   const url = `${api}${_apiArtwork.verifiedartworks}`;
   return http.post(url);
}

export function getArtworkDetails(ArtworkId) {
   const url = `${api}${_apiArtwork.artworkdetails}`;
   return http.post(url, { ArtworkId });
}

export function deleteArtwork(ArtworkId) {
   const url = `${api}${_apiArtwork.artworkdelete}`;
   return http.post(url, { ArtworkId });
}

export function markArtworkAsReadyForVerification(ArtworkId) {
   const url = `${api}${_apiArtwork.markasreadyforverification}`;
   return http.post(url, { ArtworkId });
}

export function reverifyArtwork(ArtworkId) {
   const url = `${api}${_apiArtwork.reverify}`;
   return http.post(url, { ArtworkId });
}

export function markArtworkAsVerified(ArtworkId) {
   const url = `${api}${_apiArtwork.markasverified}`;
   return http.post(url, { ArtworkId });
}

export function updateVerifiedArtwork(artwork) {
   const url = `${api}${_apiArtwork.updateVerifiedArtwork}`;
   return http.post(url, artwork);
}

export function updateArtwork(artwork) {
   const url = `${api}${_apiArtwork.artworkupdate}`;
   return http.post(url, artwork);
}

export function getAuthorList() {
   const url = `${api}${_apiArtwork.authorlist}`;
   return http.post(url);
}

export function getCollections(SearchTerm) {
   const url = `${api}${_apiArtwork.collection}`;
   return http.post(url, { SearchTerm });
}

export function createOrUpdateCollection(newCollection) {
   const url = `${api}${_apiArtwork.createorupdatecollection}`;
   return http.post(url, newCollection);
}

/*
            EXHIBITION
*/

export function getExhibitions() {
   const url = `${api}${_apiExhibition.list}`;
   return http.post(url);
}

export function getExhibitionTypeQuantity() {
   const url = `${api}${_apiExhibition.exhibitionTypeQuantity}`;
   return http.post(url);
}

export function getExhibitionTypeKind() {
   const url = `${api}${_apiExhibition.exhibitionTypeKind}`;
   return http.post(url);
}

export function getExhibitionInstitutionType() {
   const url = `${api}${_apiExhibition.institutionType}`;
   return http.post(url);
}

export function getLocationType() {
   const url = `${api}${_apiExhibition.locationType}`;
   return http.post(url);
}

export function createExhibition(exhibition) {
   const url = `${api}${_apiExhibition.exhibitionCreate}`;
   return http.post(url, exhibition);
}

export function getCreatedExhibitions() {
   const url = `${api}${_apiExhibition.list}`;
   return http.post(url);
}

export function getExhibitionDetails(ExhibitionId) {
   const url = `${api}${_apiExhibition.exhibitionDetails}`;
   return http.post(url, { ExhibitionId });
}

export function deleteExhibition(ExhibitionId) {
   const url = `${api}${_apiExhibition.exhibitionDelete}`;
   return http.post(url, { ExhibitionId });
}

export function markExhibitionAsReadyForVerification(ExhibitionId) {
   const url = `${api}${_apiExhibition.markExhibitionAsReadyForVerification}`;
   return http.post(url, { ExhibitionId });
}
export function reverifyExhibition(ExhibitionId) {
   const url = `${api}${_apiExhibition.reverify}`;
   return http.post(url, { ExhibitionId });
}

export function markExhibitionAsVerified(ExhibitionId) {
   const url = `${api}${_apiExhibition.markExhibitionAsVerified}`;
   return http.post(url, { ExhibitionId });
}

export function updateVerifiedExhibition(exhibition) {
   const url = `${api}${_apiExhibition.updateVerifiedExhibition}`;
   return http.post(url, exhibition);
}

export function updateExhibition(exhibition) {
   const url = `${api}${_apiExhibition.exhibitionUpdate}`;
   return http.post(url, exhibition);
}

export function getMediaannouncementTypes() {
   const url = `${api}${_apiExhibition.mediaannouncementtype}`;
   return http.post(url);
}

/*
    PUBLIC
*/

export function searchForTerm(term) {
   const url = `${api}${_apiPublic.search}`;
   return http.post(url, term);
}

export function getPublicAuthorDetails(Id) {
   const url = `${api}${_apiPublic.authorDetails}`;
   return http.post(url, { Id });
}

export function getPublicArtworkDetails(Id) {
   const url = `${api}${_apiPublic.artworkDetails}`;
   return http.post(url, { Id });
}

export function getPublicExhibitionDetails(Id) {
   const url = `${api}${_apiPublic.exhibitionDetails}`;
   return http.post(url, { Id });
}

export function getAllLegalEntities() {
   let url = `${api}${_api.legalentity}`;
   return http.post(url);
}
