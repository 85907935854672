import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ButtonNext = ({ text = 'Dalje', textAlign = 'right', onClick }) => {
   const style = {
      marginTop: '1.5rem'
   };
   const classBase = 'button-next';

   return (
      <div style={{ textAlign }}>
         <button className={classBase} onClick={onClick} type='button'>
            <span className='mr-2'>{text}</span>
            <FontAwesomeIcon
               icon={faChevronRight}
               style={{ color: 'rgb(24, 144, 255)' }}
            />
         </button>
      </div>
   );
};

export default ButtonNext;
