import React, { useEffect } from 'react';
import ConfirmResult from './confirm-result';
import { useSelector, useDispatch } from 'react-redux';
import { clearConfirmData } from '../../store/common';
import MotionContainer from './motion-containers/motion-container';
import { confirmAnimation } from './confirm-animation';

const Confirm = (props) => {
    const { confirmData } = useSelector((state) => state.common);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearConfirmData());
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MotionContainer
            className='author-confirm'
            animationObject={confirmAnimation}
        >
            <ConfirmResult
                title={confirmData.title}
                status={confirmData.status}
                subtitle={confirmData.subtitle}
                btnPrimary={confirmData.btnPrimary}
                btnSecondary={confirmData.btnSecondary}
                onPrimary={confirmData.onPrimary}
                onSecondary={confirmData.onSecondary}
            />
        </MotionContainer>
    );
};

export default Confirm;
