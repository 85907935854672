import React, { useEffect, useState } from 'react';
import { getCities } from '../../services/api';
import AddNewCity from './add-new-city';
import html from '../../services/html';
import ButtonFormTooltip from './btn-form-tooltip';

const AddCity = ({
   countryId,
   onCitySelect,
   label,
   required,
   defaultCityId
}) => {
   const [cities, setCities] = useState([]);
   const [showAddNew, setShowAddNew] = useState(false);

   const loadCities = async () => {
      const { data: response } = await getCities(countryId);
      setCities(response.data);
   };

   useEffect(() => {
      loadCities();
   }, [countryId]); // eslint-disable-line react-hooks/exhaustive-deps

   const handleCloseAddNewCity = (e) => {
      loadCities();
      setShowAddNew(false);
   };

   const city_defaultValue = defaultCityId
      ? cities.length > 0
         ? {
              value: html.getCityNameById(defaultCityId, cities)
           }
         : null
      : null;

   return (
      <>
         {showAddNew && (
            <AddNewCity
               closeAddNewCity={handleCloseAddNewCity}
               countryId={countryId}
            />
         )}
         <div className='row'>
            <div className='col-8'>
               {html.renderSelectAnt(
                  label ? label : 'Grad',
                  'Name',
                  'CityId',
                  'Započnite unos ili odaberite grad...',
                  'Ne pronalazim traženi grad...',
                  cities,
                  onCitySelect,
                  null,
                  city_defaultValue,
                  required
               )}
            </div>
            <div className='col'>
               <ButtonFormTooltip
                  inline={true}
                  text='Dodaj grad'
                  onClick={(e) => {
                     e.stopPropagation();
                     setShowAddNew(true);
                  }}
                  tooltip='Ukoliko ne pronalazite grad među ponuđenim opcijama, dodajte ga sami.'
               />
            </div>
         </div>
      </>
   );
};

export default AddCity;
