import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import parseHTML from 'html-react-parser';

import { loadCollections } from '../../store/artwork';
import AddNewArtworkCollection from './add-new-artwork-collection';
import ButtonForm from './../common/btn-form';

const ArtworkCollection = ({ onAdd, defaultValue, disabled }) => {
   const [showAddNew, setShowAddNew] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   const dispatch = useDispatch();
   const { collections } = useSelector((state) => state.artwork);

   useEffect(() => {
      setKolekcija(defaultValue);
      dispatch(loadCollections());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAdd(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNew = () => {
      setShowAddNew(!showAddNew);
   };

   const handleAdd = (data) => {
      setKolekcija([...kolekcija, data]);
   };

   const handleRemoveFromList = (id) => {
      setKolekcija(
         kolekcija.filter((representation) => representation.id !== id)
      );
   };

   return (
      <>
         {showAddNew && (
            <AddNewArtworkCollection
               onAdd={handleAdd}
               onClose={showHide_AddNew}
            />
         )}
         {kolekcija.length === 0 && (
            <ButtonForm text='Dodaj zbirku' onClick={showHide_AddNew} />
         )}
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Zbirka nije dodana...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Zbirka</th>
                           <th>Poveznica</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((collection) => (
                           <tr key={uuid()}>
                              {html.td(
                                 150,
                                 html.getCollectionNameById(
                                    collection.CollectionId,
                                    collections
                                 )
                              )}
                              {html.td(
                                 180,
                                 parseHTML(
                                    `<a href='${collection.Url}' target='_blank'>${collection.Url}</a>`
                                 )
                              )}

                              {!disabled &&
                                 html.tdWithRemoveBtn(
                                    handleRemoveFromList,
                                    collection.id,
                                    {
                                       title: `Ukloni ${html.getCollectionNameById(
                                          collection.CollectionId,
                                          collections
                                       )}`
                                    }
                                 )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ArtworkCollection;
