import React from 'react';
import { getAreaofActivity } from '../../../services/api';
import Form from '../../common/form';
import ShowTooltip from '../../common/show-tooltip';
import AddNewAuthorAreaOfInterest from './add-new-author-area-of-interest';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import html from '../../../services/html';
import ButtonForm from './../../common/btn-form';

/*
    areaofactivitiesInit - inicijalno polje svih djelovanja jer kako koji pojam dodajem 
    micem ga iz polja areaofactivities
*/
class AuthorAreaOfActivity extends Form {
   state = {
      validationData: {},
      errors: {},
      areaofactivities: [],
      areaofactivitiesInit: [],
      selectedAreaOfActivity: {},
      authorAreaOfActivities: this.props.defaultValue
         ? this.props.defaultValue
         : [],
      showAddNewAreaOfActivities: false
   };

   loadAuthorAreaOfActivities = async () => {
      const { data } = await getAreaofActivity();

      this.setState({
         areaofactivities: data.data,
         areaofactivitiesInit: data.data
      });
   };

   async componentDidMount() {
      this.loadAuthorAreaOfActivities();
   }

   handleCloseAddNewAreaOfInterest = (e) => {
      this.loadAuthorAreaOfActivities();
      this.setState({ showAddNewAreaOfActivities: false });
   };

   handleAreaOfActivitySelected = (value, obj) => {
      const { id: AreaOfActivityId, value: Name } = obj;
      const selectedAreaOfActivity = {
         AreaOfActivityId,
         Name
      };
      //this.ref.current.focus();
      this.setState({ selectedAreaOfActivity });
   };

   handleAddSelectedAreaOfActivity = () => {
      const { selectedAreaOfActivity } = this.state;

      //da li je pojam vec odabran
      let authorAreaOfActivities = [...this.state.authorAreaOfActivities];
      const pojamVecOdabran = authorAreaOfActivities.some(
         (aeoi) =>
            aeoi.AreaOfActivityId === selectedAreaOfActivity.AreaOfActivityId
      );
      if (pojamVecOdabran) {
         toast.error(`Pojam ${selectedAreaOfActivity.Name} je već odabran`);
         return;
      }

      //Maksimalno se može dodati 20 pojmova
      if (authorAreaOfActivities.length === 8) {
         toast.error(`Dosegli ste maksimalan broj pojmova`);
         return;
      }

      authorAreaOfActivities = [
         ...authorAreaOfActivities,
         selectedAreaOfActivity
      ];
      this.setState({ authorAreaOfActivities });

      const { onAreaOfInterestSelect } = this.props;
      onAreaOfInterestSelect(authorAreaOfActivities);
   };

   handle_RemoveActivity = (id) => {
      const { authorAreaOfActivities } = this.state;

      const filtriranoPolje = authorAreaOfActivities.filter(
         (a) => a.AreaOfActivityId !== id
      );
      this.setState({
         authorAreaOfActivities: filtriranoPolje
      });

      const { onAreaOfInterestSelect } = this.props;
      onAreaOfInterestSelect(filtriranoPolje);
   };

   ref = React.createRef();

   render() {
      let {
         areaofactivitiesInit,
         areaofactivities,
         selectedAreaOfActivity,
         showAddNewAreaOfActivities,
         authorAreaOfActivities
      } = this.state;

      /*
            prikazujem samo pojmove koji još nisu odabrani...
        */
      const areaOfInterestsIds = authorAreaOfActivities.map(
         (a) => a.AreaOfActivityId
      );
      areaofactivities = areaofactivities.filter(
         (aoi) => !areaOfInterestsIds.includes(aoi.AreaOfActivityId)
      );

      const disableAddBtn = Object.keys(selectedAreaOfActivity).length === 0;

      const { disabled } = this.props;
      return (
         <>
            {showAddNewAreaOfActivities && (
               <AddNewAuthorAreaOfInterest
                  closeAddNewAuthorAreaOfInterest={
                     this.handleCloseAddNewAreaOfInterest
                  }
               />
            )}
            <div className='row'>
               <div className='col-6'>
                  {this.renderSelectAnt(
                     'Područje djelovanja',
                     'Name',
                     'AreaOfActivityId',
                     'Započnite unos ili odaberite područje djelovanja...',
                     'Ne pronalazim traženo područje djelovanja...',
                     areaofactivities,
                     this.handleAreaOfActivitySelected,
                     null,
                     { disabled },
                     true
                  )}
               </div>
               <div className='col'>
                  {!disabled && (
                     <ButtonForm
                        text='Odaberi'
                        onClick={this.handleAddSelectedAreaOfActivity}
                        disabled={disableAddBtn}
                        ref={this.ref}
                        inline={true}
                     />
                  )}
               </div>
               <div
                  className='col'
                  style={{
                     display: 'flex',
                     justifyContent: 'flex-end',
                     alignItems: 'flex-end',
                     paddingBottom: '.75rem'
                  }}
               >
                  {/* {!disabled && (
                     <ShowTooltip
                        title='Ukoliko ne pronalazite pojam među ponuđenim opcijama područja djelovanja, dodajte ga sami.'
                        placement='bottom'
                     >
                        <button
                           type='button'
                           className='btn btn-dark btn-sm form-control'
                           onClick={(e) => {
                              e.stopPropagation();
                              this.setState({
                                 showAddNewAreaOfActivities: true
                              });
                           }}
                        >
                           Dodaj
                        </button>
                     </ShowTooltip>
                  )} */}
               </div>
            </div>
            {authorAreaOfActivities.length === 0 && (
               <p style={{ fontSize: '.7rem' }}>
                  Nema odabranih područja djelovanja...
               </p>
            )}
            {authorAreaOfActivities.length > 0 &&
               areaofactivitiesInit.length > 0 && (
                  <div className='author-area-of-interests selected-options-grid'>
                     {authorAreaOfActivities.map((aeoi) => (
                        <ShowTooltip
                           title={
                              disabled
                                 ? ''
                                 : `Obriši ${html.getAreaOfActivityNameById(
                                      aeoi.AreaOfActivityId,
                                      areaofactivitiesInit
                                   )}`
                           }
                           placement='bottom'
                           tooltipColor='#f00'
                           visible={disabled}
                           key={uuid()}
                        >
                           <div
                              className='selected-option-style'
                              onClick={() => {
                                 if (!disabled)
                                    this.handle_RemoveActivity(
                                       aeoi.AreaOfActivityId
                                    );
                              }}
                           >
                              {html.getAreaOfActivityNameById(
                                 aeoi.AreaOfActivityId,
                                 areaofactivitiesInit
                              )}
                           </div>
                        </ShowTooltip>
                     ))}
                  </div>
               )}
         </>
      );
   }
}

export default AuthorAreaOfActivity;
