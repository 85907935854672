import React, { useState } from 'react';
import { createOrUpdateAssociation } from '../../../services/api';
import { toast } from 'react-toastify';
import PopupContainer from '../../common/popup-container/popup-container';
import AddCity from '../../common/add-city';
import html from '../../../services/html';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

const AddNewAssociation = ({ onClose }) => {
   const [Name, setName] = useState('');
   const [CityId, setCityId] = useState(null);
   const [error, setError] = useState(null);

   const handleOnInputChange = ({ target: input }) => {
      setName(input.value.trim());
      if (input.value.trim() === '')
         setError('Naziv udruženja je obavezan unos');
      else setError('');
   };

   const addNewAssociation = async () => {
      const newAssociation = {
         CityId,
         Name
      };

      const response = await createOrUpdateAssociation(newAssociation);
      const { status, message } = response.data;

      if (status === 1) {
         toast.info('Udruženje uspješno dodano');
         onClose();
      } else toast.error(`${message}`);
   };

   const handleOnSubmit = async (e) => {
      e.stopPropagation();
      addNewAssociation();
   };

   const handleOnCitySelect = (value, obj) => {
      const { id: cityId, value: cityName } = obj;
      console.log('cityName', cityName);
      setCityId(cityId);
   };

   const disabled = error || error === null || CityId === 0;

   return (
      <PopupContainer title='Dodavanje udruge' onPopupClose={onClose}>
         {html.renderSFCInput(
            'Naziv udruženja',
            'Name',
            error,
            handleOnInputChange
         )}
         <AddCity countryId={1} onCitySelect={handleOnCitySelect} />
         <hr />

         <ButtonAdd disabled={disabled} onClick={handleOnSubmit} />
         <ButtonCancel
            onClick={() => {
               onClose();
            }}
         />
      </PopupContainer>
   );
};

export default AddNewAssociation;
