import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import AddNewArtworkAuthor from './add-new-artwork-author';
import { useSelector, useDispatch } from 'react-redux';
import { refreshAuthorsList } from '../../store/common';
import ButtonForm from './../common/btn-form';

const ArtworkAuthor = ({ onAddArtworkAuthor, defaultValue }) => {
   const [showAddArtworkAuthor, setShowAddArtworkAuthor] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);
   const { authors } = useSelector((state) => state.common);
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(refreshAuthorsList());
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddArtworkAuthor(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewArtworkAuthor = () => {
      setShowAddArtworkAuthor(!showAddArtworkAuthor);
   };

   const getAuthorName = (authorId) => {
      const findAuthor = authors.find((a) => a.AuthorId === authorId);
      return `${findAuthor.Name}`;
   };

   const handle_OnArtworkAuthorAdded = (artworkAuthorData) => {
      try {
         const authorName =
            artworkAuthorData.AuthorId === null
               ? artworkAuthorData.AuthorOrGroupName
               : getAuthorName(artworkAuthorData.AuthorId);

         const newAuthor = {
            id: isNaN(artworkAuthorData) ? uuid() : artworkAuthorData,
            AuthorId: artworkAuthorData.AuthorId,
            AuthorOrGroupName: artworkAuthorData.AuthorId ? '' : authorName,
            Name: authorName
         };

         setKolekcija([...kolekcija, newAuthor]);
      } catch (error) {
         console.log('ERROR - handle_OnArtworkAuthorAdded()');
         console.log(error);
      }
   };

   const handle_RemoveFromArtworkAuthorList = (id) => {
      setKolekcija(kolekcija.filter((aa) => aa.id !== id));
   };

   return (
      <>
         {showAddArtworkAuthor && (
            <AddNewArtworkAuthor
               onArtworkAuthorAdded={handle_OnArtworkAuthorAdded}
               closeAddNewArtworkAuthor={showHide_AddNewArtworkAuthor}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               <span className='mr-1'>Autori</span>
               {html.renderRequiredTooltip()}
            </label>
            <ButtonForm
               text='Odaberi/unesi autora umjetničkog djela'
               onClick={showHide_AddNewArtworkAuthor}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih autora...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Autor</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((author) => (
                           <tr key={uuid()}>
                              {html.td(230, author.Name)}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromArtworkAuthorList,
                                 author.id,
                                 {
                                    title: `Ukloni ${author.Name}`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ArtworkAuthor;
