import React, { useState } from 'react';
import PopupContainer from '../../common/popup-container/popup-container';
import html from '../../../services/html';
import Association from './association';
import { v4 as uuid } from 'uuid';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

const AddNewAuthorAssociation = ({ onAdd, onClose }) => {
   const [AssociationId, setAssociationId] = useState(null);
   const [Name, setAssociationName] = useState('');
   const [DateFrom, setDateFrom] = useState('');
   const [DateTo, setDateTo] = useState('');

   const handleOnSubmit = () => {
      const newAssociation = {
         id: uuid(),
         AssociationId,
         Name,
         DateFrom,
         DateTo
      };
      onAdd(newAssociation);
      onClose();
   };

   const onAssociationSelect = (value, obj) => {
      const { id, value: name } = obj;
      setAssociationId(id);
      setAssociationName(name);
   };

   const handleSelectDateFrom = (e) => {
      setDateFrom(e.format('YYYY-MM-DD'));
   };

   const handleSelectDateTo = (e) => {
      setDateTo(e.format('YYYY-MM-DD'));
   };

   const disabled = AssociationId === 0 || DateFrom === '';

   return (
      <PopupContainer title='Dodavanje udruge' onPopupClose={onClose}>
         <Association onAssociationSelect={onAssociationSelect} />
         <div className='row'>
            <div className='col'>
               {html.renderDatePicker('Datum od', handleSelectDateFrom)}
            </div>
            <div className='col'>
               {html.renderDatePicker('Datum do', handleSelectDateTo)}
            </div>
         </div>

         <div style={{ marginTop: '2rem' }}>
            <ButtonAdd disabled={disabled} onClick={handleOnSubmit} />
            <ButtonCancel
               onClick={() => {
                  onClose();
               }}
            />
         </div>
      </PopupContainer>
   );
};

export default AddNewAuthorAssociation;
