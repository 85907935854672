import React, { useEffect, useState } from 'react';
import { getMediaannouncementTypes } from '../../services/api';
import html from '../../services/html';
import { motion } from 'framer-motion';

const MediaAnnouncementType = ({ onChange }) => {
   const [firstLoad, setFirstLoad] = useState(true);
   const [data, setData] = useState([]);
   const [MediaAnnouncementTypeId, setMediaAnnouncementTypeId] = useState('');
   const [MediaAnnouncementTypeOther, setMediaAnnouncementTypeOther] =
      useState('');

   useEffect(() => {
      const loadData = async () => {
         const { data: response } = await getMediaannouncementTypes();
         const { data } = response;
         setData(data);
      };

      loadData();
   }, []);
   useEffect(() => {
      if (!firstLoad)
         onChange({ MediaAnnouncementTypeId, MediaAnnouncementTypeOther });

      setFirstLoad(false);
   }, [MediaAnnouncementTypeOther]);

   const handleChange = (text, { id: MediaAnnouncementTypeId }) => {
      if (MediaAnnouncementTypeId !== 4) setMediaAnnouncementTypeOther('');
      setMediaAnnouncementTypeId(MediaAnnouncementTypeId);
      onChange({ MediaAnnouncementTypeId, MediaAnnouncementTypeOther });
   };

   const prikaziInputText = MediaAnnouncementTypeId === 4;

   return (
      <>
         {html.renderSelectAnt(
            'Vrsta objave',
            'Name',
            'MediaAnnouncementTypeId',
            'Započnite unos ili odaberite vrstu objave...',
            'Ne pronalazim traženu vrstu objave...',
            data,
            handleChange
         )}
         <motion.div
            animate={{
               height: prikaziInputText ? '4rem' : 0,
               opacity: prikaziInputText ? 1 : 0,
               transition: { duration: 0.2 }
            }}
         >
            {html.renderInput(
               'MediaAnnouncementTypeOther',
               'Druga vrsta objave',
               MediaAnnouncementTypeOther,
               ({ target }) => {
                  setMediaAnnouncementTypeOther(target.value);
               }
            )}
         </motion.div>
      </>
   );
};

export default MediaAnnouncementType;

//https://stackoverflow.com/questions/54923896/react-hooks-useeffect-fires-even-though-the-state-did-not-change
