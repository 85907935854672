import React from 'react';
import Form from '../common/form';
import MotionContainer from '../common/motion-containers/motion-container';
import { connect } from 'react-redux';
import { ExhibitionRestInformationSchema } from './../../validators/exhibition-rest-information';
import { userIsAdmin } from './../../services/auth';
import ButtonSave from './../common/btn-save';

class ExhibitionRestInformation extends Form {
   constructor(props) {
      super(props);

      const { Remark, Url } = props.data;

      this.Remark = Remark;
      this.Url = Url;

      this.state = {
         validationData: {
            Remark: this.Remark ?? '',
            Url: this.Url ?? ''
         },
         restData: {},
         errors: {}
      };
   }

   isValid = false;

   schema = ExhibitionRestInformationSchema;

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const { isEditMode, isEditAfterVerification } = this.props.exhibition;

      const btnClassBase = 'btn form-control btn-';
      const btnClass = isEditMode ? `edit-mode` : `primary-mode`;

      const btnText = isEditMode
         ? 'Ažuriraj podatke izložbe'
         : 'Kreiraj izložbu';

      const showSaveButton = !isEditAfterVerification || userIsAdmin();

      return (
         <>
            <MotionContainer className='author-professional-activity'>
               <form onSubmit={this.handleFormSubmit} className='form-style'>
                  <h2>Ostali podaci o izložbi</h2>
                  <div>
                     {this.renderFormInput({
                        label: 'URL izložbe',
                        name: 'Url'
                     })}

                     {this.renderFormInput({
                        label: 'Napomena',
                        name: 'Remark'
                     })}

                     <hr />
                     {showSaveButton && (
                        <ButtonSave
                           text={btnText}
                           className={btnClass}
                           onClick={this.handleCreateOrUpdateArtwork}
                        />
                     )}
                  </div>
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const restData = { ...this.state.restData };
      const validationData = { ...this.state.validationData };
      const data = { ...restData, ...validationData };

      data.isValid = true;
      //spremi podatke na state-u parent kontrole [ExhibitionAddPage]
      this.props.onSubmit(data);
   };

   handleCreateOrUpdateArtwork = () => {
      const { onCreateExhibition } = this.props;
      const { Remark, Url } = this.state.validationData;
      const data = {
         Url,
         Remark
      };
      this.doSubmit();
      data.isValid = true;
      onCreateExhibition(data);
   };
}
const mapStateToProps = (state) => ({
   exhibition: state.exhibition
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ExhibitionRestInformation);
