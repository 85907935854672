import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { homepageAnimation } from './animations/homepage-animation';
import { useDispatch, useSelector } from 'react-redux';
import html from '../services/html';
import { getCities } from '../services/api';
import { Image } from 'antd';
import { imgFallback } from './author-details-service';

import LoadingSpinner from '../components/common/loading-spinner';
import { detailsAnimation } from './animations/details-animation';
import { isStaticDataLoaded } from './../services/created-artworks-page';
import { loadArtworkStaticData } from './../store/artwork';
import { loadArtworkDetails } from './../store/common';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { NavLink, useHistory } from 'react-router-dom';
import {
   ucitavamDodatnePodatke,
   ucitavamPodatke
} from './../services/toast-messages';
import { hideToastProgressBar } from '../store/app-setup';
import HomepageLink from '../components/common/homepage-link';

const ArtworkDetails = (props) => {
   const dispatch = useDispatch();
   const [cities, setCities] = useState(null);
   const [staticDataLoaded, setStaticDataLoaded] = useState(false);
   const { common, artwork: artworkState } = useSelector((state) => state);

   const { artworkDetails: artwork, countries, redirectToErrorPage } = common;
   const { artworkTechnique, ownershipType } = artworkState;
   const history = useHistory();

   useEffect(() => {
      if (redirectToErrorPage)
         history.push(`${process.env.PUBLIC_URL}/error-page`);
   }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      document.title = 'Detalji umjetničkog djela';
      const { id } = props.match.params;

      toast.info(ucitavamPodatke);
      dispatch(hideToastProgressBar(true));

      dispatch(loadArtworkDetails(id)).then(() => {
         toast.dismiss();
         if (!isStaticDataLoaded(artworkState)) {
            toast(ucitavamDodatnePodatke);
            dispatch(loadArtworkStaticData()).then(() => {
               setStaticDataLoaded(true);
               toast.dismiss();
            });
         } else setStaticDataLoaded(true);
      });

      return () => {
         //dispatch(clearArtworkDetails());
         dispatch(hideToastProgressBar(false));
         toast.dismiss();
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const {
      ArtworkCreationCountryId,
      ArtworkCreationCityId,
      Name,
      ArtworkAuthors,
      ArtworkCreationYears,
      ArtworkTechniques,
      Dimensions,
      OwnershipTypeId,
      Description,
      ArtistStatement,
      ArtworkExhibitions,
      ArtworkAwards,
      ArtworkBibliographies,
      ArtworkPhotographies
   } = artwork ? artwork : {};

   useEffect(() => {
      if (ArtworkCreationCountryId) getCountryCities(ArtworkCreationCountryId);
   }, [ArtworkCreationCountryId]);

   const getCountryCities = async (countryId) => {
      if (!countryId) return;
      const { data } = await getCities(countryId);
      setCities(data.data);
   };

   const getCountryName = () => {
      const countryName = html.getCountryNameById(
         ArtworkCreationCountryId,
         countries
      );
      return countryName ? countryName : '';
   };

   const getCityName = () => {
      const cityName = cities
         ? html.getCityNameById(ArtworkCreationCityId, cities)
         : '';
      return cityName ? cityName : '';
   };

   const getGodineNastanka = () => {
      const godineNastanka = ArtworkCreationYears
         ? `${ArtworkCreationYears[0].Year} - ${
              ArtworkCreationYears[ArtworkCreationYears.length - 1].Year
           }`
         : '-';
      return godineNastanka;
   };

   return (
      <>
         {!staticDataLoaded && <LoadingSpinner />}
         {staticDataLoaded && (
            <MotionContainer
               className='artwork-details'
               animationObject={homepageAnimation}
            >
               <div className='basic-information'>
                  <div className='pic'></div>
                  <div className='txt'>
                     <div className='name'>
                        <h1>{`${Name}`}</h1>
                     </div>
                     <div className='address'>
                        {`${getCityName()}  ${getCountryName()}`}
                     </div>
                  </div>
               </div>
               {!staticDataLoaded && <LoadingSpinner />}

               {staticDataLoaded && (
                  <MotionContainer
                     className='details'
                     animationObject={detailsAnimation}
                  >
                     <div className='info'>
                        <h2>Podaci umjetničkog djela</h2>
                        <table className='table table-striped table-sm parent-table'>
                           <tbody>
                              <tr>
                                 <td className='align-middle'>Autori</td>
                                 <td className='align-middle'>
                                    {ArtworkAuthors &&
                                       ArtworkAuthors.map((a) => (
                                          <span
                                             style={{
                                                display: 'inline-block',
                                                marginRight: '1rem'
                                             }}
                                             key={uuid()}
                                          >
                                             {a.LinkAuthorId && (
                                                <NavLink
                                                   to={`${process.env.PUBLIC_URL}/details-author/${a.LinkAuthorId}`}
                                                >
                                                   {a.LinkAuthorOrGroupName
                                                      ? a.LinkAuthorOrGroupName
                                                      : a.JustTextAuthorOrGroupName}
                                                </NavLink>
                                             )}
                                             {!a.LinkAuthorId && (
                                                <span>
                                                   {a.LinkAuthorOrGroupName
                                                      ? a.LinkAuthorOrGroupName
                                                      : a.JustTextAuthorOrGroupName}
                                                </span>
                                             )}
                                          </span>
                                       ))}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Naziv na engleskom
                                 </td>
                                 <td className='align-middle'>{Name}</td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Godina/e nastanka
                                 </td>
                                 <td className='align-middle'>
                                    {getGodineNastanka()}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Tehnike</td>
                                 <td className='align-middle'>
                                    {ArtworkTechniques &&
                                       ArtworkTechniques.map((a) =>
                                          html.getTechniqueById(
                                             a.TechniqueId,
                                             artworkTechnique
                                          )
                                       ).join(', ')}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Dimenzije</td>
                                 <td className='align-middle'>
                                    {Dimensions ? Dimensions : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Vlasništvo</td>
                                 <td className='align-middle'>
                                    {html.getOwnershipTypeById(
                                       OwnershipTypeId,
                                       ownershipType
                                    )}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Opis</td>
                                 <td className='align-middle'>
                                    {Description ? Description : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Izjava umjetnika/ce
                                 </td>
                                 <td className='align-middle'>
                                    {ArtistStatement ? ArtistStatement : '-'}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>
                                    Izložbe na kojima je djelo izlagano
                                 </td>
                                 <td className='align-middle'>
                                    {ArtworkExhibitions &&
                                       ArtworkExhibitions.map((a) => (
                                          <span
                                             style={{
                                                display: 'inline-block',
                                                marginRight: '1rem'
                                             }}
                                             key={uuid()}
                                          >
                                             <NavLink
                                                to={`${process.env.PUBLIC_URL}/details-exhibition/${a.LinkExhibitionId}`}
                                             >
                                                {a.LinkExhibitionName
                                                   ? a.LinkExhibitionName
                                                   : a.JustTextExhibitionName}
                                             </NavLink>
                                          </span>
                                       ))}
                                 </td>
                              </tr>
                              <tr>
                                 <td className='align-middle'>Nagrade</td>
                                 <td className='align-middle'>
                                    {ArtworkAwards.length === 0 ? '-' : ''}
                                    {ArtworkAwards &&
                                       ArtworkAwards.map((a) => (
                                          <div key={uuid()}>
                                             {`${a.Name}, ${
                                                cities &&
                                                html.getCityNameById(
                                                   a.CityId,
                                                   cities
                                                )
                                             } - ${a.Year}`}
                                          </div>
                                       ))}
                                 </td>
                              </tr>
                              <tr
                                 style={{
                                    backgroundColor: 'transparent'
                                 }}
                              >
                                 <td className='align-middle'>Bibliografije</td>
                                 <td className='align-middle'>
                                    {ArtworkBibliographies.length === 0 && (
                                       <span>-</span>
                                    )}
                                    {ArtworkBibliographies &&
                                       ArtworkBibliographies.map((a) => (
                                          <table
                                             className='table table-striped table-sm sub-table'
                                             key={uuid()}
                                          >
                                             <tbody>
                                                <tr>
                                                   <td>Naziv</td>
                                                   <td>
                                                      {a.Name ? a.Name : '-'}
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Izdanje</td>
                                                   <td>
                                                      {a.Edition
                                                         ? a.Edition
                                                         : '-'}
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Autori</td>
                                                   <td>
                                                      {a.Authors
                                                         ? a.Authors
                                                         : '-'}
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Grad</td>
                                                   <td>
                                                      {cities &&
                                                         html.getCityNameById(
                                                            a.CityId,
                                                            cities
                                                         )}
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Godina</td>
                                                   <td>
                                                      {a.Year ? a.Year : '-'}
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       ))}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <hr />
                        {ArtworkPhotographies &&
                           ArtworkPhotographies.length === 0 && (
                              <p>
                                 Nema fotografija vezanih uz umjetničko djelo
                              </p>
                           )}
                        {ArtworkPhotographies &&
                           ArtworkPhotographies.length > 0 && (
                              <div className='artwork-photographs'>
                                 <hr />
                                 {ArtworkPhotographies.map((p) => (
                                    <div
                                       className='image-container'
                                       key={uuid()}
                                    >
                                       <Image
                                          width={200}
                                          height={135}
                                          src={
                                             p.PhotoFileContentBase64
                                                ? p.PhotoFileContentBase64
                                                : 'error'
                                          }
                                          fallback={imgFallback}
                                       />
                                    </div>
                                 ))}
                              </div>
                           )}
                     </div>
                     <HomepageLink />
                  </MotionContainer>
               )}
            </MotionContainer>
         )}
      </>
   );
};

export default ArtworkDetails;
