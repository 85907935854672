import React from 'react';
import { motion } from 'framer-motion';
import {
   popup_container_animation,
   popup_container_header_animation
} from './popup-container-animations';
import ShowTooltip from '../show-tooltip';
import MorphButton from './../morph-button';

const PopupContainer = (props) => {
   const { title, onPopupClose, children } = props;

   const handleExitClick = (e) => {
      e.stopPropagation();
      onPopupClose();
   };

   return (
      <motion.div
         className='popup-container'
         variants={popup_container_animation}
         initial='hidden'
         animate='show'
         exit='exit'
         onClick={(e) => {
            e.stopPropagation();
            //onPopupClose();
         }}
      >
         <div
            className='popup-container-background'
            onClick={(e) => {
               e.stopPropagation();
            }}
         >
            <motion.header
               variants={popup_container_header_animation}
               initial='hidden'
               animate='show'
            >
               <h5>{title}</h5>
            </motion.header>

            <div className='exit' onClick={handleExitClick}>
               <ShowTooltip title='Zatvori' placement='left'>
                  <div>
                     <MorphButton
                        type='crossSparks'
                        rolloverType='cross'
                        thickness={3}
                        color='#a390dc'
                        size={40}
                     />
                  </div>
               </ShowTooltip>
            </div>

            <div className='popup-container-content'>{children}</div>
         </div>
      </motion.div>
   );
};

export default PopupContainer;
