import Joi from 'joi-browser';

export const forgotPasswordChangeSchema = {
    NewPassword: Joi.string()
        .required()
        .label('Lozinka')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezna`
            };
        })
};

//bla: Joi.any().optional(),
