import React from 'react';
import Form from '../../common/form';
import { authorThesisSchema } from '../../../validators/author-thesis';
import PopupContainer from '../../common/popup-container/popup-container';
import { v4 as uuid } from 'uuid';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

class AddAuthorThesis extends Form {
   state = {
      validationData: {
         Mentor: '',
         Title: ''
      },
      restData: {
         YearOfGraduation: 0,
         AuthorThesisId: null,
         CroQfLevel: null
      },
      errors: []
   };

   schema = authorThesisSchema;

   render() {
      const disabled =
         this.validateForm() || this.state.restData.YearOfGraduation === 0;

      const { closeAuthorThesis, onAuthorThesisAdded } = this.props;

      return (
         <PopupContainer
            title='Diplomski/poslijediplomski rad'
            onPopupClose={closeAuthorThesis}
         >
            {this.renderInput('Title', 'Naziv rada')}
            {this.renderInput('Mentor', 'Mentor')}
            {this.renderDatePicker(
               'Godina diplome/obrane',
               this.handleDatePickerChange,
               {
                  picker: 'year',
                  placeholder: 'Odaberite godinu...',
                  format: 'YYYY'
               }
            )}
            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd
                  disabled={disabled}
                  onClick={() => {
                     const data = {
                        id: uuid(),
                        ...this.state.validationData,
                        ...this.state.restData
                     };
                     onAuthorThesisAdded(data);
                     closeAuthorThesis();
                  }}
               />
               <ButtonCancel
                  onClick={() => {
                     closeAuthorThesis();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAuthorThesis();
   };

   handleDatePickerChange = (e) => {
      const restData = { ...this.state.restData };
      restData.YearOfGraduation = parseInt(e.format('YYYY'));
      this.setState({ restData });
   };
}
export default AddAuthorThesis;
