import Joi from 'joi-browser';

export const AddNewLegalEntitySchema = {
    Name: Joi.string()
        .required()
        .label('Naziv institucije')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    Address: Joi.string()
        .required()
        .label('Adresa')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    GeoLatitude: Joi.any()
        .optional()
        .label('Latitude')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    GeoLongitude: Joi.any()
        .optional()
        .label('Latitude')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
