import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../../services/html';
import AddAtelierAddress from '../../common/add-atelier-address';
import { Checkbox } from 'antd';
import ButtonForm from './../../common/btn-form';

const AuthorAtelier = ({ onAdd, defaultValue, disabled }) => {
   const [showAddNew, setShowAddNew] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);
   const [IsAtelier, setIsAtelier] = useState(false);

   const handle_IsAtelier = () => {
      const data = {
         IsAtelier: !IsAtelier,
         AtelierAddress: '',
         AtelierGeoLatitude: null,
         AtelierGeoLongitude: null
      };

      setIsAtelier(!IsAtelier);
      onAdd(data);
   };

   const showHide_AddNew = () => {
      setShowAddNew(!showAddNew);
   };

   useEffect(() => {
      const { IsAtelier } = defaultValue;

      setIsAtelier(IsAtelier);
      if (defaultValue.AtelierAddress.trim() === '') return;
      setKolekcija([defaultValue]);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleAdd = (atelierAddress) => {
      setKolekcija([atelierAddress]);
      atelierAddress.IsAtelier = IsAtelier;
      onAdd(atelierAddress);
   };

   const handleRemoveFromList = (id) => {
      setKolekcija(kolekcija.filter((item) => item.id !== id));
   };

   return (
      <>
         <div className='atelier'>
            <Checkbox
               checked={IsAtelier}
               onChange={handle_IsAtelier}
               disabled={disabled}
            >
               Atelje
            </Checkbox>
            <span className='ml-1'>
               {IsAtelier && html.renderRequiredTooltip()}
            </span>
            <div
               className={
                  IsAtelier
                     ? 'atelier-description show-atelier-description'
                     : 'atelier-description'
               }
            >
               {showAddNew && (
                  <AddAtelierAddress
                     onAddAddress={handleAdd}
                     onClose={showHide_AddNew}
                  />
               )}
               <div className='mb-1'>
                  <ButtonForm
                     text='Dodaj atelje'
                     onClick={showHide_AddNew}
                     disabled={kolekcija.length > 0}
                  />
               </div>
               {kolekcija.length === 0 && (
                  <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
                     Atelje nije dodan...
                  </p>
               )}
               {kolekcija.length > 0 && (
                  <div className='list-container'>
                     {kolekcija.length > 0 && (
                        <table className='table table-sm table-striped'>
                           <thead className='thead-dark'>
                              <tr>
                                 {/* <th>Grad</th> */}
                                 <th>Adresa</th>
                                 <th>Širina</th>
                                 <th>Dužina</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              {kolekcija.map((item) => (
                                 <tr key={uuid()}>
                                    {/* {html.td(130, item.AtelierCityName)} */}
                                    {html.td(150, item.AtelierAddress)}
                                    {html.td(60, item.AtelierGeoLatitude)}
                                    {html.td(60, item.AtelierGeoLongitude)}

                                    {!disabled &&
                                       html.tdWithRemoveBtn(
                                          handleRemoveFromList,
                                          item.id,
                                          {
                                             title: `Ukloni ${item.AtelierAddress}`
                                          }
                                       )}
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     )}
                  </div>
               )}
            </div>
         </div>
      </>
   );
};

export default AuthorAtelier;
