import React, { useEffect, useState } from 'react';
//import { AnimatePresence } from 'framer-motion';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import Navbar from './components/navbar';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';
import { getCurrentUser } from './services/auth';
import ProtectedRoute from './components/common/protected-route';
import { useSelector, useDispatch } from 'react-redux';
import Footer from './components/footer';
// eslint-disable-next-line
import background from './services/random-background-image';
import service from './services/commmon-store';
import { loadCommonStaticData } from './store/common';
import LoadingSpinner from './components/common/loading-spinner';
import backgroundImage from './resources/img/background_2.png';

import { routes } from './services/routes';
//import ErrorBoundary from './components/common/error-handler';
import HomePage from './pages/home-page';

Storage.prototype.setObject = function (key, value) {
   this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
   var value = this.getItem(key);
   return value && JSON.parse(value);
};

const App = (props) => {
   let location = useLocation();
   const [user, setUser] = useState('');
   const [commonDataLoaded, setCommonDataLoaded] = useState(false);
   const { hideProgressBar, toastAutoCloseInterval } = useSelector(
      (state) => state.appSetup
   );

   const dispatch = useDispatch();
   const state = useSelector((state) => state.common);

   useEffect(() => {
      //if (user) {
      if (!service.isCommonDataLoaded(state)) {
         dispatch(loadCommonStaticData()).then(() => {
            setCommonDataLoaded(true);
         });
      }
      //}
   }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      //preusmeri ukoliko u url ima page parametar
      const params = queryString.parse(props.location.search);

      if (Object.keys(params).length !== 0 && params.page) {
         props.history.replace(`${process.env.PUBLIC_URL}/#${params.page}`);
      }

      try {
         setUser(getCurrentUser());
         setCommonDataLoaded(true);

         if (!service.isCommonDataLoaded(state)) {
            //dispatch(loadCommonStaticData());
         }

         //  dispatch(loadCommonStaticData()).then(() => {
         //     //setCommonDataLoaded(true);
         //  });
         //nemoj skrivat sadržaj ako si na login stranici
         const arrURL = props.history.location.pathname.split('/');
         const currentComponent = arrURL[arrURL.length - 1];
         if (currentComponent === 'login') setCommonDataLoaded(true);

         //  if (getCurrentUser() && !service.isCommonDataLoaded(state)) {
         //     dispatch(loadCommonStaticData()).then(() => {
         //        setCommonDataLoaded(true);
         //     });
         //  }
         //setJWTtoHeader(jwt);
      } catch (ex) {}
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      // <ErrorBoundary>
      <div
         className='app'
         //style={{ backgroundImage: background.get(location.pathname) }}
         style={{
            backgroundImage: `url('${backgroundImage}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
         }}
      >
         <ToastContainer
            position='bottom-right'
            autoClose={toastAutoCloseInterval}
            hideProgressBar={hideProgressBar}
            toastClassName='myToast'
         />

         {!commonDataLoaded && <LoadingSpinner />}

         <Navbar user={user ? user.username : ''} />
         {commonDataLoaded && (
            <div className='content'>
               {/* <AnimatePresence exitBeforeEnter={false} initial={false}> */}
               <Switch location={location} key={location.pathname}>
                  {routes.map((r) =>
                     !r.protected ? (
                        <Route
                           key={r.path}
                           path={`${process.env.PUBLIC_URL}${r.path}`}
                           component={r.component}
                        />
                     ) : (
                        <ProtectedRoute
                           key={r.path}
                           path={`${process.env.PUBLIC_URL}${r.path}`}
                           component={r.component}
                        />
                     )
                  )}

                  <Route
                     path={`${process.env.PUBLIC_URL}/`}
                     //component={getCurrentUser() ? HomePage : LoginPage}
                     component={HomePage}
                     exact
                  />
                  <Redirect to={`${process.env.PUBLIC_URL}/not-found`} />
               </Switch>
               {/* </AnimatePresence> */}
            </div>
         )}
         <Footer />
      </div>
      // </ErrorBoundary>
   );
};

export default withRouter(App);
