import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../../services/html';
import AddNewAuthorAssociation from './add-new-author-association';
import ButtonForm from './../../common/btn-form';
/*
    props
        onAdd()
*/

const AuthorAssociation = ({ onAdd, defaultValue }) => {
   const [showAddNew, setShowAddNew] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAdd(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNew = () => {
      setShowAddNew(!showAddNew);
   };

   const handleAdd = (associationData) => {
      setKolekcija([...kolekcija, associationData]);
   };

   const handleRemoveFromList = (id) => {
      setKolekcija(kolekcija.filter((association) => association.id !== id));
   };

   return (
      <>
         {showAddNew && (
            <AddNewAuthorAssociation
               onAdd={handleAdd}
               onClose={showHide_AddNew}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               Članstvo u udrugama
            </label>
            <ButtonForm text='Dodaj udrugu' onClick={showHide_AddNew} />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih udruga...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Udruga</th>
                           <th>Datum od</th>
                           <th>Datum do</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((association) => (
                           <tr key={uuid()}>
                              {html.td(200, association.Name)}
                              {html.td(
                                 60,
                                 association.DateFrom.indexOf('T') > 0
                                    ? html.convert_T_Date_ToLocal(
                                         association.DateFrom
                                      )
                                    : html.convertDateToLocal(
                                         association.DateFrom
                                      )
                              )}
                              {html.td(
                                 60,
                                 association.DateTo
                                    ? association.DateTo.indexOf('T') > 0
                                       ? html.convert_T_Date_ToLocal(
                                            association.DateTo
                                         )
                                       : html.convertDateToLocal(
                                            association.DateTo
                                         )
                                    : '-'
                              )}

                              {html.tdWithRemoveBtn(
                                 handleRemoveFromList,
                                 association.id,
                                 {
                                    title: `Ukloni ${association.Name}`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default AuthorAssociation;
