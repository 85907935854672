import SelectAnt from './../components/common/select-ant';
import ShowTooltip from './../components/common/show-tooltip';
import locale from 'antd/es/date-picker/locale/hr_HR';
import { DatePicker, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faInfoCircle,
   faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Input from '../components/common/input';

const td = (width, text) => (
   <td className='align-middle'>
      <div
         style={{
            width: `${width}px`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
         }}
      >
         {text}
      </div>
   </td>
);

/*
    rest = {
        title,
        placement,
        tooltipColor
    }
*/
const tdWithRemoveBtn = (onRemove, id, rest, disabled) => (
   <td className='align-middle'>
      {!disabled && (
         <ShowTooltip
            title={rest.title ? rest.title : `Ukloni sa liste`}
            placement={rest.placement ? rest.placement : 'right'}
            tooltipColor={rest.tooltipColor ? rest.tooltipColor : '#f00'}
         >
            <button
               className='btn btn-sm btn-link'
               type='button'
               onClick={() => {
                  onRemove(id);
               }}
            >
               Ukloni
            </button>
         </ShowTooltip>
      )}
   </td>
);

const btnSecondary = (txt, disabled, onClick) => (
   <div
      className='col'
      style={{
         display: 'flex',
         justifyContent: 'flex-end',
         alignItems: 'flex-end',
         paddingBottom: '.75rem'
      }}
   >
      <button
         className='btn btn-sm btn-secondary form-control'
         type='button'
         disabled={disabled}
         onClick={onClick}
      >
         {txt}
      </button>
   </div>
);

const renderSelectAnt = (
   label,
   text,
   value,
   placeholder,
   notFoundContent,
   selectOptions,
   onChange,
   onSearch,
   attributes,
   requiredLabel
) => {
   return (
      <SelectAnt
         label={label}
         text={text}
         value={value}
         placeholder={placeholder}
         notFoundContent={notFoundContent}
         selectOptions={selectOptions}
         onChange={onChange}
         onSearch={onSearch}
         attributes={attributes}
         requiredLabel={requiredLabel}
      />
   );
};

const renderRequiredTooltip = () => (
   <ShowTooltip title='Obavezan unos' placement='top' tooltipColor='#1890ff'>
      <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#1890ff' }} />
   </ShowTooltip>
);

const labelStyle = {
   marginBottom: '2px',
   fontWeight: 700,
   fontSize: '.9rem'
};

// options - {label, value}, defaultValue - [1,2,3...] indeksira od 1
const renderCheckBoxGroup = (
   label,
   options,
   onChange,
   defaultValue,
   requiredLabel,
   disabled
) => (
   <div>
      <div>
         <label style={labelStyle} className='mr-1'>
            {label}
         </label>
         {requiredLabel && renderRequiredTooltip()}
      </div>
      <Checkbox.Group
         options={options}
         onChange={onChange}
         value={defaultValue}
         disabled={disabled}
      />
   </div>
);

//add-new-association
const renderSFCInput = (label, name, error, onChange, rest, requiredLabel) => {
   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };
   return (
      <div className='form-group'>
         <label style={style} className='mr-1'>
            {label}
         </label>
         {requiredLabel && renderRequiredTooltip()}
         <input
            autoComplete='off'
            autoFocus
            name={name}
            id={name}
            className='form-control form-control-sm'
            onChange={onChange}
            {...rest}
         />
         {error && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {error}
            </div>
         )}
      </div>
   );
};

const renderSFCTextArea = (label, name, error, onChange, rest) => {
   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };
   return (
      <div className='form-group'>
         <label style={style}>{label}</label>
         <textarea
            autoComplete='off'
            autoFocus
            name={name}
            id={name}
            className='form-control form-control-sm'
            onChange={onChange}
            {...rest}
         />
         {error && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {error}
            </div>
         )}
      </div>
   );
};

const renderDatePicker = (label, onChange, rest) => {
   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };
   return (
      <div className='form-group'>
         <label style={style}>{label}</label>
         <DatePicker
            format='DD.MM.YYYY'
            locale={locale}
            style={{ borderRadius: '.2rem', width: '100%' }}
            onChange={onChange}
            {...rest}
         />
      </div>
   );
};

const renderInput = (name, label, value, onChange, attributes, required) => {
   return (
      <Input
         id={name}
         name={name}
         label={label}
         {...attributes}
         value={value}
         onChange={onChange}
         required={required}
      />
   );
};

const getLegalEntityNameById = (id, legalEntities) => {
   const legalEntityFound = legalEntities.find((le) => le.LegalEntityId === id);
   return legalEntityFound ? legalEntityFound.Name : '';
};

const getEducationLevelNameById = (id, educationLevels) => {
   const educationLevelFound = educationLevels.find(
      (el) => el.EducationLevelId === id
   );
   return educationLevelFound ? educationLevelFound.Name : '';
};

const getJobfunctionNameById = (id, jobfunctions) => {
   const jobfunctionFound = jobfunctions.find((jf) => jf.JobFunctionId === id);
   return jobfunctionFound ? jobfunctionFound.Name : '';
};

const getGalleryNameById = (id, galleries) => {
   const galleryFound = galleries.find((g) => g.GalleryId === id);
   return galleryFound ? galleryFound.Name : '';
};

const getCollectionNameById = (id, collections) => {
   const collectionFound = collections.find((g) => g.CollectionId === id);
   return collectionFound ? collectionFound.Name : '';
};

const getAreaOfInterestNameById = (id, areaofinterests) => {
   const areaOfInterestFound = areaofinterests.find(
      (aoi) => aoi.AreaOfInterestId === id
   );
   return areaOfInterestFound ? areaOfInterestFound.Name : '';
};

const getAreaOfActivityNameById = (id, areaofactivity) => {
   const areaOfActivityFound = areaofactivity.find(
      (aoa) => aoa.AreaOfActivityId === id
   );
   return areaOfActivityFound ? areaOfActivityFound.Name : '';
};

const getTechniqueById = (id, techniques) => {
   const techniqueFound = techniques.find((aoi) => aoi.TechniqueId === id);
   return techniqueFound ? techniqueFound.Name : '';
};

const getOwnershipTypeById = (id, ownershipTypes) => {
   const ownershipTypeFound = ownershipTypes.find(
      (ot) => ot.OwnershipTypeId === id
   );
   return ownershipTypeFound ? ownershipTypeFound.Name : '';
};

const getBibliographyNameById = (id, bibliographies) => {
   const bibliographyFound = bibliographies.find(
      (bib) => bib.BibliographyCategoryId === id
   );
   return bibliographyFound ? bibliographyFound.Name : '';
};

const getCountryNameById = (id, countries) => {
   if (!id) return null;
   const countryFound = countries.find((c) => c.CountryId === id);
   return countryFound ? countryFound.Name : '';
};

const getCityNameById = (id, cities) => {
   if (!id) return null;
   const cityFound = cities.find((bib) => bib.CityId === id);
   return cityFound ? cityFound.Name : '';
};

const getSexNameById = (id, sexes) => {
   if (!id) return null;
   const sexFound = sexes.find((s) => s.SexId === id);
   return sexFound ? sexFound.Name : '';
};

const getExhibitionTypeNameById = (id, exhibitionTypes) => {
   if (!id) return null;
   const exhibitionFound = exhibitionTypes.find(
      (c) => c.ExhibitionTypeQuantityId === id
   );
   return exhibitionFound ? exhibitionFound.Name : '';
};

const getExhibitionTypeKindNameById = (id, exhibitionTypeKinds) => {
   if (!id) return null;
   const exhibitionTypeKindeFound = exhibitionTypeKinds.find(
      (c) => c.ExhibitionTypeKindId === id
   );
   return exhibitionTypeKindeFound ? exhibitionTypeKindeFound.Name : '';
};

const getLocationTypeNameById = (id, locationTypes) => {
   if (!id) return null;
   const locationTypeFound = locationTypes.find((c) => c.LocationTypeId === id);
   return locationTypeFound ? locationTypeFound.Name : '';
};

const getInstitutionTypeNameById = (id, institutionTypes) => {
   if (!id) return null;
   const institutionTypeFound = institutionTypes.find(
      (c) => c.InstitutionTypeId === id
   );
   return institutionTypeFound ? institutionTypeFound.Name : '';
};

const getSpecializationTypeNameById = (id, specializationTypes) => {
   if (!id) return null;
   const specializationFound = specializationTypes.find(
      (c) => c.SpecializationTypeId === id
   );
   return specializationFound ? specializationFound.Name : '';
};

const getAcademicStatusNamesFromIDs = (IDs, academicStatusNames) => {
   if (!IDs) return null;

   return IDs.map((id) => {
      const found = academicStatusNames.find(
         (a) => a.AcademicStatusId === id.AcademicStatusId
      );
      return found ? found.Name : '';
   });
};

const getReadonlyCheckBox = (checked) =>
   checked ? (
      <input type='checkbox' checked disabled />
   ) : (
      <input type='checkbox' disabled />
   );

const convertDateToLocal = (date) => {
   if (!date) return null;
   const d = date.split('-');
   return `${d[2]}.${d[1]}.${d[0]}`;
};

/*  FORMAT DATUMA YYYY-MM-DD    */
const convert_T_Date_ToLocal = (date) => {
   if (!date) return null;
   const tIndex = date.lastIndexOf('T');
   return moment(new Date(date.substr(0, tIndex))).format('DD.MM.YYYY');
};

//2021-01-29T12:47:46.083
const convertDateTime = (dateTime) => {
   const parseDate = Date.parse(dateTime);
   return moment(new Date(parseDate)).format('DD.MM.YYYY - HH:mm:ss');
};

const htmlExport = {
   td,
   tdWithRemoveBtn,
   renderSelectAnt,
   renderSFCInput,
   renderSFCTextArea,
   btnSecondary,
   renderDatePicker,
   renderRequiredTooltip,
   renderCheckBoxGroup,
   renderInput,
   convertDateToLocal,
   getReadonlyCheckBox,
   convertDateTime,
   convert_T_Date_ToLocal,
   getLegalEntityNameById,
   getEducationLevelNameById,
   getJobfunctionNameById,
   getBibliographyNameById,
   getGalleryNameById,
   getAreaOfInterestNameById,
   getCountryNameById,
   getCityNameById,
   getSexNameById,
   getTechniqueById,
   getOwnershipTypeById,
   getExhibitionTypeNameById,
   getExhibitionTypeKindNameById,
   getInstitutionTypeNameById,
   getLocationTypeNameById,
   getAcademicStatusNamesFromIDs,
   getSpecializationTypeNameById,
   getAreaOfActivityNameById,
   getCollectionNameById
};

export default htmlExport;
