import React from 'react';
import { Descriptions } from 'antd';
import { useHistory } from 'react-router-dom';

const AuthorDescription = ({ data }) => {
   const { AuthorId, FirstName, LastName, City, Country } = data;

   const history = useHistory();

   return (
      <div className='search-description'>
         <Descriptions
            title={
               <span>
                  <button
                     type='button'
                     style={{ textDecoration: 'none' }}
                     className='btn btn-link description-tearm'
                     onClick={() => {
                        history.push(
                           `${process.env.PUBLIC_URL}/details-author/${AuthorId}`
                        );
                     }}
                  >
                     <span className='mr-4'>{`${FirstName} ${LastName}`}</span>
                     <span>
                        <span className='cityCountry'>
                           {City.toLowerCase()},
                        </span>
                     </span>
                     <span className='ml-1'>
                        <span className='cityCountry'>
                           {Country.toLowerCase()}
                        </span>
                     </span>
                  </button>
               </span>
            }
            bordered
            size='small'
         >
            {/* <Descriptions.Item
               label='Područje interesa'
               style={{ minWidth: '8rem' }}
            >
               {AreaOfInterests ? AreaOfInterests : '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Biografija'>
               {ShortBiography ? ShortBiography : '-'}
            </Descriptions.Item> */}
         </Descriptions>
         {/* <hr /> */}
      </div>
   );
};

export default AuthorDescription;
