import React from 'react';
import Form from '../common/form';
import { AddNewArtworkAuthorSchema } from '../../validators/add-new-artwork-author';
import PopupContainer from '../common/popup-container/popup-container';
import html from '../../services/html';
import { Checkbox } from 'antd';
import { connect } from 'react-redux';
import { refreshAuthorsList } from '../../store/common';
import ButtonAdd from '../common/btn-add';
import ButtonCancel from '../common/btn-cancel';

class AddNewArtworkAuthor extends Form {
   state = {
      validationData: {
         AuthorOrGroupName: ''
      },
      restData: {
         AuthorId: null
      },
      authors: [],
      customAuthorNameEnter: false,
      errors: []
   };

   schema = AddNewArtworkAuthorSchema;

   // async componentDidMount() {
   //     const { data: result } = await getAuthorList();
   //     this.setState({ authors: result.data });
   // }

   async componentDidMount() {
      this.props.refreshAuthorsList();
   }

   render() {
      const { customAuthorNameEnter } = this.state;

      const { authors, closeAddNewArtworkAuthor } = this.props;

      const { AuthorOrGroupName } = this.state.validationData;
      const { AuthorId } = this.state.restData;

      const nijeUnesenAutor =
         AuthorId === null && AuthorOrGroupName.trim() === '';

      const disabled = nijeUnesenAutor;

      const _authors = authors
         .filter((a) => a.AuthorTypeId === 1)
         .map((a) => ({
            AuthorId: a.AuthorId,
            Name: `${a.Name}`
         }));
      return (
         <PopupContainer
            title='Odabir/unos autora umjetničkog djela'
            onPopupClose={closeAddNewArtworkAuthor}
         >
            {/* <div style={{ marginBottom: '1rem' }}>
               <Checkbox onChange={this.handleCheckboxChecked}>
                  Slobodan unos autora
               </Checkbox>
            </div> */}
            {!customAuthorNameEnter &&
               html.renderSelectAnt(
                  'Odaberi autora',
                  'Name',
                  'AuthorId',
                  'Započnite unos ili odaberite autora...',
                  'Ne pronalazim traženog autora...',
                  _authors,
                  this.handleAuthorSelect
               )}

            {customAuthorNameEnter && (
               <div>
                  {this.renderInput(
                     'AuthorOrGroupName',
                     'Naziv autora ili grupe autora',
                     {
                        style: { height: '35px' },
                        placeholder: 'Unesite ime i prezime autora...'
                     }
                  )}
               </div>
            )}

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewArtworkAuthor();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewArtworkAuthor();
   };

   handleOnSubmit = () => {
      const { onArtworkAuthorAdded, closeAddNewArtworkAuthor } = this.props;
      const { AuthorOrGroupName } = this.state.validationData;
      const { AuthorId } = this.state.restData;

      // if (AuthorOrGroupName) onArtworkAuthorAdded(AuthorOrGroupName);
      // else onArtworkAuthorAdded(AuthorId);
      onArtworkAuthorAdded({ AuthorOrGroupName, AuthorId });
      closeAddNewArtworkAuthor();
   };

   handleAuthorSelect = (selectedText, valueObj) => {
      const restData = {};
      restData.AuthorId = valueObj.id;
      this.setState({ restData });
   };

   handleCheckboxChecked = ({ target: input }) => {
      if (input.checked) {
         const restData = {};
         restData.AuthorId = null;
         this.setState({ restData });
      } else {
         const validationData = {};
         validationData.AuthorOrGroupName = '';
         this.setState({ validationData });
      }
      this.setState({ customAuthorNameEnter: input.checked });
   };
}

const mapStateToProps = (state) => ({
   authors: state.common.authors
});

const mapDispatchToProps = (dispatch) => ({
   refreshAuthorsList: () => dispatch(refreshAuthorsList())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewArtworkAuthor);
