import React from 'react';
import Form from '../../common/form';
import AddLegalEntity from '../../common/add-legal-entity';
import { v4 as uuid } from 'uuid';
import AddJobFunction from './add-jobfunction';
import PopupContainer from '../../common/popup-container/popup-container';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

class AddNewAuthorWorkExperience extends Form {
   state = {
      validationData: {},
      restData: {
         AuthorWorkExperienceId: null,
         DateFrom: '',
         DateTo: '',
         JobFunctionId: 0,
         LegalEntityId: 0
      },
      legalEntitySelected: {},
      jobFunctionSelected: {},
      errors: []
   };

   render() {
      const { DateFrom, LegalEntityId, JobFunctionId } = this.state.restData;

      const { closeAddNewWorkExperience } = this.props;
      const disabled =
         DateFrom === '' || LegalEntityId === 0 || JobFunctionId === 0;

      return (
         <PopupContainer
            title='Dodavanje novog radnog iskustva'
            onPopupClose={closeAddNewWorkExperience}
         >
            <div className='row'>
               <div className='col'>
                  {this.renderDatePicker('Datum od', this.handleDateFrom)}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Datum do', this.handleDateTo)}
               </div>
            </div>
            <AddJobFunction
               onJobFunctionSelect={this.handleJobFunctionSelected}
            />
            <AddLegalEntity
               onLegalEntitySelect={this.handleLegalEntitySelected}
            />
            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewWorkExperience();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleOnSubmit = () => {
      const { onWorkExperienceAdded, closeAddNewWorkExperience } = this.props;
      const newWorkExperience = { ...this.state.restData };

      //dodatne informacije za lakše renderiranje
      const { legalEntitySelected, jobFunctionSelected } = this.state;
      console.log(legalEntitySelected, jobFunctionSelected);
      newWorkExperience.LegalEntityName = legalEntitySelected.LegalEntityName;
      newWorkExperience.JobFunctionName = jobFunctionSelected.JobFunctionName;

      newWorkExperience.id = uuid();

      onWorkExperienceAdded(newWorkExperience);
      closeAddNewWorkExperience();
   };

   handleLegalEntitySelected = (value, obj) => {
      const { id: LegalEntityId, value: LegalEntityName } = obj;

      const legalEntitySelected = { ...this.state.legalEntitySelected };
      legalEntitySelected.LegalEntityId = LegalEntityId;
      legalEntitySelected.LegalEntityName = LegalEntityName;

      const { restData } = this.state;
      restData.LegalEntityId = LegalEntityId;
      this.setState({ restData, legalEntitySelected });
   };

   handleJobFunctionSelected = (value, obj) => {
      const { id: JobFunctionId, value: JobFunctionName } = obj;

      const jobFunctionSelected = { ...this.state.legalEntitySelected };
      jobFunctionSelected.JobFunctionId = JobFunctionId;
      jobFunctionSelected.JobFunctionName = JobFunctionName;

      const { restData } = this.state;
      restData.JobFunctionId = JobFunctionId;
      this.setState({ restData, jobFunctionSelected });
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewWorkExperience();
   };

   handleDateFrom = (e) => {
      const { restData } = this.state;
      restData.DateFrom = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };

   handleDateTo = (e) => {
      const { restData } = this.state;
      restData.DateTo = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };
}
export default AddNewAuthorWorkExperience;
