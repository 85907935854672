import React, { useEffect } from 'react';

import { motion } from 'framer-motion';
import { pageAnimation } from './page-transition-animation';
import { registerHeaderAnimation } from './animations/register-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrinAlt } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';
import MotionContainer from '../components/common/motion-containers/motion-container';

const MailVerifiedPage = (props) => {
    useEffect(() => {
        document.title = 'E-mail adresa uspješno verificirana';
    });

    return (
        <MailVerifiedPageStyled
            variants={pageAnimation}
            initial='hidden'
            animate='show'
            exit='exit'
        >
            <div className='mail-verified-content'>
                <MotionContainer animationObject={registerHeaderAnimation}>
                    <h1>
                        <div>
                            <FontAwesomeIcon
                                icon={faGrinAlt}
                                className='icon-header mr-3'
                            />
                        </div>
                        E-mail adresa uspješno verificirana
                        <hr />
                        {/* <span>
                            Administrator će provjeriti unesene podatke i
                            povratno Vam se javiti.
                        </span> */}
                        <span>
                            <button
                                className='btn btn-link'
                                style={{ boxShadow: 'none', fontSize: '2rem' }}
                                onClick={() => {
                                    window.location = `${process.env.PUBLIC_URL}/#/login`;
                                }}
                            >
                                Prijavi se
                            </button>
                        </span>
                    </h1>
                </MotionContainer>
            </div>
        </MailVerifiedPageStyled>
    );
};

const MailVerifiedPageStyled = styled(motion.div)`
    min-height: calc(100vh - 10rem);
    display: flex;
    justify-content: center;
    align-items: center;

    .mail-verified-content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 4rem;
            font-weight: 100;
            text-align: center;
            span {
                display: block;
                font-size: 2rem;
                color: #999;
            }
        }
    }
`;

export default MailVerifiedPage;
