import Joi from 'joi-browser';

export const ExhibitionBasicInformationSchema = {
   Name: Joi.string()
      .required()
      .label('Naziv izložbe')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   NameEn: Joi.string()
      .required()
      .label('Naziv izložbe na engleskom')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   ExhibitionKindOther: Joi.string()
      .allow('')
      .optional()
      .label('Ostalo')
      .max(255)
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} - max 255 znakova`
         };
      })
};
