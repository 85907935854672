import React, { useEffect, useState } from 'react';
import { pageAnimation } from './page-transition-animation';
import { authorAdd_StepsAnimation } from './animations/author-add-steps';
import { Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setToastAutoCloseInterval } from '../store/app-setup';
import LoadingSpinner from '../components/common/loading-spinner';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { useHistory } from 'react-router-dom';
import ExhibitionBasicInformation from '../components/exhibition/exhibition-basic-information';
import {
   clearExhibitionkData,
   loadExhibitionStaticData,
   setExhibitionEditAfterVerificationMode,
   setExhibitionEditMode
} from './../store/exhibition';
import ExhibitionRestInformation from '../components/exhibition/exhibition-rest-information';
import ConfirmCreateExhibition from '../components/exhibition/confirm-create-exhibition';
import {
   createExhibition,
   updateVerifiedExhibition,
   updateExhibition
} from './../services/api';
import service from '../services/created-exhibitions-page';
import { setConfirmData } from './../store/common';
import {
   exhibitionSuccess,
   exhibitionSuccessAfterEdit,
   exhibitionError,
   exhibitionErrorAfterEdit
} from './../services/confirm';
import { ucitavamDodatnePodatke } from '../services/toast-messages';

const ExhibitionAddPage = (props) => {
   const { Step } = Steps;

   const [current, setCurrent] = useState(0);
   const [dataLoaded, setDataLoaded] = useState(false);

   const {
      exhibitionData: exhibitionEditData,
      isEditMode,
      isEditAfterVerification,
      redirectToErrorPage
   } = useSelector((state) => state.exhibition);

   let basicInformationData = {};
   let restInformationData = {};

   if (exhibitionEditData) {
      basicInformationData = exhibitionEditData.basicInformation;
      restInformationData = exhibitionEditData.restInformation;
   }

   const [basicInformation, setBasicInformation] =
      useState(basicInformationData);
   const [restInformation, setRestInformation] = useState(restInformationData);

   const [initRender, setInitRender] = useState(true);

   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      return () => {
         dispatch(clearExhibitionkData());
         dispatch(setExhibitionEditMode(false));
         dispatch(setExhibitionEditAfterVerificationMode(false));
         toast.dismiss();
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (redirectToErrorPage)
         history.push(`${process.env.PUBLIC_URL}/error-page`);
      //window.location = `${process.env.PUBLIC_URL}/error-page`;
   }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

   const state = useSelector((state) => state.exhibition);

   useEffect(() => {
      document.title = 'Dodavanje izložbe';
      if (!service.isStaticDataLoaded(state)) {
         dispatch(setToastAutoCloseInterval(15000));
         toast(ucitavamDodatnePodatke);

         dispatch(loadExhibitionStaticData()).then(() => {
            toast.dismiss();
            setDataLoaded(true);
            dispatch(setToastAutoCloseInterval(3000));
         });
      } else {
         setDataLoaded(true);
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleBasicInformationSave = (data) => {
      setBasicInformation(data);
      // setCurrent(1);
   };

   const handleRestInformationSave = (data) => {
      setRestInformation(data);
   };

   const allDataIsValid = () => {
      return basicInformation.isValid && restInformation.isValid;
   };

   const handleExhibitionCreate = (data) => {
      setRestInformation(data);
      setCurrent(2);
   };

   const handleCancelExhibitionCreate = () => {
      setCurrent(0);
   };

   const handleOnCreateExhibitionBtnClick = () => {
      sendDataToServer();
   };

   const sendDataToServer = async () => {
      const exhibition = {
         ...basicInformation,
         ...restInformation
      };

      if (isEditMode) exhibition.ExhibitionId = exhibitionEditData.ExhibitionId;

      if (!allDataIsValid()) {
         toast.error('Nisu ispunjene sve informacije o izložbi!');
         return;
      }

      dispatch(setToastAutoCloseInterval(500));
      toast.info('Preusmjeravanje...');

      try {
         if (isEditMode) {
            const { data: response } = isEditAfterVerification
               ? await updateVerifiedExhibition(exhibition)
               : await updateExhibition(exhibition);
            handleAfterServerResponse(response);
         } else {
            const { data: response } = await createExhibition(exhibition);
            handleAfterServerResponse(response);
         }
      } catch (error) {
         console.log('error', error);
         dispatch(setToastAutoCloseInterval(5000));
         toast.error(`${error.data.Message} - ${error.data.ExceptionMessage}`, {
            onClose: () => {
               history.push(`${process.env.PUBLIC_URL}/exhibitions`);
            }
         });
      }
   };

   const handleAfterServerResponse = (response) => {
      if (response.status === 1) {
         if (isEditMode) dispatch(setConfirmData(exhibitionSuccessAfterEdit));
         else dispatch(setConfirmData(exhibitionSuccess));
      } else {
         if (isEditMode) {
            exhibitionErrorAfterEdit.subtitle = response.message;
            dispatch(setConfirmData(exhibitionErrorAfterEdit));
         } else {
            exhibitionError.subtitle = response.message;
            dispatch(setConfirmData(exhibitionError));
         }
      }
      history.push(`${process.env.PUBLIC_URL}/confirm`);
   };

   //stepIndex je index komponente koja je trenutno prikazana, na kojoj se kliknulo dalje>
   const handleOnNextClick = (stepIndex) => {
      //markiraj slijedeći korak kao kliknut
      if (!clickedSteps.includes(stepIndex + 1))
         setClickedSteps([...clickedSteps, stepIndex + 1]);

      setInitRender(false);
      setCurrent(stepIndex + 1);
   };

   //  Polje gdje pratim koji koraci su do sada bili kliknuti, inicijalno je prikazan prvi korak, zato index 0 u polju
   const [clickedSteps, setClickedSteps] = useState([0]);
   const onChange = (current) => {
      //markiraj kliknuti korak kao posjećen
      if (!clickedSteps.includes(current))
         setClickedSteps([...clickedSteps, current]);

      setInitRender(false);
      setCurrent(current);
   };

   /*
    Svaka komponenta vraca svojstvi [isValid] koje je istina ukolko su se ispunila obavezna polja
    Komponente gdje to nije napravljeno se markiraju kao nedovršene

    Inicijalni prikaz prikazuje sve komponente (osim prve, inicijalno prikazane) u stanju 'wait'
    Jednom kada se na neki korak klikne on je u stanju 'process'
    Kada se sa koraka makne, ako su obavezni podaci ispunjeni stanje ce biti 'finish' u protivnom 'error'
*/

   const isValidStepIndex = (stepIndex) => {
      let isValid = false;
      switch (stepIndex) {
         case 0:
            isValid = basicInformation.isValid;
            break;
         case 1:
            isValid = restInformation.isValid;
            break;
         default:
            console.log('ERROR - isValidStepIndex(stepIndex)');
            break;
      }
      return isValid;
   };

   const getInitRenderStepStatus = (stepIndex) => {
      if (initRender && stepIndex === 0) return 'process';
      else return 'wait';
   };

   const getCurrentStepStatus = (stepIndex, currentStep) => {
      if (initRender) return getInitRenderStepStatus(stepIndex);

      if (!clickedSteps.includes(stepIndex)) return 'wait';

      return currentStep === stepIndex
         ? 'process'
         : isValidStepIndex(stepIndex)
         ? 'finish'
         : 'error';
   };

   return (
      <>
         {!dataLoaded && <LoadingSpinner text='' />}
         {dataLoaded && (
            <MotionContainer
               className='exhibition-add-page-container'
               animationObject={pageAnimation}
            >
               <div className='exhibition-add-page-content'>
                  <div className='author-group-add-page'>
                     <MotionContainer
                        className='exhibition-add-page-steps'
                        animationObject={authorAdd_StepsAnimation}
                     >
                        <Steps current={current} onChange={onChange}>
                           <Step
                              title='Osnovni podaci o izložbi'
                              description='Autori, Naziv, Vrijeme i mjesto nastanka...'
                              status={getCurrentStepStatus(0, current)}
                           />
                           <Step
                              title='Spremi podatke'
                              description='Url, napomena...'
                              status={getCurrentStepStatus(1, current)}
                           />
                        </Steps>
                     </MotionContainer>
                  </div>
                  <div>
                     {current === 0 && (
                        <ExhibitionBasicInformation
                           data={basicInformation}
                           onSubmit={handleBasicInformationSave}
                           onNextClick={() => {
                              handleOnNextClick(current);
                           }}
                        />
                     )}
                     {current === 1 && (
                        <ExhibitionRestInformation
                           data={restInformation}
                           onSubmit={handleRestInformationSave}
                           onCreateExhibition={handleExhibitionCreate}
                        />
                     )}
                     {current === 2 && (
                        <ConfirmCreateExhibition
                           allDataIsValid={allDataIsValid()}
                           data={basicInformation}
                           onCreate={handleOnCreateExhibitionBtnClick}
                           onCancelCreate={handleCancelExhibitionCreate}
                        />
                     )}
                  </div>
               </div>
            </MotionContainer>
         )}
      </>
   );
};

export default ExhibitionAddPage;
