import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const HomepageLink = (props) => {
    return (
        <div className='homepage-link'>
            <NavLink to={`${process.env.PUBLIC_URL}/homepage`}>
                <FontAwesomeIcon icon={faHome} />
            </NavLink>
        </div>
    );
};

export default HomepageLink;
