import React, { useEffect } from 'react';
import { pageAnimation } from './page-transition-animation';
import { registerHeaderAnimation } from './animations/register-page';
import Register from '../components/register/register';
import MotionContainer from '../components/common/motion-containers/motion-container';

const RegisterPage = (props) => {
    useEffect(() => {
        document.title = 'Registracija novog korisnika';
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MotionContainer
            className='register-page'
            animationObject={pageAnimation}
        >
            <div className='register-page-content'>
                <MotionContainer animationObject={registerHeaderAnimation}>
                    <h1>
                        Registracija <span>novog korisnika</span>
                    </h1>
                </MotionContainer>
                <Register />
            </div>
        </MotionContainer>
    );
};

export default RegisterPage;
