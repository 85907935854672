import React from 'react';
import SelectAnt from './select-ant';

/*
    attributes - objekt s ostalim atributima za Antd Select komponentu {...attributes}
    željeni value na DDL se postavlja na text vrijednost

    selectedOption={getSelectedOptionText()}
    DDL vežem uz odabranu vrijednost(value), međutim da selektiram odgovarajuču
    opciju trebam podesiti text value opcije
*/
const AntDDL = ({
   allowClear = false,
   label,
   options,
   onChange,
   attributes,
   hideLabel = false,
   requiredLabel,
   text = 'text',
   value = 'value',
   selectedOption = null,
   showLoadingIcon = true,
   placeholder = 'Započnite unos ili odaberite opciju...',
   notFound = 'Ne pronalazim traženu vrijednost...',
   disabled = false
}) => {
   const getSelectedOptionText = () => {
      // eslint-disable-line
      // eslint-disable-next-line
      const found = options.find((o) => o[value] == selectedOption);
      return found ? found[text] : null;
   };

   /**
    * ako je kliknuto brisanje polja vrijednosti su prazni stringovi
    */
   const handleOnChange = (value, obj) => {
      onChange({
         text: value ?? '',
         value: obj ? obj.id ?? '' : ''
      });
   };

   return (
      <SelectAnt
         label={label}
         allowClear={allowClear}
         text={text}
         value={value}
         placeholder={placeholder}
         notFoundContent={notFound}
         selectOptions={options}
         onChange={handleOnChange}
         onSearch={null}
         showLoadingIcon={showLoadingIcon}
         hideLabel={hideLabel}
         attributes={attributes}
         requiredLabel={requiredLabel}
         selectedOption={getSelectedOptionText()}
         disabled={disabled}
      />
   );
};

export default AntDDL;
