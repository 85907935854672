import React from 'react';
import Form from '../common/form';
import MotionContainer from '../common/motion-containers/motion-container';
import html from '../../services/html';
import { connect } from 'react-redux';
import { userIsAdmin } from '../../services/auth';
import ArtworkAuthor from './artwork-author';
import { loadArtworkStaticData, loadCities } from '../../store/artwork';
import { ArtworkBasicInformationSchema } from './../../validators/artwork-basic-information';
import AddCity from '../common/add-city';
import ArtworkAward from './artwork-award';
import ArtworkBibliography from './artwork-bibliography';
import ArtworkExhibition from './artwork-exhibition';
import ArtworkCreationYearsComponent from './artwork-creation-years';
import ArtworkCollection from './artwork-collection';
import ButtonNext from './../common/btn-next';
import DDL from './../common/ant-ddl';

class ArtworkBasicInformation extends Form {
   constructor(props) {
      super(props);

      const {
         ArtworkAuthors,
         Name,
         ArtworkCreationYears,
         ArtworkCreationCountryId,
         ArtworkCreationCityId,
         countrySelected,
         Dimensions,
         Techniques,
         OwnershipTypeId,
         Description,
         ArtistStatement,
         ArtworkAwards,
         ArtworkBibliographies,
         ArtworkExhibitions,
         ArtworkCollections
      } = props.data;

      this.ArtworkAuthors = ArtworkAuthors;
      this.Name = Name;
      this.ArtworkCreationYears = ArtworkCreationYears;
      this.ArtworkCreationCountryId = ArtworkCreationCountryId;
      this.ArtworkCreationCityId = ArtworkCreationCityId;
      this.countrySelected = countrySelected;
      this.dimensions = Dimensions;
      this.Techniques = Techniques;
      this.OwnershipTypeId = OwnershipTypeId;
      this.Description = Description;
      this.ArtistStatement = ArtistStatement;
      this.ArtworkAwards = ArtworkAwards;
      this.ArtworkBibliographies = ArtworkBibliographies;
      this.ArtworkExhibitions = ArtworkExhibitions;
      this.ArtworkCollections = ArtworkCollections;

      this.state = {
         validationData: {
            Name: this.Name ?? '',
            Dimensions: this.dimensions ?? '',
            Description: this.Description ?? '',
            ArtistStatement: this.ArtistStatement ?? '',
            Techniques: this.Techniques ?? ''
         },
         restData: {
            ArtworkAuthors: this.ArtworkAuthors ?? [],
            ArtworkCreationYears: this.ArtworkCreationYears ?? [],
            ArtworkCreationCountryId: this.ArtworkCreationCountryId ?? null,
            ArtworkCreationCityId: this.ArtworkCreationCityId ?? null,
            OwnershipTypeId: this.OwnershipTypeId ?? 0,
            ArtworkAwards: this.ArtworkAwards ?? [],
            ArtworkBibliographies: this.ArtworkBibliographies ?? [],
            ArtworkExhibitions: this.ArtworkExhibitions ?? [],
            ArtworkCollections: this.ArtworkCollections ?? []
         },
         countrySelected: this.countrySelected ?? false,
         errors: {}
      };
   }

   isValid = false;

   schema = ArtworkBasicInformationSchema;

   async componentDidMount() {
      this.props.loadArtworkStaticData();
   }

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const { Name } = this.state.validationData;

      const {
         ArtworkAuthors,
         ArtworkCreationYears,
         ArtworkCreationCountryId: countryId,
         ArtworkCreationCityId,
         OwnershipTypeId,
         ArtworkAwards,
         ArtworkBibliographies,
         ArtworkExhibitions,
         ArtworkCollections
      } = this.state.restData;

      const { countries, ownershipType } = this.props;

      const { countrySelected } = this.state;

      const nijeUnioAutore = ArtworkAuthors.length === 0;
      const nijeUnioNaziv = Name.trim().length === 0;
      const nijeUnioGodine = ArtworkCreationYears.length === 0;

      //const disable = this.validateForm();
      this.isValid =
         !nijeUnioAutore &&
         !nijeUnioNaziv &&
         !nijeUnioGodine &&
         OwnershipTypeId !== 0;

      const { isEditAfterVerification } = this.props.artworkState;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      const ownershipType_defaultValue = OwnershipTypeId
         ? ownershipType.length > 0
            ? {
                 value: html.getOwnershipTypeById(
                    OwnershipTypeId,
                    ownershipType
                 ),
                 disabled: isUserEditAfterVerification
              }
            : null
         : null;

      return (
         <>
            <MotionContainer className='author-professional-activity'>
               <form
                  onSubmit={this.handleFormSubmit}
                  className='form-style-width'
               >
                  <h2>Osnovni podaci o umjetničkom djelu</h2>
                  <div className='row'>
                     <div className='col-6'>
                        <ArtworkAuthor
                           onAddArtworkAuthor={this.handleArtworkAuthorAdded}
                           defaultValue={ArtworkAuthors}
                        />
                        <hr />

                        {this.renderFormInput({
                           label: 'Naziv umjetničkog djela',
                           name: 'Name',
                           required: true
                        })}

                        <ArtworkCreationYearsComponent
                           onYearsSelect={this.handleCreationYearsAdded}
                           defaultValue={ArtworkCreationYears}
                           disabled={false}
                        />

                        <DDL
                           label='Država nastanka'
                           text='Name'
                           value='CountryId'
                           options={countries}
                           onChange={this.handleCountrySelected}
                           selectedOption={countryId}
                        />

                        {countrySelected && (
                           <AddCity
                              label='Grad nastanka'
                              countryId={countryId}
                              onCitySelect={this.handleCitySelected}
                              required={false}
                              defaultCityId={ArtworkCreationCityId}
                           />
                        )}

                        {this.renderFormInput({
                           label: 'Tehnike',
                           name: 'Techniques',
                           required: true
                        })}

                        <hr />

                        {this.renderFormInput({
                           label: 'Dimenzije/trajanje',
                           name: 'Dimensions'
                        })}

                        <DDL
                           label='Vlasništvo'
                           text='Name'
                           value='OwnershipTypeId'
                           options={ownershipType}
                           onChange={this.handleOwnershipTypeSelected}
                           selectedOption={OwnershipTypeId}
                           requiredLabel={true}
                        />

                        {OwnershipTypeId === 1 && (
                           <ArtworkCollection
                              onAdd={this.handleArtworkCollectionAdded}
                              defaultValue={ArtworkCollections}
                           />
                        )}
                     </div>
                     <div className='col-6'>
                        <ArtworkExhibition
                           onAddArtworkExhibition={
                              this.handleArtworkExhibitionAdded
                           }
                           defaultValue={ArtworkExhibitions}
                           disabled={isUserEditAfterVerification}
                        />
                        <hr />
                        <ArtworkAward
                           onAddArtworkAward={this.handleAddArtworkAward}
                           defaultValue={ArtworkAwards}
                           disabled={isUserEditAfterVerification}
                        />
                        <hr />
                        <ArtworkBibliography
                           onAddAuthorBibliography={
                              this.handleAddAuthorBibliography
                           }
                           defaultValue={ArtworkBibliographies}
                           disabled={isUserEditAfterVerification}
                        />
                        <hr />

                        {this.renderFormInput({
                           label: 'Opis',
                           name: 'Description'
                        })}

                        {this.renderFormInput({
                           label: 'Izjava umjetnika/ce',
                           name: 'ArtistStatement'
                        })}
                     </div>
                  </div>
                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(2);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const restData = { ...this.state.restData };
      const validationData = { ...this.state.validationData };
      const data = { ...restData, ...validationData };
      data.countrySelected = this.state.countrySelected;
      data.isValid = this.isValid;
      this.props.onSubmit(data);
   };

   handleAddArtworkAward = (ArtworkAwards) => {
      const restData = { ...this.state.restData };
      restData.ArtworkAwards = ArtworkAwards;
      this.setState({ restData });
   };

   handleAddAuthorBibliography = (ArtworkBibliographies) => {
      const restData = { ...this.state.restData };
      restData.ArtworkBibliographies = ArtworkBibliographies;
      this.setState({ restData });
   };

   handleArtworkAuthorAdded = (artworkAuthors) => {
      const restData = { ...this.state.restData };
      restData.ArtworkAuthors = artworkAuthors;
      this.setState({ restData });
   };

   handleArtworkExhibitionAdded = (artworkExhibitions) => {
      const restData = { ...this.state.restData };
      restData.ArtworkExhibitions = artworkExhibitions;
      this.setState({ restData });
   };

   handleArtworkCollectionAdded = (artworkCollection) => {
      const restData = { ...this.state.restData };
      restData.ArtworkCollections = artworkCollection;
      this.setState({ restData });
   };

   handleCreationYearsAdded = (ArtworkCreationYears) => {
      const restData = { ...this.state.restData };
      restData.ArtworkCreationYears = ArtworkCreationYears;
      this.setState({ restData });
   };

   handleOwnershipTypeSelected = ({ value: OwnershipTypeId }) => {
      const restData = { ...this.state.restData };
      restData.OwnershipTypeId = OwnershipTypeId;
      this.setState({ restData });
   };

   handleYearSelected = (e) => {
      const { restData } = this.state;
      restData.Year = e.format('YYYY');
      this.setState({ restData });
   };

   handleCountrySelected = async ({ value: ArtworkCreationCountryId }) => {
      const restData = { ...this.state.restData };
      restData.ArtworkCreationCountryId = ArtworkCreationCountryId;

      this.setState({
         restData,
         countrySelected: true
      });
   };

   handleCitySelected = (value, obj) => {
      const { id: ArtworkCreationCityId } = obj;

      const restData = { ...this.state.restData };
      restData.ArtworkCreationCityId = ArtworkCreationCityId;
      this.setState({ restData });
   };
}
const mapStateToProps = (state) => ({
   commonState: state.common,
   authorState: state.author,
   artworkState: state.artwork,
   authors: state.common.authors,
   countries: state.common.countries,
   artworkTechnique: state.artwork.artworkTechnique,
   ownershipType: state.artwork.ownershipType,
   isEditAfterVerification: state.artwork.isEditAfterVerification
});

const mapDispatchToProps = (dispatch) => ({
   loadArtworkStaticData: () => dispatch(loadArtworkStaticData()),
   loadCities: (countryId) => dispatch(loadCities(countryId))
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ArtworkBasicInformation);
