import Joi from 'joi-browser';

export const AddNewArtworkExhibitionSchema = {
    ExhibitionName: Joi.string()
        .required()
        .label('Izložba')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
