//provjeri jesu li na store učitani svi podaci
export const isStaticDataLoaded = (state) => {
    const {
        authorAreaOfInterests,
        jobfunctions,
        legalEntities,
        authorAssociations,
        authorBibliographies,
        specializationTypes,
        galleries,
        educationLevels,
        authorAcademicStatuses
    } = state;

    return (
        authorAreaOfInterests.length > 0 &&
        jobfunctions.length > 0 &&
        legalEntities.length > 0 &&
        authorAssociations.length > 0 &&
        authorBibliographies.length > 0 &&
        specializationTypes.length > 0 &&
        galleries.length > 0 &&
        educationLevels.length > 0 &&
        authorAcademicStatuses.length > 0
    );
};

const exportObj = {
    isStaticDataLoaded
};

export default exportObj;
