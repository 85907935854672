import React from 'react';
import Form from '../../common/form';
import Afilijacija from '../../common/afilijacija';
import AuthorThesisComponent from './author-thesis';
import AuthorEducationLevel from './author-education-level';
import AuthorSpecializationType from './author-specialization-type';
import AuthorStatus from './author-status';
import { AuthorEducationInformationSchema } from '../../../validators/author-education-information';
import MotionContainer from '../../common/motion-containers/motion-container';
import { connect } from 'react-redux';
import { userIsAdmin } from './../../../services/auth';
import AuthorScolarship from './author-scolarship';
import ButtonNext from './../../common/btn-next';

class AuthorEducationInformation extends Form {
   constructor(props) {
      super(props);

      const {
         ScientificRegisterCode,
         IsAffiliated,
         AffiliationLegalEntityId,
         AuthorAcademicStatuses,
         AuthorEducationLevels,
         AuthorThesis,
         AuthorSpecializationTypes,
         AuthorScholarships,
         statusIndices
      } = props.data;

      this.ScientificRegisterCode = ScientificRegisterCode;
      this.IsAffiliated = IsAffiliated;
      this.AffiliationLegalEntityId = AffiliationLegalEntityId;
      this.AuthorAcademicStatuses = AuthorAcademicStatuses;
      this.AuthorEducationLevels = AuthorEducationLevels;
      this.AuthorThesis = AuthorThesis;
      this.AuthorSpecializationTypes = AuthorSpecializationTypes;
      this.AuthorScholarships = AuthorScholarships;
      this.statusIndices = statusIndices;

      this.state = {
         validationData: {
            ScientificRegisterCode: this.ScientificRegisterCode ?? ''
         },
         restData: {
            IsAffiliated: this.IsAffiliated ?? false,
            AffiliationLegalEntityId: this.AffiliationLegalEntityId ?? null,
            AuthorAcademicStatuses: this.AuthorAcademicStatuses ?? [],
            AuthorEducationLevels: this.AuthorEducationLevels ?? [],
            AuthorThesis: this.AuthorThesis ?? [],
            AuthorSpecializationTypes: this.AuthorSpecializationTypes ?? [],
            AuthorScholarships: this.AuthorScholarships ?? []
         },
         statusIndices: this.statusIndices ?? [],
         errors: {},
         showAuthorEducationLevel: false,
         showAuthorThesis: false,
         showAuthorSpecializationType: false
      };
   }

   schema = AuthorEducationInformationSchema;

   componentWillUnmount() {
      this.doSubmit();
   }

   isValid = false;

   render() {
      const {
         IsAffiliated,
         AffiliationLegalEntityId,
         AuthorEducationLevels,
         AuthorThesis,
         AuthorSpecializationTypes,
         AuthorScholarships
      } = this.state.restData;

      const { isEditAfterVerification } = this.props.authorState;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      const { statusIndices } = this.state;

      const nijeOdabraoAfilijaciju =
         IsAffiliated && AffiliationLegalEntityId === 0;
      const nijeStudentskiStatus =
         statusIndices.length > 0 && !statusIndices.includes(4); //4-student
      const nijeOdabranStatus = statusIndices.length === 0;
      const nijeOdabranoObrazovanje = AuthorEducationLevels.length === 0;
      const nijeUnioDiplomskiRad =
         nijeStudentskiStatus && AuthorThesis.length === 0;

      this.isValid = !(
         nijeOdabraoAfilijaciju ||
         nijeOdabranStatus ||
         nijeOdabranoObrazovanje ||
         nijeUnioDiplomskiRad
      );

      const alilijacija_defaultValue = {
         IsAffiliated,
         AffiliationLegalEntityId
      };

      return (
         <>
            <MotionContainer className='author-education-information'>
               <form className='form-style'>
                  <h2>Podaci o obrazovanju</h2>
                  <Afilijacija
                     disabled={isUserEditAfterVerification}
                     defaultValue={alilijacija_defaultValue}
                     onAfilijacijaSelected={this.handleAfilijacijaSelected}
                     required={true}
                  />
                  <hr />
                  <AuthorStatus
                     disabled={isUserEditAfterVerification}
                     defaultValue={statusIndices}
                     onAuthorStatusCheck={this.handleAcademicStatusChecked}
                  />
                  <hr />
                  {nijeStudentskiStatus && (
                     <>
                        <AuthorThesisComponent
                           defaultValue={AuthorThesis}
                           onAddAuthorThesis={this.handleAuthorThesisAdded}
                        />
                        <hr />
                     </>
                  )}

                  <AuthorEducationLevel
                     defaultValue={AuthorEducationLevels}
                     onAddEducationLevel={this.handleAuthorEducationLevelAdded}
                  />
                  <hr />

                  <AuthorScolarship
                     defaultValue={AuthorScholarships}
                     onAddSpecialization={this.handleScolarshipAdded}
                  />
                  <hr />

                  <AuthorSpecializationType
                     defaultValue={AuthorSpecializationTypes}
                     onAddSpecialization={this.handleSpecializationAdded}
                  />
                  <hr />

                  {this.renderFormInput({
                     label: 'Upisnik znanstvenika u RH',
                     name: 'ScientificRegisterCode'
                  })}
                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(2);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const { validationData } = this.state;
      const { restData } = this.state;
      const data = { ...validationData, ...restData };
      data.isValid = this.isValid;

      const { statusIndices } = this.state;
      data.statusIndices = statusIndices;

      this.props.onSubmit(data);
   };

   handleAfilijacijaSelected = (isAffiliated, AffiliationLegalEntityId) => {
      const restData = { ...this.state.restData };
      restData.IsAffiliated = isAffiliated;
      restData.AffiliationLegalEntityId = AffiliationLegalEntityId;
      this.setState({ restData });
   };

   handleAcademicStatusChecked = (statusIndices) => {
      const restData = { ...this.state.restData };
      restData.AuthorAcademicStatuses = statusIndices.map((s) => ({
         AcademicStatusId: s
      }));
      this.setState({ restData, statusIndices: [...statusIndices] });
   };

   handleAuthorEducationLevelAdded = (educationLevels) => {
      console.log('educationLevels', educationLevels);
      const restData = { ...this.state.restData };
      restData.AuthorEducationLevels = educationLevels;
      this.setState({ restData });
   };

   handleAuthorThesisAdded = (authorThesis) => {
      const restData = { ...this.state.restData };
      restData.AuthorThesis = authorThesis;
      this.setState({ restData });
   };

   handleSpecializationAdded = (authorSpecializations) => {
      const restData = { ...this.state.restData };
      restData.AuthorSpecializationTypes = authorSpecializations;
      this.setState({ restData });
   };

   handleScolarshipAdded = (authorScolarships) => {
      const restData = { ...this.state.restData };
      restData.AuthorScholarships = authorScolarships;
      this.setState({ restData });
   };
}
const mapStateToProps = (state) => ({
   authorState: state.author
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorEducationInformation);
