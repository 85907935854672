import React from 'react';
import Form from '../../common/form';
import AddLegalEntity from '../../common/add-legal-entity';
import { v4 as uuid } from 'uuid';
import PopupContainer from '../../common/popup-container/popup-container';
import { AddNewEducationLevelSchema } from './../../../validators/add-new-aducation-level';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

/*
    props
        educationLevelId
        closeAddEducationLevel()
        onEducationLevelAdded()
*/

class AddNewEducationLevel extends Form {
   state = {
      validationData: {
         Thesis: ''
      },
      restData: {
         AuthorEducationLevelId: null,
         EducationLevelId: 0,
         DateFrom: '',
         DateTo: '',
         LegalEntityId: 0,
         LegalEntityExposedId: null
      },
      selectedEducationLevel: this.props.selectedEducationLevel,
      errors: []
   };

   schema = AddNewEducationLevelSchema;

   async componentDidMount() {
      const { restData, selectedEducationLevel } = this.state;
      restData.EducationLevelId = selectedEducationLevel.EducationLevelId;
      this.setState({
         restData
      });
   }

   render() {
      const { Thesis } = this.state.validationData;
      const { DateFrom, LegalEntityId } = this.state.restData;

      const disabled = DateFrom === '' || LegalEntityId === 0;

      const { closeAddEducationLevel } = this.props;
      const { selectedEducationLevel } = this.state;
      return (
         <PopupContainer
            title={selectedEducationLevel.Name}
            onPopupClose={closeAddEducationLevel}
         >
            <div className='row'>
               <div className='col'>
                  {this.renderDatePicker('Datum od', this.handleDateFrom)}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Datum do', this.handleDateTo)}
               </div>
            </div>

            <AddLegalEntity
               onLegalEntitySelect={this.handleLegalEntitySelected}
            />

            {this.renderTextArea('Thesis', 'Završni/diplomski rad', {
               value: Thesis
            })}

            <AddLegalEntity
               label='Institucija u kojoj je rad izložen'
               onLegalEntitySelect={this.handleLegalEntityExposedSelected}
            />

            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     this.props.closeAddEducationLevel();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleOnSubmit = () => {
      const data = { ...this.state.restData, ...this.state.validationData };
      //dodatne informacije za lakše renderiranje
      data.selectedEducationLevel = this.state.selectedEducationLevel;
      data.id = uuid();
      this.props.onEducationLevelAdded(data);
      this.props.closeAddEducationLevel();
   };

   handleLegalEntitySelected = (value, obj) => {
      const { id: LegalEntityId, value: LegalEntityName } = obj;

      const selectedEducationLevel = { ...this.state.selectedEducationLevel };
      selectedEducationLevel.LegalEntityName = LegalEntityName;
      const { restData } = this.state;
      restData.LegalEntityId = LegalEntityId;
      this.setState({ restData, selectedEducationLevel });
   };

   handleLegalEntityExposedSelected = (value, obj) => {
      const { id: LegalEntityExposedId, value: LegalEntityName } = obj;

      const restData = { ...this.state.restData };
      restData.LegalEntityExposedId = LegalEntityExposedId;
      this.setState({ restData });
   };

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddEducationLevel();
   };

   handleDateFrom = (e) => {
      const { restData } = this.state;
      restData.DateFrom = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };

   handleDateTo = (e) => {
      const { restData } = this.state;
      restData.DateTo = e.format('YYYY-MM-DD');
      this.setState({ restData });
   };
}
export default AddNewEducationLevel;
