import React, { useState } from 'react';
import { MorphIcon } from 'react-svg-buttons';

const MorphButton = ({ type, rolloverType, size, thickness, color }) => {
    const [btnType, setBtnType] = useState(type);
    return (
        <div style={{ cursor: 'pointer' }}>
            <MorphIcon
                onMouseEnter={() => {
                    setBtnType(rolloverType);
                }}
                onMouseLeave={() => {
                    setBtnType(type);
                }}
                type={btnType}
                size={size}
                thickness={thickness}
                color={color}
            />
        </div>
    );
};

export default MorphButton;
