import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import { useSelector, useDispatch } from 'react-redux';
import AddNewArtworkExhibition from './add-new-artwork-exhibition';
import { loadExhibitionStaticData } from './../../store/exhibition';
import ButtonForm from './../common/btn-form';

/*
    props
        onAddArtworkExhibition()
*/

const ArtworkExhibition = ({
   onAddArtworkExhibition,
   defaultValue,
   disabled
}) => {
   const [showAddArtworkExhibition, setShowAddArtworkExhibition] =
      useState(false);
   const [kolekcija, setKolekcija] = useState([]);
   const { exhibitions } = useSelector((state) => state.exhibition);

   const dispatch = useDispatch();

   useEffect(() => {
      setKolekcija(defaultValue);
      dispatch(loadExhibitionStaticData());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddArtworkExhibition(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewArtworkExhibition = () => {
      setShowAddArtworkExhibition(!showAddArtworkExhibition);
   };

   const getExhibitionName = (exhibitionId) => {
      const findExhibition = exhibitions.find(
         (a) => a.ExhibitionId === exhibitionId
      );
      return `${findExhibition.Name}`;
   };

   const handle_OnArtworkExhibitionAdded = (artworkExhibitionData) => {
      console.log('artworkExhibitionData', artworkExhibitionData);
      const exhibitionName = isNaN(artworkExhibitionData)
         ? artworkExhibitionData
         : getExhibitionName(artworkExhibitionData);

      const newExhibition = {
         id: isNaN(artworkExhibitionData) ? uuid() : artworkExhibitionData,
         ExhibitionName: exhibitionName
      };

      setKolekcija([...kolekcija, newExhibition]);
   };

   const handle_RemoveFromArtworkExhibitionList = (id) => {
      setKolekcija(kolekcija.filter((aa) => aa.id !== id));
   };

   return (
      <>
         {showAddArtworkExhibition && (
            <AddNewArtworkExhibition
               onArtworkExibitionAdded={handle_OnArtworkExhibitionAdded}
               closeAddNewArtworkExhibition={showHide_AddNewArtworkExhibition}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               <span className='mr-1'>Izložbe</span>
            </label>
            <ButtonForm
               text='Odaberi/unesi izložbe na kojima je umjetničko djelo izlagano'
               onClick={showHide_AddNewArtworkExhibition}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih izložbi
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Izložba</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((exhibition) => (
                           <tr key={uuid()}>
                              {html.td(230, exhibition.ExhibitionName)}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromArtworkExhibitionList,
                                 exhibition.id,
                                 {
                                    title: `Ukloni ${exhibition.ExhibitionName}`
                                 },
                                 disabled
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ArtworkExhibition;
