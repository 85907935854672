import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const LinkUnderline = (props) => {
    const { path, subpaths } = props;
    const { pathname } = useLocation();

    const allPaths = [
        path,
        ...subpaths.map((sub) => `${process.env.PUBLIC_URL}${sub}`)
    ];

    return (
        <UnderlineLink
            className='link-underline'
            transition={{ duration: 0.5 }}
            initial={{ width: '0%' }}
            animate={{
                width: allPaths.includes(pathname) ? '100%' : '0%'
            }}
        ></UnderlineLink>
    );
};

const UnderlineLink = styled(motion.div)`
    height: 0.2rem;
    background-color: #a390dc;
    width: 0%;
    position: absolute;
    bottom: -0.25rem;
    left: 0;
`;

export default LinkUnderline;
