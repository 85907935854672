import _api from '../config/api-config.json';
import jwtDecode from 'jwt-decode';
import http from './http';
const api = _api.api;

export function registerNewUser(user) {
    const url = `${api}${_api.register}`;
    return http.post(url, user);
}

export function getEntities(searchTerm) {
    const url = `${api}${_api.legalentity}`;
    return http.post(url, { searchTerm });
}

export function loginUser(user) {
    const url = `${api}${_api.token}`;
    return http.post(url, user);
}

export function getCurrentUser() {
    try {
        //izvuci username iz JWT-a
        const jwt = localStorage.getItem('token');
        const roleId = localStorage.getItem('roleId');
        const user = jwtDecode(jwt);
        return { username: user.unique_name, roleId };
    } catch (ex) {
        return null;
    }
}

export function userIsAdmin() {
    const roleId = parseInt(localStorage.getItem('roleId'));
    return roleId === 2;
}
