import React from 'react';
import Form from '../common/form';
import { v4 as uuid } from 'uuid';
import AddCity from '../common/add-city';
import PopupContainer from '../common/popup-container/popup-container';
import { AddNewExhibitionCatalogueSchema } from '../../validators/add-new-exhibition-catalogue';
import { connect } from 'react-redux';
import ButtonAdd from './../common/btn-add';
import ButtonCancel from './../common/btn-cancel';

class AddNewExhibitionCatalogue extends Form {
   state = {
      validationData: {
         Authors: '',
         Name: '',
         Publisher: '',
         Editors: ''
      },
      restData: {
         ExhibitionCatalogueId: null,
         Year: '',
         CountryId: null,
         CityId: null,
         CityName: ''
      },
      legalEntitySelected: {},
      errors: []
   };

   schema = AddNewExhibitionCatalogueSchema;

   render() {
      const { Authors, Name, Publisher, Editors } = this.state.validationData;
      const { Year, CountryId, CityId } = this.state.restData;

      const { closeAddNewCatalogue } = this.props;
      const { countries } = this.props.commonState;

      const disabled =
         Authors === '' ||
         Name === '' ||
         Publisher === '' ||
         Editors === '' ||
         Year === '' ||
         CityId === null;

      return (
         <PopupContainer
            title='Dodavanje kataloga'
            onPopupClose={closeAddNewCatalogue}
         >
            <div className='row'>
               <div className='col'>{this.renderInput('Name', 'Naslov')}</div>
               <div className='col'>{this.renderInput('Authors', 'Autor')}</div>
            </div>
            <div className='row'>
               <div className='col'>
                  {this.renderInput('Publisher', 'Izdavač')}
               </div>
               <div className='col'>
                  {this.renderDatePicker('Godina', this.handleYearSelected, {
                     picker: 'year',
                     placeholder: 'Odaberite godinu...',
                     format: 'YYYY'
                  })}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {this.renderInput('Editors', 'Urednik/ci')}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {this.renderSelectAnt(
                     'Država',
                     'Name',
                     'CountryId',
                     'Započnite unos ili odaberite državu...',
                     'Ne pronalazim traženu državu...',
                     countries,
                     this.handleCountrySelected
                  )}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {CountryId && (
                     <AddCity
                        countryId={CountryId}
                        onCitySelect={this.handleCitySelected}
                     />
                  )}
               </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewCatalogue();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewCatalogue();
   };

   handleOnSubmit = () => {
      const { onCatalogueAdded, closeAddNewCatalogue } = this.props;
      const newCatalogue = {
         ...this.state.validationData,
         ...this.state.restData
      };
      newCatalogue.id = uuid();
      console.log(newCatalogue);
      onCatalogueAdded(newCatalogue);
      closeAddNewCatalogue();
   };

   handleCountrySelected = async (value, obj) => {
      const { id: CountryId } = obj;

      const restData = { ...this.state.restData };
      restData.CountryId = CountryId;

      this.setState({
         restData
      });
   };

   handleYearSelected = (e) => {
      if (!e) return;
      const { restData } = this.state;
      restData.Year = Number(e.format('YYYY'));
      this.setState({ restData });
   };

   handleCitySelected = (value, obj) => {
      const { id: CityId, value: CityName } = obj;

      const restData = { ...this.state.restData };
      restData.CityId = CityId;
      restData.CityName = CityName;

      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   commonState: state.common
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddNewExhibitionCatalogue);
