import React from 'react';
import { authorPersonalDataSchema } from '../../../validators/author-personal-data';
import Form from '../../common/form';
import { getCities } from '../../../services/api';
import AddCity from '../../common/add-city';
import moment from 'moment';
import { connect } from 'react-redux';
import MotionContainer from '../../common/motion-containers/motion-container';
import html from '../../../services/html';
import { userIsAdmin } from './../../../services/auth';
import ButtonNext from '../../common/btn-next';
import DDL from './../../common/ant-ddl';
import AntDatepicker from './../../common/ant-datepicker';
class AuthorPersonalInformation extends Form {
   constructor(props) {
      super(props);

      const {
         FirstName,
         LastName,
         BirthDate,
         BirthCountryId,
         BirthCityName,
         BirthCityId,
         countrySelected,
         SexId
      } = props.data;

      this.FirstName = FirstName;
      this.LastName = LastName;
      this.BirthDate = BirthDate;
      this.BirthCountryId = BirthCountryId;
      this.BirthCityName = BirthCityName;
      this.BirthCityId = BirthCityId;
      this.countrySelected = countrySelected;
      this.SexId = SexId;

      this.state = {
         validationData: {
            FirstName: this.FirstName ?? '',
            LastName: this.LastName ?? ''
         },
         restData: {
            BirthDate: this.BirthDate ?? '',
            BirthCountryId: this.BirthCountryId ?? '',
            BirthCityId: this.BirthCityId ?? 0,
            BirthCityName: this.BirthCityName ?? '',
            SexId: this.SexId ?? 4
         },
         errors: {},
         countrySelected: this.countrySelected ? this.countrySelected : '',
         cities: [],
         sex: [],
         addCityYourself: false,
         dateValidationMessage: ''
      };
   }

   schema = authorPersonalDataSchema;

   componentWillUnmount() {
      this.doSubmit();
   }

   render() {
      const { countrySelected, dateValidationMessage } = this.state;
      const { isEditAfterVerification } = this.props.authorState;
      const { countries, sex } = this.props.commonState;
      const {
         BirthCountryId: countryId,
         BirthDate,
         SexId,
         BirthCityId
      } = this.state.restData;

      const isUserEditAfterVerification =
         isEditAfterVerification && !userIsAdmin();

      return (
         <>
            <MotionContainer className='author-personal-data'>
               <form onSubmit={this.handleFormSubmit} className='form-style'>
                  <h2>Osobni podaci</h2>

                  {this.renderFormInput({
                     label: 'Ime',
                     name: 'FirstName',
                     required: true
                  })}

                  {this.renderFormInput({
                     label: 'Prezime',
                     name: 'LastName',
                     required: true,
                     attributes: { disabled: isUserEditAfterVerification }
                  })}

                  <AntDatepicker
                     label='Datum rođenja'
                     requiredLabel={true}
                     defaultDate={BirthDate ? moment(BirthDate) : null}
                     onChange={this.handleDatePickerChange}
                     validationMessage={dateValidationMessage}
                  />

                  <DDL
                     label='Rod'
                     text='Name'
                     value='SexId'
                     options={sex}
                     onChange={this.handleSex}
                     selectedOption={SexId}
                     requiredLabel={true}
                     disabled={isUserEditAfterVerification}
                  />

                  <DDL
                     label='Država rođenja'
                     text='Name'
                     value='CountryId'
                     options={countries}
                     onChange={this.handleCountrySelected}
                     selectedOption={countryId}
                     requiredLabel={true}
                  />

                  {countrySelected && (
                     <AddCity
                        label='Grad rođenja'
                        countryId={countryId}
                        onCitySelect={this.handleCitySelected}
                        required={true}
                        defaultCityId={BirthCityId}
                     />
                  )}
                  <hr />
                  <ButtonNext
                     onClick={() => {
                        this.props.onNextClick(0);
                     }}
                  />
               </form>
            </MotionContainer>
         </>
      );
   }

   doSubmit = () => {
      const { BirthDate, SexId } = this.state.restData;
      const {
         validationData,
         restData,
         countrySelected,
         dateValidationMessage
      } = this.state;
      const data = { ...validationData, ...restData };
      data.isValid =
         !this.validateForm() &&
         BirthDate !== '' &&
         dateValidationMessage === '' &&
         SexId !== 0;
      data.countrySelected = countrySelected;

      this.props.onSubmit(data);
   };

   handleDatePickerChange = (e) => {
      if (!e) return;
      const { restData } = this.state;
      // console.log(e.year());
      if (e.year() < 1753) {
         restData.BirthDate = null;
         this.setState({
            restData,
            dateValidationMessage: 'Godina rođenja ne može biti manja od 1753'
         });
      } else {
         this.setState({
            dateValidationMessage: ''
         });
         restData.BirthDate = e.format('YYYY-MM-DD');
         this.setState({ restData });
      }
   };

   handleCountrySelected = async ({ value: BirthCountryId }) => {
      const { data } = await getCities(BirthCountryId);

      const restData = { ...this.state.restData };
      restData.BirthCountryId = BirthCountryId;

      this.setState({
         restData,
         cities: data.data,
         countrySelected: true
      });
   };

   handleCitySelected = (value, obj) => {
      const { id: BirthCityId, value: BirthCityName } = obj;

      const restData = { ...this.state.restData };
      restData.BirthCityId = BirthCityId;
      restData.BirthCityName = BirthCityName;
      this.setState({ restData, citySelected: true });
   };

   handleSex = ({ value }) => {
      const restData = { ...this.state.restData };
      restData.SexId = value;

      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   commonState: state.common,
   authorState: state.author,
   personalInformation: state.author.personalInformation
});

const mapDispatchToProps = (dispatch) => ({
   // add: (article) => dispatch(addArticle(article)),
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorPersonalInformation);
