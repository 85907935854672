import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import html from '../../../services/html';
import AddScholarship from './add-scholarship';
import ButtonForm from './../../common/btn-form';

const AuthorScolarship = ({ onAddSpecialization, defaultValue }) => {
   const [kolekcija, setKolekcija] = useState([]);
   const [showAdd, setShowAdd] = useState(false);

   const { legalEntities } = useSelector((state) => state.author);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddSpecialization(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide = () => {
      setShowAdd(!showAdd);
   };

   const handleRemoveFromKolekcija = (id) => {
      setKolekcija(kolekcija.filter((spec) => spec.id !== id));
   };

   const handleAddToKolekcija = (data) => {
      setKolekcija([...kolekcija, data]);
   };

   return (
      <>
         {showAdd && (
            <AddScholarship onClose={showHide} onAdd={handleAddToKolekcija} />
         )}
         <div className='row'>
            <div className='col'>
               <ButtonForm text='Dodaj stipendiju' onClick={showHide} />
            </div>
         </div>
         {kolekcija.length > 0 && (
            <div className='list-container'>
               <table className='table table-sm table-striped'>
                  <thead className='thead-dark'>
                     <tr>
                        <th>Ustanova</th>
                        <th>Od</th>
                        <th>Do</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     {kolekcija.map((as) => (
                        <tr key={uuid()}>
                           {html.td(
                              280,
                              legalEntities.length > 0
                                 ? legalEntities.find(
                                      (le) =>
                                         le.LegalEntityId === as.LegalEntityId
                                   ).Name
                                 : ''
                           )}
                           {html.td(
                              60,
                              as.DateFrom.indexOf('T') > 0
                                 ? html.convert_T_Date_ToLocal(as.DateFrom)
                                 : html.convertDateToLocal(as.DateFrom)
                           )}
                           {html.td(
                              60,
                              as.DateTo
                                 ? as.DateTo.indexOf('T') > 0
                                    ? html.convert_T_Date_ToLocal(as.DateTo)
                                    : html.convertDateToLocal(as.DateTo)
                                 : '-'
                           )}

                           {html.tdWithRemoveBtn(
                              handleRemoveFromKolekcija,
                              as.id,
                              {
                                 title: `Ukloni ${
                                    legalEntities.length > 0
                                       ? legalEntities.find(
                                            (le) =>
                                               le.LegalEntityId ===
                                               as.LegalEntityId
                                         ).Name
                                       : ''
                                 } sa liste`
                              }
                           )}
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         )}
      </>
   );
};

export default AuthorScolarship;
