import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import { useSelector } from 'react-redux';
import AddNewExhibitionArtwork from './add-new-exhibition-artwork';
import ButtonForm from './../common/btn-form';
/*
    props
        onAddExhibitionArtwork()
*/

const ExhibitionArtwork = ({
   onAddExhibitionArtwork,
   defaultValue,
   disabled
}) => {
   const [showAddExhibitionArtwork, setShowAddExhibitionArtwork] =
      useState(false);
   const [kolekcija, setKolekcija] = useState([]);
   const { artworks } = useSelector((state) => state.exhibition);

   // const dispatch = useDispatch();

   useEffect(() => {
      setKolekcija(defaultValue);
      //dispatch(loadExhibitionStaticData());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddExhibitionArtwork(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewExhibitionArtwork = () => {
      setShowAddExhibitionArtwork(!showAddExhibitionArtwork);
   };

   const getExhibitionArtworkName = (artworkId) => {
      const exArtwork = artworks.find((a) => a.ArtworkId === artworkId);
      return `${exArtwork.Name}`;
   };

   const handle_OnExhibitionArtworkAdded = (exhibitionArtworkData) => {
      //ako je rucno uneseno ime
      if (isNaN(exhibitionArtworkData)) {
         setKolekcija([
            ...kolekcija,
            {
               id: uuid(),
               Naziv: exhibitionArtworkData,
               ArtworkName: exhibitionArtworkData
            }
         ]);
      }
      //ako je proslijeđen ID umjetničkog djela
      if (!isNaN(exhibitionArtworkData)) {
         setKolekcija([
            ...kolekcija,
            {
               id: uuid(),
               Naziv: getExhibitionArtworkName(exhibitionArtworkData),
               ArtworkId: exhibitionArtworkData
            }
         ]);
      }
   };

   const handle_RemoveFromExhibitionArtworkList = (id) => {
      setKolekcija(kolekcija.filter((aa) => aa.id !== id));
   };

   return (
      <>
         {showAddExhibitionArtwork && (
            <AddNewExhibitionArtwork
               onExibitionArtworkAdded={handle_OnExhibitionArtworkAdded}
               closeAddNewExhibitionArtwork={showHide_AddNewExhibitionArtwork}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               <span className='mr-1'>Izloženo djelo/a</span>
            </label>
            <ButtonForm
               text='Dodaj izloženo djelo'
               onClick={showHide_AddNewExhibitionArtwork}
               disabled={disabled}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih djela...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Izložba</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((artwork) => (
                           <tr key={uuid()}>
                              {html.td(230, artwork.Naziv)}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromExhibitionArtworkList,
                                 artwork.id,
                                 {
                                    title: `Ukloni ${artwork.Naziv}`
                                 },
                                 disabled
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ExhibitionArtwork;
