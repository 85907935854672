import Joi from 'joi-browser';

export const authorThesisSchema = {
    Title: Joi.string()
        .required()
        .label('Naziv rada')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan`
            };
        }),
    Mentor: Joi.string()
        .required()
        .label('Mentor')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan`
            };
        })
};

//bla: Joi.any().optional(),
