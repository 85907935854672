import Joi from 'joi-browser';

export const AddNewExhibitionCatalogueSchema = {
   Authors: Joi.string()
      .required()
      .label('Autor')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Name: Joi.string()
      .required()
      .label('Nagrada')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Publisher: Joi.string()
      .required()
      .label('Izdavač')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      }),
   Editors: Joi.string()
      .required()
      .label('Urednik/ci')
      .error((errors) => {
         return {
            message: `${errors[0].flags.label} je obavezan unos`
         };
      })
};
