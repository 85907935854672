import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import html from '../../../services/html';
import { loadJobFunctions, loadLegalEnteties } from '../../../store/author';
import AddNewAuthorWorkExperience from './add-new-author-work-experience';
import ButtonForm from './../../common/btn-form';

const AuthorWorkExperience = ({ onAddWorkExperience, defaultValue }) => {
   const [showAddWorkExperience, setShowAddWorkExperience] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   const { jobfunctions, legalEntities } = useSelector((state) => state.author);
   const dispatch = useDispatch();

   useEffect(() => {
      setKolekcija(defaultValue);
      // dispatch(loadLegalEnteties());
      dispatch(loadJobFunctions());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddWorkExperience(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewWorkExperience = () => {
      setShowAddWorkExperience(!showAddWorkExperience);
   };

   const handle_OnWorkExperianceAdded = (authorWorkExperienceData) => {
      setKolekcija([...kolekcija, authorWorkExperienceData]);
   };

   const handleRemoveFromWorkList = (id) => {
      setKolekcija(kolekcija.filter((work) => work.id !== id));
   };

   return (
      <>
         {showAddWorkExperience && (
            <AddNewAuthorWorkExperience
               onWorkExperienceAdded={handle_OnWorkExperianceAdded}
               closeAddNewWorkExperience={showHide_AddNewWorkExperience}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               Radno iskustvo
            </label>
            <ButtonForm
               text='Dodaj novo radno iskustvo'
               onClick={showHide_AddNewWorkExperience}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih radnih iskustava...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Institucija</th>
                           <th>Radno iskustvo</th>
                           <th>Od</th>
                           <th>Do</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((work) => (
                           <tr key={uuid()}>
                              {html.td(
                                 100,
                                 html.getLegalEntityNameById(
                                    work.LegalEntityId,
                                    legalEntities
                                 )
                              )}
                              {html.td(
                                 100,
                                 html.getJobfunctionNameById(
                                    work.JobFunctionId,
                                    jobfunctions
                                 )
                              )}
                              {html.td(
                                 60,
                                 work.DateFrom.indexOf('T') > 0
                                    ? html.convert_T_Date_ToLocal(work.DateFrom)
                                    : html.convertDateToLocal(work.DateFrom)
                              )}
                              {html.td(
                                 60,
                                 work.DateTo
                                    ? work.DateTo.indexOf('T') > 0
                                       ? html.convert_T_Date_ToLocal(
                                            work.DateTo
                                         )
                                       : html.convertDateToLocal(work.DateTo)
                                    : '-'
                              )}

                              {html.tdWithRemoveBtn(
                                 handleRemoveFromWorkList,
                                 work.id,
                                 {
                                    title: `Ukloni sa liste ${html.getLegalEntityNameById(
                                       work.LegalEntityId,
                                       legalEntities
                                    )}`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default AuthorWorkExperience;
