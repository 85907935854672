import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import { useSelector } from 'react-redux';
import AddNewExhibitionAuthor from './add-new-exhibition-author';
import ButtonForm from './../common/btn-form';

const ExhibitionAuthor = ({ onAddExhibitionAuthor, defaultValue }) => {
   const [showAddExhibitionAuthor, setShowAddExhibitionAuthor] =
      useState(false);
   const [kolekcija, setKolekcija] = useState([]);
   const { authors } = useSelector((state) => state.common);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddExhibitionAuthor(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewExhibitionAuthor = () => {
      setShowAddExhibitionAuthor(!showAddExhibitionAuthor);
   };

   const getAuthorName = (authorId) => {
      const findAuthor = authors.find((a) => a.AuthorId === authorId);
      return `${findAuthor.Name}`;
   };

   const handle_OnExhibitionAuthorAdded = (exhibitionAuthorData) => {
      const authorName =
         exhibitionAuthorData.AuthorId === null
            ? exhibitionAuthorData.AuthorName
            : getAuthorName(exhibitionAuthorData.AuthorId);

      const newAuthor = {
         id: isNaN(exhibitionAuthorData) ? uuid() : exhibitionAuthorData,
         AuthorId: exhibitionAuthorData.AuthorId,
         AuthorName: exhibitionAuthorData.AuthorId ? '' : authorName,
         Name: authorName
      };

      setKolekcija([...kolekcija, newAuthor]);
   };

   const handle_RemoveFromExhibitionAuthorList = (id) => {
      setKolekcija(kolekcija.filter((ea) => ea.id !== id));
   };

   return (
      <>
         {showAddExhibitionAuthor && (
            <AddNewExhibitionAuthor
               onArtworkAuthorAdded={handle_OnExhibitionAuthorAdded}
               closeAddNewArtworkAuthor={showHide_AddNewExhibitionAuthor}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               <span className='mr-1'>Autori</span>
               {html.renderRequiredTooltip()}
            </label>
            <ButtonForm
               text='Odaberi/unesi autora izložbe'
               onClick={showHide_AddNewExhibitionAuthor}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih autora...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Autor</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((author) => (
                           <tr key={uuid()}>
                              {html.td(230, author.Name)}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromExhibitionAuthorList,
                                 author.id,
                                 {
                                    title: `Ukloni ${author.Name}`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ExhibitionAuthor;
