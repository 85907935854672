import React, { useState } from 'react';
import { createOrUpdateCity } from '../../services/api';
import { toast } from 'react-toastify';

import PopupContainer from './popup-container/popup-container';
import ButtonAdd from './btn-add';
import ButtonCancel from './btn-cancel';

const AddNewCity = (props) => {
   const [Name, setName] = useState('');
   const [error, setError] = useState(null);

   const handleOnInputChange = ({ target: input }) => {
      setName(input.value.trim());
      if (input.value.trim() === '') setError('Naziv grada je obavezan');
      else setError('');
   };

   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };

   const renderInput = (name, error) => (
      <div className='form-group'>
         <label style={style}>Naziv grada</label>
         <input
            autoFocus
            name={name}
            id={name}
            className='form-control form-control-sm'
            onChange={handleOnInputChange}
         />
         {error && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {error}
            </div>
         )}
      </div>
   );

   const addNewCity = async (city) => {
      const { countryId: CountryId, closeAddNewCity } = props;
      const newCity = {
         Name,
         CountryId
      };
      const response = await createOrUpdateCity(newCity);
      const { status, message } = response.data;

      if (status === 1) {
         toast.info('Grad uspješno dodan');
         closeAddNewCity();
      } else toast.error(`${message}`);
      console.log('response', response);
   };

   const handleOnSubmit = async (e) => {
      e.stopPropagation();
      addNewCity();
   };

   const disabled = error || error === null;

   return (
      <PopupContainer
         title='Dodavanje novog grada'
         onPopupClose={props.closeAddNewCity}
      >
         {renderInput('Name', error)}
         <hr />

         <ButtonAdd
            title='Dodaj grad'
            disabled={disabled}
            onClick={handleOnSubmit}
         />
         <ButtonCancel
            onClick={() => {
               props.closeAddNewCity();
            }}
         />
      </PopupContainer>
   );
};

export default AddNewCity;
