import {
    getAuthorList,
    getCountries,
    getPublicArtworkDetails,
    getPublicAuthorDetails,
    getPublicExhibitionDetails,
    getSex
} from '../services/api';

const initState = {
    confirmData: {},
    sex: [],
    countries: [],
    authors: [],
    authorDetails: null,
    artworkDetails: null,
    exhibitionDetails: null,
    redirectToErrorPage: false,
    errorMessage: ''
};

export const setConfirmData = (confirmData) => ({
    type: 'SET_CONFIRM_DATA',
    payload: {
        confirmData
    }
});

export const clearConfirmData = () => ({
    type: 'CLEAR_CONFIRM_DATA'
});

export const redirectToErrorPage = (redirectToErrorPage) => ({
    type: 'REDIRECT_TO_ERROR_PAGE',
    payload: {
        redirectToErrorPage
    }
});

export const saveErrorMessage = (errorMessage) => ({
    type: 'SAVE_ERROR_MESSAGE',
    payload: {
        errorMessage
    }
});

export const clearAuthorDetails = () => ({
    type: 'CLEAR_AUTHOR_DETAILS'
});

export const clearArtworkDetails = () => ({
    type: 'CLEAR_ARTWORK_DETAILS'
});

export const clearExhibitionDetails = () => ({
    type: 'CLEAR_EXHIBITION_DETAILS'
});

export const loadAuthorDetails = (authorId) => async (dispatch, getState) => {
    try {
        dispatch(saveErrorMessage(''));
        dispatch(redirectToErrorPage(false));
        const { data: authorDetails } = await getPublicAuthorDetails(authorId);
        if (authorDetails.status === 0) {
            dispatch(saveErrorMessage(authorDetails.message));
            dispatch(redirectToErrorPage(true));
            return;
        }
        if (!authorDetails.status) {
            dispatch(saveErrorMessage('An error has occurred.'));
            dispatch(redirectToErrorPage(true));
            return;
        }

        const payload = {
            authorDetails: authorDetails.details
        };
        await dispatch({ type: 'LOAD_AUTHOR_DETAILS', payload });
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log('LOAD_AUTHOR_DETAILS', ex.response?.data);
        }
    }
};

export const loadArtworkDetails = (artworkId) => async (dispatch, getState) => {
    try {
        dispatch(redirectToErrorPage(false));
        dispatch(saveErrorMessage(''));
        const { data: artworkDetails } = await getPublicArtworkDetails(
            artworkId
        );
        if (artworkDetails.status === 0) {
            dispatch(saveErrorMessage(artworkDetails.message));
            dispatch(redirectToErrorPage(true));
        }

        const payload = {
            artworkDetails: artworkDetails.details
        };

        await dispatch({ type: 'LOAD_ARTWORK_DETAILS', payload });
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log('LOAD_ARTWORK_DETAILS', ex.response?.data);
        }
    }
};

export const loadExhibitionDetails =
    (exhibitionId) => async (dispatch, getState) => {
        try {
            dispatch(redirectToErrorPage(false));
            dispatch(saveErrorMessage(''));
            const { data: exhibitionDetails } =
                await getPublicExhibitionDetails(exhibitionId);
            if (exhibitionDetails.status === 0) {
                dispatch(saveErrorMessage(exhibitionDetails.message));
                dispatch(redirectToErrorPage(true));
            }

            const payload = {
                exhibitionDetails: exhibitionDetails.details
            };
            await dispatch({ type: 'LOAD_EXHIBITION_DETAILS', payload });
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                console.log('LOAD_EXHIBITION_DETAILS', ex.response?.data);
            }
        }
    };

export const loadCommonStaticData = () => async (dispatch, getState) => {
    try {
        const { data: sex } = await getSex();
        const { data: countries } = await getCountries();
        const { data: authors } = await getAuthorList();
        const payload = {
            sex: sex.data,
            countries: countries.data,
            authors: authors.data
        };

        await dispatch({ type: 'LOAD_COMMON_STATIC_DATA', payload });
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log('LOAD_COMMON_STATIC_DATA', ex.response?.data);
        }
    }
};

export const refreshAuthorsList = () => async (dispatch, getState) => {
    try {
        const { data: authors } = await getAuthorList();
        const payload = {
            authors: authors.data
        };

        await dispatch({ type: 'REFRESH_AUTHORS_LIST', payload });
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            console.log('REFRESH_AUTHORS_LIST', ex.response?.data);
        }
    }
};

//  REDUCER
export default function reducer(state = initState, action) {
    switch (action.type) {
        case 'SET_CONFIRM_DATA':
            const { confirmData } = action.payload;
            return {
                ...state,
                confirmData
            };
        case 'CLEAR_CONFIRM_DATA':
            return {
                ...state,
                confirmData: {}
            };
        case 'REDIRECT_TO_ERROR_PAGE':
            const { redirectToErrorPage } = action.payload;
            return {
                ...state,
                redirectToErrorPage
            };
        case 'SAVE_ERROR_MESSAGE':
            const { errorMessage } = action.payload;
            return {
                ...state,
                errorMessage
            };
        case 'CLEAR_AUTHOR_DETAILS':
            return {
                ...state,
                authorDetails: null
            };
        case 'CLEAR_ARTWORK_DETAILS':
            return {
                ...state,
                artworkDetails: null
            };
        case 'CLEAR_EXHIBITION_DETAILS':
            return {
                ...state,
                exhibitionDetails: null
            };
        case 'LOAD_AUTHOR_DETAILS':
            const { authorDetails } = action.payload;
            // if (!authorDetails) {
            //     localStorage.removeItem('authorDetails');
            //     return { ...state, authorDetails: null };
            // }

            // localStorage.setObject('authorDetails', authorDetails);
            return {
                ...state,
                authorDetails
            };
        case 'LOAD_ARTWORK_DETAILS':
            const { artworkDetails } = action.payload;
            // if (!artworkDetails) {
            //     localStorage.removeItem('artworkDetails');
            //     return { ...state, artworkDetails: null };
            // }
            //localStorage.setObject('artworkDetails', artworkDetails);
            return {
                ...state,
                artworkDetails
            };
        case 'LOAD_EXHIBITION_DETAILS':
            const { exhibitionDetails } = action.payload;
            // if (!exhibitionDetails) {
            //     localStorage.removeItem('exhibitionDetails');
            //     return { ...state, exhibitionDetails: null };
            // }

            // localStorage.setObject('exhibitionDetails', exhibitionDetails);
            return {
                ...state,
                exhibitionDetails
            };
        case 'REFRESH_AUTHORS_LIST':
            const { authors: _authors } = action.payload;
            return {
                ...state,
                authors: _authors
            };
        case 'LOAD_COMMON_STATIC_DATA':
            const { sex, countries, authors } = action.payload;
            return {
                ...state,
                sex,
                countries,
                authors
            };
        default:
            return state;
    }
}
