import React, { useState } from 'react';
import { createOrUpdateJobFunction } from '../../../services/api';
import { toast } from 'react-toastify';
import PopupContainer from '../../common/popup-container/popup-container';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

/*
    onExitClick()
*/

const AddNewJobFunction = (props) => {
   const [Name, setName] = useState('');
   const [error, setError] = useState(null);

   const handleOnInputChange = ({ target: input }) => {
      setName(input.value.trim());
      if (input.value.trim() === '') setError('Radno mjesto je obavezan unos');
      else setError('');
   };

   const style = {
      marginBottom: '2px',
      fontWeight: 700,
      fontSize: '.9rem'
   };

   const renderInput = (name, error, rest) => (
      <div className='form-group'>
         <label style={style}>Radno mjesto</label>
         <input
            autoComplete='off'
            autoFocus
            name={name}
            maxlength='200'
            {...rest}
            id={name}
            className='form-control form-control-sm'
            onChange={handleOnInputChange}
         />
         {error && (
            <div className='alert alert-primary' style={{ fontWeight: 700 }}>
               {error}
            </div>
         )}
      </div>
   );

   const addNewJobFunction = async () => {
      const { closeAddNewJobFunction } = props;
      const newJobFunction = {
         Name
      };
      const response = await createOrUpdateJobFunction(newJobFunction);
      const { status, message } = response.data;
      console.log(response);
      if (status === 1) {
         toast.info('Radno mjesto uspješno dodano');
         closeAddNewJobFunction();
      } else toast.error(`${message}`);
      console.log('response', response);
   };

   const handleOnSubmit = async (e) => {
      e.stopPropagation();
      addNewJobFunction();
   };

   const disabled = error || error === null;

   const { closeAddNewJobFunction } = props;
   return (
      <PopupContainer
         title='Dodavanje radnog mjesta'
         onPopupClose={closeAddNewJobFunction}
      >
         {renderInput('Name', error)}
         <hr />

         <ButtonAdd
            text='Dodaj radno mjesto'
            disabled={disabled}
            onClick={handleOnSubmit}
         />
         <ButtonCancel
            onClick={() => {
               closeAddNewJobFunction();
            }}
         />
      </PopupContainer>
   );
};

export default AddNewJobFunction;
