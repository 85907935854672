import React from 'react';
import Form from '../../common/form';
import { AuthorRestInformationSchema } from '../../../validators/author-rest-information';
import MotionContainer from '../../common/motion-containers/motion-container';
import { connect } from 'react-redux';
import ImageUpload from './../../common/image-upload';
import { Image } from 'antd';
import { userIsAdmin } from './../../../services/auth';
import ButtonSave from './../../common/btn-save';

class AuthorRestInformation extends Form {
   constructor(props) {
      super(props);

      const {
         Remark,
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         authorPhotographies,
         componentPhotographies
      } = props.data;

      this.Remark = Remark;
      this.PhotoFileContentBase64 = PhotoFileContentBase64;
      this.PhotoOriginalFileName = PhotoOriginalFileName;
      this.authorPhotographies = authorPhotographies;
      this.componentPhotographies = componentPhotographies;

      this.state = {
         validationData: {
            Remark: this.Remark ?? ''
         },
         PhotoFileContentBase64: this.PhotoFileContentBase64 ?? '',
         PhotoOriginalFileName: this.PhotoOriginalFileName ?? '',
         authorPhotographies: this.authorPhotographies ?? [],
         componentPhotographies: this.componentPhotographies ?? [],
         errors: {}
      };
   }

   schema = AuthorRestInformationSchema;

   componentDidMount() {
      const { data } = this.props;
      const {
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         Remark,
         authorPhotographies,
         componentPhotographies
      } = data;

      const validationData = { Remark };

      this.setState({
         validationData,
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         componentPhotographies,
         authorPhotographies
      });
   }

   componentWillUnmount() {
      this.doSubmit();
   }

   doSubmit = () => {
      const { Remark } = this.state.validationData;
      const {
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         componentPhotographies,
         authorPhotographies
      } = this.state;
      const data = {
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         componentPhotographies,
         authorPhotographies,
         Remark
      };
      data.isValid = true;
      this.props.onSubmit(data);
   };

   handleCreateOrUpdateAuthor = () => {
      const { onCreateAuthor } = this.props;
      const { Remark } = this.state.validationData;
      const { PhotoFileContentBase64, PhotoOriginalFileName } = this.state;
      const data = {
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         Remark
      };
      this.doSubmit();
      data.isValid = true;
      onCreateAuthor(data);
   };

   handleOnPhotoAdded = (photos, componentPhotographies) => {
      if (photos.length === 0) return;

      const PhotoFileContentBase64 = photos[0].PhotoFileContentBase64;
      const PhotoOriginalFileName = photos[0].PhotoOriginalFileName;
      this.setState({
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         componentPhotographies,
         authorPhotographies: photos
      });
   };

   handleOnPhotoRemove = () => {
      const PhotoFileContentBase64 = '';
      const PhotoOriginalFileName = '';
      this.setState({
         PhotoFileContentBase64,
         PhotoOriginalFileName,
         componentPhotographies: [],
         authorPhotographies: []
      });
   };

   render() {
      const { Remark } = this.state;
      const { isEditMode, isEditAfterVerification } = this.props.authorState;

      const btnClassBase = 'btn form-control btn-';
      const btnClass = isEditMode ? `edit-mode` : `primary-mode`;

      const btnText = isEditMode ? 'Ažuriraj podatke autora' : 'Kreiraj autora';

      const { componentPhotographies, authorPhotographies } = this.state;
      const img = !authorPhotographies
         ? null
         : authorPhotographies.length > 0
         ? authorPhotographies[0].PhotoFileContentBase64
         : null;
      const nakonVerifikacijeBezFotke = !img && isEditAfterVerification;

      const showSaveButton = !isEditAfterVerification || userIsAdmin();

      return (
         <>
            <MotionContainer className='form-style'>
               <h2>Ostali podaci</h2>
               {this.renderTextArea('Remark', 'Napomena', {
                  value: Remark
               })}
               <div>
                  {!nakonVerifikacijeBezFotke && (
                     <div className='mb-3'>
                        <hr />
                        <div className='label-style mr-1 mb-1'>
                           {isEditAfterVerification
                              ? 'Fotografije'
                              : 'Odaberite fotografije'}
                           {!isEditAfterVerification && (
                              <span
                                 style={{
                                    fontSize: '.6rem',
                                    display: 'inherit',
                                    opacity: '.5'
                                 }}
                              >
                                 Maksimalna dozvoljena veličina fotografije: 1MB
                              </span>
                           )}
                        </div>
                     </div>
                  )}
                  <div className='rest-pictures'>
                     {isEditAfterVerification && (
                        <div
                           style={{
                              margin: '.3rem',
                              display: 'inline-block',
                              cursor: 'pointer'
                           }}
                        >
                           {!nakonVerifikacijeBezFotke && (
                              <Image
                                 width={300}
                                 height={200}
                                 src={
                                    authorPhotographies[0]
                                       .PhotoFileContentBase64
                                 }
                              />
                           )}
                        </div>
                     )}
                     {!isEditAfterVerification && (
                        <ImageUpload
                           max={1}
                           onPhotoAdded={this.handleOnPhotoAdded}
                           defaultComponentValues={componentPhotographies}
                           defaultArtworkPhotographies={authorPhotographies}
                           onPhotoRemove={this.handleOnPhotoRemove}
                           isEditMode={this.props.authorState.isEditMode}
                        />
                     )}
                  </div>
               </div>
               <hr />

               {showSaveButton && (
                  <ButtonSave
                     text={btnText}
                     className={btnClass}
                     onClick={this.handleCreateOrUpdateAuthor}
                  />
               )}
            </MotionContainer>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   authorState: state.author
});

const mapDispatchToProps = (dispatch) => ({
   //refreshUserConfirmList: () => dispatch(loadListConfirmed())
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AuthorRestInformation);
