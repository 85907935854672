import Joi from 'joi-browser';

export const loginUserSchema = {
    Username: Joi.string()
        .email()
        .required()
        .label('Korisničko ime')
        .error((errors) => {
            if (errors[0].type === 'any.empty')
                return {
                    message: `${errors[0].flags.label} je obavezno`
                };
            if (errors[0].type === 'string.email')
                return {
                    message: `Neispravno ${errors[0].flags.label}`
                };
            return {
                message: `${errors[0].flags.label} - greška.`
            };
        }),
    Password: Joi.string()
        .required()
        .label('Lozinka')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezna`
            };
        })
};
