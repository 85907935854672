import React from 'react';

const ButtonSave = ({ text = 'Dodaj', className, onClick }) => {
   let classBase = 'button-save';
   return (
      <button
         className={`${classBase} ${className}`}
         onClick={onClick}
         type='button'
      >
         {text}
      </button>
   );
};

export default ButtonSave;
