import React from 'react';
import { Descriptions } from 'antd';
import { useHistory } from 'react-router-dom';

const ExhibitionDescription = ({ data }) => {
    const {
        ExhibitionId,
        Name,
        NameEn,
        ExhibitionTypeKindName,
        ExhibitionTypeQuantityName,
        InstitutionTypeName
    } = data;

    const history = useHistory();
    return (
        <div className='search-description'>
            <Descriptions
                title={
                    <button
                        type='button'
                        style={{ textDecoration: 'none' }}
                        className='btn btn-link description-tearm'
                        onClick={() => {
                            history.push(
                                `${process.env.PUBLIC_URL}/details-exhibition/${ExhibitionId}`
                            );
                        }}
                    >{`${Name} - ${NameEn}`}</button>
                }
                bordered
                size='small'
            >
                <Descriptions.Item label='Tip izložbe'>
                    {ExhibitionTypeKindName ? ExhibitionTypeKindName : '-'}
                </Descriptions.Item>
                <Descriptions.Item label='Vrsta izložbe'>
                    {ExhibitionTypeQuantityName
                        ? ExhibitionTypeQuantityName
                        : '-'}
                </Descriptions.Item>
                <Descriptions.Item label='Vrsta institucije'>
                    {InstitutionTypeName ? InstitutionTypeName : '-'}
                </Descriptions.Item>
            </Descriptions>
            <hr />
        </div>
    );
};

export default ExhibitionDescription;
