import React from 'react';
import { NavLink } from 'react-router-dom';

import LinkUnderline from './navbar-link-underline';

const NavbarLink = (props) => {
    const { text, path, subpaths = [] } = props;
    return (
        <>
            <NavLink to={path}>{text}</NavLink>
            <LinkUnderline path={path} subpaths={subpaths} />
        </>
    );
};

export default NavbarLink;
