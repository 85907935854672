import { useEffect } from 'react';

const Logout = (props) => {
    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('roleId');
        //window.location = `${process.env.PUBLIC_URL}?page=login`;
        //window.location = `${process.env.PUBLIC_URL}/#/login`;
        window.location = `/`;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
};

export default Logout;
