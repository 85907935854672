import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import html from '../../services/html';
import AddNewExhibitionMediaAnnouncement from './add-new-exhibition-media-announcement';
import parseHTML from 'html-react-parser';
import ButtonForm from './../common/btn-form';

const ExhibitionMediaAnnouncement = ({
   onAddExhibitionMedia,
   defaultValue
}) => {
   const [showAddMedia, setShowAddMedia] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   // const { cities } = useSelector((state) => state.author);

   useEffect(() => {
      setKolekcija(defaultValue);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAddExhibitionMedia(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNewCatalogue = () => {
      setShowAddMedia(!showAddMedia);
   };

   const handle_OnMediaAdded = (catalogueData) => {
      setKolekcija([...kolekcija, catalogueData]);
   };

   const handle_RemoveFromMediaList = (id) => {
      setKolekcija(kolekcija.filter((catalogue) => catalogue.id !== id));
   };

   return (
      <>
         {showAddMedia && (
            <AddNewExhibitionMediaAnnouncement
               onMediaAdded={handle_OnMediaAdded}
               closeAddNewMedia={showHide_AddNewCatalogue}
            />
         )}
         <div>
            <label style={{ fontSize: '.9rem', fontWeight: '700' }}>
               Medijske objave
            </label>
            <ButtonForm
               text='Dodaj objavu'
               onClick={showHide_AddNewCatalogue}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih objava...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Autor</th>
                           <th>Naziv</th>
                           {/* <th>Grad</th> */}
                           <th>Godina</th>
                           <th>Poveznica</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((catalogue) => (
                           <tr key={uuid()}>
                              {html.td(115, catalogue.Authors)}
                              {html.td(115, catalogue.Name)}
                              {/* {html.td(120, catalogue.CityName)} */}
                              {html.td(60, catalogue.Year)}
                              {html.td(
                                 60,
                                 parseHTML(
                                    `<a href='${catalogue.Url}' target='_blank'>${catalogue.Url}</a>`
                                 )
                              )}

                              {html.tdWithRemoveBtn(
                                 handle_RemoveFromMediaList,
                                 catalogue.id,
                                 {
                                    title: `Ukloni ${catalogue.Name}`
                                 }
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default ExhibitionMediaAnnouncement;
