import React from 'react';
import Form from '../../common/form';
import { v4 as uuid } from 'uuid';
import AddCity from '../../common/add-city';
import { AddNewAuthorAwardSchema } from '../../../validators/add-author-award';
import PopupContainer from '../../common/popup-container/popup-container';
import { connect } from 'react-redux';
import ButtonAdd from './../../common/btn-add';
import ButtonCancel from './../../common/btn-cancel';

/*
    props
        closeAddNewAward()
        onAwardAdded()
*/

class AddNewAuthorAward extends Form {
   state = {
      validationData: {
         Name: ''
      },
      restData: {
         AuthorAwardId: null,
         Year: '',
         CountryId: null,
         CityId: null,
         CityName: ''
      },
      legalEntitySelected: {},
      jobFunctionSelected: {},
      errors: []
   };

   schema = AddNewAuthorAwardSchema;

   render() {
      const { Name } = this.state.validationData;
      const { Year, CityId, CountryId } = this.state.restData;
      const { countries } = this.props.commonState;

      const { closeAddNewAward } = this.props;
      const disabled = Name === '' || Year === '' || CityId === null;

      return (
         <PopupContainer
            title='Dodavanje nagrade'
            onPopupClose={closeAddNewAward}
         >
            <div className='row'>
               <div className='col'>{this.renderInput('Name', 'Nagrada')}</div>
               <div className='col'>
                  {this.renderDatePicker('Godina', this.handleYearSelected, {
                     picker: 'year',
                     placeholder: 'Odaberite godinu...',
                     format: 'YYYY'
                  })}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {this.renderSelectAnt(
                     'Država',
                     'Name',
                     'CountryId',
                     'Započnite unos ili odaberite državu...',
                     'Ne pronalazim traženu državu...',
                     countries,
                     this.handleCountrySelected
                  )}
               </div>
            </div>
            <div className='row'>
               <div className='col'>
                  {CountryId && (
                     <AddCity
                        countryId={CountryId}
                        onCitySelect={this.handleCitySelected}
                     />
                  )}
               </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
               <ButtonAdd disabled={disabled} onClick={this.handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     closeAddNewAward();
                  }}
               />
            </div>
         </PopupContainer>
      );
   }

   handleExitClick = (e) => {
      e.stopPropagation();
      this.props.closeAddNewAward();
   };

   handleCountrySelected = async (value, obj) => {
      const { id: CountryId } = obj;

      const restData = { ...this.state.restData };
      restData.CountryId = CountryId;

      this.setState({
         restData
      });
   };

   handleOnSubmit = () => {
      const { onAwardAdded, closeAddNewAward } = this.props;

      const newAward = { ...this.state.restData };
      const { Name } = this.state.validationData;
      newAward.Name = Name;

      newAward.id = uuid();
      console.log(newAward);
      onAwardAdded(newAward);
      closeAddNewAward();
   };

   handleYearSelected = (e) => {
      const { restData } = this.state;
      restData.Year = e.format('YYYY');
      this.setState({ restData });
   };

   handleCitySelected = (value, obj) => {
      const { id: CityId, value: CityName } = obj;

      const restData = { ...this.state.restData };
      restData.CityId = CityId;
      restData.CityName = CityName;

      this.setState({ restData });
   };
}

const mapStateToProps = (state) => ({
   commonState: state.common
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAuthorAward);
