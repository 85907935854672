import React, { useEffect, useState } from 'react';
import html from '../../../services/html';

import AddNewAssociation from './add-new-association';
import { useDispatch, useSelector } from 'react-redux';
import { loadAuthorAssociations } from '../../../store/author';
import ButtonFormTooltip from './../../common/btn-form-tooltip';

const Association = ({ onAssociationSelect }) => {
   const [showAddNew, setShowAddNew] = useState(false);

   const { authorAssociations } = useSelector((state) => state.author);
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(loadAuthorAssociations());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleCloseAddNewAssociation = (e) => {
      dispatch(loadAuthorAssociations());
      setShowAddNew(false);
   };

   return (
      <>
         {showAddNew && (
            <AddNewAssociation onClose={handleCloseAddNewAssociation} />
         )}
         <div className='row'>
            <div className='col-8'>
               {html.renderSelectAnt(
                  'Udruga',
                  'Name',
                  'AssociationId',
                  'Započnite unos ili odaberite udrugu...',
                  'Ne pronalazim traženu udrugu...',
                  authorAssociations,
                  onAssociationSelect
               )}
            </div>
            <div className='col'>
               <ButtonFormTooltip
                  inline={true}
                  text='Dodaj udrugu'
                  onClick={(e) => {
                     e.stopPropagation();
                     setShowAddNew(true);
                  }}
                  tooltip='Ukoliko ne pronalazite udrugu među ponuđenim opcijama, dodajte ju sami.'
               />
            </div>
         </div>
      </>
   );
};

export default Association;
