import React, { useEffect } from 'react';
import { pageAnimation } from './page-transition-animation';
import MotionContainer from '../components/common/motion-containers/motion-container';
import ForgotPassword from '../components/forgot-password/forgot-password';
import { forgotPasswordAnimation } from './animations/forgot-password-animation';

const ForgotPasswordPage = (props) => {
    useEffect(() => {
        document.title = 'Zaboravljena lozinka';
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MotionContainer
            className='register-page'
            animationObject={pageAnimation}
        >
            <div className='register-page-content'>
                <MotionContainer animationObject={forgotPasswordAnimation}>
                    <h1>
                        Zaboravljena <span>lozinka</span>
                    </h1>
                </MotionContainer>
                <ForgotPassword />
            </div>
        </MotionContainer>
    );
};

export default ForgotPasswordPage;
