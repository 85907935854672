import React from 'react';
import Form from '../common/form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPasswordChangeSchema } from './forgot-password-change-validation';
import { sendNewPasswordAndGUID } from './../../services/api';
import { toast } from 'react-toastify';
import {
   hideToastProgressBar,
   setToastAutoCloseInterval
} from '../../store/app-setup';

class ForgotPasswordChange extends Form {
   state = {
      validationData: {
         NewPassword: ''
      },
      restData: {
         NewPasswordConfirm: ''
      },
      errors: {}
   };

   schema = forgotPasswordChangeSchema;

   componentDidMount() {
      const { data } = this.props.match.params;
   }

   render() {
      const { passwordsMatch } = this.state;
      const disable = !passwordsMatch;
      return (
         <>
            <div className='register'>
               <form onSubmit={this.handleFormSubmit}>
                  {this.renderInput('NewPassword', 'Nova lozinka', {
                     type: 'password'
                  })}
                  {this.renderInputRestData(
                     'NewPasswordConfirm',
                     'Ponovite lozinku',
                     ({ target: input }) => {
                        const { NewPassword: password } =
                           this.state.validationData;
                        const { errors } = this.state;

                        const restData = { ...this.state.restData };
                        restData[input.name] = input.value;
                        const passwordsMatch =
                           restData[input.name] === password;

                        if (!passwordsMatch)
                           errors[input.name] = 'Lozinke se moraju podudarati';
                        else delete errors[input.name];

                        this.setState({ restData, passwordsMatch });
                     },
                     {
                        type: 'password'
                     }
                  )}
                  <hr />
                  {this.renderSubmitButton('Spremi', disable)}
               </form>
            </div>
         </>
      );
   }

   doSubmit = async () => {
      const { data: guid } = this.props.match.params;
      const { NewPassword } = this.state.validationData;
      const { hideToastProgressBar, setToastAutoCloseInterval } = this.props;

      const { data: response } = await sendNewPasswordAndGUID(
         NewPassword,
         guid
      );

      const { status, message } = response;

      hideToastProgressBar(false);
      setToastAutoCloseInterval(1500);

      if (status === 1) {
         //disejblaj formu
         this.setState({ passwordsMatch: false });
         toast.info('Uspješno ste promijenili lozinku', {
            onClose: () => {
               this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            }
         });
      } else toast.error(message);
   };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
   hideToastProgressBar: (hide) => dispatch(hideToastProgressBar(hide)),
   setToastAutoCloseInterval: (interval) =>
      dispatch(setToastAutoCloseInterval(interval))
});

export default withRouter(
   connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordChange)
);
