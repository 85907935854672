import React, { useEffect, useState } from 'react';
import { getCountries } from '../../services/api';
import Openstreet from '../openstreet/openstreet';
import AddCity from './add-city';
import PopupContainer from './popup-container/popup-container';
import html from '../../services/html';
import ButtonAdd from './btn-add';
import ButtonCancel from './btn-cancel';

const AddAtelierAddress = ({ onAddAddress, onClose }) => {
   const [CountryId, setCountryId] = useState(0);
   const [countries, setCountries] = useState([]);

   const [countrySelected, setCountrySelected] = useState(false);
   const [citySelected, setCitySelected] = useState(false);

   const [AtelierCityName, setAtelierCityName] = useState('');

   const [AtelierAddress, setAtelierAddress] = useState('');
   const [errorAtelierAddress, setErrorAtelierAddress] = useState(null);

   const [AtelierGeoLatitude, setAtelierGeoLatitude] = useState('');
   const [errorAtelierGeoLatitude, setErrorAtelierGeoLatitude] = useState(null);

   const [AtelierGeoLongitude, setAtelierGeoLongitude] = useState('');
   const [errorAtelierGeoLongitude, setErrorAtelierGeoLongitude] =
      useState(null);

   const loadCountries = async () => {
      const { data: response } = await getCountries();
      setCountries([...response.data]);
   };

   useEffect(() => {
      loadCountries();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handleCountrySelected = async (value, obj) => {
      const { id } = obj;
      setCountryId(id);
      setCountrySelected(true);
   };

   const handleCitySelected = (value, obj) => {
      const { value: cityName } = obj;

      setAtelierCityName(cityName);
      setCitySelected(true);
   };

   const handleOpenstreetClick = ({ latlng, address }) => {
      setAtelierAddress(address.Address);
      setAtelierGeoLatitude(latlng.lat);
      setAtelierGeoLongitude(latlng.lng);
   };

   const handleOnAtelierAddressChange = ({ target: input }) => {
      setAtelierAddress(input.value);
      if (input.value.trim() === '')
         setErrorAtelierAddress('Adresa je obavezna');
      else setErrorAtelierAddress('');
   };

   const handleOnAtelierGeoLatitudeChange = ({ target: input }) => {
      setAtelierGeoLatitude(input.value.trim());
      if (input.value.trim() === '')
         setErrorAtelierGeoLatitude('Širina je obavezna');
      else setErrorAtelierGeoLatitude('');
   };

   const handleOnAtelierGeoLongitudeChange = ({ target: input }) => {
      setAtelierGeoLongitude(input.value.trim());
      if (input.value.trim() === '')
         setErrorAtelierGeoLongitude('Dužina je obavezna');
      else setErrorAtelierGeoLongitude('');
   };

   const handleOnSubmit = () => {
      onAddAddress({
         IsAtelier: true,
         AtelierAddress,
         AtelierGeoLongitude,
         AtelierGeoLatitude,
         AtelierCityName
      });
      onClose();
   };

   const disabled = AtelierAddress.trim() === '';

   return (
      <PopupContainer title='Unesite adresu ateljea' onPopupClose={onClose}>
         <div className='add-new-legal-entity-content'>
            {html.renderSelectAnt(
               'Država',
               'Name',
               'CountryId',
               'Započnite unos ili odaberite državu...',
               'Ne pronalazim traženu državu...',
               countries,
               handleCountrySelected
            )}

            <div className={countrySelected ? 'city showCity' : 'city'}>
               {countrySelected && (
                  <AddCity
                     countryId={CountryId}
                     onCitySelect={handleCitySelected}
                  />
               )}
            </div>

            <div className={citySelected ? 'address showAddress' : 'address'}>
               {html.renderSFCInput(
                  'Adresa',
                  'AtelierAddress',
                  errorAtelierAddress,
                  handleOnAtelierAddressChange,
                  {
                     value: AtelierAddress
                  }
               )}
               <div className='row'>
                  <div className='col'>
                     {html.renderSFCInput(
                        'Širina',
                        'AtelierGeoLatitude',
                        errorAtelierGeoLatitude,
                        handleOnAtelierGeoLatitudeChange,
                        {
                           value: AtelierGeoLatitude
                        }
                     )}
                  </div>
                  <div className='col'>
                     {html.renderSFCInput(
                        'Dužina',
                        'AtelierGeoLongitude',
                        errorAtelierGeoLongitude,
                        handleOnAtelierGeoLongitudeChange,
                        {
                           value: AtelierGeoLongitude
                        }
                     )}
                  </div>
               </div>

               <Openstreet
                  onOpenstreetClick={handleOpenstreetClick}
                  center={`${AtelierCityName},${AtelierAddress}`}
                  disable={false}
               />

               <ButtonAdd disabled={disabled} onClick={handleOnSubmit} />
               <ButtonCancel
                  onClick={() => {
                     onClose();
                  }}
               />
            </div>
         </div>
      </PopupContainer>
   );
};

export default AddAtelierAddress;
