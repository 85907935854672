import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import html from '../../../services/html';
import { loadGalleries } from '../../../store/author';
import AddNewAuthorRepresentation from './add-new-author-representation';
import parseHTML from 'html-react-parser';
import ButtonForm from './../../common/btn-form';

const AuthorRepresentation = ({ onAdd, defaultValue, disabled }) => {
   const [showAddNew, setShowAddNew] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   const dispatch = useDispatch();
   const { galleries } = useSelector((state) => state.author);

   useEffect(() => {
      setKolekcija(defaultValue);
      dispatch(loadGalleries());
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      onAdd(kolekcija);
   }, [kolekcija]); // eslint-disable-line react-hooks/exhaustive-deps

   const showHide_AddNew = () => {
      setShowAddNew(!showAddNew);
   };

   const handleAdd = (data) => {
      setKolekcija([...kolekcija, data]);
   };

   const handleRemoveFromList = (id) => {
      setKolekcija(
         kolekcija.filter((representation) => representation.id !== id)
      );
   };

   return (
      <>
         {showAddNew && (
            <AddNewAuthorRepresentation
               onAdd={handleAdd}
               onClose={showHide_AddNew}
            />
         )}
         <div className='mb-1'>
            <ButtonForm
               text='Dodaj galeriju'
               onClick={showHide_AddNew}
               disabled={kolekcija.length > 0}
            />
         </div>
         {kolekcija.length === 0 && (
            <p style={{ fontSize: '.7rem', marginTop: '1rem' }}>
               Nema unesenih galerija...
            </p>
         )}
         {kolekcija.length > 0 && (
            <div className='list-container'>
               {kolekcija.length > 0 && (
                  <table className='table table-sm table-striped'>
                     <thead className='thead-dark'>
                        <tr>
                           <th>Galerija</th>
                           <th>Datum od</th>
                           <th>Datum do</th>
                           <th>Poveznica</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {kolekcija.map((gallery) => (
                           <tr key={uuid()}>
                              {html.td(
                                 150,
                                 html.getGalleryNameById(
                                    gallery.GalleryId,
                                    galleries
                                 )
                              )}
                              {html.td(
                                 60,

                                 gallery.DateFrom.indexOf('T') > 0
                                    ? html.convert_T_Date_ToLocal(
                                         gallery.DateFrom
                                      )
                                    : html.convertDateToLocal(gallery.DateFrom)
                              )}
                              {html.td(
                                 60,
                                 gallery.DateFrom.indexOf('T') > 0
                                    ? html.convert_T_Date_ToLocal(
                                         gallery.DateTo
                                      )
                                    : html.convertDateToLocal(gallery.DateTo)
                              )}
                              {html.td(
                                 60,
                                 parseHTML(
                                    `<a href='${gallery.Url}' target='_blank'>Klikni me</a>`
                                 )
                              )}

                              {!disabled &&
                                 html.tdWithRemoveBtn(
                                    handleRemoveFromList,
                                    gallery.id,
                                    {
                                       title: `Ukloni ${html.getGalleryNameById(
                                          gallery.GalleryId,
                                          galleries
                                       )}`
                                    }
                                 )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               )}
            </div>
         )}
      </>
   );
};

export default AuthorRepresentation;
