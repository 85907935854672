import React, { useEffect, useState } from 'react';
import { pageAnimation } from './page-transition-animation';
import { authorAdd_StepsAnimation } from './animations/author-add-steps';
import AuthorPersonalInformation from '../components/author/personal-information/author-personal-information';
import { Steps } from 'antd';
import AuthorContactInformation from '../components/author/contact-information/author-contact-information';
import AuthorProfessionalActivityInformation from '../components/author/professionalactivity-information/author-professional-activity-information';
import { useDispatch, useSelector } from 'react-redux';
import {
   clearAuthorData,
   loadAuthorStaticData,
   setAuthorOrGroupEditAfterVerificationMode,
   setAuthorOrGroupEditMode
} from '../store/author';
import { toast } from 'react-toastify';
import { setToastAutoCloseInterval } from './../store/app-setup';
import AuthorRestInformation from '../components/author/rest-information/author-rest-information';
import { createAuthor } from '../services/api';
import MotionContainer from '../components/common/motion-containers/motion-container';
import AuthorEducationInformation from './../components/author/education-information/author-education-information';
import ConfirmCreateAuthor from '../components/author/confirm-create-author';
import { useHistory, withRouter } from 'react-router-dom';
import { updateAuthor, updateVerifiedAuthor } from './../services/api';
import service from '../services/created-authors-page';
import {
   authorSuccess,
   authorSuccessAfterEdit,
   authorError,
   authorErrorAfterEdit
} from './../services/confirm';
import { setConfirmData } from './../store/common';
import LoadingSpinner from '../components/common/loading-spinner';
import { ucitavamDodatnePodatke } from '../services/toast-messages';

const AuthorAddPage = (props) => {
   const { Step } = Steps;

   const [current, setCurrent] = useState(0);
   const [dataLoaded, setDataLoaded] = useState(false);

   const {
      authorData: authorEditData,
      isEditMode,
      isEditAfterVerification,
      redirectToErrorPage
   } = useSelector((state) => state.author);

   let personalInformationData = {};
   let contactInformationData = {};
   let educationLevelData = {};
   let professionalActivityData = {};
   let restInformationData = {};

   if (authorEditData) {
      personalInformationData = authorEditData.personalInformation;
      contactInformationData = authorEditData.contactInformation;
      educationLevelData = authorEditData.educationInformation;
      professionalActivityData = authorEditData.professionalInformation;
      restInformationData = authorEditData.restInformation;
   }

   const [personalInformation, setPersonalInformation] = useState(
      personalInformationData
   );
   const [contactInformation, setContactInformation] = useState(
      contactInformationData
   );
   const [educationLevel, setEducationLevel] = useState(educationLevelData);
   const [professionalActivity, setProfessionalActivity] = useState(
      professionalActivityData
   );
   const [restInformation, setRestInformation] = useState(restInformationData);

   const [initRender, setInitRender] = useState(true);

   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      return () => {
         dispatch(clearAuthorData());
         dispatch(setAuthorOrGroupEditMode(false));
         dispatch(setAuthorOrGroupEditAfterVerificationMode(false));
         toast.dismiss();
      };
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (redirectToErrorPage)
         history.push(`${process.env.PUBLIC_URL}/error-page`);
      //window.location = `${process.env.PUBLIC_URL}/error-page`;
   }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

   const state = useSelector((state) => state.author);

   useEffect(() => {
      document.title = 'Dodavanje autora';
      //provjerim postoji li na storu jedan od X predefiniranih podataka, ako ne, učitaj ih...
      if (!service.isStaticDataLoaded(state)) {
         dispatch(setToastAutoCloseInterval(15000));
         toast(ucitavamDodatnePodatke);

         dispatch(loadAuthorStaticData()).then(() => {
            toast.dismiss();
            setDataLoaded(true);
            dispatch(setToastAutoCloseInterval(3000));
         });
      } else {
         setDataLoaded(true);
      }
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const handlePersonalInformationSave = (data) => {
      setPersonalInformation(data);
      // setCurrent(1);
   };

   const handleContactInformationSave = (data) => {
      setContactInformation(data);
      // setCurrent(2);
   };

   const handleEducationInformationSave = (data) => {
      setEducationLevel(data);
      // setCurrent(3);
   };

   const handleProfessionalActivityInformationSave = (data) => {
      setProfessionalActivity(data);
      // setCurrent(4);
   };

   const handleRestInformationSave = (data) => {
      setRestInformation(data);
   };

   const allDataIsValid = () => {
      return (
         personalInformation.isValid &&
         contactInformation.isValid &&
         educationLevel.isValid &&
         professionalActivity.isValid &&
         restInformation.isValid
      );
   };

   const handleAuthorCreate = (data) => {
      setRestInformation(data);
      setCurrent(5);
   };
   const handleCancelAuthorCreate = () => {
      setCurrent(0);
   };

   const handleOnCreateAuthorButtonClick = () => {
      if (!allDataIsValid()) {
         toast.error('Nisu ispunjene sve informacije o autoru!');
         return;
      }
      sendDataToServer();
   };

   const sendDataToServer = async () => {
      const author = {
         ...personalInformation,
         ...contactInformation,
         ...educationLevel,
         ...professionalActivity,
         ...restInformation
      };

      author.AuthorTypeId = 1;
      if (isEditMode) author.AuthorId = authorEditData.AuthorId;

      dispatch(setToastAutoCloseInterval(500));

      try {
         if (isEditMode) {
            const { data: response } = isEditAfterVerification
               ? await updateVerifiedAuthor(author)
               : await updateAuthor(author);
            handleAfterServerResponse(response);
         } else {
            const { data: response } = await createAuthor(author);
            handleAfterServerResponse(response);
         }
      } catch (error) {
         console.log('error', error);
         dispatch(setToastAutoCloseInterval(3000));
         toast.error(`author-add-page: sendDataToServer() error...`, {
            onClose: () => {
               history.push(`${process.env.PUBLIC_URL}/authors`);
            }
         });
      }
   };

   const handleAfterServerResponse = (response) => {
      if (response.status === 1) {
         if (isEditMode) dispatch(setConfirmData(authorSuccessAfterEdit));
         else dispatch(setConfirmData(authorSuccess));
      } else {
         if (isEditMode) {
            authorErrorAfterEdit.subtitle = response.message;
            dispatch(setConfirmData(authorErrorAfterEdit));
         } else {
            authorError.subtitle = response.message;
            dispatch(setConfirmData(authorError));
         }
      }
      history.push(`${process.env.PUBLIC_URL}/confirm`);
   };

   //stepIndex je index komponente koja je trenutno prikazana, na kojoj se kliknulo dalje>
   const handleOnNextClick = (stepIndex) => {
      //markiraj slijedeći korak kao kliknut
      if (!clickedSteps.includes(stepIndex + 1))
         setClickedSteps([...clickedSteps, stepIndex + 1]);

      setInitRender(false);
      setCurrent(stepIndex + 1);
   };

   //  Polje gdje pratim koji koraci su do sada bili kliknuti, inicijalno je prikazan prvi korak, zato index 0 u polju
   const [clickedSteps, setClickedSteps] = useState([0]);
   const onChange = (current) => {
      //markiraj kliknuti korak kao posjećen
      if (!clickedSteps.includes(current))
         setClickedSteps([...clickedSteps, current]);

      setInitRender(false);
      setCurrent(current);
   };

   /*
    Svaka komponenta vraca svojstvi [isValid] koje je istina ukolko su se ispunila obavezna polja
    Komponente gdje to nije napravljeno se markiraju kao nedovršene

    Inicijalni prikaz prikazuje sve komponente (osim prve, inicijalno prikazane) u stanju 'wait'
    Jednom kada se na neki korak klikne on je u stanju 'process'
    Kada se sa koraka makne, ako su obavezni podaci ispunjeni stanje ce biti 'finish' u protivnom 'error'
*/

   const isValidStepIndex = (stepIndex) => {
      let isValid = false;
      switch (stepIndex) {
         case 0:
            isValid = personalInformation.isValid;
            break;
         case 1:
            isValid = contactInformation.isValid;
            break;
         case 2:
            isValid = educationLevel.isValid;
            break;
         case 3:
            isValid = professionalActivity.isValid;
            break;
         case 4:
            isValid = restInformation.isValid;
            break;
         default:
            console.log('ERROR - getCurrentStepStatus(currentStep)');
            break;
      }
      return isValid;
   };

   const getInitRenderStepStatus = (stepIndex) => {
      if (initRender && stepIndex === 0) return 'process';
      else return 'wait';
   };

   const getCurrentStepStatus = (stepIndex, currentStep) => {
      if (initRender) return getInitRenderStepStatus(stepIndex);

      if (!clickedSteps.includes(stepIndex)) return 'wait';

      return currentStep === stepIndex
         ? 'process'
         : isValidStepIndex(stepIndex)
         ? 'finish'
         : 'error';
   };

   return (
      <>
         {!dataLoaded && <LoadingSpinner text='' />}
         {dataLoaded && (
            <MotionContainer
               className='author-add-page-container'
               animationObject={pageAnimation}
            >
               <div className='author-add-page-content'>
                  <div className='author-add-page'>
                     <MotionContainer
                        className='author-add-page-steps'
                        animationObject={authorAdd_StepsAnimation}
                     >
                        <Steps current={current} onChange={onChange}>
                           <Step
                              title='Osobni podaci'
                              description='Ime, prezime, datum rođenja...'
                              status={getCurrentStepStatus(0, current)}
                           />
                           <Step
                              title='Kontakt'
                              description='Telefon, E-mail, URL...'
                              status={getCurrentStepStatus(1, current)}
                           />
                           <Step
                              title='Podaci o obrazovanju'
                              description='Obrazovanje, usavršavanje...'
                              status={getCurrentStepStatus(2, current)}
                           />
                           <Step
                              title='Profesionalno djelovanje'
                              description='Područje interesa, radno iskustvo...'
                              status={getCurrentStepStatus(3, current)}
                           />
                           <Step
                              title='Spremi podatke'
                              description='Fotografija, napomena...'
                              status={getCurrentStepStatus(4, current)}
                           />
                        </Steps>
                     </MotionContainer>
                  </div>
                  <div>
                     {current === 0 && (
                        // <AuthorPersonalInformationEdit
                        //     data={personalInformation}
                        //     onSubmit={handlePersonalInformationSave}
                        //     onNextClick={() => {
                        //         handleOnNextClick(current);
                        //     }}
                        // />
                        <AuthorPersonalInformation
                           data={personalInformation}
                           onSubmit={handlePersonalInformationSave}
                           onNextClick={() => {
                              handleOnNextClick(current);
                           }}
                        />
                     )}
                     {current === 1 && (
                        <AuthorContactInformation
                           data={contactInformation}
                           onSubmit={handleContactInformationSave}
                           onNextClick={() => {
                              handleOnNextClick(current);
                           }}
                        />
                     )}
                     {current === 2 && (
                        <AuthorEducationInformation
                           data={educationLevel}
                           onSubmit={handleEducationInformationSave}
                           onNextClick={() => {
                              handleOnNextClick(current);
                           }}
                        />
                     )}
                     {current === 3 && (
                        <AuthorProfessionalActivityInformation
                           data={professionalActivity}
                           onSubmit={handleProfessionalActivityInformationSave}
                           onNextClick={() => {
                              handleOnNextClick(current);
                           }}
                        />
                     )}
                     {current === 4 && (
                        <AuthorRestInformation
                           data={restInformation}
                           onSubmit={handleRestInformationSave}
                           onCreateAuthor={handleAuthorCreate}
                        />
                     )}
                     {current === 5 && (
                        <ConfirmCreateAuthor
                           allDataIsValid={allDataIsValid()}
                           data={personalInformation}
                           onCreate={handleOnCreateAuthorButtonClick}
                           onCancelCreate={handleCancelAuthorCreate}
                        />
                     )}
                  </div>
               </div>
            </MotionContainer>
         )}
      </>
   );
};

export default withRouter(AuthorAddPage);
