import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import AuthorRepresentation from './author-representation';
import html from '../../../services/html';
import { motion } from 'framer-motion';

const AddAuthorRepresentation = ({
   onArtworkInCollectionsChange,
   defaultValue,
   disabled
}) => {
   const [IsAuthorRepresentation, setIsAuthorRepresentation] = useState(false);
   const [kolekcija, setKolekcija] = useState([]);

   const handle_IsGalleryRepresentation = () => {
      onArtworkInCollectionsChange(kolekcija, !IsAuthorRepresentation);
      setIsAuthorRepresentation(!IsAuthorRepresentation);
   };

   const onAdd = (representations) => {
      setKolekcija(representations);
      onArtworkInCollectionsChange(
         [...representations],
         IsAuthorRepresentation
      );
   };

   useEffect(() => {
      const { IsAuthorRepresentation } = defaultValue;
      setIsAuthorRepresentation(IsAuthorRepresentation);
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className='artwork-in-collections'>
         <Checkbox
            checked={IsAuthorRepresentation}
            onChange={handle_IsGalleryRepresentation}
            disabled={disabled}
         >
            Galerijsko zastupstvo
         </Checkbox>

         <span className='ml-1'>
            {IsAuthorRepresentation && html.renderRequiredTooltip()}
         </span>

         <motion.div
            animate={{
               opacity: IsAuthorRepresentation ? 1 : 0,
               height: IsAuthorRepresentation ? '4.5rem' : 0,
               // display: IsAuthorRepresentation ? 'block' : 'none',
               transition: { duration: 0.2, display: { delay: 0.2 } }
            }}
         >
            {
               <div className='mt-2'>
                  <AuthorRepresentation
                     disabled={disabled}
                     onAdd={onAdd}
                     defaultValue={defaultValue.AuthorRepresentations}
                  />
               </div>
            }
         </motion.div>
      </div>
   );
};

export default AddAuthorRepresentation;
