import React from 'react';
import ShowTooltip from './show-tooltip';
import { userIsAdmin } from './../../services/auth';

const VerificationIndicator = ({ item }) => {
   const verificationIndicatorColor = item.IsVerified ? '#0f0' : '#f00';

   const title = item.IsVerified ? 'Verificirano' : 'Nije verificirano';

   if (!userIsAdmin()) return null;

   return (
      <ShowTooltip title={title} tooltipColor={verificationIndicatorColor}>
         <div
            style={{
               width: 20,
               height: 20,
               display: 'inline-block',
               borderRadius: 10,
               textAlign: 'center',
               backgroundColor: verificationIndicatorColor
            }}
         ></div>
      </ShowTooltip>
   );
};

export default VerificationIndicator;
