import React, { useState } from 'react';
import ShowTooltip from '../../common/show-tooltip';
import html from '../../../services/html';

import AddNewGallery from './add-new-gallery';
import { useDispatch, useSelector } from 'react-redux';
import { loadGalleries } from '../../../store/author';
import ButtonFormTooltip from './../../common/btn-form-tooltip';

const Gallery = ({ onGallerySelect }) => {
   const [showAddNew, setShowAddNew] = useState(false);
   const dispatch = useDispatch();
   const { galleries } = useSelector((state) => state.author);

   const handleCloseAddNewGallery = (e) => {
      dispatch(loadGalleries());
      setShowAddNew(false);
   };

   return (
      <>
         {showAddNew && <AddNewGallery onClose={handleCloseAddNewGallery} />}
         <div className='row'>
            <div className='col-8'>
               {html.renderSelectAnt(
                  'Galerija',
                  'Name',
                  'GalleryId',
                  'Započnite unos ili odaberite galeriju...',
                  'Ne pronalazim traženu galeriju...',
                  galleries,
                  onGallerySelect
               )}
            </div>
            <div className='col'>
               <ButtonFormTooltip
                  inline={true}
                  text='Dodaj galeriju'
                  onClick={(e) => {
                     e.stopPropagation();
                     setShowAddNew(true);
                  }}
                  tooltip='Ukoliko ne pronalazite galeriju među ponuđenim opcijama, dodajte ju sami.'
               />
            </div>
         </div>
      </>
   );
};

export default Gallery;
