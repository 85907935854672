import Joi from 'joi-browser';

export const AddNewEntitySchema = {
    LegalEntityName: Joi.string()
        .required()
        .label('Naziv institucije')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    LegalEntityAddress: Joi.string()
        .required()
        .label('Adresa')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    LegalEntityLatitude: Joi.any()
        .optional()
        .label('Latitude')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    LegalEntityLongitude: Joi.any()
        .optional()
        .label('Latitude')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
