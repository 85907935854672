import React, { useEffect, useState } from 'react';
import MotionContainer from '../components/common/motion-containers/motion-container';
import { homepageAnimation } from './animations/homepage-animation';
import { v4 as uuid } from 'uuid';
import {
    clearAuthorDetails,
    loadAuthorDetails,
    loadExhibitionDetails
} from '../store/common';
import { useDispatch, useSelector } from 'react-redux';
import html from '../services/html';
import { Image, Avatar } from 'antd';
import { imgFallback } from './author-details-service';
import { loadAuthorStaticData } from '../store/author';

import LoadingSpinner from '../components/common/loading-spinner';
import { isStaticDataLoaded } from '../services/created-authors-page';
import { detailsAnimation } from './animations/details-animation';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    ucitavamDodatnePodatke,
    ucitavamPodatke
} from './../services/toast-messages';
import { hideToastProgressBar } from '../store/app-setup';
import HomepageLink from '../components/common/homepage-link';

const AuthorGroupDetails = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [cities] = useState(null);
    const [staticDataLoaded, setStaticDataLoaded] = useState(false);
    const { common, author: authorState } = useSelector((state) => state);

    const { authorDetails: author, redirectToErrorPage } = common;
    const {
        authorAreaOfInterests: areaOfInterests,
        galleries,
        authorBibliographies
    } = authorState;

    useEffect(() => {
        if (redirectToErrorPage)
            history.push(`${process.env.PUBLIC_URL}/error-page`);
        //window.location = `${process.env.PUBLIC_URL}/error-page`;
    }, [redirectToErrorPage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        toast.dismiss();
    }, [staticDataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.title = 'Detalji grupe autora';
        const { id } = props.match.params;
        toast.info(ucitavamPodatke);
        dispatch(hideToastProgressBar(true));
        dispatch(loadAuthorDetails(id)).then(() => {
            toast.dismiss();
            if (!isStaticDataLoaded(authorState)) {
                toast(ucitavamDodatnePodatke);
                dispatch(loadAuthorStaticData()).then(() => {
                    setStaticDataLoaded(true);
                });
            } else setStaticDataLoaded(true);
        });
        return () => {
            dispatch(clearAuthorDetails());
            dispatch(hideToastProgressBar(false));
            toast.dismiss();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        GroupName,
        GroupDateOfCreation,
        ContactEmail,
        ContactPhone,
        PersonalOrGroupUrl,
        GroupDescription,
        PhotoFileContentBase64: avatar,
        SocialNetworks,
        AuthorAreaOfInterests,
        ArtworkInCollectionsDescription,
        AuthorRepresentations,
        IsAtelier,
        AtelierAddress,
        AuthorAwards,
        AuthorBibliographies,
        AuthorAssociations,
        AuthorArtworks,
        AuthorExhibitions
    } = author ? author : {};

    return (
        <>
            {!staticDataLoaded && <LoadingSpinner />}
            {staticDataLoaded && (
                <MotionContainer
                    className='author-details'
                    animationObject={homepageAnimation}
                >
                    <div className='basic-information'>
                        <div className='pic'>
                            <Avatar
                                size={100}
                                shape='circle'
                                src={avatar ? avatar : imgFallback}
                            />
                        </div>
                        <div className='txt'>
                            <div className='name'>
                                <h1>{`${GroupName}`}</h1>
                            </div>
                            <div className='address'>
                                <a href={`mailto:${ContactEmail}`}>
                                    {ContactEmail}
                                </a>
                            </div>
                        </div>
                    </div>
                    {!staticDataLoaded && <LoadingSpinner />}

                    {staticDataLoaded && (
                        <MotionContainer
                            className='details'
                            animationObject={detailsAnimation}
                        >
                            <div className='info'>
                                <h2>Osnovni podaci</h2>
                                <table className='table table-striped table-sm'>
                                    <tbody>
                                        <tr>
                                            <td className='align-middle'>
                                                Datum nastanka
                                            </td>
                                            <td className='align-middle'>
                                                {html.convert_T_Date_ToLocal(
                                                    GroupDateOfCreation
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                Telefon
                                            </td>
                                            <td className='align-middle'>
                                                {ContactPhone
                                                    ? ContactPhone
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                URL
                                            </td>
                                            <td className='align-middle'>
                                                <a
                                                    href={`{PersonalOrGroupUrl}`}
                                                >
                                                    {PersonalOrGroupUrl
                                                        ? PersonalOrGroupUrl
                                                        : '-'}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                Društvene mreže
                                            </td>
                                            <td className='align-middle'>
                                                {SocialNetworks
                                                    ? SocialNetworks
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                Kratki opis skupine
                                            </td>
                                            <td className='align-middle'>
                                                {GroupDescription
                                                    ? GroupDescription
                                                    : '-'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='info'>
                                <h2>
                                    Podaci o profesionalnom djelovanju skupine
                                </h2>
                                <table className='table table-striped table-sm'>
                                    <tbody>
                                        <tr>
                                            <td className='align-middle'>
                                                Umjetnička djela u zbirkama
                                            </td>
                                            <td className='align-middle'>
                                                {ArtworkInCollectionsDescription
                                                    ? ArtworkInCollectionsDescription
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                Galerije
                                            </td>
                                            <td className='align-middle'>
                                                {AuthorRepresentations &&
                                                    AuthorRepresentations.map(
                                                        (a) => (
                                                            <div key={uuid()}>
                                                                {`${
                                                                    galleries
                                                                        ? html.getGalleryNameById(
                                                                              a.GalleryId,
                                                                              galleries
                                                                          )
                                                                        : '-'
                                                                } (${html.convert_T_Date_ToLocal(
                                                                    a.DateFrom
                                                                )} - ${html.convert_T_Date_ToLocal(
                                                                    a.DateTo
                                                                )})`}{' '}
                                                                <a href={a.Url}>
                                                                    Više...
                                                                </a>
                                                            </div>
                                                        )
                                                    )}
                                            </td>
                                        </tr>

                                        {IsAtelier && (
                                            <tr>
                                                <td>Adresa ateljea</td>
                                                <td>
                                                    {AtelierAddress
                                                        ? AtelierAddress
                                                        : '-'}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td>
                                                Područje interesa/djelovanja
                                            </td>
                                            <td>
                                                {AuthorAreaOfInterests &&
                                                    AuthorAreaOfInterests.map(
                                                        (a) =>
                                                            html.getAreaOfInterestNameById(
                                                                a.AreaOfInterestId,
                                                                areaOfInterests
                                                            )
                                                    ).join(', ')}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className='align-middle'>
                                                Nagrade
                                            </td>
                                            <td className='align-middle'>
                                                {AuthorAwards &&
                                                    AuthorAwards.map((a) => (
                                                        <div key={uuid()}>
                                                            {`${a.Name}, ${
                                                                cities
                                                                    ? html.getCityNameById(
                                                                          a.CityId,
                                                                          cities
                                                                      )
                                                                    : '-'
                                                            } - ${a.Year}`}
                                                        </div>
                                                    ))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                Bibliografije
                                            </td>
                                            <td className='align-middle'>
                                                {AuthorBibliographies &&
                                                    AuthorBibliographies.map(
                                                        (ab) => (
                                                            <div
                                                                style={{
                                                                    margin:
                                                                        '.5rem 0'
                                                                }}
                                                                key={uuid()}
                                                            >
                                                                <table
                                                                    id='bibliography'
                                                                    className='table-borderless'
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Naziv
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    ab.Name
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Izdanje
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    ab.Edition
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Autori
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    ab.Authors
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Godina
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    ab.Year
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Potkategorija
                                                                            </td>
                                                                            <td>
                                                                                {authorBibliographies
                                                                                    ? html.getBibliographyNameById(
                                                                                          ab.BibliographyCategoryId,
                                                                                          authorBibliographies
                                                                                      )
                                                                                    : '-'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Grad
                                                                            </td>
                                                                            <td>
                                                                                {cities
                                                                                    ? html.getCityNameById(
                                                                                          ab.CityId,
                                                                                          cities
                                                                                      )
                                                                                    : '-'}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <hr />
                                                            </div>
                                                        )
                                                    )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                Članstvo u udrugama{' '}
                                            </td>
                                            <td className='align-middle'>
                                                {AuthorAssociations &&
                                                    AuthorAssociations.map(
                                                        (aa) => (
                                                            <div key={uuid()}>
                                                                {`${aa.Name}, ${
                                                                    cities
                                                                        ? html.getCityNameById(
                                                                              aa.CityId,
                                                                              cities
                                                                          )
                                                                        : '-'
                                                                } (${html.convert_T_Date_ToLocal(
                                                                    aa.DateFrom
                                                                )} - ${html.convert_T_Date_ToLocal(
                                                                    aa.DateTo
                                                                )})`}
                                                            </div>
                                                        )
                                                    )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='info'>
                                <h2>Umjetnička djela autora</h2>
                                <div className='artworks'>
                                    {AuthorArtworks &&
                                        AuthorArtworks.length === 0 && (
                                            <p>
                                                Nema umjetničkih djela vezanih
                                                uz autora...
                                            </p>
                                        )}
                                    {AuthorArtworks &&
                                        AuthorArtworks.map((a) => (
                                            <div
                                                className='artwork-container'
                                                key={uuid()}
                                            >
                                                <table className='table table-striped table-sm'>
                                                    <tbody>
                                                        <tr>
                                                            <td className='align-middle'>
                                                                Naziv
                                                            </td>
                                                            <td className='align-middle naziv'>
                                                                {a.Name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='align-middle'>
                                                                Opis
                                                            </td>
                                                            <td className='align-middle'>
                                                                {a.Description}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {a.Photographs.length === 0 && (
                                                    <p>
                                                        Nema fotografija vezanih
                                                        uz umjetničko djelo...
                                                    </p>
                                                )}
                                                {a.Photographs.length > 0 && (
                                                    <div className='artwork-photographs'>
                                                        <hr />
                                                        {a.Photographs.map(
                                                            (p) => (
                                                                <div
                                                                    className='image-container'
                                                                    key={uuid()}
                                                                >
                                                                    <Image
                                                                        width={
                                                                            100
                                                                        }
                                                                        height={
                                                                            100
                                                                        }
                                                                        src={
                                                                            p.PhotoFileContentBase64
                                                                                ? p.PhotoFileContentBase64
                                                                                : 'error'
                                                                        }
                                                                        fallback={
                                                                            imgFallback
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className='info'>
                                <h2>Izložbe autora</h2>
                                <div className='exhibitions'>
                                    {AuthorExhibitions &&
                                        AuthorExhibitions.length === 0 && (
                                            <p>
                                                Nema izložbi vezanih uz
                                                autora...
                                            </p>
                                        )}
                                    {AuthorExhibitions &&
                                        AuthorExhibitions.length > 0 && (
                                            <table className='table table-striped table-sm'>
                                                <thead>
                                                    <tr>
                                                        <th className='align-middle'>
                                                            Naziv
                                                        </th>
                                                        <th className='align-middle'>
                                                            Naziv na engleskom
                                                        </th>
                                                        <th className='align-middle'>
                                                            Godina
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AuthorExhibitions.map(
                                                        (a) => (
                                                            <tr key={uuid()}>
                                                                <td className='align-middle'>
                                                                    {a.Name}
                                                                </td>
                                                                <td className='align-middle'>
                                                                    {a.NameEn}
                                                                </td>
                                                                <td className='align-middle'>
                                                                    {a.Year}
                                                                </td>
                                                                <td className='align-middle'>
                                                                    <button
                                                                        className='btn btn-link btn-sm'
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                loadExhibitionDetails(
                                                                                    a.ExhibitionId
                                                                                )
                                                                            ).then(
                                                                                () => {
                                                                                    history.push(
                                                                                        `${process.env.PUBLIC_URL}/details-exhibition`
                                                                                    );
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        Prikaži
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                </div>
                            </div>
                            <HomepageLink />
                        </MotionContainer>
                    )}
                </MotionContainer>
            )}
        </>
    );
};

export default AuthorGroupDetails;
