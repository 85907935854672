import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const ConfirmResult = ({
    title = 'Title',
    subTitle = '...',
    status = 'success',
    btnPrimary = 'Button primary',
    btnSecondary = 'Button secondary',
    onPrimary,
    onSecondary
}) => {
    const history = useHistory();
    return (
        <div className='success'>
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={[
                    <Button
                        type='primary'
                        key='console'
                        onClick={() => {
                            history.push(
                                `${process.env.PUBLIC_URL}/${onPrimary}`
                            );
                        }}
                    >
                        {btnPrimary}
                    </Button>,
                    <Button
                        key='buy'
                        onClick={() => {
                            history.push(
                                `${process.env.PUBLIC_URL}/${onSecondary}`
                            );
                        }}
                    >
                        {btnSecondary}
                    </Button>
                ]}
            />
        </div>
    );
};

export default ConfirmResult;
