import Joi from 'joi-browser';

export const AddNewAuthorBibliographySchema = {
    Authors: Joi.string()
        .required()
        .label('Autor')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    Edition: Joi.string()
        .required()
        .label('Izdanje')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        }),
    Name: Joi.string()
        .required()
        .label('Naziv')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
