import React from 'react';
import NavbarLink from './navbar-link';
import { NavLink } from 'react-router-dom';
import Submenu from './submenus/submenu';
import { userIsAdmin } from './../services/auth';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faHome } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ user }) => {
   return (
      <>
         <Submenu />
         <motion.div
            className='navbar'
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.75, ease: 'easeOut' }}
         >
            <div className='logo'>
               <NavLink to={`${process.env.PUBLIC_URL}/homepage`}>
                  <FontAwesomeIcon icon={faHome} />
               </NavLink>
            </div>
            <div className='nav'>
               <nav>
                  {!user && (
                     <ul>
                        <li>
                           <NavbarLink
                              text='Pertraga'
                              path={`${process.env.PUBLIC_URL}/`}
                           />
                        </li>
                        <li>
                           <NavbarLink
                              text='Autori'
                              path={`${process.env.PUBLIC_URL}/homepage/authors`}
                           />
                        </li>
                        <li>
                           <NavbarLink
                              text='Umjetnička djela'
                              path={`${process.env.PUBLIC_URL}/homepage/artworks`}
                           />
                        </li>
                        <li>
                           <NavbarLink
                              text='Izložbe'
                              path={`${process.env.PUBLIC_URL}/homepage/exhibitions`}
                           />
                        </li>
                     </ul>
                  )}

                  <ul>
                     {user && (
                        <li>
                           <NavbarLink
                              text='Autori'
                              path={`${process.env.PUBLIC_URL}/authors`}
                              subpaths={[
                                 '/add-author',
                                 '/add-author-group',
                                 '/list-author'
                              ]}
                           />
                        </li>
                     )}
                     {user && (
                        <li>
                           <NavbarLink
                              text='Umjetnička djela'
                              path={`${process.env.PUBLIC_URL}/artworks`}
                              subpaths={['/add-artwork', '/list-artworks']}
                           />
                        </li>
                     )}
                     {user && (
                        <li>
                           <NavbarLink
                              text='Izložbe'
                              path={`${process.env.PUBLIC_URL}/exhibitions`}
                              subpaths={[
                                 '/add-exhibition',
                                 '/list-exhibitions'
                              ]}
                           />
                        </li>
                     )}
                     {userIsAdmin() && (
                        <li>
                           <NavbarLink
                              text='Korisnici'
                              path={`${process.env.PUBLIC_URL}/admin-list-confirmed`}
                           />
                        </li>
                     )}
                     {user && (
                        <li>
                           <NavbarLink
                              text='Pretraga'
                              path={`${process.env.PUBLIC_URL}/homepage`}
                           />
                        </li>
                     )}
                  </ul>
               </nav>
            </div>
            <div className='user'>
               {user && (
                  <div>
                     <span>
                        {user}
                        {/* <a href={`mailto:${user}`}>{user}</a> */}
                     </span>
                     <NavLink
                        to={`${process.env.PUBLIC_URL}/logout`}
                        style={{ color: '#fff' }}
                     >
                        <FontAwesomeIcon
                           style={{ fontSize: '1.3rem' }}
                           icon={faSignOutAlt}
                        />
                     </NavLink>
                  </div>
               )}
               {!user && (
                  <motion.div
                     initial={{ x: 50, opacity: 0 }}
                     animate={{ x: 0, opacity: 1 }}
                     transition={{
                        duration: 0.5,
                        delay: 1,
                        type: 'spring',
                        stiffness: 300
                     }}
                  >
                     <NavLink
                        to={`${process.env.PUBLIC_URL}/login`}
                        style={{ color: '#fff' }}
                     >
                        Prijava
                     </NavLink>
                  </motion.div>
               )}
            </div>
         </motion.div>
      </>
   );
};

export default Navbar;
