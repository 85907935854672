import Joi from 'joi-browser';

export const AddNewExhibitionAuthorSchema = {
    AuthorName: Joi.string()
        .required()
        .label('Izlagač/autor')
        .error((errors) => {
            return {
                message: `${errors[0].flags.label} je obavezan unos`
            };
        })
};
