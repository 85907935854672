import 'leaflet/dist/leaflet.css';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

// import './styles.css';
import 'leaflet/dist/leaflet.js';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.js';

import './Map.css';

import React, { useState } from 'react';
import 'leaflet/dist/leaflet.css';
import {
    MapContainer,
    TileLayer,
    useMap,
    Marker,
    Popup,
    useMapEvents
} from 'react-leaflet';
import * as ELG from 'esri-leaflet-geocoder';
//https://www.npmjs.com/package/esri-leaflet-geocoder

//https://github.com/PaulLeCam/react-leaflet/issues/453
import L from 'leaflet';
// L.Icon.Default.imagePath = 'resources/';

// import marker icons
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl:
        'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png'
});

const zoom = 14;

const Openstreet = (props) => {
    const style = {
        width: '100%',
        height: '20rem',
        margin: '.75rem 0',
        zIndex: 1
    };
    const [position, setPosition] = useState([
        45.807240000000036,
        15.967570000000023
    ]);
    const [markerposition, setMarkerPosition] = useState([
        45.807240000000036,
        15.967570000000023
    ]);

    return (
        <div
            className='openstreet'
            style={{ zIndex: 1, opacity: props.disable ? 0 : 1 }}
        >
            <MapContainer
                className='map'
                center={position}
                zoom={zoom}
                style={style}
                scrollWheelZoom={true}
            >
                <Geocoder
                    address={props.center ? props.center : 'Zagreb'}
                    setPosition={setPosition}
                />
                <MyComponent
                    setPosition={setPosition}
                    setMarkerPosition={setMarkerPosition}
                />
                <Marker position={markerposition}>
                    <Popup>Marker popup</Popup>
                </Marker>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
            </MapContainer>
        </div>
    );

    function MyComponent({ setPosition, setMarkerPosition }) {
        const bla = new ELG.geocodeService();
        const map = useMapEvents({
            click: (e) => {
                const { lat, lng } = e.latlng;
                map.setView([lat, lng], zoom);
                //map.panTo(new L.LatLng(lat, lng));
                setPosition([lat, lng]);
                setMarkerPosition([lat, lng]);

                bla.reverse()
                    .latlng(e.latlng)
                    .run(function (error, result) {
                        if (error) console.log('ERROR');
                        //console.log('QQQ', result);
                        props.onOpenstreetClick(result);
                    });
            }
        });

        //const searchControl = new ELG.Geosearch().addTo(map);
        //const results = new L.LayerGroup().addTo(map);

        return null;
    }

    function Geocoder({ address }) {
        const map = useMap();

        ELG.geocode()
            .text(address)
            .run((err, { results }, response) => {
                if (results) {
                    if (results.length === 0) return;
                    const { lat, lng } = results[0].latlng;
                    map.setView([lat, lng], zoom + 2);
                    //console.log(lat, lng);
                }
            });

        return null;
    }
};

export default Openstreet;

// useEffect(()=>{
//     const fetchCityCoordinates = async () => {
//         const result = await axios(
//           'https://hn.algolia.com/api/v1/search?query=redux',
//         );

//         setData(result.data);
//       };

//       fetchCityCoordinates();

// },[]);
