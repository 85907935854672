import React, { useEffect, useState } from 'react';
import loading from '../../resources/img/loading.png';

const Search = ({
   searchOnTyping,
   delay = 250,
   onSearch: handleOnSearch,
   isLoadingData,
   minCharacters = 1
}) => {
   // const { Search } = Input;
   const [searchTerm, setSearchTerm] = useState('');
   const [typing, setTyping] = useState(false);

   // const onSearch = (value) => {
   //     if (!value || (value.length < minCharacters && value !== '')) return;

   //     setSearchTerm(value);
   //     handleOnSearch(value);
   // };

   useEffect(() => {
      if (
         !searchOnTyping ||
         (searchTerm.length < minCharacters && searchTerm !== '')
      )
         return;
      const delayDebounceFn = setTimeout(() => {
         handleOnSearch(searchTerm);
         setTyping(false);
         // Send axios request here
      }, delay);

      return () => {
         setTyping(true);
         clearTimeout(delayDebounceFn);
      };
   }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

   return (
      <div className='search-input'>
         <input
            style={
               isLoadingData || typing
                  ? {
                       background: `url(${loading}) no-repeat scroll 7px 7px`,
                       backgroundPosition: 'center right',
                       paddingRight: '3rem'
                    }
                  : {}
            }
            autoFocus
            type='text'
            autoComplete='off'
            className='search-text'
            placeholder='Traži...'
            onChange={({ target: input }) => {
               setSearchTerm(input.value);
            }}
         />
      </div>
   );
};

export default Search;

//https://stackoverflow.com/questions/29103096/dynamic-attribute-in-reactjs
