import React from 'react';
import { NavLink } from 'react-router-dom';

const AuthorSubmenuButton = ({ path, text, icon }) => {
    return (
        <div className='author-submenu-button'>
            <NavLink to={`${process.env.PUBLIC_URL}/${path}`}>
                <div className='submenu-button-container'>
                    <div className='submenu-icon'>{icon}</div>
                    <div className='submenu-text'>{text}</div>
                </div>
            </NavLink>
        </div>
    );
};

export default AuthorSubmenuButton;
