import React, { Component } from 'react';
import html from '../../services/html';
class Textarea extends Component {
   state = {};
   inputElement = React.createRef();
   render() {
      const {
         name,
         label,
         error,
         requiredLabel,
         disabled,
         placeholder,
         ...rest
      } = this.props;

      const style = {
         marginBottom: '2px',
         fontWeight: 700,
         fontSize: '.9rem'
      };

      return (
         <div className='input'>
            <div className='form-group'>
               <label htmlFor={name} style={style} className='mr-1'>
                  {label}
               </label>
               {requiredLabel && html.renderRequiredTooltip()}
               <textarea
                  ref={this.inputElement}
                  name={name}
                  id={name}
                  className='form-control form-control-sm'
                  disabled={disabled}
                  placeholder={placeholder}
                  {...rest}
               />
               {error && (
                  <div
                     className='alert alert-primary'
                     style={{ fontWeight: 700 }}
                  >
                     {error}
                  </div>
               )}
            </div>
         </div>
      );
   }

   componentDidMount() {
      const { autoFocus } = this.props;
      if (autoFocus) this.inputElement.current.focus();
   }
}

/* autoFocus se samo doda kao atribut... bez vrijednosti... */
Textarea.defaultProps = {
   label: 'Label',
   placeholder: '',
   type: 'text',
   autoComplete: 'off',
   autoFocus: false
};
export default Textarea;
