//import axios from 'axios';
const initState = {
    data: {
        FirstName: '',
        LastName: '',
        Oib: '',
        Email: '',
        Phone: '',
        Password: '',
        isAffiliated: false
    }
};

//  ACTION CREATOR
export const updateStoreState = (data) => ({
    type: 'UPDATE_STORE_STATE',
    payload: {
        data
    }
});

//  REDUCER
export default function reducer(state = initState, action) {
    switch (action.type) {
        case 'UPDATE_STORE_STATE':
            const data = { ...state.data, ...action.payload.data };
            return { data };
        default:
            return state;
    }
}

//  ACTION CREATOR - async demo
// export const xyz = (data) => async (dispatch, getState) => {
//     try {
//        const { data: registeredUserData } = await axios.post(
//           api.registerUser,
//           user
//        );
//        const payload = {
//           user: registeredUserData
//        };
//        dispatch({ type: 'REGISTER_USER', payload });
//     } catch (ex) {
//        if (ex.response && ex.response.status === 400) {
//           console.log('EX data', ex.response?.data);
//        }
//     }
//  };
