import React, { useState } from 'react';
import html from '../../services/html';

import { useDispatch, useSelector } from 'react-redux';
import { loadCollections } from '../../store/artwork';
import AddNewCollection from './add-new-collection';
import ButtonFormTooltip from './../common/btn-form-tooltip';

const Collection = ({ onGallerySelect }) => {
   const [showAddNew, setShowAddNew] = useState(false);
   const dispatch = useDispatch();
   const { collections } = useSelector((state) => state.artwork);

   const handleCloseAddNewCollection = (e) => {
      dispatch(loadCollections());
      setShowAddNew(false);
   };

   return (
      <>
         {showAddNew && (
            <AddNewCollection onClose={handleCloseAddNewCollection} />
         )}
         <div className='row'>
            <div className='col-9'>
               {html.renderSelectAnt(
                  'Zbirka',
                  'Name',
                  'CollectionId',
                  'Započnite unos ili odaberite zbirku...',
                  'Ne pronalazim traženu zbirku...',
                  collections,
                  onGallerySelect
               )}
            </div>
            <div className='col'>
               <ButtonFormTooltip
                  inline={true}
                  text='Dodaj'
                  onClick={(e) => {
                     e.stopPropagation();
                     setShowAddNew(true);
                  }}
                  tooltip='Ukoliko ne pronalazite zbirku među ponuđenim opcijama, dodajte ju sami.'
               />
            </div>
         </div>
      </>
   );
};

export default Collection;
