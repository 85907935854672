//provjeri jesu li na store učitani svi podaci
export const isCommonDataLoaded = (state) => {
    const { sex, authors, countries } = state;

    return sex.length > 0 && countries.length > 0 && authors.length > 0;
};

const exportObj = {
    isCommonDataLoaded
};

export default exportObj;
